import { FC } from 'react';
import { Checkbox, FormControl, FormLabel, InputLabel, Select, MenuItem } from '@mui/material';
import { DropdownFilterConfig } from '../../list-filter.component';
import { useStyles } from './list-filter-dropdown.style';

type ListFilterDropdownProps = {
  config: DropdownFilterConfig;
  value?: string[];
  onChange: (value: string[]) => void;
};

export const ListFilterDropdown: FC<ListFilterDropdownProps> = ({ config, value = [], onChange }) => {
  const { classes } = useStyles();
  return (
    <>
      <FormLabel className={classes.formLabel}>{config.title}</FormLabel>
      <FormControl fullWidth className={classes.dropdownField} margin="dense" variant="outlined">
        <InputLabel id="dropdown-label" className={classes.inputLabel}>
          {config.inputLabel}
        </InputLabel>
        <Select
          multiple
          size="medium"
          color="secondary"
          labelId="dropdown-label"
          label={config.inputLabel}
          value={value || []}
          onChange={(e) => {
            onChange(e.target.value as string[]);
          }}
        >
          {config.items.map((item) => (
            <MenuItem className={classes.menuItem} key={`dropdown-choice-${item.itemKey}`} value={item.itemKey}>
              <Checkbox checked={value && value.indexOf(item.itemKey) !== -1} />
              {item.itemLabel}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
