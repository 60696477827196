import { sortHelper } from 'src/app/common/utils/display.util';
import { getCampaignList } from 'src/app/modules/Leads/network/leadsCrud';
import { TabValues } from 'src/app/modules/Leads/types/types';

type ResponseType = {
  campaignList: Array<{ itemKey: string; itemLabel: string }>;
  sourceList: Array<{ itemKey: string; itemLabel: string }>;
};

export const getEligibleCampaignsFilter = async (
  tab: TabValues,
  dispatch: React.Dispatch<any>,
): Promise<ResponseType> => {
  const res = await getCampaignList({ tab, isTalkbotEligible: true }, dispatch);
  if (res) {
    const campaignList = res.map(({ name, _id }) => ({ itemLabel: name, itemKey: _id }));
    campaignList.sort((a, b) => sortHelper(a, b, 'itemLabel'));
    const sourceList = res
      ?.filter((item) => item.source)
      ?.map(({ source }) => ({ itemKey: source!._id, itemLabel: source!.sourceName }));
    sourceList.sort((a, b) => sortHelper(a, b, 'itemLabel'));

    return {
      campaignList,
      sourceList: sourceList.filter(
        ({ itemKey }, index) => sourceList.findIndex((i) => i.itemKey === itemKey) === index,
      ),
    };
  }

  return { campaignList: [], sourceList: [] };
};
