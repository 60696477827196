import React, { memo } from 'react';
import { useCommonLeadList } from './common-lead-list.hook';
import {
  FilterTableContainer,
  FilterTableContainerProps,
} from 'src/app/common/components/filter-table-container/filter-table-container.component';
import { Lead } from 'src/app/modules/Leads/types/types';
import { ActionItems } from '../action-items/action-items.component';

type ComponentProps = {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  onLoad?: (
    record: Lead[],
    pageInfo?: {
      page?: number;
      pageSize?: number;
      total?: number;
    },
  ) => void;
} & Pick<FilterTableContainerProps<Lead, any>, 'getData' | 'getColumns' | 'ActionNode' | 'rowSelection' | 'actionRef'>;
export const CommonLeadList: React.FC<ComponentProps> = memo(
  ({ getData, getColumns, cacheParams, ActionNode, rowSelection, actionRef, onLoad }: ComponentProps) => {
    // custom-hook
    const { onClickRow, defaultRowSelection } = useCommonLeadList();

    return (
      <FilterTableContainer
        actionRef={actionRef}
        cacheParams={cacheParams}
        rowKey={'_id'}
        getData={getData}
        getColumns={getColumns}
        ActionNode={ActionNode ?? ActionItems}
        onClickRow={onClickRow}
        rowSelection={rowSelection ?? defaultRowSelection}
        showFilter={false}
        onLoad={onLoad}
      />
    );
  },
);
