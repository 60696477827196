import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getExportList } from '../../../network/talkbot-export-history-crud';

export const useList = () => {
  // redux
  const dispatch = useDispatch();

  const getData = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ) => {
      const { page, pageSize } = params;
      let sortBy = 'date:-1';
      if (sort && Object.keys(sort).length > 0) {
        const [key, val] = Object.entries(sort)[0];
        sortBy = val === 'desc' ? `${key}:-1` : `${key}:1`;
      }
      const res = await getExportList({ page: page + 1, limit: pageSize, sortBy }, dispatch);
      const { docs, totalDocs } = res ?? {};
      return {
        success: true,
        data: docs ?? [],
        total: totalDocs ?? 0,
      };
    },
    [],
  );

  return {
    getData,
  };
};
