import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '24px',
    marginBottom: '24px',
    '& > .Mui-focused fieldset': {
      borderColor: 'rgba(145, 158, 171, 0.32) !important', // enhance
    },
  },
  searchIcon: { color: '#919EAB', fontSize: 24 },
  clearIcon: { width: '24px', height: '24px' },
  input: { height: '24px', lineHeight: '24px', fontSize: 16, fontWeight: 400 },
  inputContainer: {
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: 'rgba(145, 158, 171, 0.32)',
    width: '527px',
  },
  filterIconButtonContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  filterIconButtonText: { fontSize: 16, fontWeight: 400, marginLeft: '10px' },
  mb24: { marginBottom: '24px' },
  filters: { marginBottom: '24px', alignItems: 'center', display: 'flex', flexWrap: 'wrap' },
  filterText: { fontSize: 16, fontWeight: 500, display: 'inline-block', marginRight: '12px' },
  mr12: {
    marginRight: '12px',
  },
  chipsContainer: {
    display: 'inline-block',
  },
  ml4: {
    marginLeft: '4px',
  },
  filterResultText: { fontSize: 16, fontWeight: 400 },
  deleteIcon: { color: '#E8192C', width: '18px' },
  deleteText: { color: '#E8192C', fontSize: 16, fontWeight: 400, marginLeft: '10px' },
  filterIcon: { color: '#E8192C', width: '18px' },
  highlight: {
    color: '#000000',
  },
}));
