import { BDMdashboardName, TopDashboardDetail } from './dashboard-types';

export const BDMdashboardDataUat = '6364a40a-0ffb-4da4-8fd2-4f791c6cd778';

//please revise all dashboardLeft,dashboardRight,chartID into uat
//notice :
//'Both' -> both are numbers
//'Among' -> one number and one table
//'Unique' -> only one table

export enum LostReportTop {
  type = 'Both',
  dashboardLeft = '628c44e1-1caa-42f0-8bc4-1512e01ab3b5',
  dashboardRight = '628c44e1-1caa-462f-87e4-1512e01ab3b3',
}

export enum SubmissionReportTop {
  type = 'Both',
  dashboardLeft = '62e7629f-8e95-4240-8bcb-c2bb67cacaa0',
  dashboardRight = '62e7629f-8e95-4e8d-8cd2-c2bb67caca9c',
}

export enum SubsourceTop {
  type = 'Both',
  dashboardLeft = '62b269e6-b622-4792-86cc-e9ffaba428b3',
}

export enum AssignedTodayTop {
  type = 'Both',
  dashboardLeft = '628c4645-3ceb-407c-8baa-b52ae38cf441',
}

export enum WeekCreateTop {
  type = 'Both',
  dashboardLeft = '628c4698-0b13-46df-86d4-94ce59f37183',
}

export enum AverageTop {
  type = 'Both',
  dashboardLeft = '62b26a7b-2b11-459b-8dcc-6d176c490089',
}

export enum StatusTop {
  type = 'Among',
  dashboardLeft = '628c45ef-b3f9-4efa-804d-6191e58a6b4d',
  dashboardRight = '628c45ef-b3f9-4855-870f-6191e58a6b49',
}

export enum PerformanceTop {
  type = 'Among',
  dashboardLeft = '628c4534-b3f9-48c8-8c92-6191e58a1f8e',
  dashboardRight = '628c4534-b3f9-4912-8f76-6191e58a1f8c',
}

export enum LostReasonTop {
  type = 'Unique',
  dashboardLeft = '63060e5c-6dc9-488f-8fed-95757e40d86e',
}

export const TopDashboardsUat: Map<string, any> = new Map<BDMdashboardName, TopDashboardDetail>([
  [BDMdashboardName.LOSTREPORT, LostReportTop],
  [BDMdashboardName.SUBMIISSIONREPORT, SubmissionReportTop],
  [BDMdashboardName.SUBSOURCE, SubsourceTop],
  [BDMdashboardName.ASSIGNEDTODAY, AssignedTodayTop],
  [BDMdashboardName.WEEKCREATE, WeekCreateTop],
  [BDMdashboardName.AVERAGE, AverageTop],
  [BDMdashboardName.STATUS, StatusTop],
  [BDMdashboardName.SOURCE, PerformanceTop],
  [BDMdashboardName.LOSTREASON, LostReasonTop],
]);

export const DashboardLibraryUat = {
  report: [
    {
      chartName: 'List of Participants',
      chartID: '62c3ed9b-6bc4-4a84-855d-15b909d5682e',
      chartType: 'Table',
    },
    {
      chartName: 'List of Campaigns',
      chartID: '62c3ed9b-6bc4-4bba-86dc-15b909d5682a',
      chartType: 'Table',
    },
    {
      chartName: 'List of Leads',
      chartID: '62c3ed9b-6bc4-485b-8d22-15b909d5682c',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Lost Report',
      chartID: 'd66ee20f-1438-4271-aee1-a7eeeb66186d',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Lost Report',
      chartID: '628c44e1-1caa-462f-87e4-1512e01ab3b3',
      chartType: 'Chart',
    },
    {
      chartName: 'Appointment to Submission Report',
      chartID: '2b3d05e2-dd6c-44ea-ab1c-a0ac6e8b8414',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Submission Report',
      chartID: '62e7629f-8e95-4e8d-8cd2-c2bb67caca9c',
      chartType: 'Chart',
    },
    {
      chartName: 'Assigned to Call Report',
      chartID: '63060d84-fd14-43d8-8d16-17b14254ff69',
      chartType: 'Chart',
    },
    {
      chartName: 'Assigned to Call Report',
      chartID: '87ca498e-e19a-40b0-b96e-3dfdb4609588',
      chartType: 'Table',
    },
    {
      chartName: 'Top Consultants by Lead Capture',
      chartID: '63060dc3-e486-4149-8feb-2cc9ce9d5e29',
      chartType: 'Chart',
    },
    {
      chartName: 'Top Consultants by Lead Capture',
      chartID: '3fa4ceeb-7514-41e1-8c2b-c42ffa1292a4',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Pending Call',
      chartID: 'ea840205-b945-43dd-8050-fe4a73c429d6',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Pending Call',
      chartID: '62f45d2d-2b22-436a-8195-949b797df523',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Aging Report - Engagement',
      chartID: 'a8acb882-9e9a-440b-aec7-1dd8207810d6',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Engagement',
      chartID: '6303427c-df9a-48f8-8a01-687fa35f68d8',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Aging Report - Submission',
      chartID: 'a0e4089d-cab4-4fdd-b6c3-094d8d4e19b8',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Submission',
      chartID: '628c4619-1caa-4f8f-8d86-1512e01b5465',
      chartType: 'Chart',
    },
    {
      chartName: 'Cumulative Leads Aging Report',
      chartID: 'fa806091-4528-4a3d-b497-6eb1bee91259',
      chartType: 'Table',
    },
    {
      chartName: 'Cumulative Leads Aging Report',
      chartID: '62e762d6-e5b8-466b-867b-c87aeb2929ec',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Report by Sub Source',
      chartID: 'ab0edde0-cb50-413f-b421-e6844aa55249',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Report by Sub Source',
      chartID: '62b269e6-b622-49e4-8949-e9ffaba428af',
      chartType: 'Chart',
    },
    {
      chartName: 'Lead Stage Report',
      chartID: '37d4672b-e751-4ee2-9d91-4715a8f30e28',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Stage Report',
      chartID: '628c45c4-ea37-4791-859d-740bc83913d5',
      chartType: 'Chart',
    },
    {
      chartName: 'Dropped-Off Report',
      chartID: 'bf491794-69ae-4677-9b4c-ddd83708a4d8',
      chartType: 'Table',
    },
    {
      chartName: 'Dropped-Off Report',
      chartID: '628c4586-100c-471b-858b-8ff55b6eaa46',
      chartType: 'Chart',
    },
    {
      chartName: 'Lead Status Report by Agent',
      chartID: '628c45ef-b3f9-495c-8ce8-6191e58a6b4b',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Status Report by Agent',
      chartID: '628c45ef-b3f9-4bd9-8d84-6191e58a6b47',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Create Per Week',
      chartID: 'd6b4beaf-fffc-4f20-8a91-a9d9b092c479',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Create Per Week',
      chartID: '628c4698-0b13-46e0-82cd-94ce59f3717f',
      chartType: 'Chart',
    },
    {
      chartName: 'Unaccepted Leads by Consultants',
      chartID: '63060de1-6dc9-4853-8138-95757e40a784',
      chartType: 'Chart',
    },
    {
      chartName: 'Unaccepted Leads by Consultants',
      chartID: 'ea63f424-d4df-461f-96dd-81b217973849',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Lost Reason Report',
      chartID: '63060e5c-6dc9-4ebe-8e0a-95757e40d86c',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Lost Reason Report',
      chartID: '63060e5c-6dc9-4209-83d2-95757e40d86a',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Assigned Today by Source',
      chartID: '628c4645-3ceb-4244-847f-b52ae38cf443',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Assigned Today by Source',
      chartID: '628c4645-3ceb-485d-8c41-b52ae38cf43f',
      chartType: 'Chart',
    },
    {
      chartName: 'Average Conversion Time Report',
      chartID: '62b26a7b-2b11-4430-894a-6d176c49008b',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days',
      chartID: 'f876d5e7-850f-4bc9-8eb8-45ac40bd4c6d',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days',
      chartID: '62b26a7b-2b11-459b-8dcc-6d176c490089',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion Performance Report by Source',
      chartID: '6d482232-19d0-4a1b-baeb-d25b6a2dece1',
      chartType: 'Table',
    },
    {
      chartName: 'Conversion Performance Report by Source',
      chartID: '628c4534-b3f9-47d4-8d0f-6191e58a1f8a',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion performance by Lead Assigned Date',
      chartID: '630446cc-cd9c-447e-8c70-a8124aa04c31',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion performance by Lead Assigned Date',
      chartID: '7778344e-6401-496c-9718-abc86b479792',
      chartType: 'Table',
    },
    {
      chartName: 'Conversion Performance by Last Updated Date',
      chartID: '63060d6f-3efe-4d3a-8f37-ef3e40082a46',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion Performance by Last Updated Date',
      chartID: '05fb72a2-8d45-45f1-8756-5930d582c67a',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days by Consultants',
      chartID: 'be94000f-59a4-4736-aa36-848b28d2c37d',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days by Consultants',
      chartID: '63060daa-09a6-40a7-8d14-b2af48cf28c1',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Create Per Week - phase 2',
      chartID: '63060d3e-0989-4d49-8304-795bb4e5dae2',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Create Per Week - phase 2',
      chartID: '63060d3e-0989-42f9-8362-795bb4e5dae4',
      chartType: 'Table',
    },
  ],
};
