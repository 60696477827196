import { Columns, TabValues } from '../Leads/types/types';

export const AI_TALKBOT_TITLE = 'title.talkbot';
export const AI_TALKBOT_EXPORT_LEADS_TITLE = 'title.talkbot.export_leads';
export const AI_TALKBOT_EXPORT_LEADS_LIST_TITLE = 'title.talkbot.export_leads';
export const AI_TALKBOT_EXPORT_LEADS_AUTO_DROP_LIST_TITLE = 'lead.autoDrop.list.header';
export const AI_TALKBOT_EXPORT_HISTORY_TITLE = 'title.talkbot.export_history';
export const AI_TALKBOT_EXPORT_DETAIL_TITLE = 'title.talkbot.export_details';

export const AI_TALKBOT_BASE_PATH = '/talkbot';
export const AI_TALKBOT_EXPORT_LEADS_PATH = '/export-leads';
export const LIST_PATH = '/list';
export const AI_TALKBOT_EXPORT_HISTORY_PATH = '/export-history-list';
export const DETAIL_PATH = '/detail';

export const AI_TALKBOT_NAMESPACE = 'TALKBOT';
export const AI_TALKBOT_EXPORT_LEADS_NAMESPACE = 'TALKBOT-EXPORT_LEADS';
export const AI_TALKBOT_EXPORT_HISTORY_NAMESPACE = 'TALKBOT-EXPORT_HISTORY';

export const LIST_DEFAULT_PARAMS = () => ({
  selectedTab: TabValues.opportunities,
  [TabValues.opportunities]: {
    sorter: { [Columns.updatedAt]: 'desc' },
  },
  [TabValues.followUp]: {
    sorter: { [Columns.updatedAt]: 'desc' },
  },
});
