import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Dialog, DialogTitle } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './participant-add-list.style';
import { useParticipantAddList } from './participant-add-list.hook';
import ProFilterTable from 'src/app/common/components/ProTable';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components/pro-search-filter/pro-search-filter.component';
import { ParticipantEligibilityComponent } from '../participant-eligibility/participant-eligibility.component';
import { AgentItem, CampaignStructureItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { FullScreenDialog } from 'src/app/common/components/Dialog/full-screen-dialog/full-screen-dialog.component';

type ComponentProps = ParamsProps & {
  campaignObjId?: string;
  campaignTypeStructureData: CampaignStructureItem;
  doneImport: (data: AgentItem[]) => void;

  fullScreenDialogTitle: string;
  fullScreenDialogOpen: boolean;
  onClose: () => void;
};

export const ParticipantAddListComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    columns,
    getData,
    onFilterChange,
    toggleECheckDialog,
    setToggleECheckDialog,
    formState,
    filterState,
    onImportFromList,
    setSelectedRows,
    removeAndProceed,
    onPreviousFromFailImport,
  } = useParticipantAddList(props);
  const { fullScreenDialogOpen, fullScreenDialogTitle, onClose } = props;
  return (
    <FullScreenDialog
      title={fullScreenDialogTitle}
      open={fullScreenDialogOpen}
      onClose={() => {
        onClose();
      }}
      confirmBtnText={Translation('app.button.import')}
      onConfirm={onImportFromList}
    >
      <div className={styles.tableContainer}>
        <ProFilterTable
          rowKey="agentCode"
          columns={columns}
          request={getData}
          enableRefresh={false}
          Filter={ProSearchFilter}
          showFilterIcon={false}
          filterState={filterState}
          onFilterChange={onFilterChange}
          rowSelection={{
            onChange: (rows) => {
              setSelectedRows(rows);
            },
            getCheckboxProps: (record, selectRows) => {
              return {};
            },
          }}
        />
        <Dialog
          open={toggleECheckDialog}
          onClose={() => {
            setToggleECheckDialog(false);
          }}
          maxWidth="md"
        >
          <DialogTitle className={styles.dialogTitle}>
            {Translation('agencyCampaign.create.eligibilityCheck')}
          </DialogTitle>
          <ParticipantEligibilityComponent
            dataList={formState.failImportList}
            onCancel={async () => {
              await onPreviousFromFailImport();
              setToggleECheckDialog(false);
            }}
            onFinish={() => onClose()}
            onProcess={() => removeAndProceed()}
          />
        </Dialog>
      </div>
    </FullScreenDialog>
  );
});
