import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip, Typography } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './prospect-category.style';
import { useProspectCategory } from './prospect-category.hook';

export type ProspectCategoryProps = {
  id: string;
  name: string;
  rawType: string;
  type: string;
  description: string;
  isDefault: boolean;
  targetNumber: number;
  targetFrequency: number;
  color: string;
  backgroundColor: string;
};

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  size?: string;
  data: ProspectCategoryProps;
  showDetail?: boolean;
};

export enum SIZE_TYPE {
  'small' = 'small',
  'medium' = 'medium',
  'large' = 'large',
}

enum LABEL_SIZE {
  'small' = 40,
  'medium' = 52,
  'large' = 72,
}

enum LABEL_TEXT_SIZE {
  'small' = 24,
  'medium' = 24,
  'large' = 34,
}

export const ProspectCategoryDetail = ({ data }: { data: ProspectCategoryProps }) => {
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  return (
    <Tooltip title={data.description ?? ''}>
      <div className={styles.detailBox}>
        <Typography className={styles.detailTitle}>{data.name}</Typography>
        <Typography className={styles.detailDesc}>{data.description}</Typography>
      </div>
    </Tooltip>
  );
};

export const ProspectCategoryLabel = (props: ComponentProps) => {
  const { data, size = SIZE_TYPE.medium } = props;
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const { color, backgroundColor } = data;
  const labelSize = LABEL_SIZE[size as SIZE_TYPE];
  const labelTextSize = LABEL_TEXT_SIZE[size as SIZE_TYPE];

  return (
    <div
      className={styles.label}
      style={{ backgroundColor: backgroundColor, width: labelSize, height: labelSize, flexBasis: labelSize }}
    >
      <Typography style={{ fontSize: labelTextSize, color: color }}>
        {data.type !== 'None' ? data.type : '?'}
      </Typography>
    </div>
  );
};

export const ProspectCategory: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { showDetail } = props;
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <div className={styles.container}>
      <ProspectCategoryLabel {...props} />
      {showDetail && (
        <div className={styles.detailWrap}>
          <ProspectCategoryDetail data={props.data} />
        </div>
      )}
    </div>
  );
});
