import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { RequestData } from 'src/app/common/components/ProTable';
import { AgentItem, CampaignStructureItem, ImportVerify } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { fetchDownlineList, verifyNonDownlineList } from 'src/app/modules/AgencyCampaign/network/campaignCurd';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';
import { getColumns } from './util/participant-add-list.util';
import { getDisplayName } from 'src/app/common/utils/common-utils';

interface HookProps {
  campaignObjId?: string;
  campaignTypeStructureData: CampaignStructureItem;
  doneImport: (data: AgentItem[]) => void;
}

interface ImportItem {
  agentCode: string;
  businessName?: string;
  unitCode?: string;
  unit?: string;
  reason?: string;
}

type ManualUpdateFormState = {
  filename?: string;
  participantListfromFile: any[];
  participantListFormatError: string[];
  successImportList: AgentItem[];
  failImportList: AgentItem[];
  failEligibilityList: AgentItem[];
  successEligibilityList: AgentItem[];
  importedAgentList: AgentItem[];
};

type ManualUpdateFormAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ManualUpdateFormState;
    value: any;
  };
};

const formReducer = (state: ManualUpdateFormState, action: ManualUpdateFormAction): ManualUpdateFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const initialState: any = {
  participantListfromFile: [],
  participantListFormatError: [],
  successImportList: [],
  failImportList: [],
  failEligibilityList: [],
  successEligibilityList: [],
  importedAgentList: [],
};

const defaultParam = (): FilterState => {
  return { filterState: { page: 0, keyword: '', pageSize: 20 } };
};

export const useParticipantAddList = ({ campaignObjId, campaignTypeStructureData, doneImport }: HookProps) => {
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);

  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const [filterState, setFilterState] = useState<FilterState>(defaultParam());
  const [toggleECheckDialog, setToggleECheckDialog] = useState(false);
  const [formState, formDispatch] = useReducer(formReducer, initialState);
  const [selectedRowsState, setSelectedRows] = useState<AgentItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const participantSectionTypeStructureData = campaignTypeStructureData.sections.filter(
    (item) => item.key === 'participant',
  )[0];
  const participantNameRules =
    participantSectionTypeStructureData.sectionFields.filter((item) => item.key === 'participantName').length > 0
      ? participantSectionTypeStructureData.sectionFields.filter((item) => item.key === 'participantName')[0].rules
      : [];
  const eligibilityRules: string[] =
    participantNameRules.filter((item) => item.key === 'eligibility').length > 0
      ? participantNameRules.filter((item) => item.key === 'eligibility')[0].value
      : [];
  const needEligibilityCheck = (eligibilityRules as string[]).length > 0;

  const getData = useCallback(async (): Promise<RequestData<AgentItem>> => {
    const res = await fetchDownlineList(
      {
        page: filterState.filterState?.page + 1,
        limit: filterState.filterState?.pageSize,
        search: filterState.filterState?.keyword,
      },
      sortKey,
      dispatch,
    );
    if (res) {
      const { docs, totalDocs, ...rest } = res;
      return {
        success: true,
        data: docs ?? [],
        ...rest,
        total: totalDocs,
      };
    }
    return { success: false, data: [], total: 0 };
  }, [dispatch, filterState, sortKey]);

  const columns = useMemo(
    () => getColumns({ t: Translation, keyword: filterState.filterState?.keyword }),
    [Translation, filterState.filterState?.keyword],
  );

  const checkEligibility = async (
    importList: AgentItem[],
    campaignTypeStructureData: any,
    campaignObjId: any,
    eligibilityRules: any,
    dispatch: any,
    lang: any,
  ): Promise<{ failList: any[]; successList: any[] }> => {
    const importListAgentList = importList.map((item) => item.agentCode);
    const campaignTypeObjId = campaignTypeStructureData._id;
    const param: ImportVerify = {
      agentCodeArr: importListAgentList,
      campaignID: campaignObjId,
      campaignTypeID: campaignTypeObjId,
      rules: eligibilityRules,
    };
    let { success = [], fail = [] } = await verifyNonDownlineList(param, dispatch);
    const arrFailedDisplay: ImportItem[] | undefined = fail?.map((item) => {
      const arrSource: ImportItem[] = importList.filter((sourceItem) => sourceItem.agentCode === item.agentCode);
      item.businessName = getDisplayName(arrSource[0], lang);
      item.unitCode = arrSource[0]?.unit;
      return item as ImportItem;
    });
    const arrSuccess: ImportItem[] | undefined = success?.map((item) => {
      const arrSource: ImportItem[] = importList.filter((sourceItem) => sourceItem.agentCode === item.agentCode);
      return arrSource[0];
    });
    return { failList: arrFailedDisplay, successList: arrSuccess };
  };

  const proceedEligibility = async (importedList: AgentItem[]) => {
    setLoading(true);
    const { successList, failList } = await checkEligibility(
      importedList,
      campaignTypeStructureData,
      campaignObjId,
      eligibilityRules,
      dispatch,
      locale,
    );

    setLoading(false);

    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'failImportList', value: failList || [] } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'importedAgentList', value: successList || [] } });
    if (!failList || failList.length <= 0) {
      doneImport(importedList);
    }
  };

  const onImportFromList = () => {
    if (needEligibilityCheck) {
      proceedEligibility(selectedRowsState);
    } else {
      doneImport(selectedRowsState);
    }
  };

  const removeAndProceed = async () => {
    doneImport(formState.importedAgentList);
  };

  const onFilterChange = useCallback((filterState: FilterState) => {
    const sorter = filterState.sorter;
    if (typeof sorter === 'object') {
      Object.entries(sorter).map((item) => setSortKey([{ key: item[0], value: item[1] as string }]));
    }
    setFilterState(filterState);
  }, []);

  const onPreviousFromFailImport = () => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'failImportList', value: [] } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'successImportList', value: [] } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'importedAgentList', value: [] } });
  };

  useEffect(() => {
    setToggleECheckDialog(formState.failImportList && formState.failImportList.length > 0);
  }, [formState]);
  return {
    columns,
    toggleECheckDialog,
    formState,
    filterState,
    getData,
    onFilterChange,
    setToggleECheckDialog,
    onImportFromList,
    setSelectedRows,
    removeAndProceed,
    onPreviousFromFailImport,
  };
};
