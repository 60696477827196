import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { localeMapToBackend } from 'src/app/modules/AgentProfile/common/utils/locale-utils';
import { useForm } from 'src/app/common/components/Form';
import { fetchProspectCategories, postProspect } from 'src/app/modules/Prospect/network/prospectCurd';
import { formateProsectCategory } from 'src/app/modules/Prospect/util/common.util';
import { APP_CONFIG, getAppConfigs } from 'src/app/common/utils';
import { prospectValidator } from 'src/app/modules/Prospect/util/prospectValiade';
import { PruToast } from 'src/app/common/components/pru-toast';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { useHistory } from 'react-router-dom';
import { PROSPECT_BASE_PATH } from 'src/app/modules/Prospect/constants';
import { ProspectTypeEnum } from 'src/app/modules/Prospect/types/prospect-types';

type ProspectCategoryProps = {
  id: string;
  name: string;
  rawType: string;
  type: string;
  description: string;
  isDefault: boolean;
  targetNumber: number;
  targetFrequency: number;
  color: string;
  backgroundColor: string;
};

type Region = {
  code: string;
  displayName: string;
  regex: string;
};

const emailRegex = /^[\w.+\-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

const TranslationMap: any = {
  firstName: 'prospect.first_name',
  lastName: 'prospect.last_name',
  regionCode: 'prospect.regionCode',
  phoneNumber: 'prospect.phone_number',
  email: 'prospect.email_address',
  referredBy: 'prospect.referred_by',
};

export const useAddNewProspect = () => {
  const [form] = useForm();
  const intl = useIntl();
  const history = useHistory();

  const Translation = (key: string, values?: Record<string, any>) => intl.formatMessage({ id: key }, values);
  const locale = useLang();
  const currentLang = locale;

  const [selectedCatagory, setSelectedCatagory] = useState('');

  const [prospectCategories, setProspectCategories] = useState<ProspectCategoryProps[]>([]);
  const [showReferrby, setShowReferrby] = useState(false);
  const [selectedReferrBy, setSelectedReferrBy] = useState<any>();

  const [region, setRegion] = useState<Array<Region>>([]);
  const [selectedRegionCode, setSelectedRegionCode] = useState('');
  const [filedError, setFiledError] = useState<any>({});

  const { user } = useSelector((state: any) => state.auth);

  // // redux
  const dispatch = useDispatch();

  const { data, error, loading, run, runAsync } = useRequest(() => fetchProspectCategories(locale, dispatch), {
    manual: false,
  });

  const requiredFields: any = useMemo(() => {
    return prospectValidator.getRequiredFields();
  }, []);

  const onSelectReferrBy = useCallback((value: any) => {
    setSelectedReferrBy(value[0]);
    setShowReferrby(false);
  }, []);

  const onConfirm = useCallback(() => {
    const formModel = form.getFieldsValue();
    const { firstName, lastName, regionCode, phoneNumber, email, prospectCategory } = formModel;
    if (selectedReferrBy) {
      formModel.referredBy = selectedReferrBy._id ?? selectedReferrBy.id;
    }

    const errors: any = {};

    //mandatory fields check
    Object.keys(requiredFields).forEach((key) => {
      if (requiredFields[key] && isEmpty(formModel[key])) {
        errors[key] = { error: Translation('prospect.is_required_field', { filed: Translation(TranslationMap[key]) }) };
        // hasRequiredError = true;
        // return;
      }
    });

    //validate phone number
    if (!isEmpty(regionCode) && !isEmpty(phoneNumber)) {
      const regionRegex = region.find((item) => item.code === regionCode)?.regex;
      if (regionRegex) {
        const regex = new RegExp(regionRegex);
        if (!regex.test(phoneNumber)) {
          errors['phoneNumber'] = { error: Translation('prospect.invalid_phone_number') };
        }
      }
    }

    if (!isEmpty(email)) {
      if (!emailRegex.test(email)) {
        errors['email'] = { error: Translation('prospect.invalid_email_address') };
      }
    }

    setFiledError(errors);
    if (!isEmpty(errors)) {
      return;
    }

    const newProspect = {
      firstName,
      lastName,
      regionCode,
      phoneNumber,
      email,
      prospectCategory: selectedCatagory,
      referredBy: selectedReferrBy?._id ?? '',
      types: [ProspectTypeEnum.salesNonCustomer],
    };

    postProspect(newProspect)
      .then((res) => {
        history.push(PROSPECT_BASE_PATH);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: Translation('prospect.import_successfully'),
              content: '',
            },
          ]),
        );
      })
      .catch((err) => {
        if (err && err.code === 'conflict') {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: Translation('prospect.import_failed'),
                content: Translation('prospect.prospect_conflict'),
              },
            ]),
          );
        } else {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: Translation('common.serverError'),
                content: '',
              },
            ]),
          );
        }
      });
  }, [region, selectedCatagory, selectedReferrBy]);

  useEffect(() => {
    if (data && data.prospectCategories) {
      const categorys = formateProsectCategory(data.prospectCategories, currentLang, intl);
      const result = categorys.sort((a, b) => a.type.localeCompare(b.type));
      if (result.length > 0) {
        setSelectedCatagory(result[0].id);
        form.setFieldValue('category', result[0].id);
      }
      setProspectCategories(result);
    }
  }, [data]);

  useEffect(() => {
    const reigonList = getAppConfigs(APP_CONFIG.GLOBAL_REGION).fullRegionList ?? [];
    if (reigonList) {
      const arr: Region[] = [];
      Object.keys(reigonList).forEach((key) => {
        arr.push(reigonList[key]);
      });
      //sort by a~z
      setRegion(arr.sort((a, b) => a.displayName.localeCompare(b.displayName)));
      const defaultRegionCode = reigonList[user.region.toLowerCase()]?.code;
      form.setFieldValue('regionCode', defaultRegionCode);
    }
  }, []);

  return {
    form,
    prospectCategories,
    selectedReferrBy,
    showReferrby,
    region,
    requiredFields,
    selectedCatagory,
    filedError,
    selectedRegionCode,
    onSelectReferrBy,
    setShowReferrby,
    onConfirm,
    setSelectedCatagory,
    setSelectedRegionCode,
  };
};
