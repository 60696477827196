import { Dispatch } from 'react';
import { getQueryPath } from 'src/app/common/utils';
import { PageReqParam, PaginateList } from 'src/app/common/types';
import { apiClient, apiErrorHandler, AGENT_URL, MY_AGENT_ENDPOINT } from 'src/app/common/network';
import { TalkBotExportType } from '../types/talkbot-export-leads-types';
import { GetExportDetailReqType, ExportDetailItemType, ExportListItem } from '../types/talkbot-export-history-types';
import { talkbotEndpoint } from './constants';

export type TalkbotExportParam = {
  ids: string[];
  flowId: number;
  type: TalkBotExportType;
};

export const getExportList = (
  params: PageReqParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ExportListItem>> => {
  const queryPath = getQueryPath(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${talkbotEndpoint}/export-log/list?`, {
    ...params,
  });
  return apiClient
    .get(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getExportDetail = (
  params: GetExportDetailReqType,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ExportDetailItemType>> => {
  const queryPath = getQueryPath(`${AGENT_URL}/${MY_AGENT_ENDPOINT}/${talkbotEndpoint}/export-log/detail?`, {
    ...params,
  });
  return apiClient
    .get(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
