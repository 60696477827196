import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ModuleHierachy } from 'src/app/common/module/types';
import { ModuleContext } from 'src/app/common/module/ModuleProvider';
import { NavLink, useLocation } from 'react-router-dom';
import { HomeIcon } from 'src/app/common/components/ManagerDashboard';
import HeaderBreadcrumbsWrapper from './header-breadcrumbs-wrapper';
import { isEmpty } from 'lodash';

export default function HeaderBreadCrumbs() {
  const { classes, cx } = useStyles();
  const { pathname } = useLocation();
  const { moduleMap } = useContext(ModuleContext);
  const menuList = useMemo(() => moduleMap.filter((module) => !module.disableMenu && module.path), [moduleMap]);
  const params = useParams<{ [key: string]: string | undefined }>();

  const breadcrumbItems = useMemo(() => {
    const findBreadcrumbItems = (
      menus: ModuleHierachy[],
      curPath: string,
      breadcrumbs: ModuleHierachy[] = [],
      parentParam?: string,
    ): ModuleHierachy[] => {
      const updatedMenus =
        parentParam && params[parentParam]
          ? menus.map((item) => ({
              ...item,
              path: item.path.replace(`:${parentParam}`, params[parentParam] || ''),
            }))
          : menus;
      const menuItem = updatedMenus.find((item) => curPath.startsWith(item.path));
      if (menuItem && !menuItem.child && !menuItem.page) {
        const breadcrumb = { ...menuItem };
        return [...breadcrumbs, breadcrumb];
      } else if (menuItem) {
        const child = menuItem.child || menuItem.page;
        if (child && child.length > 0) {
          const breadcrumbItems = findBreadcrumbItems(
            child as any,
            curPath,
            [...breadcrumbs, { ...menuItem }],
            menuItem.param,
          );
          if (breadcrumbItems.length > 0) {
            return breadcrumbItems;
          }
        }
      }
      // for (let i = 0; i < menus.length; i++) {
      //   const child = menus[i].child || menus[i].page;
      //   if (child && child.length > 0) {
      //     const breadcrumbItems = findBreadcrumbItems(child as any, curPath, [...breadcrumbs, { ...menus[i] }]);
      //     if (breadcrumbItems.length > 0) {
      //       return breadcrumbItems;
      //     }
      //   }
      // }
      return breadcrumbs ?? [];
    };

    return findBreadcrumbItems(menuList, pathname);
  }, [menuList, pathname]);

  const getFullPath = (path: string, currentPath: string, param?: string) => {
    if (param) {
      return `${path}/${params[param] || ''}`;
    }

    if (
      !isEmpty(params) &&
      currentPath.startsWith(path) &&
      path.split('/').length === currentPath.split('/').length - 1
    ) {
      return currentPath;
    }
    return path;
  };

  return (
    <div>
      {breadcrumbItems?.length > 0 && (
        <div className={classes.wrapper}>
          {/* <Typography color="textPrimary" className={classes.text}>
            {breadcrumbItems?.[breadcrumbItems?.length - 1].title}
          </Typography> */}
          <Breadcrumbs aria-label="breadcrumb" separator="/">
            {/* <NavLink color="inherit" to="/">
              <HomeIcon />
            </NavLink> */}
            {breadcrumbItems?.map((item: ModuleHierachy, index: number) => (
              <NavLink
                key={index}
                color="inherit"
                to={getFullPath(item.path, pathname, item.param)}
                className={cx(classes.unFocused, { [classes.focused]: index === breadcrumbItems.length - 1 })}
              >
                {item.title}
              </NavLink>
            ))}
          </Breadcrumbs>
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& a:hover': {
      color: '#3699FF !important',
    },
    [theme.breakpoints.down('md')]: {
      // padding: '10px 0',
      display: 'none',
    },
    paddingBottom: '24px',
  },
  text: {
    marginRight: 20,
    fontWeight: 500,
  },
  unFocused: {
    color: '#999999',
    lineHeight: '22px',
  },
  focused: {
    color: '#333333',
  },
}));
