import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  campaignStatus: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '22px',
    marginRight: 19,
  },
  contentBox: {
    padding: 24,
  },
  rsvpBox: {
    padding: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rsvpTextAccept: {
    color: '#24C791',
    fontSize: 16,
    fontWeight: 600,
    paddingLeft: '24px',
    paddingRight: '16px',
  },
  rsvpTextReject: {
    color: '#24C791',
    fontSize: 16,
    fontWeight: 600,
    paddingLeft: '24px',
    paddingRight: '16px',
  },
  briefBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 160,
    marginTop: 24,
    marginBottom: 12,
  },
  briefTitle: {
    fontWeight: 500,
    color: 'rgba(153, 153, 153, 1)',
    fontSize: 12,
    marginBottom: 12,
  },
  briefContent: {
    fontWeight: 500,
    fontSize: 16,
  },
  rsvpNotAcceptTitle: {
    fontWeight: 600,
    fontSize: 14,
    color: 'rgba(232, 25, 44, 1)',
  },
  rsvpAcceptTitle: {
    fontWeight: 500,
    fontSize: 14,
  },
  papaer: {
    marginTop: 12,
    marginBottom: 12,
  },
  ml_19: {
    marginLeft: 19,
  },
  ml_24: {
    marginLeft: 24,
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
}));
