export enum ErrorFieldType {
  MANDATORY = 'mandatory',
  IMMEDIATE = 'immediate',
}

export type ErrorState = {
  mandatory: Record<string, boolean>;
  immediate: Record<string, boolean>;
};

export type ErrorFieldDef = {
  name: string;
  fieldType: ErrorFieldType;
  validateDate?: boolean; // only check immediate fieldType
  section?: string;
  condition?: (data?: any) => boolean;
};

export type DismissErrorHandler = (field: string | string[], value: any) => void;
