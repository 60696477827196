import { FC, memo } from 'react';
import { SearchSelect } from '../search-select/search-select.component';
import React from 'react';
import { AsyncSearchSelectHookProps, useAsyncSearchSelect } from './async-search-select.hook';

export type AsyncSearchSelectProps = AsyncSearchSelectHookProps;

const AsyncSearchSelectComp: FC<AsyncSearchSelectProps> = (props): React.ReactElement => {
  const {
    requestErrorInfo,
    error = false,
    onChange,
    query,
    renderInputProps,
    renderOption: customizeRenderOption,
    OptionItem,
    clientFilter,
    pagination,
    ...rest
  } = props;

  const {
    open,
    listRef,
    loadingFirstRef,
    onSearchSelectOpen,
    optsRef,
    onInputChange,
    onSearchSelectChange,
    ListBoxComponent,
    renderOption,
    setOpen,
  } = useAsyncSearchSelect(props);

  return (
    <SearchSelect
      {...rest}
      open={open}
      loading={!(listRef.current?.length > 0) && loadingFirstRef.current}
      onOpen={onSearchSelectOpen}
      onClose={() => setOpen(false)}
      options={optsRef.current}
      renderInputProps={{
        error,
        ...renderInputProps,
        onChange: onInputChange,
      }}
      onChange={onSearchSelectChange}
      ListboxComponent={ListBoxComponent}
      renderOption={renderOption}
    />
  );
};

export const AsyncSearchSelect = memo(AsyncSearchSelectComp);
