import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: { display: 'inline-block' },
  text: {
    backgroundColor: '#E8192C',
    padding: '2px 4px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '29px',
    fontSize: '10px',
    color: '#FFF',
    fontWeight: 500,
    lineHeight: '12px',
  },
}));
