import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { Appointment, EventData } from '../../../types/appointment-types';
import { patchProspectAppointment, postProspectAppointment } from '../../../network/appointmentCrud';
import { DateFormat, FormMode } from 'src/app/common/utils';
import { PruToast } from 'src/app/common/components/pru-toast';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { LeadCategoryEnum } from '../../../types/lead-category-types';
import moment from 'moment';
import { Lead, Prospect, LeadSourceEnum } from 'src/app/modules/Leads/types/types';
import { patchFollowUp } from 'src/app/modules/Leads/network/leadsCrud';
import { backendLangCodeMapToFrontend, regionLocale } from 'src/app/i18n/utils/i18n-utils';

interface HookProps {
  onClose: () => void;
  data?: EventData;
  mode: FormMode;
  prospect?: Partial<Prospect>;
  lead?: Partial<Lead>;
  actionRef?: any;
  onSuccess?: () => void;
}

export const useAddApoointmentDialog = ({ onClose, data, mode, prospect, lead, actionRef, onSuccess }: HookProps) => {
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string, locale?: string) => intl.formatMessage({ id }, { locale: locale });
  const [formData, setFormData] = useState<Partial<Appointment> | undefined>({
    startDate: moment().toISOString(),
  });
  const [errors, setErrors] = useState<any>({});
  const mandatoryField = [`purpose`, `startDate`, `outcome`];
  const createMandatoryField = [`purpose`, `startDate`];
  // redux
  const dispatch = useDispatch();
  useEffect(() => {
    if (mode === FormMode.EDIT) {
      setFormData(data?.content);
    }
    setErrors({});
  }, [mode, data]);
  const postAppointment = async (appointment: any) => {
    let body: Appointment;
    if (lead) {
      body = {
        ...appointment,
        name: lead.prospectDetail?.alias ?? '',
        leadId: lead._id ?? '',
        source: lead.leadSource === LeadSourceEnum.marketingLeadSource ? 'MARKET' : 'AGENT',
        leadCategory: LeadCategoryEnum.SALES_LEAD,
      };
    } else {
      body = {
        ...appointment,
        name: prospect?.displayName ?? '',
        prospectId: prospect?._id ?? '',
        source: 'AGENT',
        leadCategory: LeadCategoryEnum.SALES_LEAD,
      };
    }

    const res = await postProspectAppointment(body, dispatch);
    onSuccess?.();
  };
  const mapOutcome = (value: string) => {
    let mapList: any = {};
    mapList = regionLocale.reduce((result: any, lang: any) => {
      const currentLanguage = backendLangCodeMapToFrontend[lang as keyof typeof backendLangCodeMapToFrontend];
      if (currentLanguage) {
        result[Translation('salesCalendarDetailDataSet1', currentLanguage)] = 'met';
        result[Translation('salesCalendarDetailDataSet2', currentLanguage)] = 'met';
        result[Translation('salesCalendarDetailDataSet3', currentLanguage)] = 'quote';
        result[Translation('salesCalendarDetailDataSet4', currentLanguage)] = 'proposal';
        result[Translation('salesCalendarDetailDataSet5', currentLanguage)] = 'met';
      }
      return result;
    }, {});

    return mapList[value] || '';
  };
  const editAppointment = async (id: string, appointment: any) => {
    const body = { ...appointment };
    body.agentCode = appointment.agentCode;
    body.content = appointment;
    // body.date = moment(appointment.startDate).toDate();
    body.date = moment(body.startDate).local().format(DateFormat.yearMonthDay);
    const res = await patchProspectAppointment(id, body, dispatch);
    if (appointment?.leadId) {
      if (appointment.leadCategory === LeadCategoryEnum.SALES_LEAD) {
        const body = { status: '', submissionDate: moment().toISOString() };
        body.status = mapOutcome(appointment.outcome);
        if (body.status) {
          await patchFollowUp(appointment.leadId, body);
        }
      }
    }
    onSuccess?.();
  };

  const validateField = (fields: string[]) => {
    //validate mandatory
    const newErrorState: any = {};
    fields.forEach((field) => {
      if (!formData?.[field as keyof Appointment]) {
        newErrorState[field] = true;
      }
    });
    setErrors(newErrorState);
    return Object.values(newErrorState).some((r) => r === true);
  };

  const resetStatus = useCallback(() => {
    setFormData({
      startDate: moment().toISOString(),
    });
  }, []);

  const onConfirm = async () => {
    if (mode === FormMode.CREATE) {
      if (validateField(createMandatoryField)) return;
      if (formData) {
        try {
          await postAppointment(formData);
          onClose();
          actionRef?.current?.reload();
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('global.submit.success'),
                content: Translation('agentProfile.ap_created_successfully_label'),
              },
            ]),
          );
          resetStatus();
        } catch (err) {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                content: Translation('common.serverError'),
              },
            ]),
          );
        }
      }
    } else {
      if (validateField(mandatoryField)) return;
      if (formData) {
        if (data?.eventId) {
          try {
            await editAppointment(data?.eventId, formData);
            onClose();
            actionRef?.current?.reload();
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: Translation('global.submit.success'),
                  content: Translation('common.updateSuccessfully'),
                },
              ]),
            );
            resetStatus();
          } catch (err) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  content: Translation('common.serverError'),
                },
              ]),
            );
          }
        }
      }
    }
  };

  return { onConfirm, formData, setFormData, errors };
};
