import React, { FC, useCallback, useState } from 'react';
export const useAddProspectDialog = () => {
  const [type, setType] = useState<string>('');
  const [checkedTerms, setCheckedTerms] = useState<boolean>(false);
  const onCheckChangen = useCallback((event: any) => {
    setCheckedTerms((prev) => !prev);
  }, []);

  return { type, checkedTerms, onCheckChangen, setType };
};
