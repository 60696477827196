import React, { FC, memo, useMemo, useState } from 'react';
import { ProTableOperationBtnComponent } from '../../../../../common/components/ProTable/components/pro-table-operation-btn/pro-table-operation-btn.component';
import { Accept } from './components/accept/accept.component';
import { AgentStatusEnum } from '../../../types/types';
import { Reject } from './components/reject/reject.component';
import { ComponentProps, useLeadOperation } from './lead-operation.hook';
import { AddApoointmentDialogComponent } from 'src/app/modules/Prospect/pages/appointment/add-apoointment-dialog/add-apoointment-dialog.component';
import { FormMode } from 'src/app/common/utils';
import { LeadStatusUpdateDialog } from './components/lead-status-update-dialog/lead-status-update-dialog.component';

export const LeadOperationComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // --------------- PCAAEB-43960 ----------------
  const {
    showApptDialog,
    setShowApptDialog,
    doAppointmentCreate,
    rejectLoading,
    setToReject,
    Translation,
    toReject,
    doReject,
    acceptLoading,
    setToAccept,
    toAccept,
    doAccept,
    setToUpdate,
    toUpdate,
    doUpdate,
  } = useLeadOperation(props);

  const { item, tab, status, onAccept, onAppt, onReject, onUpdate } = props;
  // --------------- PCAAEB-43960 ----------------

  const leadStatus = status || item.agentStatus;

  return (
    <>
      {leadStatus === AgentStatusEnum.pending ? (
        <>
          <>
            <ProTableOperationBtnComponent
              loading={rejectLoading}
              onClick={() => {
                setToReject(true);
                onReject?.(item);
              }}
              label={Translation('app.button.reject')}
            />
            {toReject ? <Reject tab={tab} onReject={doReject} onClose={() => setToReject(false)} /> : null}
          </>
          <>
            <ProTableOperationBtnComponent
              loading={acceptLoading}
              onClick={() => {
                setToAccept(true);
                onAccept?.(item);
              }}
              label={Translation('app.button.accept')}
            />
            {toAccept ? (
              <Accept
                showDialog={item.leadWithPartialInfoDisplay}
                title={item.campaign?.leadWithPartialInfoWarning?.title}
                body={item.campaign?.leadWithPartialInfoWarning?.body}
                tnc={item.campaign?.leadWithPartialInfoWarning?.tnc}
                onAccept={async () => {
                  await doAccept();
                }}
                onClose={() => setToAccept(false)}
              />
            ) : null}
          </>
        </>
      ) : (
        <>
          <ProTableOperationBtnComponent
            onClick={() => {
              doAppointmentCreate();
              onAppt?.(item);
            }}
            label={Translation('app.button.listOperation.appt')}
          />
          <>
            <ProTableOperationBtnComponent
              onClick={() => {
                onUpdate?.(item);
                setToUpdate(true);
                console.log('onUpdate', item);
              }}
              label={Translation('app.button.listOperation.update')}
            />
            <LeadStatusUpdateDialog
              key={item.followUpStatus}
              lead={item}
              show={toUpdate}
              onUpdate={doUpdate}
              onCancel={() => setToUpdate(false)}
            />
          </>
          <AddApoointmentDialogComponent
            open={showApptDialog}
            onClose={() => setShowApptDialog(false)}
            mode={FormMode.CREATE}
            lead={item}
          />
        </>
      )}
    </>
  );
});
