import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useLeadAutoDropList } from './lead-auto-drop-list.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { FilterTableContainer } from 'src/app/common/components/filter-table-container/filter-table-container.component';
import { Button } from 'src/app/common/components/button/button.component';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { openLeadDetail } from '../../../util/leads.util';
import { OperationContext } from '../../../components/lead-list-item/lead-operation-units/util/operation-context.util';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const LeadAutoDropListPage: React.FC<PageProps> = (props: PageProps) => {
  const { t } = useTranslation();

  // custom-hook
  const { autoDropListColumns, reminderAutoDropLeadDay, getData, oppProviderValue } = useLeadAutoDropList();

  const history = useHistory();

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={t('lead.autoDrop.list.header')}
        rightNode={
          <Button variant="outlined" color="primary" onClick={() => props.history.goBack()}>
            {t('app.button.back')}
          </Button>
        }
      />
      <Typography marginTop={'24px'} marginBottom={'24px'}>
        {t('lead.autoDrop.list.description', { day: reminderAutoDropLeadDay ?? 7 })}
      </Typography>
      <OperationContext.Provider value={oppProviderValue}>
        <FilterTableContainer
          rowKey="_id"
          getData={getData}
          getColumns={autoDropListColumns}
          showFilter={false}
          showSelect={false}
          onClickRow={(record) => openLeadDetail(history, record)}
        />
      </OperationContext.Provider>
    </Page>
  );
};
