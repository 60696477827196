import React, { FC, useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { PruFilterDropdownItem } from "./PruTable/PruFilter";

type AsyncAutocompleteProps = {
  value: string;
  disabled?: boolean;
  getData: () => Promise<PruFilterDropdownItem[]>;
  onChange: (newValue: string) => void;
} & Record<string, unknown>;

const AsyncAutocomplete: FC<AsyncAutocompleteProps> = ({
  value,
  disabled,
  getData,
  onChange,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<PruFilterDropdownItem[]>([]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const response = await getData();
      if (active) {
        setOptions(response);
      }
      setIsLoading(false);
    }

    let active = true;
    if (open) {
      loadData();
    }
    return () => {
      active = false;
    }
  // eslint-disable-next-line
  }, [open]);

  return (
    <Autocomplete
      {...rest}
      disabled={disabled}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => options.find(item => item.value === option)?.displayName || option}
      options={options.map(option => option.value)}
      loading={isLoading}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue || "");
      }}
      renderInput={(params) => (
        <TextField {...params}
          variant="outlined"
          margin="dense"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

export default AsyncAutocomplete;