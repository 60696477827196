import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    width: 224,
    borderRight: '1px solid #F0F0F0',
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    flexDirection: 'row',
  },
  tableRow: {
    border: '1px solid #000000',
    padding: 4,
  },
  importFail: { marginTop: 24 },
  importFormat: {
    border: '1px solid #ccc',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    padding: 24,
    borderRadius: 8,
    marginTop: 24,
    minHeight: '318px',
  },
  red: { color: '#E8192C' },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 8,
  },
  sectionHeaderText: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '26px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  notAvailableTip: {
    marginTop: '70px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
  },
  tableContainer: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: 18,
  },
  templateContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 33,
    marginTop: 33,
  },
  templateLabel: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    marginRight: 24,
  },
  templateLink: {
    color: 'rgba(67, 134, 229, 1)',
  },
  uploadBtn: {
    width: '100%',
    height: 240,
    backgroundColor: 'rgba(240, 240, 240, 1)',
  },
  uploadBtnTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadBtnTitle: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '30px',
  },
  uploadBtnSubtitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: '#666666',
    marginTop: 8,
  },
  browser: {
    color: 'rgba(235, 58, 52, 1)',
  },
  ul: {
    width: '50%',
    minWidth: 200,
    marginTop: 24,
    marginLeft: 10,
    marginBottom: 16,
    borderRadius: 10,
    listStyle: 'inside',
    listStyleType: 'disc',
    color: '#666666',
    fontSize: 12,
    lineHeight: '20px',
  },
}));
