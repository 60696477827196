import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  buttonContainer: {
    width: 100,
    height: 90,
    borderRadius: 12,
  },
  buttonLayout: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  categoryTitle: {
    fontSize: 12,
    fontWeight: 500,
    height: 28,
    textAlign: 'left',
    width: '100%',
    lineHeight: '16px',
  },
  categoryCount: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '22px',
    marginTop: 8,
  },
}));
