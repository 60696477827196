import React from 'react';
import { FilterComponentsEnum } from 'src/app/common/components/list-filter/filter-components.enum';
import { ListFilterConfig } from 'src/app/common/components/list-filter/list-filter.component';
import { ProColumns } from 'src/app/common/components/ProTable';
import { OperationType, TalkbotOperationType } from 'src/app/modules/Leads/util/table-columns-render.util';
import { COLUMN_ITEMS } from 'src/app/modules/Leads/util/column-items.util';
import { Columns, Lead } from 'src/app/modules/Leads/types/types';

export const getOpportunitiesFilterConfig: (
  t: (val: string) => string,
  marketSourceItems?: { itemKey: string; itemLabel: string }[],
  campaignItems?: { itemKey: string; itemLabel: string }[],
) => Array<ListFilterConfig> = (t, marketSourceItems, campaignItems) => [
  {
    key: 'dateRange',
    title: t('filterOptionOppReceiveDate'),
    type: FilterComponentsEnum.DATE_TIME_PICKER,
    items: [],
  },
  {
    key: 'typeOfLead',
    title: t('filterOptionFollowUpTypeOfLead'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: [
      {
        itemKey: 'existingCustomer',
        itemLabel: t('filterOptionItemOppExistingCustomer'),
      },
      { itemKey: 'new', itemLabel: t('filterOptionItemOppNewProspect') },
      {
        itemKey: 'referral',
        itemLabel: t('filterOptionItemOppReferral'),
      },
    ],
  },
  {
    key: 'campaign',
    title: t('salesCaTitle'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: campaignItems ?? [],
  },
  {
    key: 'marketingLeadSource',
    title: t('filterOptionOppMarketingLeadSource'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: marketSourceItems ?? [],
  },
];

export const getFollowUpFilterConfig: (
  t: (val: string) => string,
  marketSourceItems?: { itemKey: string; itemLabel: string }[],
  campaignItems?: { itemKey: string; itemLabel: string }[],
  agentLeadSourceItems?: { itemKey: string; itemLabel: string }[],
) => Array<ListFilterConfig> = (t, marketSourceItems, campaignItems, agentLeadSourceItems) => [
  {
    key: 'typeOfLead',
    title: t('filterOptionFollowUpTypeOfLead'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: [
      {
        itemKey: 'existingCustomer',
        itemLabel: t('filterOptionItemFollowUpExistingCustomer'),
      },
      {
        itemKey: 'potentialCustomer',
        itemLabel: t('filterOptionItemFollowUpPotentialCustomer'),
      },
      { itemKey: 'referralCode', itemLabel: t('filterOptionItemFollowUpReferralCode') }, // app is different to figma
      {
        itemKey: 'reassignLead',
        itemLabel: t('filterOptionItemFollowUpReassignLead'),
      },
      {
        itemKey: 'redistributedLead',
        itemLabel: t('filterOptionItemFollowUpRedistributedLead'),
      },
    ],
  },
  {
    key: 'typeOfLeadSource',
    title: t('filterOptionFollowUpTypeOfLeadSource'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: [
      {
        itemKey: 'marketing',
        itemLabel: t('filterOptionItemFollowUpMarketing'),
      },
      {
        itemKey: 'agentSource',
        itemLabel: t('filterOptionItemFollowUpAgentSource'),
      },
      { itemKey: 'agencyCampaign', itemLabel: t('filterOptionItemFollowUpAgencyCampaign') }, // app is different to figma
    ],
  },
  {
    key: 'addLeadCreateDateDateRange',
    title: t('filterOptionClosedLeadCreateDate'),
    type: FilterComponentsEnum.DATE_TIME_PICKER,
    items: [],
  },
  {
    key: 'dateRange',
    title: t('filterOptionFollowUpAgedLeadPeriod'),
    type: FilterComponentsEnum.DATE_TIME_PICKER,
    items: [],
  },
  {
    key: 'agingLead',
    title: t('filterOptionFollowUpAgingLead'),
    type: FilterComponentsEnum.PURE_RADIO_GROUP,
    items: [
      {
        itemKey: 'false', // check with app
        itemLabel: t('filterOptionItemFollowUpAll'),
      },
      {
        itemKey: 'true',
        itemLabel: t('filterOptionItemFollowUpAging'),
      },
      { itemKey: 'active', itemLabel: t('filterOptionItemFollowUpActive') },
    ],
  },
  {
    key: 'status',
    title: t('filterOptionFollowUpStatus'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: [
      {
        itemKey: '',
        itemLabel: t('filterOptionItemFollowUpPendingToContact'),
      },
      {
        itemKey: 'contactSuccess',
        itemLabel: t('filterOptionItemFollowUpContacted'), // PACS Customization: PACSPFAP-2662
      },
      { itemKey: 'met', itemLabel: t('filterOptionItemFollowUpMet') }, // PACS Customization: PACSPFAP-2662
      {
        itemKey: 'quote',
        itemLabel: t('filterOptionItemFollowUpProposalGenerated'),
      },
      {
        itemKey: 'proposal',
        itemLabel: t('filterOptionItemFollowUpApplicationSubmitted'), // PACS Customization: PACSPFAP-2662
      },
    ],
  },
  {
    key: 'campaign',
    title: t('filterOptionFollowUpCampaign'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: campaignItems ?? [],
  },
  {
    key: 'marketingLeadSource',
    title: t('filterOptionFollowUpMarketingLeadSource'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: marketSourceItems ?? [],
  },
  {
    key: 'agentLeadSource',
    title: t('filterOptionFollowUpAgentLeadSource'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: agentLeadSourceItems ?? [],
  },
];

export const getClosedFilterConfig: (
  t: (val: string) => string,
  marketSourceItems?: { itemKey: string; itemLabel: string }[],
  campaignItems?: { itemKey: string; itemLabel: string }[],
  agentLeadSourceItems?: { itemKey: string; itemLabel: string }[],
) => Array<ListFilterConfig> = (t, marketSourceItems, campaignItems, agentLeadSourceItems) => [
  {
    key: 'dateRange',
    title: t('filterOptionClosedLeadCreateDate'),
    type: FilterComponentsEnum.RADIO_GROUP,
    items: [],
  },
  {
    key: 'status',
    title: t('filterOptionClosedStatus'),
    type: FilterComponentsEnum.PURE_RADIO_GROUP,
    items: [
      {
        itemKey: '', // todo: check with app
        itemLabel: t('filterOptionItemClosedAll'), // i18n different with th figma
      },
      {
        itemKey: 'policy',
        itemLabel: t('filterOptionItemClosedPolicyIssued'),
      },
      // PACS Customization - PACSPFAP-3761 - customizing the dead_recruit_lead label
      { itemKey: 'dead', itemLabel: t('dead_recruit_lead') }, // app is different to figma
    ],
  },
  {
    key: 'campaign',
    title: t('filterOptionClosedCampaign'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: campaignItems ?? [],
  },
  {
    key: 'marketingLeadSource',
    title: t('filterOptionClosedMarketingLeadSource'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: marketSourceItems ?? [],
  },
  {
    key: 'agentLeadSource',
    title: t('filterOptionClosedAgentLeadSource'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: agentLeadSourceItems ?? [],
  },
];

export const getOpportunitiesColumns = ({
  keyword,
  operations,
}: {
  keyword?: string;
  operations?: TalkbotOperationType;
}): ProColumns<Lead>[] => [
  COLUMN_ITEMS[Columns.displayName](keyword),
  COLUMN_ITEMS[Columns.typeOfLead](),
  // PACS Customization: PACSPFAP-3085
  // COLUMN_ITEMS[Columns.sla](),
  // for opportunity source name is its campaign name, for sorter field, we do not change keyIndex here
  {
    ...COLUMN_ITEMS[Columns.campaignName](),
    title: COLUMN_ITEMS[Columns.source]().title,
  },
  {
    ...COLUMN_ITEMS[Columns.leadSource](),
    sorter: true,
  },
  COLUMN_ITEMS[Columns.lastViewedAt](),
  COLUMN_ITEMS[Columns.updatedAt](),
  COLUMN_ITEMS[Columns.opportunityOperation](operations),
];

export const getFollowUpsColumns = ({ keyword }: { keyword?: string }): ProColumns<Lead>[] => [
  COLUMN_ITEMS[Columns.displayName](keyword),
  COLUMN_ITEMS[Columns.leadStatus](),
  // COLUMN_ITEMS[Columns.campaignName](),
  COLUMN_ITEMS[Columns.source](),
  COLUMN_ITEMS[Columns.leadSource](),
  COLUMN_ITEMS[Columns.typeOfLead](),
  COLUMN_ITEMS[Columns.lastViewedAt](),
  COLUMN_ITEMS[Columns.updatedAt](),
  // COLUMN_ITEMS[Columns.followUpOperation](), // PACS Customization: PACSPFAP-3086 | [Phase-1] - Removes the Operation column for phase 1
];

export const getClosedColumns = ({ keyword }: { keyword?: string }): ProColumns<Lead>[] => [
  COLUMN_ITEMS[Columns.displayName](keyword),
  COLUMN_ITEMS[Columns.leadStatus](),
  COLUMN_ITEMS[Columns.noLabelRemark](),
  // COLUMN_ITEMS[Columns.campaignName](),
  COLUMN_ITEMS[Columns.source](),
  COLUMN_ITEMS[Columns.leadSource](),
  COLUMN_ITEMS[Columns.typeOfLead](),
  COLUMN_ITEMS[Columns.lastViewedAt](),
  COLUMN_ITEMS[Columns.updatedAt](),
];

export const getAutoDropListColumns = ({ operations }: { operations?: OperationType }): ProColumns<Lead>[] => [
  COLUMN_ITEMS[Columns.displayName](),
  COLUMN_ITEMS[Columns.dropDate](),
  COLUMN_ITEMS[Columns.leadStatus](),
  // COLUMN_ITEMS[Columns.campaignName](),
  COLUMN_ITEMS[Columns.source](),
  COLUMN_ITEMS[Columns.leadSource](),
  COLUMN_ITEMS[Columns.typeOfLead](),
  COLUMN_ITEMS[Columns.lastViewedAt](),
  COLUMN_ITEMS[Columns.updatedAt](),
  COLUMN_ITEMS[Columns.prospectLeadOperation](operations),
];
