import { FC, useRef } from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { GAData } from 'src/app/common/ga/redux/gaSlice';
import { CAMPAIGN_BASE_PATH, MASS_CAMPAIGN_DETAIL_PATH, MASS_CAMPAIGN_LIST_PATH } from '../../constants';
import { DetailPagePage } from './Detail/detail-page.screen';
import { ListPage } from './List/list.screen';

const campaignListGaDataRoute15: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'View Mass Campaign',
  stage: 'View Campaign List',
  screen_name: 'Campaign List',
};
const massCampaignDetailGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'View Mass Campaign',
  stage: 'View Mass Campaign Detail',
  screen_name: 'Mass Campaign Detail',
};

export const MASS_LIST_PATH = `${CAMPAIGN_BASE_PATH}${MASS_CAMPAIGN_LIST_PATH}`;
export const MASS_DETAIL_PATH = `${CAMPAIGN_BASE_PATH}${MASS_CAMPAIGN_LIST_PATH}${MASS_CAMPAIGN_DETAIL_PATH}`;

const MassCampaignRoutes: FC<RouteComponentProps> = (props) => {
  return (
    <Switch>
      <PruRoute
        exact
        gaData={massCampaignDetailGaDataRoute}
        path={`${MASS_DETAIL_PATH}/:id`}
        render={(props) => <DetailPagePage {...props} />}
      />
      <PruRoute
        exact
        gaData={campaignListGaDataRoute15}
        path={MASS_LIST_PATH}
        render={(props) => <ListPage {...props} />}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default MassCampaignRoutes;
