import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  REPORTS_TITLE,
  REPORTS_NAMESPACE,
  REPORTS_BASE_PATH
} from './constants';

import ReportRoutes from './pages/reports/ReportRoutes';

export const reportsModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(REPORTS_TITLE),
    path: REPORTS_BASE_PATH,
    namespace: REPORTS_NAMESPACE,
    component: ReportRoutes,
    disableMenu: false,
    disableRoleControl: true,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
    page: [
      {
        title: 'Reports',
        path: `${REPORTS_BASE_PATH}`,
      },
    ],
  };
};
