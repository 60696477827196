import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    borderRadius: 5,
    paddingLeft: '24px',
    paddingRight: '24px',
    width: '586px',
  },
  error: {
    backgroundColor: 'red',
  },
  select: {
    borderColor: '#F0F0F0',
  },
  input: {
    width: '100%',
    padding: 0,
  },
  updateTime: {
    marginTop: '24px',
  },
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '26px',
  },
  btnContainer: {
    padding: '20px 0px 20px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  cancelbtn: {
    marginRight: 24,
    borderColor: '#F0F0F0',
    borderRadius: '4px',
  },
  confirmBtn: {
    borderRadius: '4px',
  },
  rightButtonBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  leftButtonBox: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  btn: {
    '--active-color': '#E8192C',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 24,
    fontSize: '16px',
    fontWeight: 400,
    borderColor: '#F0F0F0',
    height: '56px',
  },
  active: {
    color: 'var(--active-color)',
    borderWidth: 2,
    borderColor: 'var(--active-color)',
    backgroundColor: 'rgba(232, 25, 44, 0.04)',
  },
  checkBox: {
    width: '24px',
    height: '24px',
    marginRight: '12px',
    color: '#666666',
  },
  checkBoxActive: {
    color: '#E8192C',
  },
  description: {
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  deadReasonBox: {
    width: '100%',
    marginTop: '24px',
  },
}));
