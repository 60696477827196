import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './lead-basic-info.style';
import { reconstuctNameByLastName } from 'src/app/common/utils/string.util';
import { FemaleOutlined, MaleOutlined, CakeOutlined } from '@mui/icons-material';
import { ProspectGenderEnum } from 'src/app/modules/Prospect/types/prospect-types';
import { LeadDetail } from '../../../../../types/lead-detail-types';
import { checkDateEquals } from 'src/app/common/utils';
import { useLang } from 'src/app/i18n';
import { get } from 'lodash';
import { useLeadBasicInfo } from './lead-basic-info.hook';
import { ProspectMasterPreview } from '../prospect-master-preview/prospect-master-preview.component';

type ComponentProps = {
  /* otherProp: string */
  detail: LeadDetail;
};

export const LeadBasicInfo: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { detail } = props;
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const prospect = detail?.prospect || detail?.prospectMaster;
  const {
    toCampaign,
    toProspect,
    displayLeadName,
    displayLeadImage,
    sourceName,
    subSourceName,
    openPreview,
    campaignCategory,
    status,
    close,
  } = useLeadBasicInfo(detail);
  return (
    <>
      {detail && (
        <div className={styles.wrap}>
          <div className="tw-flex tw-w-full tw-flex-col">
            <div className={styles.prospectBox} onClick={toProspect}>
              <Typography variant="h4" fontWeight={700}>
                {detail.prospect?.displayName ||
                  reconstuctNameByLastName(detail.prospectDetail?.firstName, detail.prospectDetail?.lastName)}
              </Typography>

              {detail.prospectDetail?.gender === ProspectGenderEnum.salesFemale && (
                <FemaleOutlined className={styles.icon} />
              )}

              {detail.prospectDetail?.gender === ProspectGenderEnum.salesMale && (
                <MaleOutlined className={styles.maleIcon} />
              )}

              {detail.prospectMaster?.birthDate && checkDateEquals(detail.prospectMaster?.birthDate, new Date()) && (
                <CakeOutlined className={styles.cakeIcon} />
              )}
            </div>

            <div className={styles.flex_row}>
              {detail?.labels?.map((type: string, index: number) => (
                <React.Fragment key={type}>
                  <Typography fontSize="16px" color="#666666" lineHeight="24px">
                    {Translation(type)}
                  </Typography>

                  {index !== detail.labels.length - 1 && (
                    <Typography fontSize="16px" color="#666666" lineHeight="24px" margin="0 8px">
                      {' | '}
                    </Typography>
                  )}
                </React.Fragment>
              ))}

              {status && (
                <>
                  <Typography fontSize="16px" color="#666666" lineHeight="24px" margin="0 8px">
                    {' | '}
                  </Typography>
                  <Typography fontSize="16px" color="#666666" lineHeight="24px">
                    {status}
                  </Typography>
                </>
              )}
            </div>

            <div className={styles.sectionBox} onClick={toCampaign}>
              <img
                src={displayLeadImage}
                height="116px"
                width="116px"
                loading="lazy"
                alt=""
                className="tw-rounded-md tw-object-contain"
              />

              <div className="tw-flex tw-flex-col tw-justify-between tw-mb-6 tw-ml-6">
                <Typography fontSize={'16px'} fontWeight={500}>
                  {displayLeadName}
                </Typography>
                <div className="tw-mt-12">
                  <Grid container display="flex">
                    {detail.campaign?.type && (
                      <Grid
                        item
                        borderRight={1}
                        borderColor="#F0F0F0"
                        padding="0 24px 0 0"
                        display="flex"
                        flexDirection="column"
                      >
                        <Typography fontSize="12px" fontWeight={500} color="#999999" marginBottom="12px">
                          {Translation('lead.info.campaignCategory')}
                        </Typography>
                        <Typography fontSize="16px" fontWeight={500} alignSelf="flex-start">
                          {campaignCategory}
                        </Typography>
                      </Grid>
                    )}
                    {detail.campaign?.campaignType && (
                      <Grid
                        item
                        borderRight={1}
                        borderColor="#F0F0F0"
                        padding="0 24px 0 24px"
                        display="flex"
                        flexDirection="column"
                      >
                        <Typography fontSize="12px" fontWeight={500} color="#999999" marginBottom="12px">
                          {Translation('lead.info.campaignType')}
                        </Typography>
                        <Typography fontSize="16px" fontWeight={500} alignSelf="flex-start">
                          {detail.campaign?.campaignType}
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      item
                      borderRight={1}
                      borderColor="#F0F0F0"
                      padding="0 24px 0 24px"
                      display="flex"
                      flexDirection="column"
                    >
                      <Typography fontSize="12px" fontWeight={500} color="#999999" marginBottom="12px">
                        {Translation('lead.info.leadSource')}
                      </Typography>
                      <Typography fontSize="16px" fontWeight={500} alignSelf="flex-start">
                        {sourceName}
                      </Typography>
                    </Grid>
                    <Grid item alignItems="flex-start" display="flex" flexDirection="column" padding="0 0 0 24px">
                      <Typography fontSize="12px" fontWeight={500} color="#999999" marginBottom="12px">
                        {Translation('lead.info.leadSubSource')}
                      </Typography>
                      <Typography fontSize="16px" fontWeight={500} alignSelf="flex-start">
                        {subSourceName}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ProspectMasterPreview open={openPreview} onClose={close} data={detail} />
    </>
  );
});
