import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { LayoutSplashScreen } from 'src/app/layout';
import { useStyles } from './add-new-prospect.style';
import { useAddNewProspect } from './add-new-prospect.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { PROSPECT_BASE_PATH } from 'src/app/modules/Prospect/constants';
import Form, { Item } from 'src/app/common/components/Form';
import { ProspectCategory } from 'src/app/common/components/prospect-category/prospect-category.component';
import { ReferredBy } from '../../../components/referred-by/referred-by.component';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

type ActionBarProps = {
  onBack: () => void;
  onConfirm: () => void;
};

const ActionBar = ({ onBack, onConfirm }: ActionBarProps) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const Translation = (id: string) => intl.formatMessage({ id });
  return (
    <div>
      <Button variant="outlined" className={classes.backBt} onClick={onBack}>
        {Translation('app.button.back')}
      </Button>
      <Button variant="contained" color="secondary" onClick={onConfirm}>
        {Translation('app.button.confirm')}
      </Button>
    </div>
  );
};

const initialFormData = {
  prospectCategory: '',
  firstName: '',
  lastName: '',
  regionCode: '',
  phoneNumber: '',
  email: '',
  referredBy: '',
};

export const AddNewProspectPage: React.FC<PageProps> = (props: PageProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { history } = props;

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    form,
    prospectCategories,
    selectedReferrBy,
    showReferrby,
    region,
    requiredFields,
    selectedCatagory,
    filedError,
    onSelectReferrBy,
    setShowReferrby,
    onConfirm,
    setSelectedCatagory,
  } = useAddNewProspect();

  const onBack = () => {
    history.push(PROSPECT_BASE_PATH);
  };

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={Translation('prospect.add_new_prospect')}
        rightNode={<ActionBar onBack={onBack} onConfirm={onConfirm} />}
      />
      <Form form={form} initialValues={initialFormData} noValidate>
        <div className={`${styles.sectionBox} ${styles.titleBox}`}>
          <div className={styles.sectionTitle}>
            <span>{Translation('prospect.category')}</span>
          </div>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: -1 }}>
            {prospectCategories.map((item, index) => {
              return (
                <Grid item xs={6} key={item.id}>
                  <Item name={'prospectCategory'}>
                    <div className={styles.radionContainer}>
                      <Radio
                        checked={selectedCatagory === item.id}
                        onChange={(event) => {
                          setSelectedCatagory(event.target.value);
                        }}
                        value={item.id}
                        className={styles.radio}
                      />
                      <ProspectCategory data={item} showDetail />
                    </div>
                  </Item>
                </Grid>
              );
            })}
          </Grid>
        </div>

        <div style={{ marginTop: 24 }}>
          <div className={styles.sectionBox} style={{ height: '404px' }}>
            <div className={styles.sectionTitle}>
              <span>{Translation('common.detail')}</span>
            </div>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <Item name={'firstName'} rules={[{ required: requiredFields.firstName, max: 6 }]} hideError>
                  <TextField
                    label={Translation('prospect.first_name')}
                    variant="outlined"
                    required={requiredFields.firstName}
                    className={styles.input}
                    size="medium"
                    error={Boolean(filedError['firstName'])}
                    helperText={filedError['firstName']?.error ?? ''}
                  ></TextField>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item name={'lastName'} rules={[{ required: requiredFields.lastName }]} hideError>
                  <TextField
                    label={Translation('prospect.last_name')}
                    variant="outlined"
                    required={requiredFields.lastName}
                    className={styles.input}
                    size="medium"
                    error={Boolean(filedError['lastName'])}
                    helperText={filedError['lastName']?.error ?? ''}
                  ></TextField>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item name={'regionCode'} rules={[{ required: requiredFields.regionCode }]} hideError>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label={Translation('prospect.regionCode')}
                    variant="outlined"
                    size="medium"
                    required={requiredFields.regionCode}
                    SelectProps={{
                      size: 'medium',
                    }}
                    className={styles.input}
                    error={Boolean(filedError['regionCode'])}
                    helperText={filedError['regionCode']?.error ?? ''}
                  >
                    {region?.map((item, index) => (
                      <MenuItem
                        key={`${item.code}_${index}`}
                        value={item.code}
                      >{`${item.code} ${item.displayName}`}</MenuItem>
                    ))}
                  </TextField>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item name={'phoneNumber'} rules={[{ required: requiredFields.phoneNumber }]} hideError>
                  <TextField
                    label={Translation('prospect.phone_number')}
                    variant="outlined"
                    required={requiredFields.phoneNumber}
                    className={styles.input}
                    size="medium"
                    error={Boolean(filedError['phoneNumber'])}
                    helperText={filedError['phoneNumber']?.error ?? ''}
                  ></TextField>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item name={'email'} rules={[{ required: requiredFields.email }]} hideError>
                  <TextField
                    label={Translation('prospect.email_address')}
                    variant="outlined"
                    className={styles.input}
                    size="medium"
                    required={requiredFields.email}
                    error={filedError['email']}
                    helperText={filedError['email']?.error ?? ''}
                  ></TextField>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={Translation('prospect.referred_by')}
                  variant="outlined"
                  className={styles.input}
                  size="medium"
                  onClick={() => {
                    setShowReferrby(true);
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  required={requiredFields.referredBy}
                  value={selectedReferrBy?.displayName ?? ''}
                  error={Boolean(filedError['referredBy'])}
                  helperText={filedError['referredBy']?.error ?? ''}
                ></TextField>
              </Grid>
            </Grid>
          </div>
        </div>
      </Form>
      <ReferredBy
        defaultSelectedRows={selectedReferrBy}
        open={showReferrby}
        onClose={() => setShowReferrby(false)}
        onConfirm={onSelectReferrBy}
      />
    </Page>
  );
};
