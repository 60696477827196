import { GAData as RouteGA } from '../redux/gaSlice';
import { GAObjectType } from './ga-parameter-type';

type GAData = RouteGA & { object_name: string; object_type: GAObjectType; extraInfo?: any };

export const PruLeadsHomeScreenGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'new lead reminder',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Check',
  object_type: 'BUTTON',
};
export const PruLeadsHomeScreenGaDataClick1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'new lead reminder',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Check',
  object_type: 'BUTTON',
};
export const PruLeadsHomeScreenGaDataClick2: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Confirm Contact Lead',
  stage: 'Contact Lead Reminder',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Check',
  object_type: 'BUTTON',
};
export const PruLeadsHomeScreenGaDataClick3: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Confirm Contact Lead',
  stage: 'Contact Lead Reminder',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Close',
  object_type: 'BUTTON',
};
export const PruLeadsHomeScreenGaDataClick4: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'aging pending accept lead reminder',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Check',
  object_type: 'BUTTON',
};
export const PruLeadsHomeScreenGaDataClick5: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'aging pending accept lead reminder',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Close',
  object_type: 'BUTTON',
};
export const youGotANewOpportunityGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'new lead reminder',
  screen_name: 'you got a new opportunity',
  object_name: 'Accept',
  object_type: 'BUTTON',
};
export const youGotANewOpportunityGaDataClick1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'new lead reminder',
  screen_name: 'you got a new opportunity',
  object_name: 'Reject',
  object_type: 'BUTTON',
};
export const theseLeadAreGettingColdGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'aging pending accept lead reminder',
  screen_name: 'these lead are getting cold',
  object_name: 'accept all',
  object_type: 'BUTTON',
};
export const theseLeadAreGettingColdGaDataClick1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'aging pending accept lead reminder',
  screen_name: 'these lead are getting cold',
  object_name: 'Remove all',
  object_type: 'BUTTON',
};
export const theseLeadAreGettingColdGaDataClick2: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'aging pending accept lead reminder',
  screen_name: 'these lead are getting cold',
  object_name: 'accept',
  object_type: 'BUTTON',
};
export const theseLeadAreGettingColdGaDataClick3: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'aging pending accept lead reminder',
  screen_name: 'these lead are getting cold',
  object_name: 'Reject',
  object_type: 'BUTTON',
};
export const pRULeadsOpportunityListGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'PRULeads Opportunity List',
  screen_name: 'PRULeads Opportunity List',
  object_name: 'Accept',
  object_type: 'BUTTON',
};
export const pRULeadsOpportunityListGaDataClick1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'PRULeads Opportunity List',
  screen_name: 'PRULeads Opportunity List',
  object_name: 'Reject',
  object_type: 'BUTTON',
};
export const prospectDetailGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Prospect Detail Lead List',
  screen_name: 'Prospect Detail',
  object_name: 'Accept',
  object_type: 'BUTTON',
};
export const prospectDetailGaDataClick1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Prospect Detail Lead List',
  screen_name: 'Prospect Detail',
  object_name: 'Reject',
  object_type: 'BUTTON',
};
export const prospectDetailGaDataClick2: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Prospect Detail Lead List',
  screen_name: 'Prospect Detail',
  object_name: 'Update Lead',
  object_type: 'BUTTON',
};
export const prospectDetailGaDataClick3: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Add Appointment',
  stage: 'Prospect Detail Lead List',
  screen_name: 'Prospect Detail',
  object_name: 'AddLeadApointment',
  object_type: 'BUTTON',
};
export const pRULeadsHomeScreenGaDataClick17: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Lead Auto Drop Reminder',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Check',
  object_type: 'BUTTON',
};
export const pRULeadsFollowUpListGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'PRULeads Follow Up List',
  screen_name: 'PRULeads Follow Up List',
  object_name: 'FollowUp',
  object_type: 'BUTTON',
};
export const successfullyContactedLeadYesterdayGaData: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Contact Lead Reminder',
  screen_name: 'Successfully Contacted Lead Yesterday',
  object_name: 'FollowUp',
  object_type: 'BUTTON',
};
export const successfullyContactedLeadYesterdayGaData1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Add Appointment',
  stage: 'Contact Lead Reminder',
  screen_name: 'Successfully Contacted Lead Yesterday',
  object_name: 'AddLeadApointment',
  object_type: 'BUTTON',
};

export const marketingCampaignDetailsGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Marketing Campaign Lead List',
  screen_name: 'Marketing Campaign Details',
  object_name: 'Update Lead',
  object_type: 'BUTTON',
};
export const marketingCampaignDetailsGaDataClick1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Add Appointment',
  stage: 'Marketing Campaign Lead List',
  screen_name: 'Marketing Campaign Details',
  object_name: 'AddLeadApointment',
  object_type: 'BUTTON',
};
export const marketingCampaignDetailsGaDataClick2: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Marketing Campaign Lead List',
  screen_name: 'Marketing Campaign Details',
  object_name: 'Accept',
  object_type: 'BUTTON',
};
export const marketingCampaignDetailsGaDataClick3: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Marketing Campaign Lead List',
  screen_name: 'Marketing Campaign Details',
  object_name: 'Reject',
  object_type: 'BUTTON',
};
export const agencyCampaignDetailsGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Agency Campaign Lead List',
  screen_name: 'Agency Campaign Details',
  object_name: 'Update Lead',
  object_type: 'BUTTON',
};
export const agencyCampaignDetailsGaDataClick1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Add Appointment',
  stage: 'Agency Campaign Lead List',
  screen_name: 'Agency Campaign Details',
  object_name: 'AddLeadApointment',
  object_type: 'BUTTON',
};
export const agencyCampaignDetailsGaDataClick2: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Agency Campaign Lead List',
  screen_name: 'Agency Campaign Details',
  object_name: 'Accept',
  object_type: 'BUTTON',
};
export const agencyCampaignDetailsGaDataClick3: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Agency Campaign Lead List',
  screen_name: 'Agency Campaign Details',
  object_name: 'Reject',
  object_type: 'BUTTON',
};
export const insightLeadDetailsGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Insight Lead Detail Lead List',
  screen_name: 'Insight Lead Details',
  object_name: 'Update Lead',
  object_type: 'BUTTON',
};
export const insightLeadDetailsGaDataClick1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Add Appointment',
  stage: 'Insight Lead Detail Lead List',
  screen_name: 'Insight Lead Details',
  object_name: 'AddLeadApointment',
  object_type: 'BUTTON',
};
export const insightLeadDetailsGaDataClick2: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Insight Lead Detail Lead List',
  screen_name: 'Insight Lead Details',
  object_name: 'Accept',
  object_type: 'BUTTON',
};
export const insightLeadDetailsGaDataClick3: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Insight Lead Detail Lead List',
  screen_name: 'Insight Lead Details',
  object_name: 'Reject',
  object_type: 'BUTTON',
};

export const massCampaignDetailsGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Mass Campaign Lead List',
  screen_name: 'Mass Campaign Details',
  object_name: 'Accept',
  object_type: 'BUTTON',
};
export const massCampaignDetailsGaDataClick1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Mass Campaign Lead List',
  screen_name: 'Mass Campaign Details',
  object_name: 'Reject',
  object_type: 'BUTTON',
};
export const leadAutoDropListGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Lead Auto Drop Reminder',
  screen_name: 'Lead Auto Drop List',
  object_name: 'Update Lead',
  object_type: 'BUTTON',
};
export const leadAutoDropListGaDataClick1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Add Appointment',
  stage: 'Lead Auto Drop Reminder',
  screen_name: 'Lead Auto Drop List',
  object_name: 'AddLeadApointment',
  object_type: 'BUTTON',
};
export const leadAutoDropListGaDataClick2: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Lead Auto Drop Reminder',
  screen_name: 'Lead Auto Drop List',
  object_name: 'Accept',
  object_type: 'BUTTON',
};
export const leadAutoDropListGaDataClick3: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Lead Auto Drop Reminder',
  screen_name: 'Lead Auto Drop List',
  object_name: 'Reject',
  object_type: 'BUTTON',
};
export const pRULeadsLeadListTabGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Check Close Lead',
  stage: 'PRULeads Close Lead List',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Close Tab',
  object_type: 'Tab',
};
export const pRULeadsLeadListTabGaDataClick1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'PRULeads Opportunity List',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Opportunity Tab',
  object_type: 'Tab',
};
export const pRULeadsLeadListTabGaDataClick2: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'PRULeads Follow Up List',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Follow Up Tab',
  object_type: 'Tab',
};
export const allGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'opportunity',
  stage: 'accept',
  screen_name: 'All',
  object_name: 'Accept',
  object_type: 'BUTTON',
};

// "object_name": "\"Contacted\"/ \"Met\" / \"ProposalGenerated\" /\"ApplicationSubmitted\" / \"PolicyIssued\"/ \"FailToConvert\""
export const allGaDataClick3: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'confirm_update_lead',
  screen_name: 'PRULeads Follow Up List',
  object_type: 'BUTTON',
  object_name: 'Contacted',
};

// "object_name": "\"Contacted\"/ \"Met\" / \"ProposalGenerated\" /\"ApplicationSubmitted\" /  \"PolicyIssued\"/ \"FailToConvert\"",
export const allGaDataClick2: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Follow Up',
  stage: 'update_status',
  screen_name: 'All',
  object_type: 'BUTTON',
  object_name: 'Contacted',
};
export const pRULeadsHomePageGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Add Appointment',
  stage: 'PRULeads Follow Up List',
  screen_name: 'PRULeads Home Page',
  object_name: 'AddLeadApointment',
  object_type: 'BUTTON',
};

export const leadDetailsGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Edit Sales Lead',
  stage: 'Edit Sales Lead',
  screen_name: 'Lead Details',
  object_name: 'Edit',
  object_type: 'BUTTON',
};
export const reassignLeadGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Reassign Lead',
  stage: 'Reassign Lead Reminder',
  screen_name: 'PRULeads Home Page',
  object_name: 'Check',
  object_type: 'BUTTON',
};
export const reassignLeadGaDataClick1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Reassign Lead',
  stage: 'Select Unit',
  screen_name: 'Reassign Lead',
  object_name: 'Select Unit',
  object_type: 'BUTTON',
};
export const reassignLeadGaDataClick2: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Reassign Lead',
  stage: 'Edit Lead Status',
  screen_name: 'Reassign Lead',
  object_name: 'Edit Lead Status',
  object_type: 'BUTTON',
};
export const reassignLeadGaDataClick3: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Reassign Lead',
  stage: 'Select Agent',
  screen_name: 'Reassign Lead',
  object_name: 'Continue',
  object_type: 'BUTTON',
};
export const handleWithCareReminderGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Handle With Care',
  screen_name: 'PRULeads Home Page',
  object_name: 'Confirm',
  object_type: 'BUTTON',
};
