import React, { memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Button } from '@mui/material';
import { useStyles } from './action-items.style';
import { useDispatch } from 'react-redux';
import { Dialog } from 'src/app/common/components/Dialog/dialog/dialog.component';
import { CampaignLeadItem } from 'src/app/modules/Campaign/types/campaign-types';
import { ActionNodeTypeProps } from 'src/app/common/components/ProTable';
import { campaignAcceptLeads } from 'src/app/modules/Campaign/network/campaignCurd';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';

export type ActionNodeProps = ActionNodeTypeProps<CampaignLeadItem>;

export const ActionItems: React.FC<ActionNodeProps> = memo(({ selectedRows, onAction }: ActionNodeProps) => {
  const intl = useIntl();
  const Translation = (id: string, values?: any) => intl.formatMessage({ id }, values);
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const acceptSeleted = async (selectedRows: CampaignLeadItem[]) => {
    const leadIds = selectedRows.map((item) => item._id);
    try {
      const result = await campaignAcceptLeads(leadIds);
      onAction(['clearRow', 'refresh']);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('global.submit.success'),
            content: Translation('common.updateSuccessfully'),
          },
        ]),
      );
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: Translation('component.status.failed'),
            content: Translation('common.pleaseTryAgain'),
          },
        ]),
      );
    }
  };

  return (
    <Box>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        color="secondary"
      >
        {Translation('campaign.lead.accept.selected')}
      </Button>
      {open && (
        <Dialog
          title={Translation('campaign.marketing.detail.leads.acceptDialog.title')}
          content={Translation('campaign.marketing.detail.leads.acceptDialog.desc')}
          open={open}
          onConfirm={() => {
            acceptSeleted(selectedRows);
            setOpen(false);
          }}
          onClose={() => setOpen(false)}
        />
      )}
    </Box>
  );
});
