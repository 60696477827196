import { RequestData } from 'src/app/common/components/ProTable';
import { fetchProspectCategories, fetchProspects } from '../../../network/prospectCurd';
import { Prospect } from '../../../types/prospect-types';
import { getQueryDate } from '../../../util/purge-prospect.util';

export const getProspectCategories = async (locale: string, dispatch: React.Dispatch<any>) => {
  const res = await fetchProspectCategories(locale, dispatch);
  return res.prospectCategories.sort((a, b) => (a.type > b.type ? 1 : -1));
};

export const getProspects = async (
  dispatch: React.Dispatch<any>,
  params: { page: number; pageSize: number },
  sort?: { [key: string]: any },
  filter?: { [key: string]: string },
): Promise<RequestData<Prospect>> => {
  const { page, pageSize } = params;

  const sortBy = 'updatedAt';

  const res = await fetchProspects({ sortBy, page, pageSize, updatedDateTo: getQueryDate() }, dispatch);

  if (res) {
    const { prospects, totalDocs, ...rest } = res;

    return {
      success: true,
      data: prospects ?? [],
      total: totalDocs,
      ...rest,
    };
  }

  return {
    success: false,
    data: [],
    total: 0,
  };
};
