import { AppConfigs } from '../types/app-configs';

class ConfigManager {
  private KEY = 'appConfigs';
  private CONFIGS: { [key: string]: AppConfigs[] } = {
    [this.KEY]: [],
  };

  public setConfigs(config: AppConfigs[]): void {
    this.CONFIGS[this.KEY] = config;
  }

  private getConfigs(): AppConfigs[] {
    return this.CONFIGS[this.KEY];
  }

  public getConfig(name: string) {
    return this.getConfigs().find(({ name: configName }) => name === configName)?.content;
  }

  public initConfigs(): void {
    const config = this.getConfigs();
    if (config?.length) return;
    try {
      const configs = window.localStorage.getItem(this.KEY);
      const appConfigs = configs ? JSON.parse(configs) : [];
      this.setConfigs(appConfigs);
    } catch (err) {
      console.warn('initConfigs error: ', err);
    }
  }

  constructor() {
    this.initConfigs();
  }
}

export const configManager = new ConfigManager();
