import React, { useState } from 'react';
import { FollowUpStatusEnum, Lead, TabValues } from '../../../types/types';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { accept, rejectFollowUp, rejectOpportunity } from '../../../pages/leads/leads-list/util/api.util';

export type ComponentProps = {
  item: Lead;
  onReject?: (item: Lead) => void;
  onAccept?: (item: Lead) => void;
  onAppt?: (item: Lead) => void;
  onUpdate?: (item: Lead) => void;
  tab?: TabValues;
  actions: (actions: ('clearRow' | 'refresh')[]) => void;
  status?: FollowUpStatusEnum;
};

export const useLeadOperation = ({ item, tab, actions }: ComponentProps) => {
  const { t: Translation } = useTranslation();

  const [showApptDialog, setShowApptDialog] = useState(false);

  const doAppointmentCreate = () => {
    setShowApptDialog(true);
  };

  const dispatch = useDispatch();

  const [toAccept, setToAccept] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);

  const [toReject, setToReject] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const [toUpdate, setToUpdate] = useState(false);

  const doAccept = async () => {
    setAcceptLoading(true);
    const res = await accept(item._id, dispatch, Translation);
    res && actions(['refresh', 'clearRow']);
    setAcceptLoading(false);
  };

  const isOpportunity = !tab || tab === TabValues.opportunities;

  const doReject = async (rejectReason: string[]) => {
    const api = !isOpportunity ? rejectFollowUp : rejectOpportunity;
    setRejectLoading(true);
    const res = await api(item._id, rejectReason, dispatch, Translation);
    res && actions(['refresh', 'clearRow']);
    setRejectLoading(false);
  };

  const doUpdate = async () => {
    setToUpdate(false);
    actions(['refresh']);
  };

  return {
    showApptDialog,
    setShowApptDialog,
    doAppointmentCreate,
    rejectLoading,
    setToReject,
    Translation,
    toReject,
    doReject,
    acceptLoading,
    setToAccept,
    toAccept,
    doAccept,
    setToUpdate,
    toUpdate,
    doUpdate,
  };
};
