import React, { FC, useState, useMemo, Fragment } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useLanding } from './landing.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { OverviewComponent } from './components/overview/overview.component';
import { TalkbotDialogueComponent } from './components/talkbot-dialogue/talkbot-dialogue.component';
import { CircularProgress } from '@mui/material';
import { TalkbotDialogueDescComponent } from './components/talkbot-dialogue-desc/talkbot-dialogue-desc.component';
import { ListComponent } from './components/list/list.component';
import { Card } from 'src/app/common/components/card/card.component';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const LandingPage: React.FC<PageProps> = (props: PageProps) => {
  const { t } = useTranslation();
  const { overviewData, loading, dialogueLoading, dialogueList, curDialogue, setCurDialogue } = useLanding();

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle title={t('title.talkbot.export_leads')} />
      {loading || dialogueLoading ? (
        <div className="tw-flex tw-items-center tw-justify-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Fragment>
          <OverviewComponent data={overviewData} />
          {dialogueList && dialogueList?.length > 0 && (
            <TalkbotDialogueComponent
              data={dialogueList}
              curDialogue={curDialogue}
              onClick={(value) => setCurDialogue(value)}
            />
          )}
          {curDialogue && (
            <Card>
              <TalkbotDialogueDescComponent curDialogue={curDialogue} />
              <ListComponent curDialogue={curDialogue} />
            </Card>
          )}
        </Fragment>
      )}
    </Page>
  );
};
