import React, { memo, useMemo, useRef } from 'react';
import { useStyles } from './referred-by.style';
import { useReferredBy } from './referred-by.hook';
import {
  FullScreenDialog,
  FullScreenDialogProps,
} from 'src/app/common/components/Dialog/full-screen-dialog/full-screen-dialog.component';
import { ProspectFilterTable } from '../prospect-filter-table/prospect-filter-table.component';
import { Box } from '@mui/material';
import { Prospect } from '../../../types/prospect-types';
import { CheckType } from 'src/app/common/components/ProTable';

interface ComponentProps extends Pick<FullScreenDialogProps, 'open' | 'PaperComponent' | 'onClose' | 'keepMounted'> {
  onConfirm?: (rows: Record<string, any>) => void;
  defaultSelectedRows?: Prospect[];
}

export const ReferredBy: React.FC<ComponentProps> = memo(
  ({ open, onConfirm, onClose, defaultSelectedRows, keepMounted = true }: ComponentProps) => {
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    const { Translation, selectedRow, rowSelection } = useReferredBy({ defaultSelectedRows });
    const defaultCacheParams = useRef({ sorter: { displayName: 'asc' } });

    return (
      <FullScreenDialog
        keepMounted={keepMounted}
        open={open}
        title={Translation('pruleads.prospect.referredBy')}
        onClose={onClose}
        disableConfirm={!(selectedRow && selectedRow.length > 0)}
        onConfirm={() => {
          onConfirm?.(selectedRow!);
        }}
      >
        <Box className={styles.tableContainer}>
          <ProspectFilterTable
            rowSelection={rowSelection}
            defaultSelectedRows={defaultSelectedRows}
            checkType={CheckType.radio}
            showActionBar={false}
            cacheParams={defaultCacheParams}
          />
        </Box>
      </FullScreenDialog>
    );
  },
);
