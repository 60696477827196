import { useRequest } from 'ahooks';
import { useEffect } from 'react';
import { useProjectDispatch, useProjectSelector } from 'src/redux/store';
import { getDialogueList } from '../../network/talkbot-export-leads-crud';
import { setActiveFlowIds } from '../../redux/talkbot-redux';

export const useActiveDialogueHook = () => {
  const activeFlowIds = useProjectSelector((state) => state.talkbot.activeFlowIds);
  const dispatch = useProjectDispatch();

  const { runAsync } = useRequest(() => getDialogueList(dispatch), { manual: true });

  useEffect(() => {
    if (!activeFlowIds) {
      runAsync().then((res) => {
        const ids = res?.map((item) => item.flowId);
        dispatch(setActiveFlowIds(ids));
      });
    }
  }, [activeFlowIds]);
};
