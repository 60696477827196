import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRequest } from 'ahooks';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { RouteComponentProps } from 'react-router-dom';
import { LIST_DEFAULT_PARAMS } from '../../../constants';
import { Lead, TabValues } from '../../../types/types';
import {
  getTotalOpportunities,
  getTotalFollowUps,
  getTotalClosed,
  getMarketSource,
  getCampaignsFilter,
  getAgentSourceFilter,
} from './util/api.util';
import {
  getOpportunitiesColumns,
  getFollowUpsColumns,
  getClosedColumns,
  getOpportunitiesFilterConfig,
  getFollowUpFilterConfig,
  getClosedFilterConfig,
} from './util/list.util';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import {
  pRULeadsLeadListTabGaDataClick,
  pRULeadsFollowUpListGaDataClick,
  pRULeadsHomePageGaDataClick,
  pRULeadsOpportunityListGaDataClick,
  pRULeadsOpportunityListGaDataClick1,
  pRULeadsLeadListTabGaDataClick1,
  pRULeadsLeadListTabGaDataClick2,
} from 'src/app/common/ga/types/ga-click-lead';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { getEligibleLeads } from 'src/app/modules/ai-talkbot/common/utils/talkbot.utils';
import { useProjectSelector } from 'src/redux/store';
import { get } from 'lodash';
import { getAppConfigs, APP_CONFIG } from 'src/app/common/utils';
import { useActiveDialogueHook } from 'src/app/modules/ai-talkbot/common/hooks/active-dialogue.hook';

export type ListPageProps = ParamsProps &
  RouteComponentProps & {
    cacheParams: React.MutableRefObject<Record<string, any> | undefined>;
  };

export type AutoCallDialogConfig = {
  open: boolean;
  item?: Lead[];
};

export const useLeadsList = (props: ListPageProps) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(props.cacheParams.current?.selectedTab);
  const [exportData, setExportData] = useState<Lead[]>();
  const activeFlowIds = useProjectSelector((state) => state.talkbot.activeFlowIds);
  const enableMassAcceptLeads = get(getAppConfigs(APP_CONFIG.SALES_COMMON), 'Sales.lead.enableMassAcceptLeads', true);
  useActiveDialogueHook();

  const dispatch = useDispatch();

  const opportunitiesColumns = useMemo(
    () =>
      ({ keyword }: { keyword: string }) =>
        getOpportunitiesColumns({
          keyword,
          operations: { onAutoCall: (item) => setExportData(getEligibleLeads([{ ...item }], activeFlowIds)) },
        }),
    [activeFlowIds],
  );

  const followUpsColumns = useMemo(
    () =>
      ({ keyword }: { keyword: string }) =>
        getFollowUpsColumns({ keyword }),
    [],
  );

  const closedColumns = useMemo(
    () =>
      ({ keyword }: { keyword: string }) =>
        getClosedColumns({ keyword }),
    [],
  );

  const onGaTabEvent = useCallback(
    (newVal: TabValues) => {
      if (newVal !== selectedTab) {
        switch (newVal) {
          case TabValues.closed:
            takeUIClickEvent(pRULeadsLeadListTabGaDataClick);
            break;
          case TabValues.followUp:
            takeUIClickEvent(pRULeadsLeadListTabGaDataClick2);
            break;
          case TabValues.opportunities:
            takeUIClickEvent(pRULeadsLeadListTabGaDataClick1);
            break;
        }
      }
    },
    [selectedTab],
  );

  const onTabChange = useCallback(
    (event: React.SyntheticEvent, newVal: TabValues) => {
      if (props.cacheParams?.current && newVal !== selectedTab) {
        props.cacheParams.current.selectedTab = newVal;
      }
      setSelectedTab(newVal);
      onGaTabEvent(newVal);
    },
    [props.cacheParams, selectedTab, onGaTabEvent],
  );

  const [oppCount, setOppCount] = useState<number>();
  const [follCount, setFollCount] = useState<number>();
  const [cloCount, setCloCount] = useState<number>();

  const opp = props.cacheParams.current?.[TabValues.opportunities];
  const foll = props.cacheParams.current?.[TabValues.followUp];
  const clo = props.cacheParams.current?.[TabValues.closed];

  const { data: opportunitiesCountAndAccept, runAsync: runAsyncOpp } = useRequest(
    async () => getTotalOpportunities(opp.filterState, opp.sorter, opp.filter, dispatch),
    {
      manual: false,
      onSuccess: (res) => {
        setOppCount(res?.total);
      },
    },
  );

  const { runAsync: runAsyncFoll } = useRequest(
    async () => getTotalFollowUps(foll.filterState, foll.sorter, foll.filter, dispatch),
    {
      manual: false,
      onSuccess: (res) => {
        setFollCount(res);
      },
    },
  );

  const { runAsync: runAsyncClo } = useRequest(
    async () => getTotalClosed(clo.filterState, clo.sorter, clo.filter, dispatch),
    {
      manual: false,
      onSuccess: (res) => {
        setCloCount(res);
      },
    },
  );

  const { data: marketSourceItems } = useRequest(async () => getMarketSource(TabValues.opportunities, dispatch), {
    manual: false,
  });

  const { data: campaignItems } = useRequest(async () => getCampaignsFilter(TabValues.opportunities, dispatch), {
    manual: false,
  });

  const { data: followUpMarketSourceItems } = useRequest(async () => getMarketSource(TabValues.followUp, dispatch), {
    manual: false,
  });

  const { data: followUpCampaignItems } = useRequest(async () => getCampaignsFilter(TabValues.followUp, dispatch), {
    manual: false,
  });

  const { data: closedMarketSourceItems } = useRequest(async () => getMarketSource(TabValues.closed, dispatch), {
    manual: false,
  });

  const { data: closedCampaignItems } = useRequest(async () => getCampaignsFilter(TabValues.closed, dispatch), {
    manual: false,
  });

  const { data: agentSourceItems } = useRequest(async () => getAgentSourceFilter(dispatch), {
    manual: false,
  });

  const opportunitiesFilterConfigs = useMemo(
    () => getOpportunitiesFilterConfig(t, marketSourceItems, campaignItems),
    [campaignItems, marketSourceItems, t],
  );

  const followUpFilterConfigs = useMemo(
    () => getFollowUpFilterConfig(t, followUpMarketSourceItems, followUpCampaignItems, agentSourceItems),
    [followUpCampaignItems, followUpMarketSourceItems, agentSourceItems, t],
  );

  const closedFilterConfigs = useMemo(
    () => getClosedFilterConfig(t, closedMarketSourceItems, closedCampaignItems, agentSourceItems),
    [closedCampaignItems, closedMarketSourceItems, agentSourceItems, t],
  );

  const updatePageInfo = useCallback((updateFunc: (val: number) => void, pageInfo?: { total?: number }) => {
    if (typeof pageInfo?.total === 'number') {
      updateFunc(pageInfo?.total);
    }
  }, []);

  const onAccepted = useCallback(
    (leads: Lead[]) => {
      runAsyncFoll();
    },
    [runAsyncFoll],
  );

  const onUpdated = useCallback(() => {
    runAsyncClo();
  }, [runAsyncClo]);

  const gaOppData = useMemo(
    () => ({
      accept: pRULeadsOpportunityListGaDataClick,
      reject: pRULeadsOpportunityListGaDataClick1,
      update: pRULeadsFollowUpListGaDataClick,
      newApp: pRULeadsHomePageGaDataClick,
    }),
    [],
  );

  const oppProviderValue = useMemo(
    () => ({
      gaData: gaOppData,
      onAccept: onAccepted,
    }),
    [gaOppData, onAccepted],
  );

  const follProviderValue = useMemo(
    () => ({
      gaData: gaOppData, // todo:
      onUpdateFoll: onUpdated,
    }),
    [gaOppData, onUpdated],
  );

  return {
    activeFlowIds,
    exportData,
    selectedTab,
    setOppCount,
    setFollCount,
    setCloCount,
    opportunitiesColumns,
    followUpsColumns,
    closedColumns,
    onTabChange,
    oppCount,
    follCount,
    cloCount,
    opportunitiesCountAndAccept,
    opportunitiesFilterConfigs,
    followUpFilterConfigs,
    closedFilterConfigs,
    updatePageInfo,
    oppProviderValue,
    follProviderValue,
    onAccepted,
    onGaTabEvent,
    setExportData,
    enableMassAcceptLeads,
  };
};
