import React from 'react';
import { FilterComponentsEnum } from 'src/app/common/components/list-filter/filter-components.enum';
import { Prospect, ProspectCategory, ProspectSourceTypes, ProspectTypes, ProspectSegmentEnum, ProspectupgraderShortfallEnum, ProspectIsCustomerOpusEnum } from '../../../../types/prospect-types'; // PACS Customization | [PACSPFAP-3059]
import { HighlightMatchedText } from 'src/app/common/components/highlight-matched-text/highlight-matched-text.component';
import { ListFilterConfig } from 'src/app/common/components/list-filter/list-filter.component';
import { ProColumns } from 'src/app/common/components/ProTable';
import {
  renderProspectName,
  renderGender,
  renderTag,
  renderSource,
  renderCategory,
  renderDate,
  renderDateTime,
} from 'src/app/modules/Prospect/util/table-column.util';

export const getFilterConfig: (
  t: (val: string) => string,
  prospectCategories?: ProspectCategory[],
) => Array<ListFilterConfig> = (t, prospectCategories) => [
  {
    key: 'types',
    title: t('pruleads.prospect.list.type'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: [
      { itemKey: ProspectTypes.prospect_type_customer, itemLabel: t('pruleads.prospect.list.prospectTypeCustomer') },
      {
        itemKey: ProspectTypes.prospect_type_non_customer,
        itemLabel: t('pruleads.prospect.list.prospectTypeNonCustomer'),
      },
    ],
  },
  {
    key: 'sources',
    title: t('pruleads.prospect.list.source'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: [
      { itemKey: ProspectSourceTypes.prospect_source_marketing, itemLabel: t('pruleads.prospect.list.salesMarketing') },
      { itemKey: ProspectSourceTypes.prospect_source_agent, itemLabel: t('pruleads.prospect.list.salesAgent') },
    ],
  },
  {
    key: 'categories',
    title: t('pruleads.prospect.list.category'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: prospectCategories?.map(({ _id: itemKey, type: itemLabel }) => ({ itemKey, itemLabel })) ?? [],
  },
  {
    key: 'dateRange',
    title: t('pruleads.prospect.list.createDate'),
    type: FilterComponentsEnum.RADIO_GROUP,
    items: [],
  },
  // PACS Customization | [PACSPFAP-3059] | Start
  {
    key: 'segments',
    title: t('pruleads.prospect.list.segment'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: [
      { itemKey: ProspectSegmentEnum.MM, itemLabel: t('pruleads.prospect.list.segment.mass') },
      { itemKey: ProspectSegmentEnum.EA, itemLabel: t('pruleads.prospect.list.segment.emergingAffluent') },
      { itemKey: ProspectSegmentEnum.AF, itemLabel: t('pruleads.prospect.list.segment.affluent') },
      { itemKey: ProspectSegmentEnum.HN, itemLabel: t('pruleads.prospect.list.segment.highNetworth') },
      { itemKey: ProspectSegmentEnum.UH, itemLabel: t('pruleads.prospect.list.segment.ultraHighNetworth') },
    ],
  },
  {
    key: 'upgraderShortfall',
    title: t('pruleads.prospect.list.upgraderShortfallRange'),
    type: FilterComponentsEnum.PURE_RADIO_GROUP,
    items: [
      { itemKey: ProspectupgraderShortfallEnum.below_3k, itemLabel: t('pruleads.prospect.list.upgraderShortfallRange.upgraderShortfall_below_3k') },
      { itemKey: ProspectupgraderShortfallEnum.below_5k, itemLabel: t('pruleads.prospect.list.upgraderShortfallRange.upgraderShortfall_below_5k') },
      { itemKey: ProspectupgraderShortfallEnum.below_10k, itemLabel: t('pruleads.prospect.list.upgraderShortfallRange.upgraderShortfall_below_10k') },
      { itemKey: ProspectupgraderShortfallEnum.below_20k, itemLabel: t('pruleads.prospect.list.upgraderShortfallRange.upgraderShortfall_below_20k') },
      { itemKey: ProspectupgraderShortfallEnum.below_30k, itemLabel: t('pruleads.prospect.list.upgraderShortfallRange.upgraderShortfall_below_30k') },
    ],
  },
  {
    key: 'isCustomerOpus',
    title: t('pruleads.prospect.list.isCustomerOpus'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: [
      { itemKey: ProspectIsCustomerOpusEnum.Yes, itemLabel: t('pruleads.prospect.list.isCustomerOpus.yes') },
      { itemKey: ProspectIsCustomerOpusEnum.No, itemLabel: t('pruleads.prospect.list.isCustomerOpus.no') },
    ],
  },
  // PACS Customization | [PACSPFAP-3059] | End
];

export const getColumns = ({
  t,
  prospectCategories,
  keyword,
  styles,
}: {
  t: (val: string) => string;
  prospectCategories?: ProspectCategory[];
  keyword?: string;
  styles: Record<string, any>;
}): ProColumns<Prospect>[] => [
  {
    title: t('pruleads.prospect.list.column.name'),
    dataIndex: 'displayName',
    sorter: true,
    width: '290px',
    render: (currValue: string, item) => renderProspectName(currValue, item, keyword),
  },
  {
    title: t('pruleads.prospect.list.column.gender'),
    dataIndex: 'gender',
    width: '176px',
    render: (currValue: 'F' | 'M') => renderGender(currValue, t),
  },
  {
    title: t('pruleads.prospect.list.column.prospectType'), // PACS Customization: PACSPFAP-3083 | Updated Label
    dataIndex: 'types',
    width: '176px',
    render: renderTag,
  },
  {
    title: t('pruleads.prospect.list.column.source'),
    dataIndex: 'sourceType',
    width: '176px',
    render: (currValue: string) => renderSource(currValue, t),
  },
  {
    title: t('pruleads.prospect.list.column.category'),
    dataIndex: 'prospectCategory',
    width: '176px',
    render: (currValue: string) => renderCategory(currValue, prospectCategories),
  },
  {
    title: `${t('pruleads.prospect.list.column.leadInProgress')}`,
    sorter: true,
    dataIndex: 'activeLeads',
    width: '176px',
  },
  {
    title: t('pruleads.prospect.list.createDate'),
    sorter: true,
    dataIndex: 'createdAt',
    width: '176px',
    render: renderDate,
  },
  {
    title: t('pruleads.prospect.list.column.lastUpdateTime'),
    sorter: true,
    dataIndex: 'updatedAt',
    width: '176px',
    render: renderDateTime,
  },
  {
    title: t('pruleads.prospect.list.column.lastViewedAt'),
    sorter: true,
    dataIndex: 'lastViewedAt',
    width: '176px',
    render: renderDateTime,
  },
  {
    title: t('pruleads.prospect.list.column.phoneNumber'),
    dataIndex: 'phoneNumber',
    width: '176px',
    render: (currValue: string, item) => {
      return (
        <HighlightMatchedText textStyle={{ color: '#666666' }} highLightText={keyword}>
          {(item.countryCode ?? '') + (currValue ?? '')}
        </HighlightMatchedText>
      );
    },
  },
];
