import { useCallback, useState } from 'react';
import { ListFilterData } from './list-filter.component';

interface HookProps {
  filterValues?: ListFilterData;
  defaultValues?: ListFilterData;
}

export const useListFilter = ({ filterValues, defaultValues }: HookProps) => {
  // redux
  const [filter, setFilter] = useState<ListFilterData>(filterValues || {});
  const resetFilter = useCallback(() => {
    setFilter(defaultValues ?? {});
  }, [defaultValues]);

  return {
    filter,
    setFilter,
    resetFilter,
  };
};
