export const styles = {
  labelView: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: 4,
  },
  potentialCustomerColor: {
    backgroundColor: '#FFE4E6',
    color: '#E8192C',
  },

  existingCustomerColor: {
    backgroundColor: '#EBF3FF',
    color: '#4386E5',
  },
  referralCodeColor: {
    backgroundColor: '#DFF7EF',
    color: '#24C791',
  },
};
