import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './prospect-name.style';
import { Prospect } from '../../../types/prospect-types';
import { checkDateEquals, reconstuctNameByLastName } from 'src/app/common/utils';
import CakeIcon from '@mui/icons-material/Cake';
import { HighlightMatchedText } from 'src/app/common/components/highlight-matched-text/highlight-matched-text.component';

type ComponentProps = {
  displayName?: string;
  item: Prospect;
  keyword?: string;
};

export const ProspectName: React.FC<ComponentProps> = memo(
  ({ displayName: currValue, item, keyword }: ComponentProps) => {
    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);
    return (
      <Box>
        <HighlightMatchedText highLightText={keyword ?? ''}>
          {currValue || reconstuctNameByLastName(item.firstName, item.lastName)}
        </HighlightMatchedText>
        {item.birthDate && checkDateEquals(item.birthDate, new Date()) ? (
          <Box className={styles.birthdayIconContainer}>
            <CakeIcon className={styles.birthdayIcon} />
          </Box>
        ) : null}
      </Box>
    );
  },
);
