import React, { useCallback, useMemo, useState } from 'react';
import { FilterState } from '../ProTable/ProFilter';
import { ProColumns } from '../ProTable';

export type UseFilterTableContainerProps<T> = {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  getColumns: (params: { keyword: string }) => ProColumns<T>[];
};

export const useFilterTableContainer = <T>({ cacheParams, getColumns }: UseFilterTableContainerProps<T>) => {
  const [keyword, setKeyword] = useState(cacheParams?.current?.filterState?.keyword ?? '');

  const onFilterChange = useCallback(
    (params: FilterState) => {
      const { keyword } = params.filterState;
      if (cacheParams) {
        if (cacheParams.current) {
          Object.assign(cacheParams.current, params);
        } else {
          cacheParams.current = params;
        }
      }
      setKeyword(keyword);
    },
    [cacheParams],
  );

  const columns = useMemo(() => getColumns({ keyword }), [keyword, getColumns]);

  return {
    columns,
    keyword,
    onFilterChange,
  };
};
