import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'flex',
    flex: 1,
    borderRadius: '4px',
    height: '48px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  icon_reminder: {
    width: '22px',
    height: '22px',
    color: '#0288D1',
  },
  icon_warning: {
    width: '22px',
    height: '22px',
    color: '#EF6C00',
  },
  text_fw500_fs16: {
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: '12px',
    lineHeight: '24px',
  },
  textIcon: {
    display: 'flex',
  },
  acceptBtn: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '22px',
  },

  warning: {
    backgroundColor: '#FFF4E5',
    color: '#663C00',
  },
  reminder: {
    backgroundColor: '#E5F6FD',
    color: '#014361',
  },
}));
