/* eslint-disable react/style-prop-object */
import React, { memo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SearchIcon: React.FC<SvgIconProps> = memo((props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.31 15.9L20.71 19.29C20.8993 19.4778 21.0058 19.7334 21.0058 20C21.0058 20.2666 20.8993 20.5222 20.71 20.71C20.5222 20.8993 20.2666 21.0058 20 21.0058C19.7334 21.0058 19.4778 20.8993 19.29 20.71L15.9 17.31C14.5025 18.407 12.7767 19.0022 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19.0022 12.7767 18.407 14.5025 17.31 15.9ZM11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5Z"
          fill="#919EAB"
        />
      </svg>
    </SvgIcon>
  );
});

export const FilterIcon: React.FC<SvgIconProps> = memo((props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.26654 0H16.7805C17.387 0 17.8786 0.492487 17.8786 1.1C17.8786 1.3629 17.7846 1.61711 17.6136 1.81657L12.6713 7.5L12.672 17.5C11.61 17.0747 8.22975 15.7874 6.80313 15.2451C6.4148 15.0974 6.15802 14.7253 6.15802 14.3098V7.5L0.489925 1.87766C0.0918051 1.47869 0.0634872 0.849655 0.404886 0.417954L0.490234 0.322028C0.696152 0.115832 0.975389 0 1.26654 0ZM14.8246 2H3.43441L8.15455 6.5V13.5L10.6748 14.5L10.6752 6.5L14.8246 2Z"
          fill="#212B36"
        />
      </svg>
    </SvgIcon>
  );
});

export const ClearIcon: React.FC<Omit<SvgIconProps, 'color'> & { color?: string }> = memo(
  (props: Omit<SvgIconProps, 'color'> & { color?: string }) => {
    const { color = '#E8192C', ...rest } = props;
    return (
      <SvgIcon {...rest}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
          <rect x="-0.00537109" y="3" width="17.9687" height="2" fill={color} />
          <rect x="3.98779" width="9.98261" height="2" fill={color} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.99121 5C1.99121 3.89543 2.88508 3 3.98773 3H13.9703C15.073 3 15.9669 3.89543 15.9669 5V17C15.9669 18.1046 15.073 19 13.9703 19H3.98773C2.88508 19 1.99121 18.1046 1.99121 17V5ZM3.98773 5V17H13.9703V5H3.98773Z"
            fill={color}
          />
          <rect x="7.98096" y="8" width="6" height="1.99652" transform="rotate(90 7.98096 8)" fill={color} />
          <rect x="11.9736" y="8" width="6" height="1.99652" transform="rotate(90 11.9736 8)" fill={color} />
        </svg>
      </SvgIcon>
    );
  },
);

export const EditIcon: React.FC<SvgIconProps> = memo((props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2628 0.174957L17.8315 5.73028L5.57303 17.9593L0.0536351 18.0229L-0.00976562 12.4181L12.2628 0.174957ZM1.99964 13.2386L12.2628 3L14.9996 5.73028L4.73649 15.9688L2.03088 16L1.99964 13.2386Z"
          fill={'#E8192C'}
        />
      </svg>
    </SvgIcon>
  );
});

export const MergeIcon: React.FC<SvgIconProps> = memo((props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect x="4" y="9" width="11" height="11" stroke="#E8192C" strokeWidth="2" />
        <rect x="9" y="4" width="11" height="11" stroke="#E8192C" strokeWidth="2" />
      </svg>
    </SvgIcon>
  );
});

export const EmptyIcon: React.FC<SvgIconProps> = memo((props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="162" height="155" viewBox="0 0 162 155" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 9909" filter="url(#filter0_d_4270_3281)">
          <path
            id="Subtract"
            d="M42.602 22.3183L21 60.6938V113.352C21 121.724 27.7865 128.51 36.158 128.51H125.842C134.214 128.51 141 121.724 141 113.352V60.6938L118.913 22.242C117.111 19.1045 113.769 17.1699 110.151 17.1699H51.408C47.7583 17.1699 44.3922 19.1379 42.602 22.3183Z"
            fill="url(#paint0_linear_4270_3281)"
          />
          <path
            id="Subtract_2"
            d="M45.7432 22.2226V60.6938V113.352C45.7432 121.724 52.5296 128.51 60.9011 128.51H101.101C109.472 128.51 116.259 121.724 116.259 113.352V60.6938V22.2226C116.259 19.4321 113.996 17.1699 111.206 17.1699H50.7958C48.0053 17.1699 45.7432 19.4321 45.7432 22.2226Z"
            fill="url(#paint1_linear_4270_3281)"
          />
          <g id="Group 9625">
            <g id="Group 9624">
              <g id="Vector 44" filter="url(#filter1_dd_4270_3281)">
                <path
                  d="M61.9928 5.07395C61.9928 5.07395 52.4082 4.57393 52.4082 13.3929C52.4082 13.3929 52.996 24.6197 48.4933 59.4169C43.9906 94.2142 32.0061 107.093 37.3776 107.288L103.179 109.682C103.39 103.87 106.116 92.6137 110.341 60.6381C112.655 43.1267 112.993 31.4467 112.993 22.5739C112.993 6.07391 118.993 5.07393 118.993 5.07393L61.9928 5.07395Z"
                  fill="url(#paint2_linear_4270_3281)"
                />
                <path
                  d="M61.9928 5.07395C61.9928 5.07395 52.4082 4.57393 52.4082 13.3929C52.4082 13.3929 52.996 24.6197 48.4933 59.4169C43.9906 94.2142 32.0061 107.093 37.3776 107.288L103.179 109.682C103.39 103.87 106.116 92.6137 110.341 60.6381C112.655 43.1267 112.993 31.4467 112.993 22.5739C112.993 6.07391 118.993 5.07393 118.993 5.07393L61.9928 5.07395Z"
                  fill="url(#paint3_linear_4270_3281)"
                  fillOpacity="0.2"
                />
              </g>
              <g id="Vector 48" filter="url(#filter2_f_4270_3281)">
                <path
                  d="M63.2361 70.5562C60.3465 63.2236 52.1515 63.7387 48.4152 64.9129C57.8368 64.453 59.6401 67.9436 60.5482 72.3102C61.2746 75.8035 64.1527 78.4178 65.5009 79.2883L87.9358 79.2336C93.3201 79.2204 94.6652 78.7803 98.2526 77.8978C101.84 77.0153 100.933 73.0855 101.82 68.7145C102.529 65.2177 107.494 64.9144 109.888 65.1998L109.884 63.4523C108.986 63.4545 105.027 59.0953 101.796 59.1032C97.7581 59.113 98.6566 59.5477 93.2818 63.4928C90.686 65.398 88.3546 66.9999 83.4275 70.5069L68.6322 75.3487C67.7355 75.6422 65.4043 76.4925 63.2361 70.5562Z"
                  fill="url(#paint4_linear_4270_3281)"
                  fillOpacity="0.4"
                />
              </g>
            </g>
          </g>
          <g id="Group 9623">
            <g id="Subtract_3" filter="url(#filter3_i_4270_3281)">
              <path
                d="M60 68.0863C60 63.8798 56.59 60.4697 52.3834 60.4697H21V113.353C21 121.725 27.7865 128.511 36.158 128.511H125.842C134.214 128.511 141 121.725 141 113.353V60.4697H109.617C105.41 60.4697 102 63.8798 102 68.0863C102 72.2928 98.59 75.7028 94.3834 75.7028H67.6166C63.41 75.7028 60 72.2928 60 68.0863Z"
                fill="url(#paint5_linear_4270_3281)"
              />
            </g>
          </g>
          <g
            id="Intersect"
            style={{
              mixBlendMode: 'multiply',
            }}
            opacity="0.1"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M116.998 127.727L64.499 75.2282L64.5514 75.1257C65.4564 75.5131 66.4531 75.7275 67.4999 75.7275H94.4999C98.642 75.7275 102 72.3696 102 68.2275C102 64.0854 105.358 60.7275 109.5 60.7275H141V112.569C141 120.941 134.213 127.727 125.842 127.727H116.998Z"
              fill="url(#paint6_linear_4270_3281)"
            />
          </g>
          <g id="Line 105" filter="url(#filter4_d_4270_3281)">
            <line
              x1="95.2337"
              y1="60.3493"
              x2="107.11"
              y2="73.8757"
              stroke="white"
              strokeWidth="6"
              strokeLinecap="round"
            />
          </g>
          <g id="Group 9628" filter="url(#filter5_bd_4270_3281)">
            <circle
              id="Ellipse 60"
              cx="84.6386"
              cy="46.7131"
              r="15"
              transform="rotate(17.7904 84.6386 46.7131)"
              stroke="white"
              strokeWidth="6"
            />
          </g>
          <path
            id="Subtract_4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113.439 12.6513L113.287 16.8177L125.104 17.0544C125.407 17.1771 125.733 17.1877 126.092 17.0742C126.151 17.0556 126.211 17.0336 126.272 17.0082C125.102 16.7932 124.111 16.0696 123.535 15.0752C123.523 15.075 123.511 15.0746 123.5 15.0742C123.47 15.012 123.44 14.9328 123.409 14.8386C123.155 14.3239 123.009 13.7467 123 13.1364C122.862 12.4553 122.721 11.6842 122.586 10.9451C122.359 9.70841 122.149 8.56115 122 8.07419C121.416 6.17006 120 5.0742 119 5.07422C115.5 5.07422 113.563 9.21868 113.439 12.6513Z"
            fill="#EB3838"
          />
          <path
            id="Ellipse 131"
            d="M131 13.0742C131 15.2834 129.209 17.0742 127 17.0742C124.791 17.0742 123 15.2834 123 13.0742C123 13.0742 124.791 13.0742 127 13.0742C129.209 13.0742 131 13.0742 131 13.0742Z"
            fill="#FF8181"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_4270_3281"
            x="0.789368"
            y="0.0157013"
            width="160.421"
            height="163.864"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="15.158" />
            <feGaussianBlur stdDeviation="10.1053" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.207843 0 0 0 0 0.258824 0 0 0 0.04 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4270_3281" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4270_3281" result="shape" />
          </filter>
          <filter
            id="filter1_dd_4270_3281"
            x="28.4396"
            y="2.54203"
            width="98.1326"
            height="119.771"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="5.05266" />
            <feGaussianBlur stdDeviation="3.78949" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.704449 0 0 0 0 0.704449 0 0 0 0 0.704449 0 0 0 0.6 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4270_3281" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="3" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.314913 0 0 0 0 0.372035 0 0 0 0 0.404167 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="effect1_dropShadow_4270_3281" result="effect2_dropShadow_4270_3281" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4270_3281" result="shape" />
          </filter>
          <filter
            id="filter2_f_4270_3281"
            x="42.0992"
            y="52.7877"
            width="74.1043"
            height="32.8162"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="3.15791" result="effect1_foregroundBlur_4270_3281" />
          </filter>
          <filter
            id="filter3_i_4270_3281"
            x="21"
            y="60.4697"
            width="120"
            height="68.041"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2.52633" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.825554 0 0 0 0 0.830047 0 0 0 0 0.879474 0 0 0 1 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_4270_3281" />
          </filter>
          <filter
            id="filter4_d_4270_3281"
            x="90.9702"
            y="54.8233"
            width="27.9813"
            height="29.6317"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="3.78949" dy="2.52633" />
            <feGaussianBlur stdDeviation="2.52633" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.437782 0 0 0 0 0.366642 0 0 0 0 0.366642 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4270_3281" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4270_3281" result="shape" />
          </filter>
          <filter
            id="filter5_bd_4270_3281"
            x="64.1075"
            y="26.1817"
            width="46.1151"
            height="44.8519"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.26316" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4270_3281" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="3.78949" dy="2.52633" />
            <feGaussianBlur stdDeviation="1.89475" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.762782 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="effect1_backgroundBlur_4270_3281" result="effect2_dropShadow_4270_3281" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4270_3281" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_4270_3281"
            x1="81"
            y1="17.1699"
            x2="81"
            y2="128.51"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CFD6E5" />
            <stop offset="1" stopColor="#CED0D4" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_4270_3281"
            x1="81.251"
            y1="17.1699"
            x2="81.251"
            y2="128.51"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E5ED" />
            <stop offset="1" stopColor="#CCD4E3" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_4270_3281"
            x1="69.8122"
            y1="-16.0234"
            x2="85.1802"
            y2="82.095"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFBF9A" />
            <stop offset="0.989603" stopColor="#FF3939" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_4270_3281"
            x1="112.993"
            y1="25.574"
            x2="105.993"
            y2="25.074"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_4270_3281"
            x1="74.9235"
            y1="79.2653"
            x2="75.3234"
            y2="68.7782"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EB2020" />
            <stop offset="1" stopColor="#CFABAB" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_4270_3281"
            x1="52.5518"
            y1="61.4852"
            x2="83.6269"
            y2="149.359"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0095307" stopColor="#F5F7FA" />
            <stop offset="0.374871" stopColor="#E9ECF4" />
            <stop offset="1" stopColor="#D2D8E5" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_4270_3281"
            x1="102.749"
            y1="60.7275"
            x2="102.749"
            y2="127.727"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8EA0B2" />
            <stop offset="1" stopColor="#B4C0CC" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
});

export const ProgressIcon: React.FC<SvgIconProps> = memo((props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 9475">
          <circle id="Ellipse 117" opacity="0.2" cx="9" cy="9" r="9" fill="#38527E" />
          <path
            id="component / progress_bar / in_progress"
            d="M13.5 9C13.5 11.4853 11.4853 13.5 9 13.5C6.51472 13.5 4.5 11.4853 4.5 9C4.5 6.51472 6.51472 4.5 9 4.5C11.4853 4.5 13.5 6.51472 13.5 9Z"
            fill="white"
          />
          <path
            id="component / progress_bar / in_progress (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25ZM9 13.5C11.4853 13.5 13.5 11.4853 13.5 9C13.5 6.51472 11.4853 4.5 9 4.5C6.51472 4.5 4.5 6.51472 4.5 9C4.5 11.4853 6.51472 13.5 9 13.5Z"
            fill="#38527E"
          />
        </g>
      </svg>
    </SvgIcon>
  );
});
