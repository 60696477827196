import { Grid, TextField } from '@mui/material';
import React, { memo } from 'react';
import { Card } from 'src/app/common/components/card/card.component';
import {
  FullScreenDialogProps,
  FullScreenDialog,
} from 'src/app/common/components/Dialog/full-screen-dialog/full-screen-dialog.component';
import { Translation } from 'src/app/i18n';
import { LeadDetail } from 'src/app/modules/Leads/types/lead-detail-types';
import { ProspectBasicInfo } from 'src/app/modules/Prospect/pages/prospect-detail/component/prospect-basic-info/prospect-basic-info.component';

interface ComponentProps extends Pick<FullScreenDialogProps, 'open' | 'PaperComponent' | 'onClose' | 'keepMounted'> {
  data: LeadDetail;
}

export const ProspectMasterPreview: React.FC<ComponentProps> = memo(
  ({ open, onClose, data, keepMounted = true }: ComponentProps) => {
    const readonly = true;
    const renderField = (label: string, value: string) => {
      return (
        value && (
          <Grid item xs={6}>
            <TextField
              label={label}
              variant="outlined"
              defaultValue={value}
              fullWidth
              size="medium"
              inputProps={{
                readOnly: readonly,
              }}
            />
          </Grid>
        )
      );
    };

    return (
      <FullScreenDialog
        keepMounted={keepMounted}
        open={open}
        title={Translation('salesLdProspectDetail')}
        onClose={onClose}
        onConfirm={onClose}
      >
        {data?.prospectMaster && (
          <div>
            <ProspectBasicInfo datail={data.prospectMaster} prospectCategory={null} />

            <Card>
              <div className="tw-mr-6 tw-ml-6">
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  {renderField(Translation('first_name'), data?.prospectMaster.firstName)}
                  {renderField(Translation('last_name'), data?.prospectMaster.lastName)}
                  {renderField(Translation('salesLdOccupation'), data?.prospectMaster.occupation)}
                  {renderField(Translation('company_of_employment'), data?.prospectMaster.employmentCompany)}
                  {renderField(Translation('salesLdWorkPosition'), data?.prospectMaster.workPosition)}
                  {renderField(Translation('salesLdCity'), data?.prospectMaster.city)}
                  {renderField(Translation('salesLdState'), data?.prospectMaster.state)}
                  {renderField(Translation('salesLdZipCode'), data?.prospectMaster.zipCode)}
                  {renderField(Translation('salesLdOptOutStatus'), data?.prospectMaster.optOutStatus)}
                  {renderField(Translation('salesLdNotes'), data?.prospectMaster.prospectNotes)}
                </Grid>
              </div>
            </Card>
          </div>
        )}
      </FullScreenDialog>
    );
  },
);
