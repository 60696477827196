import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    width: '588px',
    margin: 0,
  },
  btn: {
    '--active-color': '#E8192C',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 24,
    fontSize: '16px',
    fontWeight: 400,
    borderColor: '#F0F0F0',
  },
  active: {
    color: 'var(--active-color)',
    borderWidth: 2,
    borderColor: 'var(--active-color)',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  radioContainer: {
    display: 'flex',
    fontSize: '16px',
    lineHeight: '24px',
    alignItems: 'center',
    marginTop: 24,
  },
  checkbox: {
    padding: 0,
    marginRight: 16,
  },
}));
