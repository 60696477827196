import { get, findIndex, nth, last, isEmpty } from 'lodash';
import { FollowUp, LeadCategoryEnum, SalesLeadStatusEnum } from './lead-status.enum';
import { AgentStatusEnum, FollowUpStatusEnum } from '../../types/types';
const defaultConfig = require('./sales-lead.config.json');
/**
 * Recruit Lead Full Config
 * @returns
 */
export function getSalesLeadConfig() {
  // todo: change it to DB config if needed.
  return defaultConfig.salesFollowUpStatus;
}
/**
 * convert followUp array to timeline array, reverse it and add extra props to array item.
 * example:
 * input: followUp: [{ status: 'contactSuccess' }, { status: 'met' }, { status: 'dead' }];
 * output: [
    {"status": "policy", "icon": "pending", "isActive": false, "createdAt": ""},
    {"status": "proposal","icon": "pending","isActive": false, "createdAt": ""},
    {"status": "quote","icon": "dead","isActive": false, "createdAt": "", "reasons": []},
    {"status": "met","icon": "completed","isActive": false, "createdAt": ""},
    {"status": "contactSuccess","icon": "completed","isActive": false, "createdAt": ""},
    {"status": "created","icon": "completed","isActive": false,"isFirst": true, "createdAt": ""}]
 * @param followUp array: follow up array
 * @param leadCreatedAt date-string: lead created at
 * @returns timeline array
 */
export function convertStatusHistory(
  followUp: FollowUp[] = [],
  leadCreatedAt: string, // date string
): {
  stageBarList: FollowUp[]; // target array for status-history
  isClosed: boolean; // lead is dead or policy-issued
} {
  const { stageBarList, activeStep, isClosed } = convertFollowUp(followUp);
  const stageBarListMap = stageBarList.map((item, index) => {
    const isActive = index <= activeStep;
    return {
      ...item,
      isActive: isActive,
    };
  });
  // status-history display in desc order
  stageBarListMap.reverse();
  // push the first item (Created/Accepted), it's not in the DB followup array
  stageBarListMap.push({
    status: 'created',
    icon: 'completed',
    createdAt: leadCreatedAt,
    isFirst: true,
    isActive: isClosed ? false : true,
  });

  return { stageBarList: stageBarListMap, isClosed };
}

export function formatDeadReason(
  t: Function,
  deadReasons: string[] | undefined,
  deadReason = '',
  leadCategory: LeadCategoryEnum = LeadCategoryEnum.SALES_LEAD,
) {
  if (!Array.isArray(deadReasons)) {
    return '';
  }
  const arrReason = deadReasons.map((item) => {
    if (item === 'leadCantConvert') {
      return deadReason ?? '';
    }

    if (leadCategory === LeadCategoryEnum.SALES_LEAD) {
      const reasonArray = item.split('&');
      const i18nKey = `lead_dead_reason_${reasonArray[0]}`;
      return t(i18nKey, { days: reasonArray?.[1] });
    } else {
      const i18nKey = `lead_dead_reason_${item}`;
      return t(i18nKey);
    }
  });
  return arrReason.join(', ');
}
/**
 * convert followUp array to stepper array, add extra icon prop to array item.
 * example:
 * input: [{ status: 'contactSuccess' }, { status: 'met' }, { status: 'dead' }];
 * output: [
  { status: 'contactSuccess', icon: 'completed' },
  { status: 'met', icon: 'completed' },
  { status: 'quote', icon: 'dead' },
  { status: 'proposal', icon: 'pending' },
  { status: 'policy', icon: 'pending' }]
 * @param followUp
 * @returns
 */
export function convertFollowUp(
  followUp: FollowUp[] = [],
  agentStatus?: string,
): {
  stageBarList: FollowUp[];
  activeStep: number;
  isClosed: boolean;
} {
  // last-follow-up status
  const lastStatus = getLastFollowupStatus(followUp);
  let activeStep = agentStatus === AgentStatusEnum.pending ? -1 : 0; // when followUp array is empty (followUp=[]), first lead-status is active; for opportunity, it should be -1
  if (!isEmpty(followUp)) {
    // index of next-follow-up status , -1 if lead is closed(dead or policy issued)
    activeStep = getActiveStepIndex(lastStatus);
  }

  const deadIndex = followUp.findIndex((step) => step.status === SalesLeadStatusEnum.dead);
  const isClosed = followUp.some(
    (step) => step.status === SalesLeadStatusEnum.dead || step.status === SalesLeadStatusEnum.policy,
  );

  const stageBarList = getStageBarList();
  const stageBarListMap = stageBarList.map((status: string) => {
    const completedStep = followUp.find((step) => step.status === status);
    if (completedStep) {
      // found item, means current step is 'completed', otherwise 'pending'
      return {
        ...completedStep,
        icon: 'completed',
      };
    } else {
      return {
        status: status,
        icon: 'pending',
      };
    }
  });

  if (deadIndex !== -1) {
    // lead is dead, mark next-follow-up step icon="dead".
    // at this time: deadIndex = next-follow-up index
    stageBarListMap[deadIndex] = {
      ...followUp[deadIndex],
      icon: 'dead',
      status: stageBarList[deadIndex],
    };
  } else {
    if (activeStep !== -1) {
      // lead is NOT dead, mark next-follow-up icon="active"
      stageBarListMap[activeStep].icon = 'active';
    }
  }
  return { stageBarList: stageBarListMap, activeStep, isClosed };
}

/**
 * get current active step index, return -1 if not-found (dead or policy-issued)
 * @param status last-follow-up status
 * @returns
 */
export function getActiveStepIndex(status: string | undefined) {
  const followUpStatusList = getSalesLeadConfig().followUpStatusList;
  const indexDefault = -1;

  const foundIndex = findIndex(followUpStatusList, (item) => item === status);
  const nextIndex = foundIndex === -1 ? indexDefault : foundIndex + 1; // use default if not found
  return nextIndex;
}

/**
 * Stage Bar List, for lead-status and lead-update component
 * @param appendDeadStatus add dead (for lead-update component)
 * @returns Stage Bar List
 */
export function getStageBarList(appendDeadStatus: boolean = false) {
  const config = getSalesLeadConfig();
  let stageBarList = config.stageBarList; // original array

  if (appendDeadStatus) {
    stageBarList = stageBarList.concat(config.deadStatusList); // add dead (for lead-update component)
  }
  return stageBarList;
}

/**
 * get lead dead reason by status
 * @param status
 * @returns dead reason array
 */
export function getDeadReasonsByStatus(status: string) {
  const config = getSalesLeadConfig();
  if (!status) {
    // default: use first-one(pending_respond) if not followup yet
    status = config.followUpStatusList[0];
  }
  return get(config.deadReasons, status) || [];
}
/**
 * get next follow up status, return default if status is empty.
 * example: getNextStatus("responded") // "met".
 * stageBarList example: ["pending_respond", "responded", "met", ...]
 * @param status
 * @returns
 */
export function getNextStatus(status: string) {
  const followUpStatusList: Array<string> = getSalesLeadConfig().followUpStatusList;
  const indexDefault = 1; // default: use second-one (responded) if not followup yet
  if (!status) {
    return nth(followUpStatusList, indexDefault);
  }
  const foundIndex = findIndex(followUpStatusList, (item) => item === status);
  const nextIndex = foundIndex === -1 ? indexDefault : foundIndex + 1; // use default if not found
  const nextElement = nth(followUpStatusList, nextIndex);
  return nextElement;
}

/**
 * get last-follow-up status, return 'contactSuccess' if empty
 * @returns
 */
export function getLastFollowupStatus(followUp: FollowUp[] | undefined): string | undefined {
  const lastFollowUpElement: FollowUp | undefined = last(followUp);
  const status = lastFollowUpElement ? lastFollowUpElement.status : '';

  const followUpStatusList: Array<string> = getSalesLeadConfig().followUpStatusList;
  const indexDefault = 0; // default: contactSuccess if not followup yet
  if (!status) {
    return nth(followUpStatusList, indexDefault);
  }
  const foundIndex = findIndex(followUpStatusList, (item) => item === status);
  if (foundIndex === -1) {
    return undefined;
  }
  const nextElement = nth(followUpStatusList, foundIndex);
  return nextElement;
}
/**
 * filter followUp array and only retrieve items that are valid.
 * all valid enum: config.statusEnum
 */
export function filterFollowUpStatus(followUp: FollowUp[]) {
  if (!followUp || followUp.length < 1) {
    return [];
  }
  // all valid status enum
  const statusEnum = getSalesLeadConfig().statusEnum;
  const filteredItems = followUp.filter((item) => statusEnum.includes(item.status));
  return filteredItems;
}

/**
 * get dead followup status
 * @returns status e.g. 'cant_be_converted'
 */
export function getDeadStatus(): string {
  const config = getSalesLeadConfig();
  const deadStatusList = config.deadStatusList;
  return Array.isArray(deadStatusList) ? deadStatusList[0] : deadStatusList;
}

export const followUpStatusToTimelineNumber = (input: FollowUpStatusEnum): number => {
  switch (input) {
    case FollowUpStatusEnum.pending:
      return -1;
    case FollowUpStatusEnum.contact:
      return 0;
    case FollowUpStatusEnum.met:
      return 1;
    case FollowUpStatusEnum.quote:
      return 2;
    case FollowUpStatusEnum.proposal:
      return 3;
    case FollowUpStatusEnum.policy:
      return 4;
    case FollowUpStatusEnum.issued:
      return 5;
    case FollowUpStatusEnum.dead:
      return Number.MAX_VALUE;
    default:
      return 0;
  }
};

export const followUpStatusToSubmissionStatus = (input: FollowUpStatusEnum): string => {
  switch (input) {
    case FollowUpStatusEnum.contact:
      return 'contactSuccess';
    case FollowUpStatusEnum.met:
      return 'met';
    case FollowUpStatusEnum.quote:
      return 'quote';
    case FollowUpStatusEnum.proposal:
      return 'proposal';
    case FollowUpStatusEnum.policy:
      return 'policy';
    case FollowUpStatusEnum.dead:
      return 'dead';
    case FollowUpStatusEnum.pendingToContact:
      return '';
    default:
      return input;
  }
};
