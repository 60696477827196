import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get, set } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { getDownline, getLeadsByGroupCode } from 'src/app/modules/Leads/network/leadsCrud';
import { GetLeadsByGroupReqDto } from 'src/app/modules/Leads/types/types';
import { getReassignLeadTableColumns } from '../../util/reassign-lead.util';
import { ActionType } from 'src/app/common/components/ProTable';

interface HookProps {
  onUpdatedLead?: (lead: any[]) => void;
}

const defaultParam = {
  page: 1,
  pageSize: 20,
  limit: 5,
};

export const useAgingLeadTable = ({ onUpdatedLead }: HookProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [selectedUnit, setSelectedUnit] = useState<string>();
  const [defaultUnit, setDefaultUnit] = useState<string>();
  const [units, setUnits] = useState<Array<{ groupCode: string; count: number }>>([]);
  const [showDialog, setShowDialog] = useState(false);
  const [targetLead, setTargetLead] = useState<any>();
  const targetLeadRef = useRef<any>();
  const actionRef = useRef<ActionType>();

  const [statusUpdatedLead, setStatusUpdatedLead] = useState<any[]>([]);

  useEffect(() => {
    getDownline(dispatch).then((res) => {
      if (res && res.length > 0) {
        setUnits(res);
        const defaultUnit = res[0].groupCode;
        setDefaultUnit(defaultUnit);
        setSelectedUnit(defaultUnit);
      }
    });
  }, []);

  const getData = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<any> => {
      const { page, pageSize, keyword: searchkey } = params;
      const { groupCode } = filter ?? {};
      if (!groupCode) {
        return {
          success: false,
          data: [],
          total: 0,
        };
      }

      const queryParam: GetLeadsByGroupReqDto | any = {
        groupCode: groupCode,
        pagination: true,
        page: page + 1,
        limit: pageSize,
      };

      // todo: check this is asc or desc
      let sortBy = '-nameWeight -firstName';
      let flag = '';

      if (sort && Object.keys(sort).length > 0) {
        const [key, val] = Object.entries(sort)[0];

        if (val === 'desc') {
          flag = '-';
        }
        sortBy = `${key}`;
        queryParam['sortBy'] = sortBy;
        queryParam['sortOrder'] = flag === '-' ? -1 : 1;
      }

      const res = await getLeadsByGroupCode(queryParam, dispatch);

      if (res) {
        let data = res.docs;
        return {
          success: true,
          data: data ?? [],
          total: res.totalDocs,
        };
      }

      return {
        success: false,
        data: [],
        total: 0,
      };
    },
    [dispatch, selectedUnit],
  );

  const onEditClick = useCallback((lead: any) => {
    setShowDialog(true);
    setTargetLead(lead);
    targetLeadRef.current = lead;
  }, []);

  const onConfirmEditStatus = useCallback(
    (status: string) => {
      setShowDialog(false);
      let updatedLead = { ...targetLeadRef.current, leadStatus: status };
      const index = statusUpdatedLead.findIndex((lead) => lead.leadId === updatedLead.leadId);
      if (index > -1) {
        setStatusUpdatedLead((prev) => {
          prev[index] = updatedLead;
          return prev;
        });
      } else {
        setStatusUpdatedLead((prev) => {
          return [...prev, updatedLead];
        });
      }
    },
    [statusUpdatedLead],
  );

  const resetStatus = () => {
    setStatusUpdatedLead([]);
    setTargetLead(null);
    targetLeadRef.current = null;
    actionRef.current?.clearSelected?.();
  };

  const tableColumns = useMemo(
    () =>
      getReassignLeadTableColumns({
        t: Translation,
        keyword: '',
        styles: {},
        onEditClick,
        statusUpdatedLead: statusUpdatedLead,
      }),
    [statusUpdatedLead],
  );

  useEffect(() => {
    onUpdatedLead && onUpdatedLead(statusUpdatedLead);
  }, [statusUpdatedLead]);

  return {
    selectedUnit,
    units,
    tableColumns,
    defaultUnit,
    showDialog,
    targetLead,
    actionRef,
    resetStatus,
    setShowDialog,
    getData,
    setSelectedUnit,
    onConfirmEditStatus,
  };
};
