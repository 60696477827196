import React, { memo } from 'react';
import { AgentStatusEnum, FollowUpStatusEnum, Lead } from 'src/app/modules/Leads/types/types';
import { LeadOpportunityOperation } from '../lead-opportunity-operation/lead-opportunity-operation.component';
import { LeadFollowUpOperation } from '../lead-follow-up-operation/lead-follow-up-operation.component';

type ComponentProps = {
  item: Lead;
  actions: (actions: ('clearRow' | 'refresh')[]) => void;
  type?: unknown;
  status?: unknown;
};

export const ProspectLeadOperation: React.FC<ComponentProps> = memo(
  ({ item, actions, type, status }: ComponentProps) => {
    const { agentStatus, lastFollowUpStatus } = item;
    return (
      <>
        {agentStatus === AgentStatusEnum.pending ? (
          <LeadOpportunityOperation item={item} actions={actions} rejectButton={true} /> // // PACS customization PACSPFAP-3691 | rejectButton should be visible for Prospect Tab
        ) : agentStatus !== AgentStatusEnum.accepted ||
          lastFollowUpStatus === FollowUpStatusEnum.dead ||
          lastFollowUpStatus === FollowUpStatusEnum.policy ? null : ''
          // (<LeadFollowUpOperation item={item} actions={actions} />) PACS Customization | PACSPFAP-3691 | Phase-2
        }
      </>
    );
  },
);
