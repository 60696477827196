import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  tableContainer: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: 18,
  },
}));
