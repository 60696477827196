export enum FCDashboardName {
  ALL = 'All',
  LASTMONTH = 'Last Month',
  THISQUARTER = 'This Quarter',
  LASTQUARTER = 'Last Quarter',
  THISYEAR = 'This Year',
  LASTYEAR = 'Last Year',
}

export enum AlDashboardName {
  ALL = 'All',
  LASTQUARTER = 'Last Quarter',
  THISYEAR = 'This Year',
  LASTYEAR = 'Last Year',
}

export type DashboardItem = {
  periodRange: string;
  chartID: string;
  chartType: string;
  filter: string;
  value: string;
  createdAt: Date;
  updatedAt: Date;
};

export type TopDashboardType = 'Both' | 'Among' | 'Unique';

export type TopDashboardDetail = {
  type: TopDashboardType;
  dashboardLeft: string;
  dashboardRight?: string;
};

enum NormalFilter {
  assignedDate = 'Assigned Date',
}

export enum DashboardType {
  TABLE = 'Table',
  CHART = 'Chart',
}

export enum AlDashboardType {
  TABLE = 'Table',
}

 export const BDMdashboardFilter: Map<string, any> = new Map<FCDashboardName, any>([
  [FCDashboardName.ALL, NormalFilter],
]);

export const FCDashboardFilter: Map<string, any> = new Map<DashboardType, any>([
  [DashboardType.TABLE, NormalFilter],
  [DashboardType.CHART, NormalFilter],
]);

/* export enum FCDashboardFilter  {
  assignedDate = 'Assigned Date'
} */

export enum All {
  type = 'Unique',
  dashboardLeft = '62da4cf1-f1eb-49bc-8648-5681185edbde',
}

export const TopDashboards: Map<string, any> = new Map<FCDashboardName, TopDashboardDetail>([
  [FCDashboardName.ALL, All],
]);

/* export const DashboardLibrary = {
  report: [
    {
      chartID: '62da4cf1-f1eb-49bc-8648-5681185edbde',
      chartType: 'Table',
    },

    {
      chartID: '62bd4752-a69a-468b-84b7-2cdf1739fddc',
      chartType: 'Chart',
    },
    {
      chartID: '62c2acdf-f6cc-458d-898e-6bde7271910b',
      chartType: 'Chart',
    },
    {
      chartID: '421443a6-27ec-4830-98ef-cd085e0ecb77',
      chartType: 'Chart',
    },
    {
      chartID: '445ec441-3f1e-4ca6-bf80-d5f53973fc17',
      chartType: 'Chart',
    },
    {
      chartID: '78bb63b1-67de-44f6-8b74-20ac0f3f95a0',
      chartType: 'Chart',
    },
    {
      chartID: '26ca0391-c648-468d-8018-b0dd0713fac9',
      chartType: 'Chart',
    },
    {
      chartID: '2806e527-4157-4973-99a6-f5936f60551e',
      chartType: 'Chart',
    },
    {
      chartID: 'b881d5f4-576e-417a-90aa-546510d2e13d',
      chartType: 'Chart',
    },
  ],
}; */
