export const KPI_DASHBOARD_FINANCIAL_AGENT_TITLE = 'title.kpiDashboard.agent.financialAgent';
export const KPI_DASHBOARD_AGENCY_LEAD_TITLE = 'title.kpiDashboard.agent.agencyLead';
export const KPI_DASHBOARD_GROUP_LEAD_TITLE = 'title.kpiDashboard.agent.groupLead';

export const KPI_DASHBOARD_NAMESPACE = 'PULSELEADS-AGENT-LEAD-SOURCE';
export const KPI_DASHBOARD_FINANCIAL_AGENT_NAMESPACE = 'KPI_DASHBOARD_FINANCIAL_AGENT';
export const KPI_DASHBOARD_AGENCY_LEAD_NAMESPACE = 'KPI_DASHBOARD_AGENCY_LEADER';
export const KPI_DASHBOARD_GROUP_LEAD_NAMESPACE = 'KPI_DASHBOARD_GROUP_LEAD';

export const KPI_DASHBOARD_AGENT_PATH = '/agent';
export const KPI_DASHBOARD_FINANCIAL_AGENT_PATH = '/financial-agent';
export const KPI_DASHBOARD_AGENCY_LEAD_PATH = '/agency-lead';
export const KPI_DASHBOARD_GROUP_LEAD_PATH = '/group-lead';

export const NO_DATA_AVAILABLE = 'No available result. Please refine your search'
