export type AuthApiRes = {
  tokenType: string;
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
  forgeRockId: string;
};

export type IntrospectRes = {
  active: boolean;
};

export enum PermissionRole {
  AGENT = 'AGENT',
  MANAGER = 'MANAGER',
  STAFF = 'STAFF',
  ADMIN = 'ADMIN',
  SECRETARY = 'SECRETARY',
}

export type TokenInfo = {
  aud: string;
  iss: string;
  sub: string;
  username: string;
  role: PermissionRole;
  permissions: {
    [id: string]: string[];
  };
  region: string;
  channel: string;
  iat: number;
  exp: number;
};

export type AnegtInfo = {
  role: string;
  agentCode: string;
  profilePicture: {
    active: {
      owner: string;
      blobId: string;
      expiresOn: string;
      url: string;
      mimeType: string;
    };
  };
  gender: string;
  isActive: boolean;
  channel: string;
  region: string;
  [id: string]: any;
};
