import { ProColumns } from 'src/app/common/components/ProTable';
import { AgentItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import moment from 'moment';
import { ProTableOperationBtnComponent } from 'src/app/common/components/ProTable/components/pro-table-operation-btn/pro-table-operation-btn.component';
import { removeFirstLetterIfU } from 'src/app/modules/AgencyCampaign/utils/common-utils';

const RVSP_MAP = {
  Accepted: 'agencyCampaign.rsvp.accepted',
  Rejected: 'agencyCampaign.rsvp.rejected',
  Pending: 'agencyCampaign.rsvp.pending',
};

export const getColumns = ({
  t,
  startDate,
  campaignOwner,
  disabledEdit,
  disabled,
  setRemoveOption,
}: {
  t: (val: string) => string;
  startDate?: string;
  campaignOwner: string;
  disabledEdit?: boolean;
  disabled?: boolean;
  setRemoveOption: (val: any) => void;
}): ProColumns<AgentItem>[] => [
  {
    title: t('agencyCampaign.common.unit'),
    dataIndex: 'unit',
    width: '176px',
    sorter: true,
    render: (currValue: string, item) => {
      return removeFirstLetterIfU(item?.unit ?? '') as string;
    },
  },
  {
    title: t('agencyCampaign.common.businessName'),
    dataIndex: 'agencyCampaign.common.businessName',
    width: '176px',
    sorter: true,
    render: (currValue: string, item) => {
      return item.name?.enUs?.displayName as string;
    },
  },
  {
    title: t('agencyCampaign.common.agentCode'),
    dataIndex: 'agentCode',
    width: '176px',
    sorter: true,
  },
  {
    title: t('agencyCampaign.common.rsvp'),
    dataIndex: 'rsvp',
    width: '176px',
    render: (currValue: string[], item) => {
      const { invitationTime, rsvpIndicator } = item;
      const txt =
        rsvpIndicator &&
        (RVSP_MAP[rsvpIndicator as keyof typeof RVSP_MAP]
          ? t(RVSP_MAP[rsvpIndicator as keyof typeof RVSP_MAP])
          : rsvpIndicator);
      return invitationTime !== undefined && rsvpIndicator !== undefined && rsvpIndicator !== 'Pending'
        ? txt
        : moment().isAfter(startDate)
          ? t('agency.campaign.participant.no.response')
          : invitationTime !== undefined && rsvpIndicator !== undefined
            ? txt
            : '-';
    },
  },
  {
    title: t('agencyCampaign.common.action'),
    dataIndex: 'action',
    width: '176px',
    render: (_, item) => {
      if (disabledEdit || disabled) return '';
      if (item.agentCode === campaignOwner) return '';
      return (
        <ProTableOperationBtnComponent
          label={t('app.button.remove')}
          onClick={() => {
            setRemoveOption({
              open: true,
              applicantId: item.agentCode,
            });
          }}
        />
      );
    },
  },
];
