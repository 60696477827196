import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  formLabel: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 16,
    color: 'rgba(51, 51, 51, 1)',
    '&.Mui-focused': {
      color: 'rgba(51, 51, 51, 1)',
    },
  },
  dropdownField: {
    marginTop: 16,
  },
  inputLabel: {
    color: '#999999',
    '&.Mui-focused': {
      color: '#999999',
    },
  },
  menuItem: {
    '&.Mui-selected': {
      backgroundColor: '#FFE4E6',
      '&:hover': {
        backgroundColor: '#FFE4E6',
      },
    },
  },
}));
