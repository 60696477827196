import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useList } from './list.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import ProFilterTable from 'src/app/common/components/ProTable';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components/pro-search-filter/pro-search-filter.component';
import { NewCampaignDialog } from './components/new-campaign-dialog/new-campaign-dialog.component';
import { Button } from 'src/app/common/components/button/button.component';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import {
  agencyCampaignListGaDataClick,
  agencyCampaignListGaDataClick62,
  agencyCampaignListGaDataClick63,
} from 'src/app/common/ga/types/ga-click';

type PageProps = ParamsProps &
  RouteComponentProps & {
    cacheParams: React.MutableRefObject<Record<string, any> | undefined>;
  };

export const ListPage: React.FC<PageProps> = (props: PageProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // custom-hook
  const {
    filterConfig,
    cacheParams,
    onFilterChange,
    columns,
    getData,
    createModalVisible,
    handleModalVisible,
    campaignTypeList,
    onTypeSelected,
    onClickRow,
  } = useList({
    cacheParams: props.cacheParams,
    history: props.history,
  });

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={Translation('title.agencyCampaign.list')}
        rightNode={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleModalVisible(true);
            }}
          >
            {Translation('agencyCampaign.list.screen.addNew')}
          </Button>
        }
      />
      <ProFilterTable
        filterPlaceholder={Translation('agencyCampaign.list.screen.search.placeholder')}
        filterConfigs={filterConfig}
        filterState={cacheParams}
        rowKey="_id"
        showSelect={false}
        Filter={ProSearchFilter}
        columns={columns}
        request={getData}
        enableRefresh={false}
        onFilterChange={onFilterChange}
        onClickRow={onClickRow}
        onFilter={() => {
          takeUIClickEvent(agencyCampaignListGaDataClick63 as any);
        }}
        onSort={() => {
          takeUIClickEvent(agencyCampaignListGaDataClick62 as any);
        }}
        onFocus={() => {
          takeUIClickEvent(agencyCampaignListGaDataClick as any);
        }}
      />
      {campaignTypeList && campaignTypeList.length ? (
        <NewCampaignDialog
          open={createModalVisible}
          options={campaignTypeList}
          onClose={() => handleModalVisible(false)}
          onConfirm={onTypeSelected}
        />
      ) : null}
    </Page>
  );
};
