export const HEADER_HEIGHT = 88;

export const MOBILE_HEADER_HEIGHT = 55;

export const ASIDE_WIDTH = 244;

export const COLLAPSE_ASIDE_WIDTH = 70;

export const RESPONSIVE_WIDTH = 900;

export const FOOTER_HEIGHT = 46;
