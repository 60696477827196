import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField, Typography } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './client-select.style';
import { useClientSelect } from './client-select.hook';
import {
  AsyncSearchSelect,
  AsyncSearchSelectProps,
} from 'src/app/common/components/async-search-select/async-search-select.component';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
} & Omit<AsyncSearchSelectProps, 'query'>;

const OptionItem = ({ option }: { option: { label: string; value: string } }) => (
  <Box display={'flex'} marginLeft={'15px'} flexDirection={'column'}>
    {/* todo: style */}
    <Typography color={'#333333'}>{option.label}</Typography>
    <Typography color={'#666666'}>{option.value}</Typography>
  </Box>
);

export const ClientSelect: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { getClients } = useClientSelect({ id: 1 });
  return (
    <AsyncSearchSelect
      pagination
      renderInputProps={{ label: 'select refer', size: 'medium' }} // todo: i18n
      {...props}
      clientFilter={false}
      query={getClients}
      OptionItem={OptionItem}
    />
  );
});
