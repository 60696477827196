import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    margin: theme.spacing(1),
    borderRadius: 5,
  },
  error: {
    backgroundColor: 'red',
  },
  tabsButton: {
    width: 59,
    height: 32,
  },
  postDocSubmitBtn: {
    height: 32,
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabsText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
  },
  tooltip: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
}));
