import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { ServicingCampaignContext } from '../detail-page.hook';
import { DefaultFeedbackStatus } from 'src/app/modules/Campaign/constants';

interface HookProps {
  campaignId: string;
  // other params
}

export const useUpdateServicingCampaignDialog = (props: HookProps) => {
  const { campaignId } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { activityStatusFeedback } = useContext(ServicingCampaignContext);
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const feedbackStatus: any = {
    reject: Translation('campaign.servicing.feedback.reject'),
    Contacted: Translation('campaign.servicing.feedback.contacted'),
  };

  const onSelectStatus = (key: string) => {
    setSelectedStatus(key);
  };
  const updateOptions = [
    { value: Translation('campaign.servicing.feedback.pendingStatus'), key: DefaultFeedbackStatus },
  ].concat(activityStatusFeedback?.map((item) => ({ ...item, value: feedbackStatus[item.value] || item.value })));

  return {
    selectedStatus,
    activityStatusFeedback: updateOptions,
    onSelectStatus,
  };
};
