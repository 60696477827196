import React, { FC, useState, useMemo, useCallback } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { LayoutSplashScreen } from 'src/app/layout';
import { useStyles } from './add-lead.style';
import { useAddLead } from './add-lead.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { LEADS_BASE_PATH } from '../../constants';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateFormat } from 'src/app/common/utils';
import { ClientSelect } from './component/client-select/client-select.component';
import { SelectCampaign } from '../../components/select-campaign/select-campaign.component';
import { ReferredTypeEnum } from '../../types/types';
import { ArrowRight } from '@mui/icons-material';
import { ReferredBy } from 'src/app/modules/Prospect/pages/components/referred-by/referred-by.component';
import dayjs from 'dayjs';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const AddLeadPage: React.FC<PageProps> = (props: PageProps) => {
  const { history } = props;
  const intl = useIntl();
  const { id: prospectId } = useParams<{ id: string }>();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    formData,
    leadSourceData,
    leadSubSourceData,
    isReferredMandtory,
    referredSource,
    showReferrby,
    selectedReferrBy,
    filedErrors,
    canSubmit,
    enableAddLeadToCampaign,
    loading,
    setShowReferrby,
    updateFormData,
    onLeadDateChange,
    onClientChange,
    onCampaignChange,
    onSelectReferrBy,
    submit,
  } = useAddLead({
    id: prospectId,
  });

  const rightNode = useMemo(() => {
    return (
      <div>
        <Button variant="outlined" className={classes.backBt} onClick={() => history.goBack()}>
          {Translation('app.button.back')}
        </Button>
        <Button variant="contained" color="secondary" onClick={submit} disabled={!canSubmit}>
          {loading ? <CircularProgress size={18} color="inherit" /> : Translation('app.button.confirm')}
        </Button>
      </div>
    );
  }, [submit, canSubmit, loading]);

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle title={Translation('lead.addNewLead')} rightNode={rightNode} />
      <div className={styles.container}>
        <div className={styles.sectionBox}>
          <div className={styles.sectionTitle}>
            <span>{Translation('lead.addNewLead.leadDate')}</span>
          </div>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <DateTimePicker
                label={Translation('lead.addNewLead.leadCreateDate')}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    size: 'medium',
                    fullWidth: true,
                    required: true,
                    error: Boolean(filedErrors['dateTs']),
                    helperText: filedErrors['dateTs']?.error ?? '',
                  },
                }}
                ampm={false}
                format={DateFormat.datetime.replace('yyyy', 'YYYY')}
                onChange={onLeadDateChange}
                defaultValue={dayjs()}
              />
            </Grid>
          </Grid>

          <div className={styles.sectionTitle}>
            <span>{Translation('lead.addNewLead.leadSource.title')}</span>
          </div>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <TextField
                key={formData?.leadSource}
                label={Translation('lead.addNewLead.leadSource')}
                variant="outlined"
                select={true}
                SelectProps={{
                  size: 'medium',
                }}
                required={true}
                className={styles.input}
                size="medium"
                error={Boolean(filedErrors['leadSource'])}
                helperText={filedErrors['leadSource']?.error ?? ''}
                defaultValue={leadSourceData[0]?.key}
                value={formData?.leadSource}
                onChange={(e) => {
                  updateFormData('leadSource', e.target.value);
                }}
              >
                {leadSourceData.map((item, index) => (
                  <MenuItem key={`${item.key}_${index}`} value={item.key}>{`${item.value}`}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                key={formData?.leadSubSource}
                label={Translation('lead.addNewLead.leadSubSource')}
                variant="outlined"
                select={true}
                SelectProps={{
                  size: 'medium',
                }}
                required={true}
                className={styles.input}
                size="medium"
                defaultValue={leadSubSourceData[0]?.key}
                value={formData?.leadSubSource}
                onChange={(e) => {
                  updateFormData('leadSubSource', e.target.value);
                }}
              >
                {leadSubSourceData.map((item, index) => (
                  <MenuItem key={`${item.key}_${index}`} value={item.key}>{`${item.value}`}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              {referredSource !== ReferredTypeEnum.prospect ? (
                <ClientSelect
                  onChange={onClientChange}
                  renderInputProps={{
                    label: Translation('lead.addNewLead.referredBy'),
                    size: 'medium',
                    required: isReferredMandtory,
                  }}
                />
              ) : (
                <TextField
                  label={Translation('lead.addNewLead.referredBy')}
                  variant="outlined"
                  className={styles.input}
                  size="medium"
                  onClick={() => {
                    setShowReferrby(true);
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  required={isReferredMandtory}
                  value={selectedReferrBy?.displayName ?? ''}
                ></TextField>
              )}
            </Grid>
            {enableAddLeadToCampaign && (
              <Grid item xs={6}>
                <SelectCampaign
                  renderInputProps={{ size: 'medium', label: Translation('lead.addNewLead.campaign') }}
                  onChange={onCampaignChange}
                />
              </Grid>
            )}
          </Grid>

          <div className={styles.sectionTitle}>
            <span>{Translation('lead.addNewLead.information')}</span>
          </div>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <TextField
                label={Translation('lead.addNewLead.description')}
                variant="outlined"
                className={styles.input}
                size="medium"
                onChange={(e) => {
                  updateFormData('description', e.target.value);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={Translation('lead.addNewLead.interestedProduct')}
                variant="outlined"
                className={styles.input}
                size="medium"
                onChange={(e) => {
                  updateFormData('interestedProduct', e.target.value);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={Translation('lead.addNewLead.qualificationDetails')}
                variant="outlined"
                className={styles.input}
                size="medium"
                onChange={(e) => {
                  updateFormData('qualification', e.target.value);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={Translation('lead.addNewLead.financialNeeds')}
                variant="outlined"
                className={styles.input}
                size="medium"
                onChange={(e) => {
                  updateFormData('financialNeeds', e.target.value);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={Translation('lead.addNewLead.currentExpenses')}
                variant="outlined"
                className={styles.input}
                size="medium"
                onChange={(e) => {
                  updateFormData('expenses', e.target.value);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={Translation('lead.addNewLead.currentInsuranceProducts')}
                variant="outlined"
                className={styles.input}
                size="medium"
                onChange={(e) => {
                  updateFormData('insuranceProducts', e.target.value);
                }}
              ></TextField>
            </Grid>
          </Grid>

          <div className={styles.sectionTitle}>
            <span>{Translation('lead.addNewLead.preference')}</span>
          </div>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <TextField
                label={Translation('lead.addNewLead.preferredContactMethod')}
                variant="outlined"
                className={styles.input}
                size="medium"
                onChange={(e) => {
                  updateFormData('contactMethod', e.target.value);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={Translation('lead.addNewLead.preferredContactDay')}
                variant="outlined"
                className={styles.input}
                size="medium"
                onChange={(e) => {
                  updateFormData('contactDay', e.target.value);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={Translation('lead.addNewLead.preferredContactTime')}
                variant="outlined"
                className={styles.input}
                size="medium"
                onChange={(e) => {
                  updateFormData('contactTime', e.target.value);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={Translation('lead.addNewLead.otherComments')}
                variant="outlined"
                className={styles.input}
                size="medium"
                onChange={(e) => {
                  updateFormData('otherLeadComments', e.target.value);
                }}
              ></TextField>
            </Grid>
          </Grid>
        </div>
      </div>
      <ReferredBy
        defaultSelectedRows={selectedReferrBy}
        open={showReferrby}
        onClose={() => setShowReferrby(false)}
        onConfirm={onSelectReferrBy}
      />
    </Page>
  );
};
