import { IntlShape } from 'react-intl';
import { ModuleHierachy } from 'src/app/common/module/types';
import {
  CAMPAIGN_TITLE,
  CAMPAIGN_BASE_PATH,
  CAMPAIGN_NAMESPACE,
  MARKETING_CAMPAIGN_NAMESPACE,
  MARKETING_CAMPAIGN_TITLE,
  MASS_CAMPAIGN_NAMESPACE,
  MASS_CAMPAIGN_TITLE,
  SERVICING_CAMPAIGN_NAMESPACE,
  SERVICING_CAMPAIGN_TITLE,
  SERVICING_CAMPAIGN_LIST_PATH,
  MARKETING_CAMPAIGN_LIST_PATH,
  MASS_CAMPAIGN_LIST_PATH,
  MARKETING_CAMPAIGN_DETAIL_PATH,
  MASS_CAMPAIGN_DETAIL_PATH,
  SERVICING_CAMPAIGN_DETAIL_PATH,
  CAMPAIGN_DETAIL_TITLE,
} from './constants';
import MarketingCampaignRoutes from './pages/MarketingCampaign/marketing-campaign-routes';
import MassCampaignRoutes from './pages/MassCampaign/mass-campaign-routes';
import ServicingCampaignRoutes from './pages/ServicingCampaign/servicing-campaign-routes';
import { agencyCampaignModuleConfig } from '../AgencyCampaign/module-def';
import { get } from 'lodash';
import { APP_CONFIG, getAppConfigs } from 'src/app/common/utils';

export const campaignModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  const enableServicing = get(getAppConfigs(APP_CONFIG.SALES_SDK), 'Sales.campaign.enableServicingCampaign', false);
  return {
    title: Translation(CAMPAIGN_TITLE),
    path: CAMPAIGN_BASE_PATH,
    namespace: CAMPAIGN_NAMESPACE,
    disableMenu: false,
    disableRoleControl: true,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
    child: [
      {
        title: Translation(MARKETING_CAMPAIGN_TITLE),
        path: `${CAMPAIGN_BASE_PATH}${MARKETING_CAMPAIGN_LIST_PATH}`,
        namespace: MARKETING_CAMPAIGN_NAMESPACE,
        component: MarketingCampaignRoutes,
        enableRead: true,
        enableCreate: true,
        enableUpdate: true,
        page: [
          {
            title: Translation(CAMPAIGN_DETAIL_TITLE),
            path: `${CAMPAIGN_BASE_PATH}${MARKETING_CAMPAIGN_LIST_PATH}${MARKETING_CAMPAIGN_DETAIL_PATH}`,
          },
        ],
      },
      agencyCampaignModuleConfig(permissions, intl, CAMPAIGN_BASE_PATH),
      {
        title: Translation(MASS_CAMPAIGN_TITLE),
        path: `${CAMPAIGN_BASE_PATH}${MASS_CAMPAIGN_LIST_PATH}`,
        namespace: MASS_CAMPAIGN_NAMESPACE,
        component: MassCampaignRoutes,
        enableRead: true,
        enableCreate: true,
        enableUpdate: true,
        page: [
          {
            title: Translation(CAMPAIGN_DETAIL_TITLE),
            path: `${CAMPAIGN_BASE_PATH}${MASS_CAMPAIGN_LIST_PATH}${MASS_CAMPAIGN_DETAIL_PATH}`,
          },
        ],
      },
      {
        title: Translation(SERVICING_CAMPAIGN_TITLE),
        path: `${CAMPAIGN_BASE_PATH}${SERVICING_CAMPAIGN_LIST_PATH}`,
        namespace: SERVICING_CAMPAIGN_NAMESPACE,
        component: ServicingCampaignRoutes,
        enableRead: enableServicing,
        enableCreate: true,
        enableUpdate: true,
        page: [
          {
            title: Translation(CAMPAIGN_DETAIL_TITLE),
            path: `${CAMPAIGN_BASE_PATH}${SERVICING_CAMPAIGN_LIST_PATH}${SERVICING_CAMPAIGN_DETAIL_PATH}`,
          },
        ],
      },
      // {
      //   title: Translation(CAMPAIGN_TITLE),
      //   path: `${CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_DASHBOARD_PATH}`,
      //   namespace: AGENCY_CAMPAIGN_DASHBOARD_NAMESPACE,
      //   component: DashboardRoutes,
      //   enableRead: true,
      //   enableCreate: true,
      //   enableUpdate: true,
      // },
    ],
  };
};
