import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import {
  AgentItem,
  AgentListParam,
  AgentPaginateList,
  CampaignStructureItem,
} from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { addParticipant, removeParticipant } from 'src/app/modules/AgencyCampaign/network/campaignCurd';
import { useStyles } from './campaign-participant.style';

interface HookProps {
  campaignTypeStructureData: CampaignStructureItem;
  participantRange: any;
  formDispatch: (data: any) => void;
  formState: any;
  sectionKey: string;
  setParticipantSelected: React.Dispatch<React.SetStateAction<any[]>>;
  disabledEdit?: boolean;
  disabled?: boolean;
  errorState: any;
  campaignObjId?: string;
  participantSelected: any[];
  validationHandler: any;
  startDate?: any;

  disableBulkSelect: boolean;
  setDisableBulkSelect: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveSuccessDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sendInvitationToParticipant: (agentList?: string[]) => void;
  campaignOwner: string;
  isRequiredRsvp: boolean;
}

const paramsInitiator = (initialParams?: Record<string, string>): AgentListParam => {
  return {
    page: 1,
    limit: 20,
    search: '',
    id: '',
  };
};

export const useCampaignParticipant = ({
  sectionKey,
  campaignTypeStructureData,
  participantRange,
  formDispatch,
  formState,
  setParticipantSelected,
  disabledEdit,
  disabled,
  errorState,
  campaignObjId,
  participantSelected,
  validationHandler,
  startDate,

  disableBulkSelect,
  setDisableBulkSelect,
  setRemoveSuccessDialogOpen,
  sendInvitationToParticipant,
  campaignOwner,
  isRequiredRsvp,
}: HookProps) => {
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const tableRef = useRef<any>();

  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);

  const [openSelectFromList, setOpenSelectFromList] = useState<boolean>(false);
  const [openImportFromFile, setOpenImportFromFile] = useState<boolean>(false);
  const [participantList, setParticipantList] = useState<AgentPaginateList>();

  const [filterState, setFilterState] = useState<AgentListParam>(paramsInitiator());
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const [snackBar, setSnackBar] = useState<{ msg: string; visible: boolean }>({ msg: '', visible: false });

  const [removeOption, setRemoveOption] = useState<{
    open: boolean;
    applicantId: string | null | string[];
  }>({ open: false, applicantId: null });

  const structureData = campaignTypeStructureData.sections.filter((item) => item.key == sectionKey)[0];
  const tNcField =
    structureData.sectionFields.filter((item) => item.key === 'tnc').length > 0
      ? structureData.sectionFields.filter((item) => item.key === 'tnc')[0]
      : null;
  // const rsvpField =
  //   structureData.sectionFields.filter((item) => item.key === 'rsvpResponse').length > 0
  //     ? structureData.sectionFields.filter((item) => item.key === 'rsvpResponse')[0]
  //     : null;

  // const arrparticipantName = structureData.sectionFields.filter((item) => item.key === 'participantName');
  // const participantNameField = arrparticipantName.length > 0 ? arrparticipantName[0] : null;

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const onRefresh = () => {};

  const setDoneImport = (data: AgentItem[]) => {
    var agentCodeArray = data.map((data) => {
      return { ...data, campaign: campaignObjId };
    });
    addParticipant(agentCodeArray, dispatch)
      .then((res) => {
        if (
          res.length > 0 &&
          campaignTypeStructureData.sections
            .filter((item) => item.key === 'participant')[0]
            .sectionFields.filter((item) => item.key === 'rsvpResponse').length > 0
        ) {
          // send invitation in backend
          // sendInvitationToParticipant(agentCodeArray.map((item) => item.agentCode));
        }

        setSnackBar({
          msg: res.length + Translation('agencyCampaign.create.importDone'),
          visible: true,
        });
        // refreshData();
        tableRef.current?.reload();
      })
      .catch((e) => {});
  };

  // TODO: handle single remove
  const toggleRemoveDialog = () => {
    setRemoveOption({
      open: !removeOption.open,
      applicantId: null,
    });
  };

  const doRemove = () => {
    let applicantId = removeOption.applicantId;
    if (applicantId && campaignObjId) {
      const data = {
        id: campaignObjId,
        agentList: Array.isArray(applicantId) ? applicantId : [applicantId],
      };
      removeParticipant(data, dispatch).then(() => {
        setParticipantSelected((prev) => prev.filter((item: any) => item.agentCode !== removeOption.applicantId));
        setRemoveSuccessDialogOpen(true);
        tableRef.current?.reload();
      });
    }
    setRemoveOption({
      open: false,
      applicantId: null,
    });
  };

  // useEffect(() => {
  //   // refreshData();
  //   // eslint-disable-next-line
  // }, [campaignObjId, participantSelected, filterState, sortKey]);

  const closeSnackBar = () => setSnackBar({ msg: '', visible: false });

  const renderNumberLimit = () => {
    const { gte: min, lte: max } = participantRange;
    if (!max && !min) return null;
    return (
      <ul className={classes.numberLimitList}>
        {min > 0 && (
          <li className={classes.lh_20}>{`${min} ${Translation('agencyCampaign.create.participantMin')}`}</li>
        )}
        {max > 0 && (
          <li className={classes.lh_20}>{`${max} ${Translation('agencyCampaign.create.participantMax')}`}</li>
        )}
      </ul>
    );
  };
  return {
    tNcField,
    snackBar,
    participantList,
    filterState,
    openImportFromFile,
    openSelectFromList,
    removeOption,
    setDoneImport,
    setFilterState,
    onRefresh,
    setOpenSelectFromList,
    setOpenImportFromFile,
    setRemoveOption,
    renderNumberLimit,
    setSnackBar,
    closeSnackBar,
    updateSortingKey,
    toggleRemoveDialog,
    doRemove,
    tableRef,
  };
};
