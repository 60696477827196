import { FC } from 'react';
import { TableRow, TableCell, Typography } from '@mui/material';
import { EmptyIcon } from '../svg-icon/svg-icon.component';

type PruTableEmptyRowProps = {
  isEmpty: boolean;
  type?: string;
  emptyText?: string;
  customEmptyTitle?: JSX.Element;
};

const PruTableEmptyRow: FC<PruTableEmptyRowProps> = ({ isEmpty, emptyText, customEmptyTitle }) => {
  return isEmpty ? (
    <TableRow>
      <TableCell colSpan={12} align="center">
        <div
          style={{
            height: '360px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {customEmptyTitle}
          <EmptyIcon sx={{ width: '180px', height: '172px' }} />
          {emptyText ? (
            <Typography fontSize={'20px'} color="#333333">
              {emptyText}
            </Typography>
          ) : null}
        </div>
      </TableCell>
    </TableRow>
  ) : (
    <></>
  );
};

export default PruTableEmptyRow;
