import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './rsvp-reminder.style';

type ComponentProps = ParamsProps & {
  onAccept: () => void;
  onClose: () => void;
};

export const RsvpReminderComponent: React.FC<ComponentProps> = memo(({ onAccept, onClose }: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <Box className={styles.container}>
      <Box className={styles.textIcon}>
        <InfoOutlinedIcon className={styles.icon} sx={{ rotate: 'revert' }} />
        <Typography className={styles.text}>{Translation('agencyCampaign.rsvp.invitation')}</Typography>
      </Box>
      <Box>
        <Button variant="text" onClick={onAccept}>
          {Translation('agencyCampaign.rsvp.accept')}
        </Button>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
});
