export const firebaseConfig = {
    apiKey: "AIzaSyAiEjBUZCna5nB4e3R_VWd-gswMTNEXUqM",
    authDomain: "ebriefcase.firebaseapp.com",
    databaseURL: "https://ebriefcase.firebaseio.com",
    projectId: "ebriefcase",
    storageBucket: "ebriefcase.appspot.com",
    messagingSenderId: "346280346037",
    appId: window.envConfig["FIREBASE_APP_ID"] || "1:346280346037:web:388b001fba43c0454ed9fc",   // default coedev config
    measurementId: window.envConfig["FIREBASE_MEASUREMENT_ID"] || "G-LXCWY0VNYZ"
};
