import { PageReqParam } from 'src/app/common/types';

export enum TalkBotCallStatus {
  queuing = 'queuing', //After lead is exported, but before it is called via WIZ.AI
  connected = 'connected', //Call completed normally
  missed_call = 'missed-call', //	Normal call, but the user did not pick up
  power_off = 'power-off', //	Power off
  busy = 'busy', //	Customer is busy
  callee_has_unpaid_bill = 'callee-has-unpaid-bill', //Callee owes fee
  invalid_number = 'invalid-number', //	Invalid number
  blacklist = 'blacklist', //The customer number is in the system balcklist
  caller_has_unpaid_bill = 'caller-has-unpaid-bill', //Caller owns fee
  line_blind_spot = 'line-blind-spot', //Call line error
  voice_mail = 'voice-mail', //	Voice mailbox recognized before operator returns a connection message
  incompleted_call = 'incompleted-call', //Call not completed
  DNC = 'dnc', //Cannot make a call to this number (Singapore)
  network_outage = 'network-outage', //	Network interruption
  answering_machine = 'answering-machine', //Voice mailbox recognized after the operator returns a connection message will be charged
}

export enum TalkBotCallInterestTags {
  highInterest = 'high-interest',
  mediumInterest = 'medium-interest',
  noInterest = 'no-interest',
}

export enum TalkBotCallActionTags {
  callBack = 'call-back',
  stopCalling = 'stop-calling',
  speakToManager = 'speak-to-manager',
  requestToShare = 'request-to-share',
  sendTextSms = 'send-text-sms',
  otherQueries = 'other-queries',
  whichMarketingCampaign = 'which-marketing-campaign',
}

export enum TalkBotCallLeadStatusTags {
  wrongNumber = 'wrong-number',
  otherLanguage = 'other-language',
  busy = 'busy',
  alsoAnAgent = 'also-an-agent',
  foreigner = 'foreigner',
  alreadyHaveInsurance = 'already-have-insurance',
  checkWithFamily = 'check-with-family',
  alreadyRetired = 'already-retired',
  noMoney = 'no-money',
  repeatedCall = 'repeated-call',
  tooYoung = 'too-young',
  healthIssues = 'health-issues',
  alreadyUpgraded = 'already-upgraded',
  alreadyHaveAgent = 'already-have-agent',
}

export type ExportListItem = {
  date: string;
  count: number;
};

export type GetExportDetailReqType = {
  createdAtGte: string;
  createdAtLte: string;
  callStatus?: string[];
  contactTags?: string[];
} & PageReqParam;

export type ExportDetailItemType = {
  _id: string;
  flowId: number;
  agentCode: string;
  campaign?: string;
  campaignName?: string;
  displayName: string;
  exportDate?: Date;
  contactId: string;
  contactBatchId: string;
  callId?: number;
  contactPhone: string;
  countryCode: string;
  callStatus: TalkBotCallStatus;
  contactTags: Array<TalkBotCallInterestTags | TalkBotCallActionTags | TalkBotCallLeadStatusTags>;
};
