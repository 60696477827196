import { Box } from '@mui/material';
import { ProColumns } from 'src/app/common/components/ProTable';
import { HighlightMatchedText } from 'src/app/common/components/highlight-matched-text/highlight-matched-text.component';
import { APP_CONFIG, getAppConfigs } from 'src/app/common/utils';
import _parsePhoneNumber, { PhoneNumber } from 'libphonenumber-js';
import { get } from 'lodash';

export type Customer = {
  clientCode: string;
  clientName: string;
  policyNumber: string[];
};

export const getTableColumns = ({
  t,
  keyword,
  styles,
  importedClientCodes,
}: {
  t: (val: string) => string;
  keyword?: string;
  styles: Record<string, any>;
  importedClientCodes: Array<string>;
}): ProColumns<Customer>[] => {
  return [
    {
      title: t('pruleads.prospect.list.column.name'),
      dataIndex: 'clientName',
      width: '290px',
      render: (currValue: string, item) => {
        return (
          <div>
            <HighlightMatchedText highLightText={keyword ?? ''}>{currValue}</HighlightMatchedText>
          </div>
        );
      },
    },
    {
      title: t('pruleads.prospect.list.column.clientCode'),
      dataIndex: 'clientCode',
      width: '176px',
      render: (currValue: string, item) => {
        return (
          <div>
            <span>{currValue}</span>
          </div>
        );
      },
    },
    {
      title: t('pruleads.prospect.list.column.status'),
      dataIndex: 'clientCode',
      width: '466px',
      render: (currValue: string, item) => {
        return importedClientCodes.includes(currValue) ? (
          <div className={styles.importedLabel}>{t('prospect.customer.imported.label')}</div>
        ) : null;
      },
    },
    {
      title: '',
      dataIndex: 'clientCode',
      width: '176px',
      render: (currValue: string, item) => {
        return <div />;
      },
    },
  ];
};

export const parsePhoneNumber = (text: string, defaultRegion?: string): PhoneNumber => {
  const regex = /[-\+()\s]*/g;
  const phone = _parsePhoneNumber(text, 'HK');
  if (phone && /^\d+$/.test(phone.nationalNumber)) {
    return phone;
  }
  const defaultCountryCallingCode = '+852';
  const plainNationalNumber = _parsePhoneNumber(text, 'HK')?.nationalNumber ?? text.replace(regex, '');
  const phoneNumber = {
    countryCallingCode: defaultCountryCallingCode.replace('+', ''),
    nationalNumber: plainNationalNumber,
    getURI: () => {
      return `tel:+${defaultCountryCallingCode}${plainNationalNumber}`;
    },
  } as PhoneNumber;
  if (!/^\d+$/.test(phoneNumber.nationalNumber)) {
    throw new Error('Invalid phone number');
  }
  return phoneNumber;
};

export const getImported = (prospectPhonesSelector: any, clientData: any) => {
  let imported: any = [];
  const regex = /[-\+()\s]*/g;
  const importedDataMap: any = {};
  const uniqueKeys = get(getAppConfigs(APP_CONFIG.SALES_COMMON), 'Sales.prospect.uniqueRule.uniqueKeys', []);

  if (uniqueKeys) {
    // uniqueKeys: ["phoneNumber","countryCode","displayName"]
    for (let index = 0; index < prospectPhonesSelector.length; index++) {
      const prospect = prospectPhonesSelector[index];
      importedDataMap[formatUniqueKey(prospect)] = true;
    }

    for (let index = 0; index < clientData.length; index++) {
      const _client: any = clientData[index];
      const clientInfo = {
        displayName: _client.firstName + _client.lastName,
        phoneNumber: _client.phoneNumber.replace(regex, ''),
        regionCode: _client.countryCode ?? '',
        email: _client.email,
      };
      const key = formatUniqueKey(clientInfo);

      if (importedDataMap[key]) {
        imported.push(_client.prospectClientCode);
      }
    }
  }
  return imported;
};

const mapProspectDbKey = (key: string): string => {
  const mapObj: any = {
    countryCode: 'regionCode',
    alias: 'displayName',
  };
  return mapObj[key] ?? key;
};
export const formatUniqueKey = (prospect: any): string => {
  const regex = /[-\+()\s]*/g;
  const key = get(getAppConfigs(APP_CONFIG.SALES_COMMON), 'Sales.prospect.uniqueRule.uniqueKeys', [])
    ?.reduce?.((prev: any, next: any) => {
      return prev + prospect[mapProspectDbKey(next)];
    }, '')
    .replace(regex, '');
  if (!key) {
    const phoneNumber = ((prospect.regionCode || '') + prospect.phoneNumber).replace(regex, '');
    return phoneNumber;
  }
  return key;
};

export const queryCustomerData = (keyword: string, campaigns: any[]) => {
  let res: any[] = [];
  campaigns.forEach((item) => {
    //keyword are not case-sensitive
    const policyIndex = item.policies.findIndex((policy: string) => policy.includes(keyword));
    if (item.clientName.toLowerCase().includes(keyword.toLowerCase()) || policyIndex !== -1) res.push(item);
  });
  return res;
};
