import React, { useCallback, useMemo, useState } from 'react';
import { getDropList } from 'src/app/modules/Leads/pages/leads/leads-list/components/lead-list-reminder/components/auto-drop-reminder/util/api.util';
import { getAutoDropListColumns } from 'src/app/modules/Leads/pages/leads/leads-list/util/list.util';
import { addExportToColumn } from '../../../common/utils/talkbot.utils';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useActiveDialogueHook } from '../../../common/hooks/active-dialogue.hook';
import { Columns } from 'src/app/modules/Leads/types/types';

export const useAutoDropList = () => {
  const { t } = useTranslation();
  const [reminderAutoDropLeadDay, setReminderAutoDropLeadDay] = useState<number>();
  useActiveDialogueHook();

  const getTalkbotAutoDropListColumns = useCallback(() => {
    const columns = getAutoDropListColumns({}).filter((item) => item.dataIndex !== Columns.prospectLeadOperation);
    return addExportToColumn(columns, t);
  }, [t]);

  const getData = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
      dispatch?: React.Dispatch<any>,
    ) => {
      const res = await getDropList(params, sort, filter, dispatch, { isTalkbotLead: true, isExportToTalkbot: false });
      if (res) {
        setReminderAutoDropLeadDay(res.reminderAutoDropLeadDay);
      }
      return res;
    },
    [],
  );

  return { getTalkbotAutoDropListColumns, reminderAutoDropLeadDay, getData };
};
