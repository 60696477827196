import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';

import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { useLeadTypesTag } from './lead-types-tag.hook';
import { Tag } from '../../../../../common/components/tag/tag.component';
import { useStyles } from './lead-types-tag.style';
import { Lead } from '../../../types/types';

type ComponentProps = ParamsProps & {
  item: Lead;
};

export const LeadTypesTagComponent: React.FC<ComponentProps> = memo(({ item }: ComponentProps) => {
  // custom-hook
  const { configs } = useLeadTypesTag();

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <div className={styles.content}>
      {item
        ? item.labels?.map((v, index) => (
            <Box key={index} marginTop={index > 0 ? '4px' : '0px'}>
              <Tag<string> value={v} configs={configs} />
            </Box>
          ))
        : ''}
    </div>
  );
});
