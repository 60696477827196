import { Dispatch } from 'react';
import { PlatformDashboard } from '../types/dashboard-types';
import { apiClient, apiErrorHandler, CORE_URL } from 'src/app/common/network';
const jose = require('jose');

export type ChartListParam = {
  chartName: string;
  chartType: string;
  campaignId: string;
  consultantCode: string;
  campaignStatus: string;
  campaignType: string;
  campaignRecordType: string;
  leadSubSource: string;
  startDate: Date[];
  campaignStartDt: Date[];
  assignDate: Date[];
  assignedDate: Date[];
  type: string;
  leadSource: string;
  unitCode: string;
  businessManagerName: string;
  consultantName: string;
  region: string;
  channel: string;
};

export type DashboardBody = {
  name: string;
  ios: PlatformDashboard;
  android: PlatformDashboard;
};

export interface GetMongoChartTokenRes {
  tokenType: string;
  accessToken: string;
  expiresIn: number;
}

export const createDashboard = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {};

export const updateDashboard = async (id: string, body: DashboardBody, dispatch?: Dispatch<any>): Promise<void> => {};

export const fetchDashboardItem = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {};

export const fetchDashboardList = async (params: ChartListParam, dispatch?: Dispatch<any>): Promise<any> => {};

export const fetchChartList = async (params: ChartListParam, dispatch?: Dispatch<any>): Promise<any> => {};

export const deleteDashboard = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {};

export const fetchDashboardJWT = async (user: any, password: any): Promise<any> => {
  const secret = new TextEncoder().encode(password);
  const alg = 'HS256';
  return new jose.SignJWT({ username: user })
    .setProtectedHeader({ alg, typ: 'JWT' })
    .setIssuedAt()
    .setExpirationTime('1h')
    .sign(secret);
};

export const getMongoChartToken = async (dispatch?: Dispatch<any>): Promise<GetMongoChartTokenRes> => {
  return apiClient
    .post<any>(`${CORE_URL}/auth/token/mongochart`, {})
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
