import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  listContainer: {
    padding: '24px 0px',
    marginTop: '24px',
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 8,
  },
}));
