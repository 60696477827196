import { RequestData } from 'src/app/common/components/ProTable';
import { getProspectLead } from 'src/app/modules/Leads/network/leadsCrud';
import { Lead } from 'src/app/modules/Leads/types/types';

export const getProspectLeads = async (
  prospect: string,
  params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
  sort?: { [key: string]: any },
  filter?: { [key: string]: any },
  dispatch?: React.Dispatch<any>,
): Promise<RequestData<Lead>> => {
  const { page, pageSize: limit } = params;

  const formatParams: any = { page: page + 1, limit, includeMassLead: true, prospect };

  if (sort) {
    const sortItem = Object.entries(sort)?.[0];
    if (sortItem) {
      formatParams.sortBy = sortItem[0];
      formatParams.sortOrder = sortItem[1] === 'asc' ? 1 : -1;
    }
  }

  if (!formatParams.sortBy) {
    formatParams.sortBy = 'followUpTab';
    formatParams.sortOrder = 1;
  }

  const res = await getProspectLead(formatParams, dispatch);

  if (res) {
    const { lead, totalDocs, ...rest } = res ?? {};

    return {
      success: true,
      data: (lead ?? []).map(({ prospect, prospectMaster, prospectDetail, ...rest }) => ({
        ...rest,
        prospectDetail,
        prospect: prospectDetail ?? prospect ?? prospectMaster,
      })),
      total: totalDocs,
      ...rest,
    };
  }

  return {
    success: false,
    data: [],
    total: 0,
  };
};
