import { LOCAL_STORAGE_KEYS } from '../constants';

let _env: EnvData = {};

export type EnvData = {
  toastRef?: any;
  modalRef?: any;
  intlRef?: any;
  authState?: any;
  agentCode?: string;
};

function setEnv(env: EnvData): void {
  _env = env;
}

function setSafetyEnv(env: EnvData): void {
  _env = { ..._env, ...(env || {}) };
}

function getEnv(): EnvData {
  return _env;
}

function setGlobalToastRef(toastRef: any): void {
  _env = { ..._env, toastRef };
}

function getGlobalToastRef(): any {
  return _env.toastRef || {};
}

function setGlobalModalRef(modalRef: any): void {
  _env = { ..._env, modalRef };
}

function getGlobalModalRef(): any {
  return _env.modalRef || {};
}

function setGlobalIntlRef(intlRef: any): void {
  _env = { ..._env, intlRef };
}

function getGlobalIntlRef(): any {
  return _env.intlRef || {};
}

export const GlobalHelper = {
  // setter
  setEnv,
  setSafetyEnv,
  setGlobalToastRef,
  setGlobalModalRef,
  setGlobalIntlRef,

  //getter
  getEnv,
  getGlobalToastRef,
  getGlobalModalRef,
  getGlobalIntlRef,
};
