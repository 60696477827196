import React, { FC, memo, useMemo } from 'react';
import { FormControlLabel, FormLabel, Input, Radio, Stack, TextField } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { InputFilterConfig } from '../../list-filter.component';

type ComponentProps = ParamsProps & {
  value: unknown;
  filterConfig: InputFilterConfig;
  onChange?: (e: React.ChangeEvent) => void;
};

export const ListFilterInputComponent: React.FC<ComponentProps> = memo(
  ({ value, filterConfig, onChange }: ComponentProps) => {
    return (
      <Stack sx={{ mt: filterConfig.title ? 3 : undefined }}>
        <FormLabel sx={{ fontSize: 16, fontWeight: 600, color: 'rgba(51, 51, 51, 1)' }}>{filterConfig.title}</FormLabel>
        <TextField
          sx={{ mt: 2, ml: 1 }}
          variant="outlined"
          color="secondary"
          label={filterConfig.inputLabel || filterConfig.title}
          value={value || ''}
          onChange={onChange}
        />
      </Stack>
    );
  },
);
