import React, { memo, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './accept.style';
import PruDialog from 'src/app/common/components/PruDialog';

type ComponentProps = ParamsProps & {
  title?: string;
  body?: string;
  tnc?: string;
  showDialog?: boolean;
  onAccept: () => Promise<void>;
  onClose: () => void;
};

const AcceptWithConfirm: React.FC<Omit<ComponentProps, 'showDialog'>> = ({ title, body, tnc, onAccept, onClose }) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [loading, setLoading] = useState(false);
  const [selectedTnc, setSelectedTnc] = useState(false);

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <PruDialog
      open
      dialogTitle={title}
      confirmBtnText={Translation('app.button.confirm')}
      canncelBtnText={Translation('app.button.cancel')}
      onOk={async () => {
        setLoading(true);
        try {
          await onAccept();
        } finally {
          onClose();
          setLoading(false);
        }
      }}
      onCancel={() => {
        onClose?.();
      }}
      loading={loading}
      disabledConfirm={!selectedTnc}
    >
      <Box className={styles.container} onClick={(e) => e.stopPropagation()}>
        <Typography className={styles.text}>{body}</Typography>
        <Box marginTop={'16px'} display="flex" flexDirection={'row'}>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                sx={{ alignItems: 'flex-start', paddingTop: '2px' }}
                checked={selectedTnc}
                onChange={(event, checked) => {
                  setSelectedTnc(checked);
                }}
              />
            }
            sx={{ alignItems: 'flex-start' }}
            label={<Typography className={styles.tnc}>{tnc}</Typography>}
          />
        </Box>
      </Box>
    </PruDialog>
  );
};

const DirectlyAccept: React.FC<ComponentProps> = ({ onAccept, onClose }) => {
  const toAccept = async () => {
    try {
      await onAccept();
    } finally {
      onClose?.();
    }
  };

  useEffect(() => {
    toAccept();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export const Accept: React.FC<ComponentProps> = memo(
  ({ showDialog = false, onAccept, onClose, ...rest }: ComponentProps) => {
    return showDialog ? (
      <AcceptWithConfirm {...rest} onAccept={onAccept} onClose={onClose} />
    ) : (
      <DirectlyAccept onAccept={onAccept} onClose={onClose} />
    );
  },
);
