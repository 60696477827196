import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import { AGENCY_CAMPAIGN_BASE_PATH, AGENCY_CAMPAIGN_DASHBOARD_PATH } from '../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import DashboardListPage from './List/DashboardListingPage';
import { GAData } from '../../../../common/ga/redux/gaSlice';
import { CAMPAIGN_BASE_PATH } from 'src/app/modules/Campaign/constants';

export const dashboardPath = `${CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_DASHBOARD_PATH}`;

const campaignDashboardGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'View Agency Campaign',
  stage: 'View Campaign Dashboard',
  screen_name: 'Campaign Dashboard',
};

const BDMDashboardRoutes: FC<RouteComponentProps> = (props) => {
  return (
    <Switch>
      <PruRoute path={`${dashboardPath}`} component={DashboardListPage} gaData={campaignDashboardGaDataRoute} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default BDMDashboardRoutes;
