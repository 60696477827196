import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Card } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
  timelineOppositeContentClasses,
} from '@mui/lab';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './tab-approval.style';
import { CampaignStructureItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { WorkflowApprovalStatusEnum } from 'src/app/modules/AgencyCampaign/constants';
import { getDisplayDate } from 'src/app/common/utils/time-utils';
import RemarksItem from '../../Details/approval/components/RemarksItem';
import { LouTemplate } from '../../Details/approval/components/LouTemplate';
import { isEmptyArray } from 'formik';

type ComponentProps = ParamsProps & {
  campaignTypeStructureData: CampaignStructureItem;
  formDispatch: (data: any) => void;
  approvalHistoryList: any;
  campaignObjId: string | undefined;
  remarkList: any;
  submitData?: { submitDate: Date; submitBy: string; cancelDate: Date; canceledBy: string; cancelReason?: string };
  approvalData?: any;
  previousApprovalData?: any;
};

export const TabApprovalComponent: React.FC<ComponentProps> = memo(
  ({
    campaignObjId,
    approvalHistoryList,
    remarkList,
    submitData,
    approvalData,
    previousApprovalData = [],
  }: ComponentProps) => {
    // Display sequence:
    // 1. approvalHistoryList (sorted by levelOfApproval, desc)
    // 2. submitData
    // 3. previousApprovalData (sorted by updatedAt, desc)

    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    return (
      <>
        <Card elevation={3} className={styles.approvalCard}>
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.1,
              },
            }}
          >
            {submitData ? (
              <>
                {!!submitData.canceledBy && (
                  <TimelineItem>
                    <TimelineOppositeContent className={styles.time}>{`${getDisplayDate(
                      submitData.cancelDate,
                      'DD/MM/YYYY HH:mm',
                    )}`}</TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="secondary" className={styles.dot} />
                      {approvalHistoryList.length === 0 ? null : <TimelineConnector className={styles.connector} />}
                    </TimelineSeparator>
                    <TimelineContent className={styles.context}>
                      <div>{Translation('agencyCampaign.common.status.canceled')}</div>
                      <div>
                        {`${Translation('agencyCampaign.common.status.canceled')} ${Translation('common.by')} ${
                          submitData.canceledBy
                        }`}
                      </div>
                      <div>{submitData.cancelReason}</div>
                    </TimelineContent>
                  </TimelineItem>
                )}
                {approvalHistoryList
                  .sort((a: any, b: any) => b.levelOfApproval - a.levelOfApproval)
                  .map((approvalHistory: any, index: number) => (
                    <TimelineItem
                      className={`${
                        !!submitData.canceledBy ||
                        (approvalHistory.status === WorkflowApprovalStatusEnum.APPROVED && index !== 0) ||
                        (approvalHistoryList[index + 1] &&
                          approvalHistoryList[index + 1].status !== WorkflowApprovalStatusEnum.APPROVED)
                          ? styles.olderRecord
                          : ''
                      }`}
                      key={`approvalHistoryList${index}`}
                    >
                      <TimelineOppositeContent className={styles.time}>
                        {approvalHistory.status !== WorkflowApprovalStatusEnum.PENDING &&
                          `${getDisplayDate(approvalHistory.updatedAt, 'DD/MM/YYYY HH:mm')}`}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="secondary" className={styles.dot} />
                        <TimelineConnector className={styles.connector} />
                      </TimelineSeparator>
                      <TimelineContent className={styles.context}>
                        <div>
                          {approvalHistory.role.join(', ')} {Translation('agencyCampaign.approval')}
                        </div>
                        <div>
                          {Translation(`agencyCampaign.common.status.${approvalHistory.status}`)}
                          {approvalHistory.status !== WorkflowApprovalStatusEnum.PENDING &&
                            ` ${Translation('common.by')} ${approvalHistory.assigneeName}`}
                        </div>
                        {approvalHistory.comment && (
                          <div className={styles.comment}>
                            {Translation('agencyCampaign.approval.comment')}: {approvalHistory.comment}
                          </div>
                        )}
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                <TimelineItem
                  className={`
                  ${approvalHistoryList.length > 0 || !!submitData.canceledBy ? styles.olderRecord : ''}`}
                >
                  <TimelineOppositeContent className={styles.time}>
                    <div>{`${getDisplayDate(submitData.submitDate, 'DD/MM/YYYY HH:mm')}`}</div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="secondary" className={styles.dot} />
                    {previousApprovalData.length === 0 ? null : <TimelineConnector className={styles.connector} />}
                  </TimelineSeparator>
                  <TimelineContent className={styles.context}>
                    <div>{Translation('agencyCampaign.approval.submit')}</div>
                    <div>{`${Translation('agencyCampaign.approval.submittedBy')} ${submitData.submitBy}`}</div>
                  </TimelineContent>
                </TimelineItem>
              </>
            ) : (
              //approval not submit yet
              <>
                {approvalData?.length
                  ? approvalData.map((data: any, idx: number) => (
                      <TimelineItem
                        key={`approvalData${idx}`}
                        className={`${approvalData.length > 0 ? styles.olderRecord : ''}`}
                      >
                        <TimelineOppositeContent className={styles.time}>-</TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot color="secondary" className={styles.dot} />
                          <TimelineConnector className={styles.connector} />
                        </TimelineSeparator>
                        <TimelineContent className={styles.context}>
                          <div>{String(data.labelName)}</div>
                        </TimelineContent>
                      </TimelineItem>
                    ))
                  : null}
                <TimelineItem>
                  <TimelineOppositeContent className={styles.time}>-</TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="secondary" className={styles.dot} />
                    {previousApprovalData.length === 0 ? null : <TimelineConnector className={styles.connector} />}
                  </TimelineSeparator>
                  <TimelineContent className={styles.context}>
                    <div>{Translation('agencyCampaign.approval.submit')}</div>
                    <div>{Translation('agencyCampaign.common.status.pending')}</div>
                  </TimelineContent>
                </TimelineItem>
              </>
            )}
            {previousApprovalData?.length > 0 &&
              previousApprovalData
                .sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
                .map((approval: any, index: number) => (
                  <TimelineItem className={styles.olderRecord} key={`previousApprovalData${index}`}>
                    <TimelineOppositeContent className={styles.time}>
                      {approval.status !== WorkflowApprovalStatusEnum.PENDING &&
                        `${getDisplayDate(approval.submitDate, 'DD/MM/YYYY HH:mm')}`}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="secondary" className={styles.dot} />
                      {index === previousApprovalData.length - 1 ? null : (
                        <TimelineConnector className={styles.connector} />
                      )}
                    </TimelineSeparator>
                    <TimelineContent className={styles.context}>
                      <div>
                        {approval.role.join(', ')}
                        {approval.type === 'submit'
                          ? Translation('agencyCampaign.approval.submit')
                          : approval.type === 'cancel'
                            ? Translation('agencyCampaign.common.status.canceled')
                            : ` ${Translation('agencyCampaign.approval')}`}
                      </div>
                      <div>
                        {approval.type === 'cancel'
                          ? Translation('agencyCampaign.common.status.canceled')
                          : approval.type === 'submit'
                            ? Translation('agencyCampaign.approval.submittedBy')
                            : Translation(`${approval.status}`)}
                        {approval.status !== WorkflowApprovalStatusEnum.PENDING &&
                          ` ${Translation('common.by')} ${approval.assigneeName}`}
                        <div>{approval.reason}</div>
                      </div>
                      {approval.comment && (
                        <div>
                          {Translation('agencyCampaign.approval.comment')}: {approval.comment}
                        </div>
                      )}
                    </TimelineContent>
                  </TimelineItem>
                ))}
          </Timeline>
        </Card>
        {!isEmptyArray(remarkList) && (
          <Card elevation={3} className={styles.remarkCard}>
            <div className={styles.remarkCardContainer}>
              <div className={classes.sectionTitle}>{Translation('agencyCampaign.common.remarks')}</div>
              <div className={styles.remarkCardContentContainer}>
                <div className={styles.flex_1}>
                  {remarkList &&
                    remarkList.map((item: any, index: Number) => {
                      return (
                        <React.Fragment key={`reamark${index}`}>
                          {item.sendTypes.includes('applicant') || item.sendTypes.includes('participant') ? (
                            <div className={classes.level2}>
                              {`${item.updatedBy} ${getDisplayDate(item.updatedAt, 'DD/MM/YYYY HH:mm')}`}
                              <div className={styles.remarkCardFilesContainer}>
                                {item.resourceIds &&
                                  (item.resourceIds as string[]).map((item, index) => {
                                    return <RemarksItem RemarksItem={item} index={index} key={index} />;
                                  })}
                                {item.type === 'variableContent' && (
                                  <LouTemplate campaignObjId={campaignObjId} remarksItem={item} />
                                )}
                              </div>
                            </div>
                          ) : null}
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
            </div>
          </Card>
        )}
      </>
    );
  },
);
