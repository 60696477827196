import { useEffect, useState } from 'react';
import { useUpdateEffect } from './hook-utils';

export const usePaginateHandler = (
  onChangePage?: (page: number, rowsPerPage: number) => void,
  totalPages?: number,
  defaultPage?: number,
  defaultRowsPerPage?: number,
) => {
  const [page, setPage] = useState<number>(defaultPage ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage ?? 20);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (totalPages !== undefined && page + 1 > totalPages) {
      setPage(0);
    }
  }, [totalPages, page]);

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useUpdateEffect(() => {
    if (onChangePage) {
      onChangePage(page + 1, rowsPerPage);
    }
  }, [page, rowsPerPage]);

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
