import React, { memo, useMemo } from 'react';

import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { useStyles } from './lead-time-display.style';
import { DateFormat, formatDate } from '../../../../../common/utils/date.util';

type ComponentProps = ParamsProps & {
  currValue: string;
};

export const LeadTimeDisplayComponent: React.FC<ComponentProps> = memo(({ currValue }: ComponentProps) => {
  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return <div className={styles.content}>{currValue ? formatDate(currValue, DateFormat.datetime) : ''}</div>;
});
