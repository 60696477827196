import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Card, CircularProgress, Dialog, DialogTitle } from '@mui/material';
import { Button } from 'src/app/common/components/button/button.component';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './participant-add-file.style';
import { useParticipantAddFile } from './participant-add-file.hook';
import { FullScreenDialog } from 'src/app/common/components/Dialog/full-screen-dialog/full-screen-dialog.component';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import { downloadParticipantUploadCsv } from 'src/app/modules/AgencyCampaign/network/campaignCurd';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { AgentItem, CampaignStructureItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { ParticipantEligibilityComponent } from '../participant-eligibility/participant-eligibility.component';
import { toAbsoluteUrl } from 'src/app/common/utils/path-utils';

type ComponentProps = ParamsProps & {
  campaignObjId: string;
  campaignTypeStructureData: CampaignStructureItem;
  fullScreenDialogTitle: string;
  fullScreenDialogOpen: boolean;
  onClose: () => void;
  doneImport: (data: AgentItem[]) => void;
};

export const ParticipantAddFileComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const { classes: commonStyle } = useCommonStyles();

  // custom-hook
  const { campaignObjId, campaignTypeStructureData, fullScreenDialogOpen, fullScreenDialogTitle, onClose, doneImport } =
    props;
  const {
    loading,
    toggleECheckDialog,
    formState,
    handlingFile,
    t,
    formDispatch,
    setToggleECheckDialog,
    handleFile,
    onImportFromFile,
    removeAndProceed,
    removeFile,
    onPreviousFromFailImport,
    // importFile,
    _renderUploadedFileDetail,
  } = useParticipantAddFile({
    campaignObjId,
    campaignTypeStructureData,
    doneImport,
  });

  return (
    <FullScreenDialog
      title={fullScreenDialogTitle}
      open={fullScreenDialogOpen}
      onClose={() => {
        onClose();
      }}
      confirmBtnText={Translation('app.button.import')}
      onConfirm={onImportFromFile}
    >
      <div className={styles.tableContainer}>
        <Card elevation={3} className={styles.mt_24}>
          {!formState.file && (
            <div className={styles.uploadBtnContainer}>
              <div className={styles.templateContainer}>
                <span className={styles.templateLabel}>
                  {Translation('agencyCampaign.addParticipant.templateFile')}
                </span>
                <AsyncCsvLink
                  isDisabled={false}
                  filename={`AgentUpload.csv`}
                  dataParser={(str) => str}
                  asyncCall={() => downloadParticipantUploadCsv()}
                >
                  <a className={`${commonStyle.link} ${styles.templateLink}`}>
                    {Translation('agencyCampaign.create.downloadTemplate')}
                  </a>
                </AsyncCsvLink>
              </div>

              <div>
                <input
                  id="upload-participant-list-file-csv"
                  hidden
                  type="file"
                  accept=".csv"
                  onClick={(e) => {
                    const element = e.target as HTMLInputElement;
                    element.value = '';
                  }}
                  onChange={handleFile}
                />
                <Button
                  className={styles.uploadBtn}
                  onClick={() => document.getElementById('upload-participant-list-file-csv')!.click()}
                >
                  <div className={classes.uploadBtnTextContainer}>
                    <div className={classes.uploadBtnTitle}>
                      {handlingFile && <CircularProgress sx={{ marginLeft: '8px', color: 'black' }} size={14} />}{' '}
                      {Translation('agencyCampaign.upload.selectFiles')}
                    </div>
                    <div className={classes.uploadBtnSubtitle}>
                      {t('agencyCampaign.upload.selectFrom', {
                        child: <span className={classes.browser}>{Translation('agencyCampaign.upload.browse')}</span>,
                      })}
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          )}
          {formState.file && (
            <>
              {_renderUploadedFileDetail()}
              {formState.participantListFormatError.length > 0 ? (
                <>
                  <p className={styles.importFailTitle}>*{Translation('agencyCampaign.import.failed')}</p>
                  <div className={classes.importFormat}>
                    {formState.participantListFormatError.map((item, index) => (
                      <p className={styles.importFailItem} key={index}>
                        {Translation(item)}
                      </p>
                    ))}
                  </div>
                </>
              ) : (
                <div className={styles.importBtn}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    disabled={loading}
                    onClick={onImportFromFile}
                  >
                    {loading ? Translation('common.loading') : Translation('app.button.import')}
                  </Button>
                </div>
              )}
            </>
          )}
        </Card>
        <Dialog
          open={toggleECheckDialog}
          onClose={() => {
            setToggleECheckDialog(false);
          }}
          maxWidth="md"
        >
          <DialogTitle className={styles.dialogTitle}>
            {Translation('agencyCampaign.create.eligibilityCheck')}
          </DialogTitle>
          <ParticipantEligibilityComponent
            dataList={formState.failImportList}
            onCancel={async () => {
              await onPreviousFromFailImport?.();
              setToggleECheckDialog(false);
            }}
            onProcess={async () => removeAndProceed()}
            onFinish={() => onClose()}
          />
        </Dialog>
      </div>
    </FullScreenDialog>
  );
});
