import React, { FC, ReactNode, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useStyles } from './select-option.style';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {
  ProspectCategory,
  ProspectCategoryProps,
  SIZE_TYPE,
} from 'src/app/common/components/prospect-category/prospect-category.component';

export interface SelectOptionContainerProps {
  checked?: boolean;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
}

interface OptionProps<T> {
  option: T;
  checked: boolean;
}

export interface SelectOptionProps<T> extends Omit<SelectOptionContainerProps, 'onClick' | 'children'> {
  index: number;
  option: T;
  selected?: number;
  onClick?: (index: number) => void;
  OptionsComp: React.ComponentType<OptionProps<T>>;
}

export const SelectOptionContainer = ({ checked, className, onClick, children }: SelectOptionContainerProps) => {
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  return (
    <Box
      onClick={() => onClick?.()}
      className={`${styles.container} ${checked ? `${styles.selectedContainer} ${styles.selectedColor}` : ''} ${
        className ?? ''
      }`}
    >
      {children}
    </Box>
  );
};

export const SelectOption = <T,>({
  selected,
  className,
  index,
  onClick,
  option,
  OptionsComp,
}: SelectOptionProps<T>) => {
  const checked = selected === index;

  return (
    <SelectOptionContainer onClick={() => onClick?.(index)} checked={checked} className={className}>
      <OptionsComp option={option} checked={checked} />
    </SelectOptionContainer>
  );
};

type Item = OptionProps<{ label: string }>;

export const MergeSelectOptionItem = ({ option, checked }: Item) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <Box className={styles.selectOptionItemContainer}>
      <Box className={styles.left}>
        <PermIdentityIcon />
        <Typography className={styles.text}>{option.label}</Typography>
      </Box>
      {checked ? (
        <Typography className={styles.retain}>
          {Translation('pruleads.prospect.list.action.merge.dialog.retain')}
        </Typography>
      ) : null}
    </Box>
  );
};

interface MergeSelectOptionProps extends Omit<SelectOptionProps<{ label: string }>, 'option' | 'OptionsComp'> {
  label: string;
}

export const MergeSelectOption: React.FC<MergeSelectOptionProps> = memo(
  ({ label, ...rest }: MergeSelectOptionProps) => {
    return <SelectOption {...rest} option={{ label }} OptionsComp={MergeSelectOptionItem} />;
  },
);

type CategoryOption = { name: string; description: string; type: string; color: string; backgroundColor: string };

type CategoryItem = OptionProps<CategoryOption>;

export const CategorySelectOptionItem = ({ option, checked }: CategoryItem) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <Box className={styles.pd16}>
      <ProspectCategory data={option as unknown as ProspectCategoryProps} size={SIZE_TYPE.large} showDetail />
    </Box>
  );
};

interface CategorySelectOptionProps extends Omit<SelectOptionProps<CategoryOption>, 'OptionsComp'> {}

export const CategorySelectOption: React.FC<CategorySelectOptionProps> = memo((props: CategorySelectOptionProps) => {
  return <SelectOption {...props} OptionsComp={CategorySelectOptionItem} />;
});

interface CommonOptionProps extends Omit<SelectOptionProps<{ label: string }>, 'OptionsComp' | 'option'> {
  label: string;
}

export const CommonSelectOptionItem = ({ option }: Item) => {
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <Box className={styles.selectOptionItemContainer}>
      <Box className={styles.left}>
        <Typography className={styles.text}>{option.label}</Typography>
      </Box>
    </Box>
  );
};

export const AddParticipantSelectOption: React.FC<CommonOptionProps> = memo(({ label, ...rest }: CommonOptionProps) => {
  return <SelectOption {...rest} option={{ label }} OptionsComp={CommonSelectOptionItem} />;
});

const CheckableSelectOptionComp = ({ option, checked }: OptionProps<{ label: string }>) => {
  return (
    <Box height={'56px'} alignItems={'center'} display="flex" paddingX={'24px'} onClick={() => {}}>
      <Checkbox sx={{ alignItems: 'center', width: '24px', height: '24px', marginRight: '12px' }} checked={checked} />
      <Typography fontSize={'16px'} fontWeight={500}>
        {option.label}
      </Typography>
    </Box>
  );
};

export const CheckableSelectOption: React.FC<CommonOptionProps> = memo(({ label, ...rest }: CommonOptionProps) => {
  return <SelectOption {...rest} option={{ label }} OptionsComp={CheckableSelectOptionComp} />;
});
