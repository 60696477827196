import apiClient from '../axios';
import { ResourceCreateBlobRes, ResourceGetBlobRes } from '../../types';
import { Dispatch } from 'react';
import { apiErrorHandler } from '../apiErrorHandler';
import { AGENT_URL } from 'src/app/common/network';

export type CreateBlobParams = {
  mimeType: string;
  accessLevel: 'public' | 'private' | 'anonymous';
  module?: string;
  owner?: string;
  originalFilename?: string;
};

export type GetBlobParams = {
  resourceIds: string | string[];
};

export type GetLOUParams = {
  templateId: string;
  id: string; // campaign object id
};

export const createBlobAsAgent = async (
  params: CreateBlobParams,
  dispatch?: Dispatch<any>,
): Promise<ResourceCreateBlobRes[]> => {
  return apiClient
    .post<ResourceCreateBlobRes[]>(`${AGENT_URL}/agents/me/file-upload/blob`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getBlobAsAgent = async (
  params: GetBlobParams,
  dispatch?: Dispatch<any>,
): Promise<ResourceGetBlobRes[]> => {
  return apiClient
    .get<ResourceGetBlobRes[]>(`${AGENT_URL}/agents/me/file-upload/${params.resourceIds}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getLOUTemplate = async (params: GetLOUParams, dispatch?: Dispatch<any>): Promise<string> => {
  const url = `${AGENT_URL}/agents/me/agency-campaign/lou-template`;
  return apiClient
    .post<string>(url, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
