import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  content: {
    display: 'inline-block',
    color: '#666666',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    wordBreak: 'break-all',
  },
}));
