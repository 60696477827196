import { DateFormat, formatDate } from 'src/app/common/utils';
import { DateRadioGroupType } from '../components/list-filter-date-radio-group/list-filter-date-radio-group.component';
import { FilterComponentsEnum } from '../filter-components.enum';
import { ApiKey, ListFilterConfig, ListFilterData } from '../list-filter.component';
import { DateRangePickerType } from '../../simple-date-range-picker/simple-date-range-picker.component';

export const formatDateDisplay = (start?: string, end?: string) => {
  return `${start ? formatDate(start, DateFormat.date) : ''} - ${end ? formatDate(end, DateFormat.date) : ''}`;
};

export const valueToDisplay = (filterValues: ListFilterData, filterConfig: ListFilterConfig) => {
  let value: any;
  const { key, type } = filterConfig;

  switch (type) {
    case FilterComponentsEnum.RADIO_GROUP:
      value = filterValues[key] as DateRadioGroupType;
      return value?.key === 'customize'
        ? formatDateDisplay(value?.value?.startDate, value?.value?.endDate)
        : value?.value?.label;
    case FilterComponentsEnum.PURE_RADIO_GROUP:
    case FilterComponentsEnum.CHECK_BOX_GROUP:
    case FilterComponentsEnum.DROPDOWN:
      value = typeof filterValues[key] === 'string' ? [filterValues[key]] : filterValues[key];
      return value.map((v: string) => filterConfig.items?.find(({ itemKey }) => itemKey === v)?.itemLabel).join(', ');
    case FilterComponentsEnum.DATE_TIME_PICKER:
      value = filterValues[key] as DateRangePickerType;
      return formatDateDisplay(value?.startDate, value?.endDate);
  }
};
