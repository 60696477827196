import React, { FC, useRef } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import { AI_TALKBOT_BASE_PATH, LIST_PATH, AI_TALKBOT_EXPORT_LEADS_PATH, LIST_DEFAULT_PARAMS } from '../../constants';
import { LandingPage } from './landing/landing.screen';
import { ExportLeadsListPage } from './export-leads-list/export-leads-list.screen';
import { AutoDropListPage } from './auto-drop-list/auto-drop-list.screen';

const ExportLeadsRoutes: FC<RouteComponentProps> = (props) => {
  const cacheParams = useRef({ ...LIST_DEFAULT_PARAMS() });
  return (
    <Switch>
      <PruRoute exact path={`${AI_TALKBOT_BASE_PATH}${AI_TALKBOT_EXPORT_LEADS_PATH}`} component={LandingPage} />
      <PruRoute
        exact
        path={`${AI_TALKBOT_BASE_PATH}${AI_TALKBOT_EXPORT_LEADS_PATH}${LIST_PATH}`}
        render={(props) => <ExportLeadsListPage {...props} cacheParams={cacheParams} />}
      />
      <PruRoute
        exact
        path={`${AI_TALKBOT_BASE_PATH}${AI_TALKBOT_EXPORT_LEADS_PATH}${LIST_PATH}/auto-drop`}
        component={AutoDropListPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default ExportLeadsRoutes;
