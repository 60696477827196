import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './participant-list.style';
import { useParticipantList } from './participant-list.hook';
import ProFilterTable, { ActionNodeType, ProCoreActionType } from 'src/app/common/components/ProTable';
import { AgentItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { ActionItem } from '../action-item/action-item.component';

type ComponentProps = ParamsProps & {
  startDate?: string;
  campaignOwner: string;
  disabledEdit?: boolean;
  disabled?: boolean;
  setRemoveOption: (val: any) => void;
  campaignObjId?: string;
  onSelectedRowChange: (rows: AgentItem[]) => void;
  tableRef?: React.MutableRefObject<ProCoreActionType | undefined>;
  isRequiredRsvp: boolean;
};

export const ParticipantList: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { columns, getData, rowSelection } = useParticipantList(props);

  const { setRemoveOption } = props;

  const ActionNode: ActionNodeType<AgentItem> = useMemo(() => {
    return (props) => {
      const onClick = () => {
        setRemoveOption({
          open: true,
          applicantId: props.selectedRows.map(({ agentCode }) => agentCode),
        });
        props.onAction(['clearRow']);
      };

      return <ActionItem onClick={onClick} />;
    };
  }, [setRemoveOption]);

  return (
    <ProFilterTable
      showFilter={false}
      rowKey="_id"
      columns={columns}
      request={getData}
      enableRefresh={false}
      rowSelection={rowSelection}
      actionRef={props.tableRef}
      ActionNode={ActionNode}
    />
  );
});
