import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './agent-reassign-to-table.style';
import { useAgentReassignToTable } from './agent-reassign-to-table.hook';
import ProFilterTable, { CheckType } from 'src/app/common/components/ProTable';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components/pro-search-filter/pro-search-filter.component';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  groupCode: string;
  onSelectedAgent?: (selected: any) => void;
};

export const AgentReassignToTable: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { groupCode, onSelectedAgent } = props;

  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { tableColumns, getData } = useAgentReassignToTable({ groupCode: groupCode });

  const onRowSelection = (selectedRows: any[]) => {
    onSelectedAgent && onSelectedAgent(selectedRows[0]);
  };

  const rowSelection = useMemo(() => {
    return {
      onChange: onRowSelection,
      getCheckboxProps: () => {
        return {};
      },
    };
  }, []);
  return (
    <>
      <ProFilterTable
        Filter={ProSearchFilter}
        showFilterIcon={false}
        showFilter={true}
        rowKey="agentCode"
        key={groupCode}
        rowSelection={rowSelection}
        columns={tableColumns}
        request={getData}
        enableRefresh={false}
        checkType={CheckType.radio}
      />
    </>
  );
});
