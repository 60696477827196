import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { formateProsectCategory } from 'src/app/modules/Prospect/util/common.util';
import { fetchProspectCategories } from 'src/app/modules/Prospect/network/prospectCurd';
import { ProspectCategoryProps } from 'src/app/common/components/prospect-category/prospect-category.component';
import { ProspectCategoryPickerProps } from './prospect-category-picker.component';

export const useProspectCategoryPicker = (props: ProspectCategoryPickerProps) => {
  const { defaultCategory, removeNoneType, value } = props;
  const locale = useLang();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [prospectCategories, setProspectCategories] = useState<ProspectCategoryProps[]>([]);
  const [selectedCatagory, setSelectedCatagory] = useState(value ?? '');

  const { data, error, loading, run, runAsync } = useRequest(() => fetchProspectCategories(locale, dispatch), {
    manual: false,
  });

  const onChooseCategory = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCatagory(event.target.value);
  }, []);

  useEffect(() => {
    if (data && data.prospectCategories) {
      const categorys = formateProsectCategory(data.prospectCategories, locale, intl);
      let result = categorys.sort((a, b) => a.type.localeCompare(b.type));
      if (removeNoneType) {
        result = categorys.filter((item) => item.type !== 'None');
      }
      if (result.length > 0) {
        setSelectedCatagory(result[0].id);
      }
      setProspectCategories(result);
      if (defaultCategory) {
        setSelectedCatagory(defaultCategory);
      }
    }
  }, [data]);

  useEffect(() => {
    const category = prospectCategories.find((item) => item.id === selectedCatagory);
    if (category) {
      props.onChanage && props.onChanage(category);
    }
  }, [selectedCatagory]);

  useEffect(() => {
    setSelectedCatagory(value ?? '');
  }, [value]);

  return {
    prospectCategories,
    selectedCatagory,
    onChooseCategory,
  };
};
