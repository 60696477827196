import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {},
  text: {
    fontSize: 16,
    fontWeight: 400,
  },
  optionContainer: {
    paddingTop: 8,
    paddingBottom: 8,
    minWidth: 165,
    // todo: shadow and radius
  },
  option: {
    paddingLeft: 16,
    paddingRight: 16,
    height: 36,
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
  },
}));
