import React, { FC, Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Box, Button, Grid, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { Card } from 'src/app/common/components/card/card.component';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { DialogueItemType } from 'src/app/modules/ai-talkbot/types/talkbot-export-leads-types';
import { useLang } from 'src/app/i18n';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  data: DialogueItemType[];
  curDialogue?: DialogueItemType;
  onClick: (params: DialogueItemType) => void;
};

export const TalkbotDialogueComponent: React.FC<ComponentProps> = memo(
  ({ data, curDialogue, onClick }: ComponentProps) => {
    // i18n
    const { t } = useTranslation();
    const [moveInx, setMoveInx] = useState(0);

    return (
      <Box sx={{ marginTop: 5 }}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography sx={{ fontSize: 24, fontWeight: '600' }}>{t('talkbot.ai_talkbot_dialogue')}</Typography>
          <Stack flexDirection="row" sx={{ '@media (max-width:1430px)': { display: 'none' } }}>
            <Button
              disabled={moveInx === 0}
              sx={{ minWidth: 36, height: 36, padding: 0, border: '2px solid #CCC', borderRadius: 18 }}
              className=" tw-flex tw-items-center tw-justify-center"
              onClick={() => setMoveInx(moveInx - 1)}
            >
              <ArrowBack />
            </Button>
            <Button
              disabled={data?.length <= 6 || moveInx === data?.length % 6}
              sx={{ minWidth: 36, height: 36, padding: 0, border: '2px solid #CCC', borderRadius: 18, marginLeft: 1 }}
              className=" tw-flex tw-items-center tw-justify-center"
              onClick={() => setMoveInx(moveInx + 1)}
            >
              <ArrowForward />
            </Button>
          </Stack>
        </Stack>

        <Box
          sx={{
            width: '100%',
            overflow: 'hidden',
            '@media (max-width:1430px)': {
              overflowX: 'scroll',
            },
          }}
        >
          <Grid
            container
            columnSpacing={2.5}
            sx={{
              flexWrap: 'nowrap',
              paddingTop: 3,
              paddingBottom: 3,
              transform: `translateX(-${moveInx * 16.6}%)`,
              transition: 'transform 0.5s ease',
            }}
          >
            {data.map((item: DialogueItemType, index: number) => {
              return (
                <DialogueItem
                  item={item}
                  key={item.flowId}
                  isActive={curDialogue?.flowId === item.flowId}
                  onClick={(item) => onClick(item)}
                />
              );
            })}
          </Grid>
        </Box>
      </Box>
    );
  },
);

const DialogueItem = ({
  item,
  isActive,
  onClick,
}: {
  item: DialogueItemType;
  isActive: boolean;
  onClick: (item: DialogueItemType) => void;
}) => {
  const locale = useLang();
  const { image } = item;
  return (
    <Grid
      item
      sx={{
        minWidth: '16.6%',
        width: '16.6%',
        '@media (max-width:1430px)': {
          minWidth: 192,
        },
      }}
      onClick={() => onClick(item)}
    >
      <Card className="tw-flex tw-flex-col tw-w-full tw-aspect-square">
        <Stack className="tw-w-full tw-aspect-[3/2]">
          <img src={image[locale]?.url} alt="" className="tw-w-full tw-aspect-[3/2] tw-flex-shrink-0 tw-object-cover" />
        </Stack>
        <Stack
          sx={{ padding: 1 }}
          className={`tw-w-full tw-flex tw-flex-1 tw-items-center tw-justify-center tw-overflow-hidden ${
            isActive ? 'tw-bg-[#FFE4E6]' : ''
          }`}
        >
          <Typography
            className={`tw-w-full tw-text-center tw-text-ellipsis tw-break-all tw-overflow-hidden ${
              isActive ? 'tw-text-[#E8192C]' : ''
            }`}
            sx={{
              fontWeight: '500',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {item.name[locale]}
          </Typography>
        </Stack>
      </Card>
    </Grid>
  );
};
