import de from './de.json';
import es from './es.json';
import fr from './fr.json';
import ja from './ja.json';
import zhHans from './zh-Hans.json';
import id from './id.json';
// original translations
import enOrigin from './en.json';
import zhHantOrigin from './zh_HK.json';
// leads translations
import enLeads from './leads/en.json';
import zhLeads from './leads/zh_HK.json';

const en = { ...enLeads, ...enOrigin };
const zhHant = { ...zhLeads, ...zhHantOrigin };

export { de, en, es, fr, ja, zhHans, zhHant, id };
