import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRequest } from 'ahooks';
import { getReportList, getReportToken } from '../../network/reportsCrud';
const powerbi = require('powerbi-client');

export const useReportDetail = () => {
  const dispatch = useDispatch();

  const [powerBIEmbedData, setPowerBIEmbedData] = useState({
    reportId: '',
    embedUrl: '',
    embedToken: '',
    tokenExpiration: '',
  });
  const [selectedReport, setSelectedReport] = useState({ id: '', embedUrl: '', datasetId: '' });

  const { data: reportListResponse } = useRequest(() => getReportList(dispatch));
  const { runAsync } = useRequest(async (reportId, datasetId) => getReportToken(reportId, datasetId, dispatch), {
    manual: true,
  });
  const MINUTES_BEFORE_EXPIRATION = 10;
  const INTERVAL_TIME = 15 * 60 * 1000;

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => checkTokenAndUpdate(), INTERVAL_TIME);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [INTERVAL_TIME]);

  const checkTokenAndUpdate = async () => {
    // Get the current time
    const currentTime = Date.now();
    const expiration = Date.parse(powerBIEmbedData.tokenExpiration);

    // Time until token expiration in milliseconds
    const timeUntilExpiration = expiration - currentTime;
    const timeToUpdate = MINUTES_BEFORE_EXPIRATION * 60 * 1000;

    // Update the token if it is about to expired
    if (timeUntilExpiration <= timeToUpdate) {
      //Updating report access token

      const newAccessToken = await runAsync(selectedReport.id, selectedReport.datasetId);

      // Update the new token expiration time
      setPowerBIEmbedData({
        reportId: selectedReport.id,
        embedUrl: selectedReport.embedUrl,
        embedToken: newAccessToken.token,
        tokenExpiration: newAccessToken.expiration,
      });

      // Get a reference to the embedded report HTML element
      const embedContainer = $('#embedContainer')[0];

      // Get a reference to the embedded report.
      let report = new powerbi.get(embedContainer);

      // Set the new access token
      report.setAccessToken(newAccessToken.token);
    }
  };
  const handleSelectReport = async (event: any) => {
    const reportData = reportListResponse?.find((report) => report.id === event.target.value);
    if (reportData) {
      const reportToken = await runAsync(reportData.id, reportData.datasetId);
      setPowerBIEmbedData({
        reportId: reportData.id,
        embedUrl: reportData.embedUrl,
        embedToken: reportToken.token,
        tokenExpiration: reportToken.expiration,
      });
      setSelectedReport(reportData);
    }
  };

  return {
    reportListResponse,
    powerBIEmbedData,
    selectedReport,
    handleSelectReport,
  };
};
