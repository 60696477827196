import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get, isEmpty, set } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { useForm } from 'rc-field-form';
import { ProspectDetail, ProspectDetailForm, ProspectGenderEnum } from 'src/app/modules/Prospect/types/prospect-types';
import {
  booleanTypeFields,
  getCityDataSource,
  getInitValue,
  getLanguageMap,
  getMaritalStatusMap,
  getPermanentResidentPools,
  getPickerSelectionPool,
  getProspectGenderMap,
  getSmokerIndicatorPools,
  nonNegativeNumberRegex,
  nonPositiveTypeFields,
  numberTypeFields,
} from 'src/app/modules/Prospect/pages/prospect-detail/component/prospect-information/config/form.config';
import { APP_CONFIG, getAppConfigs } from 'src/app/common/utils';
import { prospectValidator } from 'src/app/modules/Prospect/util/prospectValiade';
import { getProspectById, updateProspect } from 'src/app/modules/Prospect/network/prospectCurd';
import { ProspectDetailContext } from '../../prospect-detail.hook';
import { ProspectCategoryProps } from 'src/app/common/components/prospect-category/prospect-category.component';
import { PROSPECT_BASE_PATH, PROSPECT_DETAIL_PATH, PROSPECT_REFERRER } from 'src/app/modules/Prospect/constants';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { prospectDetailsGaDataClick49 } from 'src/app/common/ga/types/ga-click';

interface HookProps {
  prospectDetail: ProspectDetail;
  // other params
}

const TranslationMap: any = {
  firstName: 'prospect.first_name',
  lastName: 'prospect.last_name',
  regionCode: 'prospect.regionCode',
  phoneNumber: 'prospect.phone_number',
  email: 'prospect.email_address',
  referredBy: 'prospect.referred_by',
};

export const useProspectInformation = ({ prospectDetail }: HookProps) => {
  const { prospectInfomationTabState, updateProspectInfoTabState, updateProspectDetail } =
    useContext(ProspectDetailContext);

  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (key: string, values?: Record<string, any>) => intl.formatMessage({ id: key }, values);
  const history = useHistory();

  const [form] = useForm();
  const prospectDetailRef = useRef<ProspectDetailForm>();
  const formModel: ProspectDetailForm = useMemo(() => {
    const initedValue = getInitValue(prospectDetail);
    prospectDetailRef.current = prospectDetail;
    return initedValue;
  }, [prospectDetail]);
  const [showReferrby, setShowReferrby] = useState(false);
  const [selectedReferrBy, setSelectedReferrBy] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState<string>(prospectDetail.prospectCategory ?? '');
  const [selectedBirthDate, setSelectedBirthDate] = useState<string>(prospectDetail.birthDate ?? '');
  const [region, setRegion] = useState<any[]>([]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [cityList, setCityList] = useState<any[]>(() => {
    return getCityDataSource(formModel.state ?? '', intl);
  });

  const [filedErrors, setFiledErrors] = useState<any>({}); // error message for each field

  const requiredFields: any = useMemo(() => {
    return prospectValidator.getRequiredFields();
  }, []);

  const selectionPools = getPickerSelectionPool(intl);

  const onSelectReferrBy = useCallback((value: any) => {
    setSelectedReferrBy(value[0]);
    setShowReferrby(false);
  }, []);

  const onFiledChange = useCallback((changedValues: any, allValues: any) => {
    const targetFiled = changedValues[0];
    const fieldName = targetFiled.name[0];

    if (fieldName === 'state') {
      const value = targetFiled.value;
      setCityList(getCityDataSource(value, intl));
    }
  }, []);

  const onCategoryChange = useCallback((category: ProspectCategoryProps) => {
    setSelectedCategory(category.id);
  }, []);

  const onDeleteDialogClose = useCallback(() => {
    setShowDeleteDialog(false);
  }, []);

  const deleteProspect = useCallback(() => {
    takeUIClickEvent(prospectDetailsGaDataClick49 as any);
    setShowDeleteDialog(true);
  }, []);

  const onBirthDateChange = useCallback((date: dayjs.Dayjs | null) => {
    if (date) {
      setSelectedBirthDate(date.toISOString());
    }
  }, []);

  const submit = useCallback(() => {
    const formData: ProspectDetailForm = form.getFieldsValue();
    formData.prospectCategory = selectedCategory;
    if (selectedBirthDate) {
      formData.birthDate = selectedBirthDate;
    }
    if (selectedReferrBy) {
      formData.referredBy = selectedReferrBy._id ?? selectedReferrBy.id;
    }

    const patchData: ProspectDetailForm = {
      id: prospectDetail.id,
    };
    const errors: any = {};

    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(formData, key) && prospectDetailRef.current) {
        const keyValue = formData[key as keyof ProspectDetailForm];
        const preKeyValue = prospectDetailRef.current[key as keyof ProspectDetailForm];
        if (keyValue !== undefined && keyValue !== null && keyValue !== preKeyValue) {
          let value = keyValue;
          // check and convert number fields

          if (numberTypeFields.includes(key)) {
            if (value) {
              const isNumber = nonNegativeNumberRegex.test(value);

              if (!isNumber) {
                errors[key] = { error: Translation('prospect.invalid.input') };
              } else {
                value = Number(keyValue);
              }
            } else {
              // avoid empty string for number fields
              value = null; // PCAAEB-24991: undefined fields will be ignored on api request
            }
          }

          if (nonPositiveTypeFields.includes(key)) {
            if (value) {
              const isPositive = Number(value) >= 0;
              if (!isPositive) {
                errors[key] = { error: Translation('prospect.invalid.positive.number') };
              }
            } else {
              // avoid empty string for number fields
              value = null; // PCAAEB-24991: undefined fields will be ignored on api request
            }
          }
          if (booleanTypeFields.includes(key)) {
            value = value === 'true' || value === true;
          }

          patchData[key as keyof ProspectDetailForm] = value;
        }
      }
    }

    //mandatory fields check
    Object.keys(requiredFields).forEach((key) => {
      if (requiredFields[key] && isEmpty(formData[key as keyof ProspectDetailForm])) {
        errors[key] = { error: Translation('prospect.is_required_field', { filed: Translation(TranslationMap[key]) }) };
      }
    });

    //validate phone number
    if (!isEmpty(formData.regionCode) && !isEmpty(formData.phoneNumber)) {
      const regionRegex = region.find((item) => item.value === formData.regionCode)?.regex;
      if (regionRegex) {
        const regex = new RegExp(regionRegex);
        if (!regex.test(formData.phoneNumber ?? '')) {
          errors['phoneNumber'] = { error: Translation('prospect.invalid_phone_number') };
        }
      }
    }

    if (!isEmpty(formData.email)) {
      const emailRegex = /^[\w.+\-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      if (!emailRegex.test(formData.email ?? '')) {
        errors['email'] = { error: Translation('prospect.invalid_email_address') };
      }
    }

    setFiledErrors(errors);
    if (!isEmpty(errors)) {
      return;
    }

    updateProspectInfoTabState && updateProspectInfoTabState({ ...prospectInfomationTabState, saving: true });

    prospectDetailRef.current = { ...prospectDetailRef.current, ...patchData };
    updateProspect(patchData, dispatch)
      .then((res) => {
        res['id'] = res['_id'];
        delete res['_id'];
        updateProspectDetail(getInitValue(res));
        updateProspectInfoTabState &&
          updateProspectInfoTabState({ ...prospectInfomationTabState, saving: false, prospectInfoReadOnly: true });
      })
      .catch((err) => {
        updateProspectInfoTabState &&
          updateProspectInfoTabState({ ...prospectInfomationTabState, saving: false, prospectInfoReadOnly: false });
      });
  }, [
    prospectInfomationTabState,
    selectedCategory,
    requiredFields,
    region,
    updateProspectInfoTabState,
    selectedReferrBy,
    selectedBirthDate,
  ]);

  const onReferredByClick = useCallback(
    (readOnly: boolean | undefined) => {
      const referredBy = selectedReferrBy;
      if (readOnly) {
        referredBy &&
          history.push(`${PROSPECT_BASE_PATH}${PROSPECT_REFERRER}${PROSPECT_DETAIL_PATH}/${referredBy.id}`, {
            prospectBasicInfo: referredBy,
          });
        return;
      }
      setShowReferrby(true);
    },
    [selectedReferrBy],
  );

  const resetFormStatus = useCallback(() => {
    setSelectedBirthDate(prospectDetail.birthDate ?? '');
    setSelectedReferrBy(null);
    setSelectedCategory(prospectDetail.prospectCategory ?? '');
  }, [prospectDetail]);

  useEffect(() => {
    const reigonList = getAppConfigs(APP_CONFIG.GLOBAL_REGION).fullRegionList ?? [];
    if (reigonList) {
      const arr: any[] = [];
      Object.keys(reigonList).forEach((key) => {
        const item = reigonList[key];

        arr.push({
          ...reigonList[key],
          label: `${item.code} ${item.displayName}`,
          value: item.code,
        });
      });
      setRegion(arr);
    }
  }, []);

  useEffect(() => {
    if (prospectDetail.referredBy) {
      getProspectById(prospectDetail.referredBy, dispatch).then((res) => {
        setSelectedReferrBy(res);
      });
    }
  }, [prospectDetail.referredBy]);

  return {
    form,
    formModel,
    selectionPools,
    showReferrby,
    selectedReferrBy,
    region,
    cityList,
    requiredFields,
    filedErrors,
    showDeleteDialog,
    selectedBirthDate,
    selectedCategory,
    onSelectReferrBy,
    setShowReferrby,
    onFiledChange,
    onCategoryChange,
    onDeleteDialogClose,
    deleteProspect,
    submit,
    onReferredByClick,
    onBirthDateChange,
    resetFormStatus,
  };
};
