import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    borderRadius: 5,
    margin: theme.spacing(1),
  },
  error: {
    backgroundColor: 'red',
  },
  mb_24: {
    marginBottom: 24,
  },
  addLeadWarning: {
    color: '#E8192C',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
}));
