import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ListFilterData } from 'src/app/common/components/list-filter/list-filter.component';
import * as InsightType from './insight.type';

const initialState = {} as InsightType.InsightState;

export const insightSlice = createSlice({
  name: 'insight',
  initialState,
  reducers: {
    setOverviewLeadStatusFilter: (state, action: PayloadAction<ListFilterData>) => {
      state.overviewLeadStatusFilter = action.payload;
    },
    setOverviewMarketingLeadFilter: (state, action: PayloadAction<ListFilterData>) => {
      state.overviewMarketingFilter = action.payload;
    },
    // insight home cascading picker
    setPicker: (state, action: PayloadAction<InsightType.InsightCascading>) => {
      state.cascading = action.payload;
    },
    setLeadStatusDetailFilter: (state, action: PayloadAction<InsightType.leadStatusDetailFilter>) => {
      state.leadStatusDetailFilter = action.payload;
    },
    clearLeadStatusDetailFilter: (state) => {
      // clear data
      if (state.leadStatusDetailFilter) {
        state.leadStatusDetailFilter = undefined;
      }
    },

    resetState: () => {
      // clear data
      return initialState;
    },
  },
});

export const insightAction = insightSlice.actions;
