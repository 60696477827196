import React, { FC, memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './lead-status-update-dialog.style';
import { useLeadStatusUpdateDialog } from './lead-status-update-dialog.hook';
import PruDialog from 'src/app/common/components/PruDialog';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateFormat } from 'src/app/common/utils';
import dayjs from 'dayjs';
import { Lead } from 'src/app/modules/Leads/types/types';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

type ComponentProps = ParamsProps & {
  show: boolean;
  onUpdate: () => void;
  onCancel: () => void;
  lead: Lead;
};

type FollowUpStatusSelectProps = {
  statusOptions: { value: string; label: string; disabled: boolean }[];
  onStatusChange?: (status: string) => void;
  onDateChange?: (date: dayjs.Dayjs | null) => void;
  showDatePick?: boolean;
};

type PolicyNumberInputProps = {
  policyNumber: string[];
  onPolicyNumberChange?: (policyNumber: string, index: number) => void;
};

type DeadReasonSelectProps = {
  reasonOptions: { value: string; label: string }[];
  onSelect?: (reason: string) => void;
};

const FollowUpStatusSelect = (props: FollowUpStatusSelectProps) => {
  const { statusOptions, onStatusChange, onDateChange, showDatePick } = props;
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  return (
    <div style={{ width: '100%' }}>
      <TextField
        label={Translation('lead.updateLead.status')}
        variant="outlined"
        select={true}
        SelectProps={{
          size: 'medium',
        }}
        required={true}
        className={styles.input}
        size="medium"
        onChange={(e) => {
          onStatusChange && onStatusChange(e.target.value);
        }}
      >
        {statusOptions.map((option) =>
          <MenuItem disabled={option.disabled} value={option.value}>{option.label}</MenuItem>, // PACS Customization | PACSPFAP-3691
        )}
      </TextField>
      {showDatePick && (
        <DateTimePicker
          label={Translation('lead.updateLead.updateTime')}
          slotProps={{
            textField: {
              variant: 'outlined',
              size: 'medium',
              fullWidth: true,
              required: true,
              // error: Boolean(filedErrors['dateTs']),
              // helperText: filedErrors['dateTs']?.error ?? '',
            },
          }}
          defaultValue={dayjs()}
          ampm={false}
          className={styles.updateTime}
          format={DateFormat.datetime.replace('yyyy', 'YYYY')}
          onChange={onDateChange}
          maxDateTime={dayjs()}
        />
      )}
    </div>
  );
};

const PolicyNumberInput = (props: PolicyNumberInputProps) => {
  const { policyNumber, onPolicyNumberChange } = props;
  function handleInputChange(event: any) {
    const value = event.target.value;
    // filter out non-digit characters
    const sanitizedValue = value.replace(/\D/g, '');
    // truncate the value to 12 characters
    const truncatedValue = sanitizedValue.slice(0, 12);
    // if the sanitized value is different from the original value,
    event.target.value = truncatedValue;
  }
  return (
    <>
      {policyNumber.map((policy, index) => (
        <TextField
          value={policy}
          onChange={(e) => {
            onPolicyNumberChange && onPolicyNumberChange(e.target.value, index);
          }}
          id="outlined-basic"
          variant="outlined"
          size="medium"
          placeholder="Policy Number"
          fullWidth={true}
          InputProps={{
            inputProps: {
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 12,
              onInput: handleInputChange,
            },
          }}
        />
      ))}
    </>
  );
};

const DeadReasonSelect = (props: DeadReasonSelectProps) => {
  const { reasonOptions, onSelect } = props;
  const [selectedReason, setSelectedReason] = useState<string[]>([]);

  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes, cx } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <div className={styles.deadReasonBox}>
      <Typography className={styles.description}>{Translation('lead.updateLead.whyCannotProceed')}</Typography>
      <div>
        {reasonOptions.map((reason) => {
          const checked = selectedReason.includes(reason.value);
          return (
            <Button
              onClick={() => {
                setSelectedReason((prev) => {
                  if (prev.includes(reason.value)) {
                    return prev.filter((item) => item !== reason.value);
                  } else {
                    return [...prev, reason.value];
                  }
                });
                onSelect && onSelect(reason.value);
              }}
              variant="outlined"
              className={cx(styles.btn, { [styles.active]: checked })}
            >
              {checked ? (
                <CheckBox
                  className={cx(styles.checkBox, {
                    [styles.checkBoxActive]: true,
                  })}
                />
              ) : (
                <CheckBoxOutlineBlank className={styles.checkBox} />
              )}
              {reason.label}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export const LeadStatusUpdateDialog: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { lead, show, onUpdate, onCancel } = props;

  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    loading,
    isDead,
    isPolicy,
    statusOptions,
    deadReasonOptions,
    policyNum,
    canSubmit,
    onStatusChange,
    onUpdateTimeChange,
    onPolicyNumberInput,
    onSelectReason,
    addPolicyNumInput,
    resetStatus,
    updateLead,
  } = useLeadStatusUpdateDialog({
    lead: lead,
    updateCallback: onUpdate,
  });

  const onConfirm = () => {
    updateLead();
  };

  return (
    <>
      <Dialog open={show}>
        <DialogTitle className={styles.dialogTitle}>{Translation('lead.updateLead.title')}</DialogTitle>
        <div className={styles.wrap}>
          <FollowUpStatusSelect
            statusOptions={statusOptions}
            onStatusChange={onStatusChange}
            onDateChange={onUpdateTimeChange}
            showDatePick={!isDead}
          />

          {isPolicy && <PolicyNumberInput policyNumber={policyNum} onPolicyNumberChange={onPolicyNumberInput} />}
          {isDead && <DeadReasonSelect reasonOptions={deadReasonOptions} onSelect={onSelectReason} />}

          <div className={classes.btnContainer}>
            {isPolicy && (
              <div className={classes.leftButtonBox}>
                <Button className={classes.cancelbtn} variant="outlined" color="inherit" onClick={addPolicyNumInput}>
                  {Translation('lead.updateLead.addPolicyNumber')}
                </Button>
              </div>
            )}

            <div className={styles.rightButtonBox}>
              <Button
                className={classes.cancelbtn}
                variant="outlined"
                color="inherit"
                onClick={() => {
                  onCancel();
                  resetStatus();
                }}
              >
                {Translation('app.button.cancel')}
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={onConfirm}
                className={classes.confirmBtn}
                disabled={!canSubmit}
              >
                {loading ? <CircularProgress size={15} color="inherit" /> : Translation('app.button.confirm')}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
});
