import React, { useContext, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { Lead, TabValues } from 'src/app/modules/Leads/types/types';
import { rejectFollowUp, rejectOpportunity } from 'src/app/modules/Leads/pages/leads/leads-list/util/api.util';
import { OperationContext } from '../../util/operation-context.util';
import { takeUIClickEvent } from 'src/app/common/ga/ga';

export interface RejectOpportunityButtonHookProps {
  item: Lead;
  tab?: TabValues;
  actions: (actions: ('clearRow' | 'refresh')[]) => void;
}

export const useRejectOpportunityButton = ({
  item,
  actions,
  tab = TabValues.opportunities,
}: RejectOpportunityButtonHookProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [toReject, setToReject] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const { gaData, onReject, event } = useContext(OperationContext);

  const isOpportunity = !tab || tab === TabValues.opportunities;

  const doReject = async (rejectReason: string[]) => {
    doRejectGA(rejectReason);
    const api = !isOpportunity ? rejectFollowUp : rejectOpportunity; // todo: currently is reject opportunity
    setRejectLoading(true);
    const res = await api(item._id, rejectReason, dispatch, t);

    res && actions(['refresh', 'clearRow']);
    event && event.emit();
    setRejectLoading(false);
  };

  const doRejectGA = (rejectReason: string[]) => {
    let ga = gaData?.reject;
    ga = typeof ga === 'function' ? ga(rejectReason) : ga;
    ga && takeUIClickEvent(ga);
    onReject?.([item]);
  };

  return {
    toReject,
    setToReject,
    rejectLoading,
    doReject,
    doRejectGA,
  };
};
