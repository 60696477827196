import React, { memo, useMemo } from 'react';

import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { useStyles } from './lead-text-display.style';

type ComponentProps = ParamsProps & {
  currValue: string;
};

export const LeadTextDisplayComponent: React.FC<ComponentProps> = memo(({ currValue }: ComponentProps) => {
  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return <div className={styles.content}>{currValue ?? ''}</div>;
});
