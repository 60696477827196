import React, { FC, useState, useMemo, useRef, useContext, useCallback } from 'react';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Box, Button, Tabs, Tab, CircularProgress } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { LayoutSplashScreen } from 'src/app/layout';
import { useStyles } from './prospect-detail.style';
import { ProspectDetailContext, useProspectDetail } from './prospect-detail.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { ProspectBasicInfo } from './component/prospect-basic-info/prospect-basic-info.component';
import { ProspectInformationPage } from './component/prospect-information/prospect-information.screen';
import {
  PROSPECT_ADD_LEAD_PATH,
  PROSPECT_BASE_PATH,
  PROSPECT_DETAIL_PATH,
  PROSPECT_DETAIL_TABS,
} from '../../constants';
import { get } from 'lodash';
import { AppointmentComponent } from '../appointment/appointment.component';
import { CommonLeadList } from 'src/app/modules/Leads/pages/leads/leads-list/components/common-lead-list/common-lead-list.component';
import { ParamsType, RequestData, ProColumns } from 'src/app/common/components/ProTable';
import { Lead } from 'src/app/modules/Leads/types/types';
import { getProspectLeads } from 'src/app/modules/Leads/pages/leads/leads-list/components/common-lead-list/util/api.util';
import { getProspectLeadsColumns } from 'src/app/modules/Leads/pages/leads/leads-list/components/common-lead-list/util/list.util';
import { OperationContext } from 'src/app/modules/Leads/components/lead-list-item/lead-operation-units/util/operation-context.util';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import {
  prospectDetailGaDataClick,
  prospectDetailsGaDataClick,
  prospectDetailsGaDataClick42,
  prospectDetailsGaDataClick46,
} from 'src/app/common/ga/types/ga-click';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

type ProspectInfoActionsProps = {
  onConfirm: () => void;
  onEdit: () => void;
  editMode: boolean;
};

const ProspectInfoActions = (props: ProspectInfoActionsProps) => {
  const { onConfirm, onEdit, editMode } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { prospectInfomationTabState } = useContext(ProspectDetailContext);
  const { classes } = useStyles();
  return (
    <div className={classes.flexRow}>
      {prospectInfomationTabState?.saving ? null : (
        <Button onClick={onEdit} variant="outlined" className={classes.buttonBorder}>
          {editMode ? Translation('app.button.cancel') : Translation('app.button.edit')}
        </Button>
      )}
      {editMode && (
        <Button onClick={onConfirm} variant="contained" className={classes.confirmButton}>
          {prospectInfomationTabState?.saving ? (
            <CircularProgress size={18} color="inherit" />
          ) : (
            Translation('app.button.confirm')
          )}
        </Button>
      )}
    </div>
  );
};

const AppointmentActions = ({ onClick }: { onClick: () => void }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  return (
    <Button onClick={onClick} variant="contained" className={classes.confirmButton}>
      {Translation('prospect.appoinment.add.appointment')}
    </Button>
  );
};

const LeadTabActions = ({ id }: { id: string }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const history = useHistory();
  return (
    <Button
      onClick={() => history.push(`${PROSPECT_BASE_PATH}${PROSPECT_DETAIL_PATH}${PROSPECT_ADD_LEAD_PATH}/${id}`)}
      variant="contained"
      className={classes.confirmButton}
    >
      {Translation('prospect.lead.addLead')}
    </Button>
  );
};

export const ProspectDetailPage: React.FC<PageProps> = (props: PageProps) => {
  const { history } = props;
  const prospectBasicInfo = get(history.location, 'state.prospectBasicInfo', null);
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  // informationTabRef.current && informationTabRef.current.submitForm();
  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    prospectDetail,
    prospectCategory,
    prospectInfoTabState,
    informationTabRef,
    appointmentTabRef,
    totalAppointmentsLabel,
    onClickEdit,
    onSubmitProspectInfo,
    onClickAddAppointment,
    updateProspectInfoTabState,
    updateProspectDetail,
    onAppointmentLoaded,
    onLoad,
    leadLabel,
    oppProviderValue,
  } = useProspectDetail({
    id: id,
  });

  const goBack = () => {
    history.goBack();
  };

  const [value, setValue] = React.useState(PROSPECT_DETAIL_TABS.LEAD);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const leadsColumns = useMemo(() => () => getProspectLeadsColumns(), []);

  const getListData = useCallback(
    (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
      dispatch?: React.Dispatch<any>,
    ) => getProspectLeads(id, params, sort, filter, dispatch),
    [id],
  );

  return (
    <ProspectDetailContext.Provider
      value={{
        prospectInfomationTabState: prospectInfoTabState,
        updateProspectInfoTabState: updateProspectInfoTabState,
        updateProspectDetail: updateProspectDetail,
      }}
    >
      <Page>
        <HeaderBreadCrumbs />
        <PageTitle
          // PACS Customization: PACSPFAP-3083 | Updated Label
          title={Translation('title.prospect')}
          rightNode={
            <Button variant="outlined" color="secondary" onClick={goBack}>
              {Translation('app.button.back')}
            </Button>
          }
        />
        {
          <div>
            <ProspectBasicInfo datail={prospectDetail || prospectBasicInfo} prospectCategory={prospectCategory} />
          </div>
        }
        <div className={styles.tabContainer}>
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { backgroundColor: '#E8192C' } }}
            sx={{
              '.Mui-selected': {
                color: '#E8192C !important',
              },
            }}
          >
            <Tab
              value={PROSPECT_DETAIL_TABS.LEAD}
              label={leadLabel}
              wrapped
              onClick={() => {
                takeUIClickEvent(prospectDetailsGaDataClick42 as any);
              }}
            />
            <Tab
              value={PROSPECT_DETAIL_TABS.APPOINTMENT}
              label={totalAppointmentsLabel}
              onClick={() => {
                takeUIClickEvent(prospectDetailsGaDataClick as any);
              }}
            />
            <Tab
              value={PROSPECT_DETAIL_TABS.INFORMATION}
              label={Translation('acm_info')}
              onClick={() => {
                takeUIClickEvent(prospectDetailsGaDataClick46 as any);
              }}
            />
          </Tabs>
          {/* PACS Customization: PACSPFAP-3083 | Phase-1 | Start */}
          {/* {value === PROSPECT_DETAIL_TABS.INFORMATION && (
            <ProspectInfoActions
              onConfirm={onSubmitProspectInfo}
              onEdit={onClickEdit}
              editMode={!prospectInfoTabState.prospectInfoReadOnly}
            />
          )}
          {value === PROSPECT_DETAIL_TABS.APPOINTMENT && <AppointmentActions onClick={onClickAddAppointment} />}
          {value === PROSPECT_DETAIL_TABS.LEAD && <LeadTabActions id={id} />} */}
          {/* PACS Customization: PACSPFAP-3083 | Phase-1 | End */}
        </div>
        <div>
          <OperationContext.Provider value={oppProviderValue}>
            <TabPanel value={value} index={PROSPECT_DETAIL_TABS.LEAD}>
              <CommonLeadList getData={getListData} getColumns={leadsColumns} onLoad={onLoad} />
            </TabPanel>
          </OperationContext.Provider>
          <TabPanel value={value} index={PROSPECT_DETAIL_TABS.APPOINTMENT}>
            <AppointmentComponent prospect={{ _id: id }} ref={appointmentTabRef} onLoaded={onAppointmentLoaded} />
          </TabPanel>
          <TabPanel value={value} index={PROSPECT_DETAIL_TABS.INFORMATION}>
            {prospectDetail && (
              <ProspectInformationPage
                ref={informationTabRef}
                {...props}
                prospectDetail={prospectDetail}
                readOnly={prospectInfoTabState.prospectInfoReadOnly}
              />
            )}
          </TabPanel>
        </div>
      </Page>
    </ProspectDetailContext.Provider>
  );
};
