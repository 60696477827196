import { Box, BoxProps, Typography } from '@mui/material';
import { ReactNode, memo, useMemo } from 'react';
import { useStyles } from './page.style';

export interface PageProps extends BoxProps {
  children: ReactNode;
  className?: string;
}

export const Page = memo(({ children, className, ...other }: PageProps) => {
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  return (
    <Box className={`${styles.pageContainer} ${className ? className : ''}`} {...other}>
      {children}
    </Box>
  );
});

export const PageTitle = memo(
  ({ title, rightNode, className }: { title: string; rightNode?: ReactNode; className?: string }) => {
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    return (
      <Box className={`${styles.titleContainer} ${className ?? ''}`}>
        <Box className={styles.leftContainer}>
          <Typography className={styles.titleText}>{title}</Typography>
        </Box>
        {rightNode ? <Box>{rightNode}</Box> : null}
      </Box>
    );
  },
);
