import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    // alignItems: 'center',
  },
  label: {
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 0,
  },
  labelText: {
    fontSize: 24,
    lineHeight: 36,
  },
  detailWrap: {
    marginLeft: 16,
    flex: 1,
    maxWidth: 220,
    flexDirection: 'column',
    display: 'flex',
  },
  detailBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  detailTitle: {
    color: '#333333',
    fontWeight: 600,
    lineHeight: '22px',
  },
  detailDesc: {
    color: '#666666',
    lineHeight: '22px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    maxHeight: '44px',
    whiteSpace: 'normal',
  },
}));
