import React, { useEffect, useState, useMemo, useRef, ReactNode, Ref, useImperativeHandle, forwardRef } from 'react';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Checkbox, IconButton, TableFooter, TablePagination, Tooltip } from '@mui/material';
import { useCommonStyles } from '../../styles/common-styles';
import PruTablePaginationActions from '../Table/PruTablePaginationActions';
import { ProColumns, ProOperationDef, ToolBarRender, HeaderTitle, RowSelection, CheckType } from '.';
import ToolBarContainer from './ToolBarContainer';
import ProTableCell from './ProTableCell';
import ProTableRow, { CHECK_CELL_WIDTH } from './ProTableRow';
import StickyTableCell from './StickyTableCell';
import PruTableLoading from '../Table/PruTableLoading';
import PruTableEmptyRow from '../Table/PruTableEmptyRow';
import { useIntl } from 'react-intl';
import { ProTableSelectedActionBar } from './components/pro-table-selected-action-bar/pro-table-selected-action-bar.component';

type ListProps<T> = {
  isLoading?: boolean;
  dataSource: T[];
  columns: ProColumns<T>[];
  operationDef?: ProOperationDef<T>[];
  operationSticky?: boolean;
  onRefresh: () => void;
  onChangePage: (params: { page?: number; pageSize?: number }) => void;
  page: number;
  pageSize: number;
  total: number;
  toolBarRender?: ToolBarRender;
  headerTitle?: HeaderTitle;
  rowKey: string;
  rowSelection?: RowSelection<T>;
  handleSort?: (sorter: { [key: string]: 'asc' | 'desc' }) => void;
  hidePagination?: boolean;
  enableRefresh?: boolean;
  formatText?: (options: { numSelected?: number; total: number }) => string;
  ActionNode?: React.ComponentType<{
    selectedRows: T[];
    onAction: (action: ('clearRow' | 'refresh')[]) => void;
  }>;
  showActionBar?: boolean;
  sorterDirection?: Record<string, 'asc' | 'desc'>;
  showSelect?: boolean;
  defaultSelectedRows?: T[];
  onClickRow?: (row: T) => void;
  checkType?: CheckType;
  formatTitle?: (title: string) => string | ReactNode;
  emptyText?: string;
  customEmptyTitle?: JSX.Element;
};

const useProTableStyles = makeStyles()((theme) => ({
  root: {
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    // overflowY: 'hidden',
    position: 'relative',
  },
  tableContainer: { width: '100%', position: 'relative' },
  table: {
    minWidth: 700,
    backgroundColor: theme.palette.common.white,
    // tableLayout: 'fixed',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tableHeader: {
    width: '100%',
    padding: '20px 0 20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  footer: {
    width: '100%',
  },
  operationContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  operationBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
  },
  disabled: {
    color: '#BBBBBB',
    cursor: 'default',
  },
  title: {
    flex: '1 1 100%',
  },
  tableBodyWraper: {
    position: 'relative',
  },
  backdrop: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.common.white} !important`,
    opacity: '0.5 !important',
  },
  checkbox: {
    margin: 16,
    padding: 0,
  },
  tableShadow: {
    boxShadow: '0px 2px 12px 0px rgba(16, 24, 40, 0.08)',
  },
}));

const defaultRowSelection = {
  // eslint-disable-next-line
  onChange: () => {},
  getCheckboxProps: () => ({ disabled: false }),
};

const ProTableList = <T extends { [key: string]: any } = { [key: string]: any }>(
  {
    isLoading,
    onRefresh,
    onChangePage,
    columns,
    operationDef,
    operationSticky,
    dataSource,
    total,
    page,
    pageSize,
    toolBarRender,
    headerTitle,
    rowKey,
    rowSelection = defaultRowSelection,
    handleSort,
    hidePagination,
    enableRefresh = true,
    formatText,
    ActionNode,
    showActionBar = true,
    sorterDirection,
    showSelect = true,
    defaultSelectedRows,
    onClickRow,
    checkType = CheckType.checkbox,
    formatTitle,
    emptyText,
    customEmptyTitle,
  }: ListProps<T>,
  ref: Ref<any>,
) => {
  const [selectedRow, setSelectedRow] = useState<T[]>(defaultSelectedRows ?? []);
  const { classes, cx } = useProTableStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });

  const { onChange: onRowSelectChange, getCheckboxProps } = rowSelection;

  const tableHeadRef = useRef<any>();
  const [tableHeadHeight, setTableHeadHeight] = useState(0);

  const selectableRowNumber = useMemo(() => {
    return dataSource.map((item) => getCheckboxProps(item, selectedRow).disabled).filter((i) => !i).length;
  }, [dataSource, getCheckboxProps, selectedRow]);

  const currPageSelected = useMemo(() => {
    return selectedRow.filter((r) => dataSource.find((row) => row[rowKey] === r[rowKey])).length;
  }, [dataSource, selectedRow, rowKey]);

  const handleChangePage = (event: any, newPage: number) => {
    onChangePage({ page: newPage });
  };

  const handleChangeRowsPerPage = (event: any) => {
    onChangePage({ page: 0, pageSize: parseInt(event.target.value) });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedRow = [...dataSource.filter((row) => !getCheckboxProps(row, selectedRow).disabled)];
    if (event.target.checked) {
      setSelectedRow((rows) =>
        rows.filter((r) => !newSelectedRow.find((row) => row[rowKey] === r[rowKey])).concat(...newSelectedRow),
      );
      return;
    } else {
      setSelectedRow((rows) => rows.filter((r) => !newSelectedRow.find((row) => row[rowKey] === r[rowKey])));
    }
  };

  const onSelected: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void = (event, checked) => {
    handleSelectAllClick(event);
  };

  const handleSelectSingleRow = (event: any, row: T) => {
    // click check box
    if (event.target.checked === true) {
      if (checkType === CheckType.checkbox) {
        const newSelectedRow = [...selectedRow, row];
        setSelectedRow(newSelectedRow);
      } else {
        setSelectedRow([row]);
      }
      return;
    } else if (event.target.checked === false) {
      if (checkType === CheckType.checkbox) {
        const index = selectedRow.findIndex((r) => r[rowKey] === row[rowKey]);
        if (index > -1) {
          const newSelectedRow = [...selectedRow];
          newSelectedRow.splice(index, 1);
          setSelectedRow(newSelectedRow);
        }
      } else {
        setSelectedRow([]);
      }
    } else {
      // click area other than check box
      onClickRow?.(row);
    }
  };

  useEffect(() => {
    onRowSelectChange([...selectedRow]);
    // eslint-disable-next-line
  }, [selectedRow]);

  const toolBar = toolBarRender?.();

  const onAction = (actions: ('clearRow' | 'refresh')[]) => {
    actions.forEach((a) => {
      if (a === 'clearRow') {
        setSelectedRow([]);
      } else if (a === 'refresh') {
        onRefresh();
      }
    });
  };

  useEffect(() => {
    if (!(showSelect && rowSelection)) return;
    if (tableHeadRef.current) {
      const element = tableHeadRef.current;
      const rect = element.getBoundingClientRect();
      setTableHeadHeight(rect.height);
    }
  }, []);

  const clearRow = () => {
    setSelectedRow([]);
  };

  useImperativeHandle(ref, () => ({
    clearRow,
    setSelectedRow,
  }));

  return (
    <div className={`${classes.root} table-body`}>
      {headerTitle || enableRefresh || (toolBar && toolBar.length > 0) ? (
        <ToolBarContainer>
          {headerTitle ? (
            <div
              className={cx(headerTitle === '' ? commonClasses.noBorderHeader : commonClasses.header, classes.title)}
            >
              {headerTitle}
            </div>
          ) : null}
          {enableRefresh ? (
            <Tooltip title="Refresh">
              <IconButton onClick={onRefresh}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          <div style={{ justifyContent: 'flex-end', marginLeft: 15 }} className={classes.rowContainer}>
            {toolBar && toolBar.length > 0
              ? toolBar.map((btn, index) => (
                  <span key={index} style={{ marginRight: 16, whiteSpace: 'nowrap' }}>
                    {btn}
                  </span>
                ))
              : null}
          </div>
        </ToolBarContainer>
      ) : null}
      <Paper className={classes.tableShadow}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead ref={tableHeadRef}>
              <TableRow>
                {showSelect && rowSelection ? (
                  <ProTableCell padding="none" align="right" width={`${CHECK_CELL_WIDTH}px`}>
                    {checkType === CheckType.checkbox ? (
                      <Checkbox
                        className={classes.checkbox}
                        disabled={selectableRowNumber === 0}
                        indeterminate={currPageSelected > 0 && currPageSelected < selectableRowNumber}
                        checked={selectableRowNumber > 0 && currPageSelected === selectableRowNumber}
                        onChange={handleSelectAllClick}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                      />
                    ) : null}
                  </ProTableCell>
                ) : null}
                {columns.map((col, index) => {
                  const width = index === columns.length - 1 ? undefined : col.width;
                  const title = formatTitle && typeof col.title === 'string' ? formatTitle(col.title) : col.title;
                  return col.sticky ? (
                    <StickyTableCell
                      key={`header-${col.dataIndex}-${index}`}
                      width={col.width}
                      className={`sticky-cell`}
                      sx={{ minWidth: col.width, width, maxWidth: width }}
                      align={col.align}
                    >
                      {title}
                    </StickyTableCell>
                  ) : (
                    <ProTableCell
                      key={`header-${col.dataIndex}-${index}`}
                      align={col.align}
                      sorter={col.sorter}
                      direction={sorterDirection?.[col.dataIndex]}
                      dataIndex={col.dataIndex}
                      handleSort={handleSort}
                      filterDropdown={col.filterDropdown}
                      width={width}
                      minWidth={col.width}
                      maxWidth={width}
                    >
                      {title}
                    </ProTableCell>
                  );
                })}
                {operationDef && operationDef.length > 0 && (
                  <>
                    {operationSticky ? (
                      <StickyTableCell key="header-operation" className={`sticky-cell`}>
                        {Translation('section.common.operation')}
                      </StickyTableCell>
                    ) : (
                      <ProTableCell key="header-operation">{Translation('section.common.operation')}</ProTableCell>
                    )}
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBodyWraper}>
              <PruTableLoading isLoading={!!isLoading} />
              <PruTableEmptyRow
                isEmpty={!!(dataSource && dataSource.length <= 0)}
                emptyText={isLoading ? undefined : emptyText}
                customEmptyTitle={customEmptyTitle}
              />
              {dataSource &&
                dataSource.map((row, index) => {
                  return (
                    <ProTableRow
                      className="pro-table-row"
                      rowKey={rowKey}
                      key={`row-${rowKey ? row[rowKey] : ''}-${index}`}
                      columns={columns}
                      operationDef={operationDef}
                      operationSticky={operationSticky}
                      rowSelection={showSelect ? rowSelection : undefined}
                      row={row}
                      selectedRow={selectedRow}
                      rowIndex={index}
                      handleSelectSingleRow={handleSelectSingleRow}
                      checkType={checkType}
                      rowClickable={!!onClickRow}
                      actions={onAction}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter component="div" className={`table-footer-css`}>
          <TableRow component="div">
            {hidePagination ? null : (
              <TablePagination
                align="left"
                rowsPerPageOptions={[5, 10, 20, 50]}
                component="div"
                colSpan={rowSelection ? columns.length + 1 : columns.length}
                count={total ? total : 0}
                rowsPerPage={pageSize}
                page={page}
                labelRowsPerPage={Translation('table.footer.rows_per_page')}
                slotProps={{
                  select: {
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={PruTablePaginationActions}
              />
            )}
          </TableRow>
        </TableFooter>
        {showActionBar ? (
          <ProTableSelectedActionBar
            numSelected={selectedRow.length}
            indeterminate={currPageSelected > 0 && currPageSelected < selectableRowNumber}
            checked={selectableRowNumber > 0 && currPageSelected === selectableRowNumber}
            onSelected={onSelected}
            total={total}
            formatText={formatText}
            actionNode={ActionNode ? <ActionNode selectedRows={selectedRow} onAction={onAction} /> : null}
            height={tableHeadHeight}
          />
        ) : null}
      </Paper>
    </div>
  );
};

export default forwardRef(ProTableList) as <T>(p: ListProps<T> & { ref?: Ref<any> }) => JSX.Element;
