import React, { FC, memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './universal-selector-dialog.style';
import PruDialog from '../PruDialog';
import { AddParticipantSelectOption } from './select-option/select-option.component';

export type DialogProps<T> = {
  open: boolean;
  onConfirm?: (options: Array<T>, index: number) => void;
  onClose?: () => void;
  description?: string;
  title?: string;
  value?: string;
  options: Array<T>;
};

export const UniversalSelectorDialogComponent = memo(
  <T extends { [key: string]: any } = {}>({
    title,
    open,
    onConfirm,
    onClose,
    description,
    options,
  }: DialogProps<T>) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    const [selected, setSelected] = useState(0);

    return (
      <PruDialog
        open={open}
        dialogTitle={title}
        confirmBtnText={Translation('app.button.confirm')}
        canncelBtnText={Translation('app.button.cancel')}
        onOk={() => {
          onConfirm?.(options, selected);
        }}
        onCancel={() => {
          onClose?.();
        }}
      >
        <Box className={styles.container}>
          {/* todo: padding issue */}
          <Typography className={styles.text}>{description}</Typography>
          <Box>
            {options.map((row, index) => {
              const { label } = row;
              return (
                <AddParticipantSelectOption
                  key={index}
                  selected={selected}
                  label={label}
                  index={index}
                  className={styles.mt_24}
                  onClick={(index) => {
                    if (selected !== index) {
                      setSelected(index);
                    }
                  }}
                />
              );
              // return <></>;
            })}
          </Box>
        </Box>
      </PruDialog>
    );
  },
);
