import React, { memo, useContext, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Lead } from 'src/app/modules/Leads/types/types';
import { ProTableOperationBtnComponent } from 'src/app/common/components/ProTable/components/pro-table-operation-btn/pro-table-operation-btn.component';
import { LeadStatusUpdateDialog } from '../../../lead-operation/components/lead-status-update-dialog/lead-status-update-dialog.component';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { OperationContext } from '../../util/operation-context.util';

type ComponentProps = {
  item: Lead;
  actions: (actions: ('clearRow' | 'refresh')[]) => void;
};

export const UpdateLeadButton: React.FC<ComponentProps> = memo(({ item, actions }: ComponentProps) => {
  // i18n
  const { t } = useTranslation();
  const [toUpdate, setToUpdate] = useState(false);
  const { onUpdateFoll, gaData } = useContext(OperationContext);
  const doUpdate = async () => {
    onUpdateFoll?.();
    setToUpdate(false);
    actions(['refresh']);
  };
  return (
    <>
      <ProTableOperationBtnComponent
        onClick={() => {
          setToUpdate(true);
          const ga = gaData?.update;
          ga && takeUIClickEvent(ga);
        }}
        label={t('app.button.listOperation.update')}
      />
      <LeadStatusUpdateDialog
        key={item.followUpStatus}
        lead={item}
        show={toUpdate}
        onUpdate={doUpdate}
        onCancel={() => setToUpdate(false)}
      />
    </>
  );
});
