import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useRequest } from 'ahooks';
import { useLang } from 'src/app/i18n';
import { formateProsectCategory } from '../../../../Prospect/util/common.util';
import { getLeadById } from '../../../network/leadsCrud';
import { FollowUpStatusEnum } from '../../../types/types';
import { fetchLeadAppointments } from '../../../../Prospect/network/appointmentCrud';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { leadDetailsGaDataClick } from 'src/app/common/ga/types/ga-click-lead';
import { CampaignTypeEnum } from 'src/app/modules/Campaign/types/campaign-types';
import { AgentStatusEnum } from 'src/app/modules/Leads/types/types';

interface HookProps {
  id: string;
}

type LeadInfomationTabState = {
  leadInfoReadOnly: boolean;
  saving: boolean;
};

type Context = {
  leadInfomationTabState: LeadInfomationTabState;
  updateLeadInfoTabState: (state: LeadInfomationTabState) => void;
  updateLeadDetail: (data: any) => void;
};

const initialContext: Context = {
  leadInfomationTabState: {
    leadInfoReadOnly: true,
    saving: false,
  },
  updateLeadInfoTabState: () => {},
  updateLeadDetail: () => {},
};

export const LeadDetailContext = React.createContext<Context>(initialContext);

export const useLeadDetail = ({ id }: HookProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const locale = useLang();
  const [totalAppointments, setTotalAppointments] = useState<number>(0);
  const [leadDetail, setLeadDetail] = useState<any>();
  const [leadInfoTabState, setLeadInfoTabState] = useState<LeadInfomationTabState>({
    leadInfoReadOnly: true,
    saving: false,
  });
  const informationTabRef = useRef<any>(null);
  const appointmentTabRef = useRef<any>(null);
  const { data: leadDetailResponse } = useRequest(() => getLeadById(id, dispatch), { manual: false });

  useEffect(() => {
    if (leadDetailResponse) {
      setLeadDetail(leadDetailResponse);
    }
  }, [leadDetailResponse]);

  //click edit button in lead information tab
  const onClickEdit = useCallback(() => {
    setLeadInfoTabState((state) => ({
      ...state,
      leadInfoReadOnly: !state.leadInfoReadOnly,
      saving: false,
    }));
    if (!leadInfoTabState.leadInfoReadOnly) {
      informationTabRef.current && informationTabRef.current.resetForm();
    }
    takeUIClickEvent(leadDetailsGaDataClick);
  }, [informationTabRef.current]);

  const canEdit = useMemo(() => {
    if (leadDetail?.agentStatus === AgentStatusEnum.pending) return false;
    if (!leadDetail?.lastFollowUpStatus) return true;
    if (
      [
        FollowUpStatusEnum.issued,
        FollowUpStatusEnum.dead,
        FollowUpStatusEnum.contactFailure,
        FollowUpStatusEnum.policy,
      ].includes(leadDetail?.lastFollowUpStatus)
    )
      return false;
    else {
      return true;
    }
  }, [leadDetail?.lastFollowUpStatus, leadDetail?.agentStatus]);

  const onSubmitLeadInfo = () => {
    if (leadInfoTabState.saving) return;
    informationTabRef.current && informationTabRef.current.submitForm();
  };

  const updateLeadInfoTabState = (state: LeadInfomationTabState) => {
    setLeadInfoTabState(state);
  };

  const updateLeadDetail = (data: any) => {
    setLeadDetail(data);
  };

  const onClickAddAppointment = () => {
    appointmentTabRef.current && appointmentTabRef.current.addAppointment();
  };

  const totalAppointmentsLabel = useMemo(() => {
    if (totalAppointments > 0) {
      return `${Translation('appointment')} ${totalAppointments > 99 ? '99+' : totalAppointments.toString()}`;
    }
    return Translation('appointment');
  }, [totalAppointments]);

  const onAppointmentLoaded = (total: number) => {
    setTotalAppointments(total);
  };

  useEffect(() => {
    fetchLeadAppointments(id, dispatch).then((res) => {
      if (res) {
        setTotalAppointments(res?.length ?? 0);
      }
    });
  }, []);

  const hideStatus = useMemo(() => {
    const isServicing = leadDetail?.campaign?.type === CampaignTypeEnum.servicing;
    const isPendingAccept = leadDetail?.agentStatus === AgentStatusEnum.pending;
    return isServicing || isPendingAccept;
  }, [leadDetail]);

  const leadCreatedAt = useMemo(() => {
    return leadDetail
      ? leadDetail.campaign &&
        (leadDetail.campaign.type == CampaignTypeEnum.normal || leadDetail.campaign.type == CampaignTypeEnum.mass)
        ? leadDetail.agentStatusUpdatedAt
        : leadDetail.createdAt
      : '';
  }, [leadDetail]);

  return {
    leadDetail,
    leadInfoTabState,
    informationTabRef,
    appointmentTabRef,
    onClickEdit,
    onSubmitLeadInfo,
    onClickAddAppointment,
    updateLeadInfoTabState,
    updateLeadDetail,
    totalAppointmentsLabel,
    onAppointmentLoaded,
    canEdit,
    hideStatus,
    leadCreatedAt,
  };
};
