import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PruFilter, {
  PruFilterDateRangeDef,
  PruFilterDropdownDef,
  PruFilterItemType,
} from '../../../../../common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import DashboardDetailPage from './components/DashboardDetailPage';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { ChartListParam, fetchChartList } from '../../../network/dashboardCrud';
import { DashboardItem, BDMdashboardName, DashboardType, BDMdashboardFilter, BDMdashboardGroupName } from '../../../types/dashboard-types';
import { useDataProvider, useJWT } from '../../../../../common/utils/hook-utils';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import { PermissionRole, TokenInfo } from 'src/app/modules/Auth/types/auth-types';
import { takeUIClickEvent } from '../../../../../common/ga/ga';
import { FILTERS_NOT_REQUIRED_FOR_FC } from '../../../constants';
import { decodeJWT } from 'src/app/modules/Auth/network/authCrud';
import { setTokenInfo } from 'src/app/modules/Auth/network/authSlice';

const ExportJsonExcel = require('js-export-excel');
type DashboardListingPageProps = {} & ParamsProps;
type PruFilterItemDef = PruFilterDateRangeDef | PruFilterDropdownDef;

// const useStyles = makeStyles((theme) => ({
//   iconButton: {
//     padding: 8,
//     marginTop: -8,
//   },
// }));

/* PACS Customization: Update to using jose library for pentest fix reasons, adapting some code to it */
const initState: ChartListParam = {
  chartName: 'Conversion Dashboard',
  chartType: 'Table',
  campaignId: '',
  consultantCode: '',
  campaignStatus: '',
  campaignType: '',
  campaignRecordType: '',
  leadSubSource: '',
  startDate: [],
  campaignStartDt: [],
  assignDate: [],
  assignedDate: [],
  type: '',
  leadSource: '',
  unitCode: '',
  businessManagerName: '',
  consultantName: '',
  region: '',
  channel: '',
};
/* PACS Customization: Update to using jose library for pentest fix reasons, adapting some code to it */

const paramsInitiator = (user: TokenInfo, initialParams?: Record<string, string>): ChartListParam => {
  return initialParams
    ? {
        chartName: 'Conversion Dashboard',
        //chartID: "62625264-8055-4590-8c78-8bf995f6bcd1",
        chartType: 'Table',
        campaignId: '',
        consultantCode: '',
        campaignStatus: '',
        campaignType: '',
        campaignRecordType: '',
        leadSubSource: '',
        startDate: [],
        campaignStartDt: [],
        assignDate: [],
        assignedDate: [],
        type: '',
        leadSource: '',
        unitCode: '',
        businessManagerName: '',
        consultantName: '',
        region: user.region,
        channel: user.channel,
      }
    : {
        ...initState,
        region: user.region,
        channel: user.channel
      }; // PACS Customization: Update to using jose library for pentest fix reasons
};

export const dashboardFilterKeys = ['bundleIdentifier', 'name', 'role', 'isPublic'];

const DashboardListingPage: FC<DashboardListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  // const classes = useStyles();
  /* PACS Customization: Update to using jose library for pentest fix reasons, adapting some code to it */
  const user = useJWT();
  const region = user.region;
  const channel = user.channel;
  const role = user.role;

  //PACS Customization: initial state for region and channel
  const initialState = {
    ...initState,
    region,
    channel
  };
  /* PACS Customization: Update to using jose library for pentest fix reasons, adapting some code to it */

  const [filterState, setFilterState] = useState<ChartListParam>(paramsInitiator(user, initialParams));
  const [filterList, setFilterList] = useState<PruFilterItemDef[]>([]);
  const [myDataList, setMyDataList] = useState<Record<string, string[]>>({
    leadSource: [],
    unitCode: [],
    businessManagerName: [],
    consultantName: [],
  });

  const [shouldRender, setShouldRender] = useState<any>(false);

  const resetFilters = () => {
    setFilterState({
      ...filterState,
      chartType: 'Table',
      campaignId: 'all',
      consultantCode: 'all',
      campaignStatus: 'all',
      campaignType: 'all',
      campaignRecordType: 'all',
      leadSubSource: 'all',
      type: 'all',
      leadSource: 'all',
      startDate: [],
      campaignStartDt: [],
      assignDate: [],
      assignedDate: [],
      unitCode: '',
      businessManagerName: 'all',
      consultantName: 'all',
    });
  };

  const [, setDashboardList] = useState<DashboardItem[]>();
  const { isLoading, refreshData } = useDataProvider<DashboardItem[]>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchChartList(filterState, dispatch);
      } catch (err) {}
    },
    setDashboardList,
    false,
  );

  const [excelData, setExcelData] = useState<{ chartName: string; promise: Promise<Object> }[]>([]);

  const exportExcel = async () => {
    takeUIClickEvent({
      journey: 'download_report',
      stage: 'download',
      object_name: 'Download',
      object_type: 'BUTTON',
    });
    excelData.forEach((chart) =>
      chart.promise.then((res) => {
        let sheetHeader: any[] = [];
        let obj = JSON.parse(JSON.stringify(res)).fields;

        for (let key in obj) {
          sheetHeader.push(obj[key]);
        }

        let sheetData: any = [];
        let response = JSON.parse(JSON.stringify(res));
        let documents = response.documents;
        if (documents && documents.length > 0) {
          if (filterState.chartType == 'Table') {
            documents.forEach((element: any) => {
              let documentObj: any = {};

              if (element.length != sheetHeader.length) {
                documentObj['group'] = element.group ? element['group'] : '';

                let sheetHeaderLength = element.value ? sheetHeader.length - 2 : sheetHeader.length - 1;
                let str = 'group_series_';
                for (let i = 0; i < sheetHeaderLength; i++) {
                  documentObj[str.concat(i.toString())] = element[str.concat(i.toString())]
                    ? element[str.concat(i.toString())]
                    : '';
                }

                documentObj['value'] = element['value'];
              }

              sheetData.push(element.length != sheetHeader.length ? documentObj : element);
            });
          }
          // over
          var option = {
            fileName: chart.chartName,
            datas: [
              {
                sheetData: sheetData,
                sheetName: 'sheet',
                sheetHeader: sheetHeader,
              },
            ],
          };
          var toExcel = new ExportJsonExcel(option);

          toExcel.saveExcel();
        }
      }),
    );
  }
  console.log(filterState);

  // const MyFilterTable = () => {

  //   const tmpFilter = JSON.parse(JSON.stringify(BDMdashboardFilter.get(filterState.chartName)));
  //   const tmpState = JSON.parse(JSON.stringify(myFilter));
  //   let map = new Map();

  //   for (const key in tmpFilter) {
  //     if (Object.prototype.hasOwnProperty.call(tmpFilter, key)) {
  //       map.set(tmpFilter[key], tmpState[key]);
  //     }
  //   }
  //   console.log(map);


  //   return (
  //     <Table aria-label="simple table" style={{ backgroundColor: 'white', marginBottom: 20, minHeight: 110 }} >
  //       <TableHead>
  //         <TableRow>
  //           {Array.from(map.keys()).length > 0 ? Array.from(map.keys()).map((key) => <TableCell align="left" >{key}</TableCell>) : <></>}
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         <TableRow>
  //           {Array.from(map.values()).length > 0 ? Array.from(map.values()).map((value: any) => (<TableCell align="left">{value ? value instanceof Array ? moment(value[0]).format("DD/MM/YYYY") + " - " + moment(value[1]).format("DD/MM/YYYY") : value : " "}</TableCell>)) : <TableCell></TableCell>}
  //         </TableRow>
  //       </TableBody>
  //     </Table >
  //   );
  // }

  useEffect(() => {
    const token = window.localStorage.getItem('jwt');

    if (token) {
      decodeJWT(token).then((jwt) => dispatch(setTokenInfo(jwt)));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filterMap = BDMdashboardFilter.get(filterState.chartName);
    let tmpList: PruFilterItemDef[] = [];
    for (const key in filterMap) {
      if (FILTERS_NOT_REQUIRED_FOR_FC.includes(key) && role !== PermissionRole.MANAGER) {
        continue;
      }
      let options =
        key === 'unitCode'
          ? [...map(myDataList[key], (option: string) => ({ displayName: option, value: option }))]
          : [
              { displayName: 'All', value: 'all' },
              ...map(myDataList[key], (option: string) => ({ displayName: option, value: option })),
            ];

      if (myDataList[key]) {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: filterMap[key],
          choices: options,
        });
      } else if (filterMap[key] === 'Region') {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: filterMap[key],
          choices: [{ displayName: region, value: region }],
        });
      } else if (filterMap[key] === 'Channel') {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: filterMap[key],
          choices: [{ displayName: channel, value: channel }],
        });
      }
    }
    setFilterList(tmpList);
  }, [filterState.chartName, myDataList]);

  return (
    <>
      <PruFilter
        title={Translation('dashboard.common.controlPanel')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'chartName',
            initialValue: filterState.chartName,
            defaultValue: filterState.chartName || initialState.chartName,
            displayName: Translation('dashboard.common.name'),
            choices: [...map(BDMdashboardGroupName, (option: string) => ({ displayName: option, value: option }))],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'chartType',
            initialValue: filterState.chartType,
            defaultValue: initialState.chartType,
            displayName: Translation('dashboard.common.type'),
            choices: [...map(DashboardType, (option: string) => ({ displayName: option, value: option }))],
          },
          ...map(filterList, (option: PruFilterItemDef) => option),
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'startDate',
            fieldTo: 'endDate',
            displayName: BDMdashboardFilter.get(filterState.chartName).assignedDate,
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'region',
            initialValue: filterState.region,
            defaultValue: initialState.region,
            displayName: 'Region',
            choices: [{ displayName: region, value: region }],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'channel',
            initialValue: filterState.channel,
            defaultValue: initialState.channel,
            displayName: 'Channel',
            choices: [{ displayName: channel, value: channel }],
          },
        ]}
        onChangeFilter={(data) => {
          return setFilterState({
            ...filterState,
            chartName: data.chartName,
            chartType: data.chartType,
            type: data.type,
            assignedDate: [!!data.startDate ? data.startDate : null, !!data.endDate ? data.endDate : null],
            leadSource: data.leadSource,
            campaignId: data.campaignId,
            consultantCode: data.consultantCode,
            campaignStatus: data.campaignStatus,
            campaignType: data.campaignType,
            campaignRecordType: data.campaignRecordType,
            unitCode: data.unitCode
              ? data.unitCode
              : myDataList['unitCode'] && myDataList['unitCode'][0]
              ? myDataList['unitCode'][0]
              : '',
            businessManagerName: data.businessManagerName,
            consultantName: data.consultantName,
            region: region,
            channel: channel,
          });
        }}
        fetchData={refreshData}
        resetFilters={resetFilters}
      />
      <DashboardDetailPage
        filter={filterState}
        onRefresh={true}
        refreshdata={refreshData}
        setExcelData={(data) => setExcelData(data)}
        getDataList={(list) => setMyDataList(list)}
        user={user}
      />
    </>
  );
};

export default DashboardListingPage;
