import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'flex',
    flex: 1,
    backgroundColor: '#E5F6FD',
    borderRadius: '4px',
    height: '48px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    width: '22px',
    height: '22px',
    color: '#0288D1',
  },
  text: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#014361',
    marginLeft: '12px',
    lineHeight: '24px',
  },
  textIcon: {
    display: 'flex',
  },
  acceptBtn: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '22px',
  },
}));
