import moment from 'moment';
import { configManager, APP_CONFIG } from 'src/app/common/utils';
import { DateFormat } from 'src/app/common/types';

export const getHeaderDate = () => {
  return moment(new Date()).format('MMM DD');
};

export const getDisplayDate = (date: Date, format?: string) => {
  return moment(date).format(format ? format : 'DD-MM-YYYY');
};

export const getDefaultDisplayDate: (date?: Date, formatKey?: keyof DateFormat) => string = (
  date,
  formatKey = 'date',
) => {
  if (date) {
    const dateFormat = configManager.getConfig(APP_CONFIG.GLOBAL_SYSTEM)?.region?.dateFormat;
    let defaultFormat = 'DD/MM/YYYY';
    if (dateFormat) {
      defaultFormat = dateFormat[formatKey] ? dateFormat[formatKey] : dateFormat['date'];
    }
    return moment(date).format(defaultFormat);
  } else {
    return '-';
  }
};

export const getDayStart = (date: Date) => {
  // set to 12:00 am
  return moment(date).startOf('day').toDate();
};

export const getDayEnd = (date: Date) => {
  return moment(date).endOf('day').toDate();
};

export const getWeekStart = (date: Date, isIsoWeek?: boolean) => {
  return moment(date)
    .startOf(isIsoWeek ? 'isoWeek' : 'week')
    .toDate();
};

export const getWeekEnd = (date: Date, isIsoWeek?: boolean) => {
  return moment(date)
    .endOf(isIsoWeek ? 'isoWeek' : 'week')
    .toDate();
};

export const getYearStart = (date: Date) => {
  return moment(date).startOf('year').toDate();
};

export const getYearEnd = (date: Date) => {
  return moment(date).endOf('year').toDate();
};

export const ignoreTimezone = (date: Date) => {
  return moment(date).format('YYYY-MM-DDThh:mm:ss.sss');
};

export const getDayStartForStrapi = (date: Date) => {
  //Strapi count day start to be 00:00:00
  const a = moment(date).startOf('day').hour(0).format('YYYY-MM-DDT00:00:00.000');
  return a;
};

export const getLocalDateFormat = (date: Date | string, format?: string) => {
  return moment(date)
    .local()
    .format(format || 'YYYY-MM-DD HH:mm');
};

export const syncDateToTime = (date: Date, time: Date) => {
  const dateValue = moment(date);
  const timeValue = moment(time);
  return dateValue
    .set({
      hour: timeValue.get('hour'),
      minute: timeValue.get('minute'),
    })
    .toDate();
};

// export const getUtcDateFormat = (
//   utcDate: string,
//   timezone: string = 'Asia/Hong_Kong', //'Asia/Singapore',
//   format: string = 'MM/DD/YYYY hh:mm:ss'
// ): string => {
//   const strDate = moment
//     .utc(utcDate)
//     .tz(timezone)
//     .format(format);
//   return strDate;
// };
