import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import React from 'react';
import { Lead } from 'src/app/modules/Leads/types/types';

type OperationContextType = {
  gaData: {
    accept?: any;
    reject?: any | ((val: any) => any);
    update?: any;
    newApp?: any;
  };
  onAccept?: (leads: Lead[]) => void;
  onReject?: (leads: Lead[]) => void;
  onUpdateFoll?: () => void;
  event?: EventEmitter<void>;
};

const initialContext: OperationContextType = {
  gaData: {},
};

export const OperationContext = React.createContext<OperationContextType>(initialContext);
