import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './lead-referred.style';
import { useLeadReferred } from './lead-referred.hook';
import { configManager } from 'src/app/common/utils/app-configs.util';
import { get } from 'lodash';
import { ReferredTypeEnum } from '../../types/types';
import { ReferredBy } from 'src/app/modules/Prospect/pages/components/referred-by/referred-by.component';
import { ClientReferredBy } from '../client-referred-by/client-referred-by.component';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  open: boolean;
  onConfirm?: (rows: Record<string, any>) => void;
  defaultSelectedRows?: any[];
  onClose?: () => void;
};

export const LeadReferredComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const referredSource = get(configManager.getConfig('sales-sdk'), 'Sales.lead.referredSource', '');

  return (
    <>
      {referredSource === ReferredTypeEnum.prospect ? (
        <ReferredBy open={props.open} onConfirm={props.onConfirm} onClose={props.onClose} />
      ) : (
        <ClientReferredBy open={props.open} onConfirm={props.onConfirm} onClose={props.onClose} />
      )}
    </>
  );
});
