export type TopDashboardType = 'Both' | 'Among' | 'Unique';

export enum DashboardType {
  CHART = 'Chart',
  TABLE = 'Table',
}

export enum LMdashboardName {
  PARTICIPANTS = 'List of Participants',
  CAMPAIGN = 'List of Campaign',
  LEADS = 'List of Leads',
}

export enum BDMdashboardGroupName {
  CONVERSION = 'Conversion Dashboard',
  LEADAGING = 'Lead Aging Dashboard',
  MANAGEMENT = 'Lead Management Dashboard',
  PERFORMANCE = 'Performance Dashboard',
}

// export enum BDMdashboardName {
//   LOSTREPORT = 'Appointment to Lost Report',
//   SUBMIISSIONREPORT = 'Appointment to Submission Report',
//   CUMULATIVE = 'Cumulative Leads Aging Report',
//   ENGAGEMENT = 'Leads Aging Report - Engagement',
//   PENDINGCALL = 'Leads Aging Report - Pending Call',
//   AGINGSUBMISSION = 'Leads Aging Report - Submission',
//   DROPPEDOFF = 'Dropped-Off Report',
//   LOSTREASON = 'Lead Lost Reason Report',
//   STAGE = 'Lead Stage Report',
//   STATUS = 'Lead Status Report by Agent',
//   SUBSOURCE = 'Leads Report by Sub Source',
//   ASSIGNEDTODAY = 'Leads Assigned Today by Source',
//   WEEKCREATE = 'Leads Create Per Week',
//   AVERAGE = 'Average Conversion Time Report',
//   SOURCE = 'Conversion Performance Report by Source',
//   AVERAGEAGENT = 'Average Conversion Days by Consultants',
//   TOPAGENT = 'Top Consultants by Lead Capture',
//   UNACCEPTED = 'Unaccepted Leads by Consultants',
//   CONVERSION = 'Conversion Dashboard',
//   LEADAGING = 'Lead Aging Dashboard',
//   MANAGEMENT = 'Lead Management Dashboard',
//   PERFORMANCE = 'Performance Dashboard',
//   AVERAGEDAYS = 'Average Conversion Days',
// }

export enum BDMdashboardName {
  LOSTREPORT = 'Appointment to Lost Report',
  SUBMIISSIONREPORT = 'Appointment to Submission Report',
  CUMULATIVE = 'Cumulative Leads Aging Report',
  ENGAGEMENT = 'Leads Aging Report - Engagement',
  PENDINGCALL = 'Leads Aging Report - Pending Call',
  AGINGSUBMISSION = 'Leads Aging Report - Submission',
  DROPPEDOFF = 'Dropped-Off Report',
  LOSTREASON = 'Lead Lost Reason Report',
  STAGE = 'Lead Stage Report',
  STATUS = 'Lead Status Report by Agent',
  SUBSOURCE = 'Leads Report by Sub Source',
  ASSIGNEDTODAY = 'Leads Assigned Today by Source',
  WEEKCREATE = 'Leads Create Per Week',
  AVERAGE = 'Average Conversion Time Report',
  SOURCE = 'Conversion Performance Report by Source',
  ASSIGNEDDAY = 'Conversion performance by Lead Assigned Date',
  WEEKCREATE2 = 'Leads Create Per Week - phase 2',
  UPDATEDDATE = 'Conversion Performance by Last Updated Date',
  ASSIGNEDCALL = 'Assigned to Call Report',
  AVERAGEAGENT = 'Average Conversion Days by Consultants',
  TOPAGENT = 'Top Consultants by Lead Capture',
  UNACCEPTED = 'Unaccepted Leads by Consultants',
  CONVERSION = 'Conversion Dashboard',
  LEADAGING = 'Lead Aging Dashboard',
  MANAGEMENT = 'Lead Management Dashboard',
  PERFORMANCE = 'Performance Dashboard',
  AVERAGEDAYS = 'Average Conversion Days',
}

export const ConversionList: Array<string> = [
  BDMdashboardName.AVERAGEDAYS,
  // BDMdashboardName.AVERAGE,
  BDMdashboardName.SOURCE,
  BDMdashboardName.ASSIGNEDDAY,
  BDMdashboardName.UPDATEDDATE,
  BDMdashboardName.AVERAGEAGENT,
];

export const LeadAgingList: Array<string> = [
  BDMdashboardName.PENDINGCALL,
  BDMdashboardName.ENGAGEMENT,
  BDMdashboardName.AGINGSUBMISSION,
  BDMdashboardName.CUMULATIVE,
];

export const ManagementList: Array<string> = [
  BDMdashboardName.SUBSOURCE,
  BDMdashboardName.STAGE,
  BDMdashboardName.DROPPEDOFF,
  BDMdashboardName.STATUS,
  BDMdashboardName.WEEKCREATE,
  BDMdashboardName.UNACCEPTED,
];

export const PerformanceList: Array<string> = [
  BDMdashboardName.LOSTREPORT,
  BDMdashboardName.SUBMIISSIONREPORT,
  BDMdashboardName.ASSIGNEDCALL,
  BDMdashboardName.TOPAGENT,
];

export const BDMdashboardGroupMap = new Map<BDMdashboardGroupName, Array<string>>([
  [BDMdashboardGroupName.CONVERSION, ConversionList],
  [BDMdashboardGroupName.LEADAGING, LeadAgingList],
  [BDMdashboardGroupName.MANAGEMENT, ManagementList],
  [BDMdashboardGroupName.PERFORMANCE, PerformanceList],
]);

enum ParticipantFilter {
  campaignStatus = 'Campaign Status',
  campaignId = 'Campaign ID',
  campaignStartDt = 'Campaign Start Date',
  campaignRecordType = 'Campaign Type',
}

enum CampaignFilter {
  campaignStatus = 'Campaign Status',
  startDate = 'Start Date',
  campaignRecordType = 'Campaign Type',
}
enum LeadsFilter {
  campaignRecordType = 'Campaign Type',
  campaignId = 'Campaign ID',
  assignDate = 'Create Date',
  leadSubSource = 'Sub-Source',
}

enum NormalFilter {
  type = 'Existing Customer',
  assignedDate = 'Assigned Date',
}

enum Phase2Filter {
  unitCode = 'Unit Code with Leads',
  consultantName = 'Consultant Name with Leads',
  assignedDate = 'Assigned Date',
  leadSource = 'Lead Source',
}

enum LostReportTop {
  type = 'Both',
  dashboardLeft = '62e80a26-41d9-4e11-8e7f-5b0ac5e73277',
  dashboardRight = '6267bd98-d717-4757-8bff-7d34608e2384',
}

enum SubmissionReportTop {
  type = 'Both',
  dashboardLeft = '1d9a81b7-6c42-4a5f-b2f2-ff42604f3c24',
  dashboardRight = '8f2af670-2ef7-454d-b3f6-4e927ef5e5b8',
}

enum SubsourceTop {
  type = 'Both',
  dashboardLeft = '6273854d-2c86-442a-8275-5b28174f1bbd',
}

enum AssignedTodayTop {
  type = 'Both',
  dashboardLeft = 'db45b716-bffb-4f64-8323-ed00315c9bb8',
}

enum WeekCreateTop {
  type = 'Both',
  dashboardLeft = '6274f2de-c2b3-41a1-83a1-70e4924fc069',
}

enum AverageTop {
  type = 'Both',
  dashboardLeft = '6274ea1f-f164-469e-8d95-81f43ee918de',
}

enum StatusTop {
  type = 'Among',
  dashboardLeft = '62739ce3-94a3-44e5-84e0-071509249dba',
  dashboardRight = '62739a5d-c2b3-4483-84b0-70e492764a26',
}

enum PerformanceTop {
  type = 'Among',
  dashboardLeft = '6273900f-2c86-4e2a-8779-5b2817527640',
  dashboardRight = '3d51dd35-dfe9-421e-91f6-b27e73ab343a',
}

enum LostReasonTop {
  type = 'Unique',
  dashboardLeft = '62733279-8d58-42c8-893c-0a94cc74661f',
}

export const LMdashboardFilter: Map<string, any> = new Map<LMdashboardName, any>([
  [LMdashboardName.PARTICIPANTS, ParticipantFilter],
  [LMdashboardName.CAMPAIGN, CampaignFilter],
  [LMdashboardName.LEADS, LeadsFilter],
]);

export const BDMdashboardFilter: Map<string, any> = new Map<BDMdashboardName, any>([
  [BDMdashboardName.LOSTREPORT, NormalFilter],
  [BDMdashboardName.SUBMIISSIONREPORT, NormalFilter],
  [BDMdashboardName.CUMULATIVE, NormalFilter],
  [BDMdashboardName.ENGAGEMENT, NormalFilter],
  [BDMdashboardName.PENDINGCALL, NormalFilter],
  [BDMdashboardName.AGINGSUBMISSION, NormalFilter],
  [BDMdashboardName.DROPPEDOFF, NormalFilter],
  [BDMdashboardName.LOSTREASON, NormalFilter],
  [BDMdashboardName.STAGE, NormalFilter],
  [BDMdashboardName.STATUS, NormalFilter],
  [BDMdashboardName.SUBSOURCE, NormalFilter],
  [BDMdashboardName.ASSIGNEDTODAY, NormalFilter],
  [BDMdashboardName.WEEKCREATE, NormalFilter],
  [BDMdashboardName.AVERAGE, NormalFilter],
  [BDMdashboardName.SOURCE, NormalFilter],
  [BDMdashboardName.AVERAGEAGENT, Phase2Filter],
  [BDMdashboardName.TOPAGENT, Phase2Filter],
  [BDMdashboardName.UNACCEPTED, Phase2Filter],
  [BDMdashboardName.CONVERSION, Phase2Filter],
  [BDMdashboardName.LEADAGING, Phase2Filter],
  [BDMdashboardName.MANAGEMENT, Phase2Filter],
  [BDMdashboardName.PERFORMANCE, Phase2Filter],
  [BDMdashboardName.AVERAGEDAYS, Phase2Filter],
]);

export const TopDashboards: Map<string, any> = new Map<BDMdashboardName, TopDashboardDetail>([
  [BDMdashboardName.LOSTREPORT, LostReportTop],
  [BDMdashboardName.SUBMIISSIONREPORT, SubmissionReportTop],
  [BDMdashboardName.SUBSOURCE, SubsourceTop],
  [BDMdashboardName.ASSIGNEDTODAY, AssignedTodayTop],
  [BDMdashboardName.WEEKCREATE, WeekCreateTop],
  [BDMdashboardName.AVERAGE, AverageTop],
  [BDMdashboardName.STATUS, StatusTop],
  [BDMdashboardName.SOURCE, PerformanceTop],
  [BDMdashboardName.LOSTREASON, LostReasonTop],
]);

export type DashboardItem = {
  chartName: string;
  chartID: string;
  chartType: string;
  filter: string;
  value: string;
  createdAt: Date;
  updatedAt: Date;
};

export type PlatformDashboard = {
  fileId: string;
  fileName: string;
  fileSize: number;
};

export type DashboardDetail = {
  id: string;
  name: string;
  ios: PlatformDashboard;
  android: PlatformDashboard;
  createdAt: Date;
  updatedAt: Date;
};

export type TopDashboardDetail = {
  type: TopDashboardType;
  dashboardLeft: string;
  dashboardRight?: string;
};
