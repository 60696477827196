import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './import-existing-customers.style';
import { useImportExistingCustomers } from './import-existing-customers.hook';
import { ProspectCategoryPicker } from '../prospect-category-picker/prospect-category-picker.component';
import { RouteComponentProps } from 'react-router-dom';
import { PROSPECT_BASE_PATH } from 'src/app/modules/Prospect/constants';
import ProFilterTable, { ActionNodeType, RowSelection } from 'src/app/common/components/ProTable';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components/pro-search-filter/pro-search-filter.component';
import { ErrorStatusDialog } from './component/error-status-dialog/error-status-dialog.component';

type ComponentProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

type ActionBarProps = {
  onBack: () => void;
  onConfirm: () => void;
  disableConfirm?: boolean;
  loading?: boolean;
};

const ActionBar = ({ onBack, onConfirm, disableConfirm, loading }: ActionBarProps) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const Translation = (id: string) => intl.formatMessage({ id });
  return (
    <div>
      <Button variant="outlined" className={classes.backBt} onClick={onBack}>
        {Translation('app.button.back')}
      </Button>
      <Button variant="contained" color="secondary" onClick={onConfirm} disabled={disableConfirm}>
        {loading ? <CircularProgress size={18} color="inherit" /> : Translation('app.button.confirm')}
      </Button>
    </div>
  );
};

export const ImportExistingCustomersPage: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { history } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const {
    loading,
    tableColumns,
    rowSelection,
    errorType,
    failedCustomers,
    showErrorDialog,
    disableConfirmButton,
    getData,
    submit,
    onSelectCategory,
    onDialogCancelClick,
    onDialogConfirmClick,
    onFilterChange,
  } = useImportExistingCustomers({});

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={Translation('prospect.import_from_customers')}
        rightNode={
          <ActionBar
            onBack={() => {
              history.push(PROSPECT_BASE_PATH);
            }}
            onConfirm={() => {
              submit();
            }}
            disableConfirm={disableConfirmButton}
            loading={loading}
          />
        }
      />
      <div className={`${styles.sectionBox} ${styles.titleBox}`}>
        <div className={styles.sectionTitle}>
          <span>{Translation('prospect.category')}</span>
        </div>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: -1 }}>
          <ProspectCategoryPicker onChanage={onSelectCategory} />
        </Grid>
      </div>
      <div className={`${styles.sectionBox} ${styles.tableBox}`}>
        <ProFilterTable
          Filter={ProSearchFilter}
          filterPlaceholder={Translation('prospect.customter.search.placeholder')}
          showFilterIcon={false}
          showFilter={true}
          filterSx={{ marginLeft: '24px', paddingTop: '6px' }}
          rowKey="clientCode"
          rowSelection={rowSelection}
          columns={tableColumns}
          request={getData}
          enableRefresh={false}
          onFilterChange={onFilterChange}
        />
      </div>
      <ErrorStatusDialog
        errorType={errorType}
        open={showErrorDialog}
        onCancel={onDialogCancelClick}
        onConfirm={onDialogConfirmClick}
        customer={failedCustomers}
      />
    </Page>
  );
});
