import { CSSProperties } from 'react';
import { TableCell } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const StickyTableCell = withStyles(
  TableCell,
  (theme) =>
    ({
      head: {
        // backgroundColor: theme.palette.common.black,
        // color: theme.palette.common.white,
        backgroundColor: '#F5F5F5',
        color: '#666',
        right: 0,
        position: 'sticky',
        zIndex: 10,
        // textAlign: 'center',
        // for the shadow
        '&::after': {
          position: 'absolute',
          top: 0,
          bottom: -1,
          left: 0,
          width: 30,
          transform: 'translateX(-100%)',
          transition: 'box-shadow 0.3s',
          content: '""',
          pointerEvents: 'none',
          boxShadow: 'inset -10px 0 8px -8px rgba(5, 5, 5, 0.06)',
        },
      },
      body: {
        backgroundColor: theme.palette.common.white,
        right: 0,
        position: 'sticky',
        zIndex: 11,
        // textAlign: 'center',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        backgroundClip: 'padding-box',
        // transform: 'scale(0.999)', // remove the style for bottom line
        // for the shadow
        '&::after': {
          position: 'absolute',
          top: 0,
          bottom: -1,
          left: 0,
          width: 30,
          transform: 'translateX(-100%)',
          transition: 'box-shadow 0.3s',
          content: '""',
          pointerEvents: 'none',
          boxShadow: 'inset -10px 0 8px -8px rgba(5, 5, 5, 0.06)',
        },
      },
    }) as Record<string, CSSProperties>,
);

export default StickyTableCell;
