export const isAlphabet = (stringToCheck: string): boolean => {
  return Boolean(stringToCheck.match(/^([a-z]|[A-Z])+$/g));
};

export function reconstuctNameByLastName(firstName?: string, lastName?: string) {
  if (lastName) {
    const firstChar = lastName.charAt(0);
    if (firstChar.match(/[a-zA-Z]/)) {
      return `${firstName ?? ''} ${lastName}`;
    } else if (firstChar.match(/[\u4e00-\u9fa5]/)) {
      return `${lastName} ${firstName ?? ''}`;
    } else {
      return `${lastName} ${firstName ?? ''}`;
    }
  } else {
    return firstName ?? '';
  }
}
