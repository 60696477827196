import React, { memo, useMemo } from 'react';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { useStyles } from './lead-remark.style';
import { FollowUpStatusEnum } from 'src/app/modules/Campaign/types/campaign-types';
import { Lead } from '../../../types/types';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { getLeadDeadReasonToDisplay } from './util/remark.util';

type ComponentProps = ParamsProps & {
  item: Lead;
  label?: boolean; // PACS Customization: PACSPFAP-3089
};

export const LeadRemarkComponent: React.FC<ComponentProps> = memo(({ item, label = true }: ComponentProps) => {
  const { t } = useTranslation();

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const { reason, reasons, issuedPolicy } = item.followUp[item.followUp.length - 1] ?? {
    reason: '',
    reasons: [],
    issuedPolicy: [],
  };

  return (
    <div className={styles.content}>
      {item.followUpStatus === FollowUpStatusEnum.dead ? (
        <div>
          {/* PACS Customization - PACSPFAP-3089 */}
          <div>{label ? t('lead_remark_deadreason') : ''}</div>
          {getLeadDeadReasonToDisplay(t, reasons, reason).map((r, index) => (
            <div key={index}>{r}</div>
          ))}
        </div>
      ) : issuedPolicy?.length ? (
        <div>
          {issuedPolicy?.map((v, idx) => {
            return (
              <div key={idx}>
                {t('salesLdPolicyNumber')}:{v.policyNumber}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
});
