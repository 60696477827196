import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Button, Divider, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { Card } from 'src/app/common/components/card/card.component';
import { useHistory } from 'react-router-dom';
import { AI_TALKBOT_BASE_PATH, AI_TALKBOT_EXPORT_LEADS_PATH, LIST_PATH } from 'src/app/modules/ai-talkbot/constants';
import { Info } from '@mui/icons-material';
import { ExportLeadOverviewType } from 'src/app/modules/ai-talkbot/types/talkbot-export-leads-types';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  data?: ExportLeadOverviewType;
};

export const OverviewComponent: React.FC<ComponentProps> = memo(({ data }: ComponentProps) => {
  // i18n
  const { t } = useTranslation();
  const history = useHistory();

  const overviewItems = useMemo(() => {
    const { pendingCount, contactCount, dropCount } = data ?? {};
    return [
      { label: t('talkbot.export_leads.pending_accept'), value: pendingCount },
      { label: t('talkbot.export_leads.pending_contact'), value: contactCount },
      { label: t('talkbot.export_leads.pending_dropped'), value: dropCount },
    ];
  }, [data]);

  return (
    <Card
      sx={{ marginTop: 3, padding: 3, cursor: 'pointer' }}
      onClick={() => {
        history.push(`${AI_TALKBOT_BASE_PATH}${AI_TALKBOT_EXPORT_LEADS_PATH}${LIST_PATH}`);
      }}
    >
      <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <Typography sx={{ fontSize: 24, fontWeight: '600', marginRight: 1 }}>
          {t('talkbot.export_leads.overview.tips')}
        </Typography>
        <Tooltip arrow title={t('talkbot.export_leads.tooltip')}>
          <Info sx={{ color: '#CCCCCC' }} />
        </Tooltip>
      </Stack>

      <Stack
        sx={{ mt: 3 }}
        onClick={() => null}
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={5}
      >
        {overviewItems.map(({ label, value }) => {
          return (
            <Stack key={label}>
              <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#999' }}>{label}</Typography>
              <Typography sx={{ fontSize: 30, fontWeight: 700 }}>{value}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
});
