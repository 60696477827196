import React, { FC, memo, useMemo, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
} from '@mui/material';
import { Button } from 'src/app/common/components/button/button.component';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './campaign-participant.style';
import { useCampaignParticipant } from './campaign-participant.hook';
import {
  AgentItem,
  CampaignDetailPageStep,
  CampaignStructureItem,
} from 'src/app/modules/AgencyCampaign/types/campaign-types';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { Add, CloseOutlined } from '@mui/icons-material';
import AddParticipantMenu from '../../../Details/application/components/AddParticipantMenu';
import ImportParticipantPopup from '../../../Details/application/components/ImportParticipantPopup';
import moment from 'moment';
import TnCItem from '../../../Details/application/components/TnCItem';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { ParticipantList } from './components/participant-list/participant-list.component';
import { UniversalSelectorDialogComponent } from 'src/app/common/components/universal-selector-dialog/universal-selector-dialog.component';
import { ParticipantAddListComponent } from '../participant-add-list/participant-add-list.component';
import { ParticipantAddFileComponent } from '../participant-add-file/participant-add-file.component';
import { SectionMask } from 'src/app/common/components/section-mask/section-mask.component';
import { globalStore } from 'src/app/common/helpers/global-store.util';

export interface CampaignParticipantComponentProps {
  campaignTypeStructureData: CampaignStructureItem;
  participantRange: any;
  formDispatch: (data: any) => void;
  formState: any;
  sectionKey: string;
  setParticipantSelected: React.Dispatch<React.SetStateAction<any[]>>;
  disabledEdit?: boolean;
  disabled?: boolean;
  errorState: any;
  campaignObjId?: string;
  participantSelected: any[];
  validationHandler: any;
  startDate?: any;
  disableBulkSelect: boolean;
  setDisableBulkSelect: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveSuccessDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sendInvitationToParticipant: (agentList?: string[]) => void;
  campaignOwner: string;
  isRequiredRsvp: boolean;
  // onStepChange: () => void;
}

export const CampaignParticipantComponent: React.FC<CampaignParticipantComponentProps> = memo(
  (props: CampaignParticipantComponentProps) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    // custom-hook
    const {
      tNcField,
      snackBar,
      participantList,
      filterState,
      openImportFromFile,
      openSelectFromList,
      removeOption,
      setDoneImport,
      setFilterState,
      toggleRemoveDialog,
      doRemove,
      setSnackBar,
      closeSnackBar,
      setOpenSelectFromList,
      setOpenImportFromFile,
      setRemoveOption,
      renderNumberLimit,
      updateSortingKey,
      onRefresh,
      tableRef,
    } = useCampaignParticipant(props);
    const {
      campaignTypeStructureData,
      campaignOwner,
      participantSelected,
      isRequiredRsvp,
      sectionKey,
      errorState,
      validationHandler,
      formState,
      disabled,
      disabledEdit,
      campaignObjId,
      formDispatch,
      setDisableBulkSelect,
      disableBulkSelect,
      setParticipantSelected,
      // onStepChange,
    } = props;

    const [toggleDialog, setToggleDialog] = useState(false);
    const [toggleFullScreenDialog, setToggleFullScreenDialog] = useState(false);
    const selectIndex = useRef<number>(0);

    const addParticipantOptions = [
      { label: Translation('agencyCampaign.addParticipant.fromList'), value: '0' },
      { label: Translation('agencyCampaign.addParticipant.fromFile'), value: '1' },
    ];

    return (
      <SectionMask showMask={!campaignObjId} text={Translation('agencyCampaign.detail.screen.mask')}>
        <Paper
          sx={{ marginTop: '24px', marginBottom: '24px', paddingTop: '24px', minHeight: '300px' }}
          // onClick={onStepChange}
        >
          <div className={classes.addBtnWrap}>
            <div className={classes.sectionTitle}>{Translation('agencyCampaign.create.editParticipant')}</div>
            <div className={classes.btnContainer}>
              {!disabledEdit && !disabled && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setToggleDialog(true);
                  }}
                  startIcon={<Add />}
                >
                  {Translation('app.button.add')}
                </Button>
              )}
            </div>
          </div>
          <div className={classes.pl_24}>{renderNumberLimit()}</div>
          {tNcField ? (
            <div className={classes.tNcContainer}>
              <div className={classes.tNcContent}>
                <Checkbox
                  classes={{
                    root: classes.checkboxItem,
                    checked: classes.checked,
                  }}
                  disabled={disabledEdit || disabled}
                  onClick={(e: any) => {
                    validationHandler.onDismissErrorHandler(`${sectionKey}_tnc`, e.target.checked);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { section: sectionKey, field: 'tnc', value: e.target.checked },
                    });
                  }}
                  checked={formState[sectionKey] && formState[sectionKey]['tnc'] ? formState[sectionKey]['tnc'] : false}
                />
                <div className={classes.tNcItem}>
                  {`${Translation('agencyCampaign.participant.agreeTo')} `}
                  {tNcField.value &&
                    (tNcField.value as any[]).map((item, index) => {
                      return <TnCItem tNcItem={item} index={index} key={index} />;
                    })}
                </div>
              </div>

              {errorState.mandatory[`${sectionKey}_tnc`] && (
                <div className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</div>
              )}
            </div>
          ) : null}
          <div className={classes.snackBarContainer}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snackBar.visible}
              autoHideDuration={5000}
              onClose={closeSnackBar}
              message={snackBar.msg}
            />
            {campaignObjId ? (
              <ParticipantList
                tableRef={tableRef}
                campaignOwner={campaignOwner}
                setRemoveOption={setRemoveOption}
                campaignObjId={campaignObjId}
                isRequiredRsvp={isRequiredRsvp}
                onSelectedRowChange={(data) => {
                  setParticipantSelected((prev) => {
                    // un-select all
                    if (data.length === 0 && prev.length > 0) return [];
                    // select all
                    if (data.length === 0 && prev.length === 0)
                      return participantList?.docs
                        ? participantList.docs.filter((item) => item.agentCode !== campaignOwner)
                        : [];
                    // select or un-select one
                    return data;
                  });
                }}
              />
            ) : null}
          </div>

          {/* remove participant dialog start */}
          <Dialog
            open={removeOption.open}
            onClose={toggleRemoveDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{Translation('agencyCampaign.create.removeTitle')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {Translation('agencyCampaign.create.removeText')}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.btnRemoveWrap}>
              <Button onClick={toggleRemoveDialog} variant="outlined" color="primary">
                {Translation('app.button.cancel')}
              </Button>
              <Button onClick={doRemove} variant="contained" color="primary" autoFocus>
                {Translation('app.button.confirm')}
              </Button>
            </DialogActions>
          </Dialog>
          {/* remove participant dialog end */}
          <UniversalSelectorDialogComponent
            title={Translation('agencyCampaign.addParticipant.title')}
            open={toggleDialog}
            options={addParticipantOptions}
            onClose={() => {
              selectIndex.current = 0;
              setToggleDialog(false);
            }}
            onConfirm={(row, index) => {
              selectIndex.current = index;
              setToggleDialog(false);
              setToggleFullScreenDialog(true);
            }}
          />
          {toggleFullScreenDialog ? (
            <>
              {selectIndex.current === 0 ? (
                <ParticipantAddListComponent
                  campaignObjId={campaignObjId}
                  campaignTypeStructureData={campaignTypeStructureData}
                  fullScreenDialogTitle={addParticipantOptions[selectIndex.current].label}
                  fullScreenDialogOpen={toggleFullScreenDialog}
                  onClose={() => {
                    setToggleFullScreenDialog(false);
                    selectIndex.current = 0;
                  }}
                  doneImport={(data) => {
                    setDoneImport(data);
                    setToggleFullScreenDialog(false);
                  }}
                />
              ) : (
                <ParticipantAddFileComponent
                  fullScreenDialogTitle={addParticipantOptions[selectIndex.current].label}
                  fullScreenDialogOpen={toggleFullScreenDialog}
                  campaignObjId={campaignObjId!}
                  campaignTypeStructureData={campaignTypeStructureData}
                  doneImport={(data) => {
                    setDoneImport(data);
                    setToggleFullScreenDialog(false);
                  }}
                  onClose={() => {
                    setToggleFullScreenDialog(false);
                    selectIndex.current = 0;
                  }}
                />
              )}
            </>
          ) : null}
        </Paper>
      </SectionMask>
    );
  },
);
