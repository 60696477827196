import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  radio: {
    marginRight: '16px',
  },
  radioBox: {
    display: 'flex',
    alignItems: 'center',
  },
}));
