import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  content: {
    display: 'inline-block',
    color: '#E8192C',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
}));
