import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  StepConnector,
  styled,
  stepConnectorClasses,
  StepIconProps,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  Typography,
} from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { LayoutSplashScreen } from 'src/app/layout';
import { useStyles } from './reassign-lead.style';
import { useReassignLead } from './reassign-lead.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { Check } from '@mui/icons-material';
import ProFilterTable from 'src/app/common/components/ProTable';
import { AgingLeadTable } from './component/aging-lead-table/aging-lead-table.component';
import { AgentReassignToTable } from './component/agent-reassign-to-table/agent-reassign-to-table.component';
// import { styled } from '@mui/material/styles';
// import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#CCCCCC',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#CCCCCC',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#CCCCCC',
    borderTopWidth: '1px',
    // borderRadius: 1,
    borderStyle: 'dashed',
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}

export const ReassignLeadPage: React.FC<PageProps> = (props: PageProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes, cx } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    steps,
    currentStep,
    selectedUnit,
    disableNext,
    handleNext,
    onSelectedLeads,
    onSelectedUnit,
    onSelectedAgent,
    onUpdatedLead,
    onCommentInput,
    setCurrentStep,
  } = useReassignLead();

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={Translation('title.reassignLead')}
        rightNode={
          <div>
            {currentStep == 2 && (
              <Button
                variant="outlined"
                onClick={() => {
                  setCurrentStep((pre) => pre - 1);
                }}
                className={styles.backBt}
              >
                {Translation('app.button.back')}
              </Button>
            )}
            <Button disabled={disableNext} variant="contained" color="secondary" onClick={handleNext}>
              {Translation('app.select.continue')}
            </Button>
          </div>
        }
      />
      <div className={styles.stepper}>
        <Stepper activeStep={currentStep} alternativeLabel connector={<QontoConnector />}>
          {steps.map((label: string, index: number) => {
            const className = cx({
              [styles.stepIcon]: true,
              [styles.activeStepIcon]: index + 1 === currentStep,
            });
            return (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    icon: <div className={className}>{index + 1}</div>,
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <div hidden={currentStep !== 1}>
        <AgingLeadTable onSelectedLead={onSelectedLeads} onSelectUnit={onSelectedUnit} onUpdatedLead={onUpdatedLead} />
      </div>
      <div hidden={currentStep !== 2}>
        {selectedUnit && <AgentReassignToTable groupCode={selectedUnit} onSelectedAgent={onSelectedAgent} />}
        <Typography className={styles.commentTtitle}>{Translation('lead.reassignLead.optionalComment')}</Typography>
        <div className={styles.textArea}>
          <TextareaAutosize
            onChange={(e) => {
              onCommentInput(e.target.value);
            }}
            placeholder="comment"
            style={{ width: '100%', height: '100%', resize: 'none' }}
          />
        </div>
      </div>
    </Page>
  );
};
