import axios from 'axios';

const OEM_APIM_SUBSCRIPTION_KEY = window.envConfig['REACT_APP_OEM_APIM_SUBSCRIPTION_KEY'] || '';

const isDev = window.envConfig['REACT_APP_ENV'] === 'dev';
const apiClient = axios.create({
  timeout: 60000,
  // local dev must use: withCredentials: false, otherwise api call will have cors error
  withCredentials: isDev ? false : true,
});

//  Pre-Config before request
apiClient.interceptors.request.use(
  (config) => {
    // const { url } = config;
    // config.url =
    //   url && url?.indexOf('?') > 0
    //     ? `${url}&subscription-key=${OEM_APIM_SUBSCRIPTION_KEY}`
    //     : `${url}?subscription-key=${OEM_APIM_SUBSCRIPTION_KEY}`;
    if (!config.headers.Authorization) {
      // set default Authorization only when it's null
      config.headers.Authorization = `Bearer ${window.localStorage.getItem('jwt')}`;
    }

    config.headers['Ocp-Apim-Subscription-Key'] = `${OEM_APIM_SUBSCRIPTION_KEY}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

//  Handle response from every api call
apiClient.interceptors.response.use(
  (data) => {
    return data;
  },
  (error) => {
    if (!error.response) {
      throw error;
    }
    if (error.response.data) {
      throw error.response.data;
    } else {
      throw error.response;
    }
  },
);

export default apiClient;
