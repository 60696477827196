import { Dispatch } from 'react';
import apiClient from '../../../common/network/axios';
import { AGENT_URL, apiErrorHandler } from 'src/app/common/network';


const queryPath = `${AGENT_URL}/agents/powerbi`;

type ReportTokenProps = {
  expiration: string;
  token: string;
  tokenId: string;
}

export const getReportList = async (
  dispatch?: Dispatch<any>,
): Promise<any[]> => {
  const path = `${queryPath}/reports`;
  return apiClient
    .get(path)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getReportToken = async (reportId: string,
  datasetId: string,
  dispatch?: Dispatch<any>,
): Promise<ReportTokenProps> => {
  const path = `${queryPath}/reportToken/${reportId}/${datasetId}`;
  return apiClient
    .post(path)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
