import { FC, useRef } from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { GAData } from 'src/app/common/ga/redux/gaSlice';
import { CAMPAIGN_BASE_PATH, MARKETING_CAMPAIGN_DETAIL_PATH, MARKETING_CAMPAIGN_LIST_PATH } from '../../constants';
import { DetailPagePage } from './Detail/detail-page.screen';
import { ListPage } from './List/list.screen';

// TODO: update GA data
const createCampaignPageGAData: GAData = {
  module: 'PRULeads',
  feature: 'ACM',
  journey: 'add_new_agency_campaign',
  stage: 'create_campaign',
  screen_name: 'CreateCampaignPage',
};

const viewCampaignGPageGAData: GAData = {
  module: 'PRULeads',
  feature: 'ACM',
  journey: 'view_campaign_detail',
  stage: 'view',
  screen_name: 'CampaignDetailPage',
};

const campaignListGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'View Marketing Campaign',
  stage: 'View Campaign List',
  screen_name: 'Campaign List',
};

const marketingCampaignDetailGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'View Marketing Campaign',
  stage: 'View Marketing Campaign Detail',
  screen_name: 'Marketing Campaign Detail',
};

export const MARKETING_LIST_PATH = `${CAMPAIGN_BASE_PATH}${MARKETING_CAMPAIGN_LIST_PATH}`;
export const MARKETING_DETAIL_PATH = `${CAMPAIGN_BASE_PATH}${MARKETING_CAMPAIGN_LIST_PATH}${MARKETING_CAMPAIGN_DETAIL_PATH}`;
const MarketingCampaignRoutes: FC<RouteComponentProps> = (props) => {
  const cacheParams = useRef();

  return (
    <Switch>
      <PruRoute
        exact
        gaData={campaignListGaDataRoute}
        path={MARKETING_LIST_PATH}
        render={(props) => <ListPage {...props} />}
      />
      <PruRoute
        exact
        gaData={marketingCampaignDetailGaDataRoute}
        path={`${MARKETING_DETAIL_PATH}/:id`}
        render={(props) => <DetailPagePage {...props} cacheParams={cacheParams} />}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default MarketingCampaignRoutes;
