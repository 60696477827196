import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { MANAGER_DASHBOARD_BASE_PATH } from 'src/app/modules/ManagerDashboard/constants';
import { DashboardSideMenu } from 'src/app/common/components/ManagerDashboard';
import HeaderMobile from './components/header/header-mobile';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Aside from './components/aside/aside';
import { ContentContainer } from './components/container/content-container';
import { HeaderPc } from './components/header/header-pc/header-pc.component';

const Layout: FC = () => {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const showHeader = params.get('noHeader') === null && sessionStorage.getItem('noHeader') !== 'true';
  const showDashboardSideMenu = !showHeader && pathname.includes(MANAGER_DASHBOARD_BASE_PATH);

  return (
    <div className={classes.container}>
      {showHeader && <HeaderPc />}
      <div className={classes.wrapper}>
        {showHeader && (
          <>
            <HeaderMobile />
            <Aside />
          </>
        )}
        {showDashboardSideMenu && <DashboardSideMenu />}
        <ContentContainer showDashboardSideMenu={showDashboardSideMenu} showHeader={showHeader} />
      </div>
    </div>
  );
};

export default Layout;

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
}));
