import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './category-history.style';
import { categoryUpdatedLog, useCategoryHistory } from './category-history.hook';

type ComponentProps = ParamsProps & {
  updateLog: categoryUpdatedLog[];
};

export const CategoryHistoryComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { updateLog } = props;
  const { historyData } = useCategoryHistory(updateLog);

  const { classes, cx } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <>
      {historyData &&
        historyData.map((log, index) => {
          const className = cx({
            [styles.normalText]: true,
            [styles.activeText]: index === 0,
          });
          return (
            <div className={styles.wrap}>
              <div className={styles.updateTimeBox}>
                <span className={className}>{log.updatedAt}</span>
              </div>
              <div className={styles.spliter}>
                <div className={styles.dot} />
                {index < historyData.length - 1 && <div className={styles.line} />}
              </div>
              <span className={className}>{`${log.changeDetail}`}</span>
            </div>
          );
        })}
    </>
  );
});
