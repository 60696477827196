import moment from 'moment';

export enum DateFormat {
  date = 'DD/MM/yyyy',
  shortDate = 'DD/MM',
  datetime = 'DD/MM/yyyy HH:mm',
  longDatetime = 'DD/MM/yyyy HH:mm:ss',
  month = 'MM/yyyy',
  time = 'HH:mm',
  longTime = 'HH:mm:ss',
  utcTime = 'YYYY-MM-DDTHH:mm:ssZ',
  monthYear = 'MMM YYYY', // used in chart
  monthDay = 'MMM DD', // used in chart
  day = 'DD', // use in chart
  yearMonthDay = 'YYYY-MM-DD',
}

/**
 * Formats a date string into a specific format.
 *
 * @param {string} date - The date string to format.
 * @returns {string} The formatted date string.
 * @description For example, "2023-10-31T01:43:05.254Z" becomes "31/10/2023 01:43".
 */
export const formatDate = (date: string, dateTye: DateFormat = DateFormat.datetime) => {
  if (!date) return date;
  if (!moment(date).isValid()) return date;
  return moment(date).format(dateTye);
};

export const checkDateEquals = (
  date1: number | string | Date,
  date2: number | string | Date,
  format: string = 'MM/DD',
): boolean => {
  const d1 = moment(new Date(date1)).format(format);
  const d2 = moment(new Date(date2)).format(format);
  return d1 === d2;
};

export const processDatetoUTCTIME = (date: string) => {
  const stillUtc = moment.utc(date).toDate();
  const localDateString = moment(stillUtc).local().format(DateFormat.utcTime);
  return moment(localDateString, DateFormat.utcTime).valueOf();
};

export const formatSLA = (expiredAt: string, currentTime: string | number) => {
  if (expiredAt) {
    const expiredTime = moment(expiredAt);
    const countedTime = moment(currentTime);
    const duration = moment.duration(moment(expiredTime).diff(countedTime));
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.subtract(days, 'days').asHours());
    const minutes = Math.floor(duration.subtract(hours, 'hours').asMinutes());

    function digit2(input: number): string {
      if (input < 10) {
        return `0${input}`;
      }
      return `${input}`;
    }
    return {
      day: days,
      hour: digit2(hours),
      minute: digit2(minutes),
    };
  }
};
