import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  iconButtonText: { fontSize: 14, fontWeight: 700, marginLeft: '10px' },
  icon: { width: '18px', height: '18px' },
  mergeIcon: { width: '24px', height: '24px' },
  disabled: {
    color: '#CCCCCC',
  },
  enable: {
    color: '#E8192C',
  },
}));
