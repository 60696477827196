import React, { useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { CampaignStructureItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { useStyles } from './tab-lead.style';
import { uploadLead } from 'src/app/modules/AgencyCampaign/network/campaignCurd';
import { fileUpload } from 'src/app/common/utils';
import { createBlobAsAgent, getBlobAsAgent } from 'src/app/common/network/crud/resourceCrud';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import {
  agencyCampaignDetailsGaDataClick2,
  agencyCampaignDetailsGaDataClick3,
  agencyCampaignDetailsGaDataClick,
  agencyCampaignDetailsGaDataClick1,
} from 'src/app/common/ga/types/ga-click-lead';
import { useEventEmitter } from 'ahooks';

interface HookProps {
  campaignTypeStructureData?: CampaignStructureItem;
  campaign: any;
  formDispatch: (data: any) => void;
  formState: any;
  cacheParams: React.MutableRefObject<Record<string, any> | undefined>;
  setToggleUploadWizard: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useTabLead = ({ campaign, setToggleUploadWizard }: HookProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const tableRef = useRef<any>();

  // redux
  const dispatch = useDispatch();
  const [toggleWizard, setToggleWizard] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [leadUploadList, setLeadUploadList] = useState<any>();
  const [leadUploadFile, setLeadUploadFile] = useState<any>();
  const [leadUploadError, setLeadUploadError] = useState<string | null>();
  const [confirmDisable, setConfirmDisable] = useState(true);

  const onImportLead = async () => {
    if (campaign.campaignId) {
      const file = leadUploadFile;
      try {
        setLoading(true);
        const createBlobRes = await createBlobAsAgent(
          { mimeType: file.type, accessLevel: 'anonymous', module: 'agencyCampaign', originalFilename: file.name },
          dispatch,
        );
        await fileUpload(createBlobRes[0].url, file, () => {});
        const blobDetail = await getBlobAsAgent({ resourceIds: createBlobRes[0].blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          const details = {
            campaignId: campaign.campaignId,
            resourceId: result.blobId,
            filename: file.name,
          };

          await uploadLead(details, dispatch);
          // clear state when import done
          setLeadUploadList(undefined);
          setLeadUploadFile(undefined);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('agencyCampaign.lead.processing'),
                content: Translation('agencyCampaign.lead.uploadDone'),
              },
            ]),
          );
          setToggleUploadWizard(false);
          tableRef.current.reload();
        }
      } catch (err) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'Fail',
              content: `Lead upload failed`,
            },
          ]),
        );
      } finally {
        setLoading(false);
      }
    } else {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Fail',
            content: `Lead upload failed`,
          },
        ]),
      );
    }
  };
  const event = useEventEmitter();
  const oppProviderValue = useMemo(
    () => ({
      gaData: {
        accept: agencyCampaignDetailsGaDataClick2,
        reject: agencyCampaignDetailsGaDataClick3,
        update: agencyCampaignDetailsGaDataClick,
        newApp: agencyCampaignDetailsGaDataClick1,
      },
      event: event,
    }),
    [],
  );

  return {
    tableRef,
    toggleWizard,
    confirmDisable,
    loading,
    setToggleWizard,
    onImportLead,
    setLeadUploadFile,
    setLeadUploadList,
    setLeadUploadError,
    setConfirmDisable,
    oppProviderValue,
  };
};
