import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  fullWidth: {
    width: '100%',
  },
  formLabel: {
    fontSize: 16,
    fontWeight: 600,
    color: 'rgba(51, 51, 51, 1)',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 700,
    color: '#999',
    display: 'block',
  },
  mt_16: {
    marginTop: 16,
  },
  mt_8: {
    marginTop: 8,
  },
  mt_24: {
    marginTop: 24,
  },
  ml_8: {
    marginLeft: 8,
  },
}));
