import React, { FC, memo, useMemo } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './lead-reminder.style';

type ComponentProps = ParamsProps & {
  type: 'reminder' | 'warning';
  reminderText: string;
  onConfirm?: () => void;
  confirmText?: string;
  onClose?: () => void;
};

export const LeadReminderComponent: React.FC<ComponentProps> = memo(
  ({ type, reminderText, onClose, confirmText, onConfirm }: ComponentProps) => {
    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);
    return (
      <Box className={`${styles.container} ${type === 'reminder' ? styles.reminder : styles.warning}`}>
        <Box className={styles.textIcon}>
          {type === 'reminder' ? <InfoOutlined className={styles.icon_reminder} sx={{ rotate: 'revert' }} /> : null}
          {type === 'warning' ? <WarningAmberIcon className={styles.icon_warning} /> : null}
          <Typography className={styles.text_fw500_fs16}>{reminderText}</Typography>
        </Box>
        <Box>
          {confirmText ? (
            <Button variant="text" onClick={onConfirm}>
              {confirmText}
            </Button>
          ) : null}

          {type === 'reminder' ? (
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
      </Box>
    );
  },
);
