import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  warning: {
    fontWeight: 500,
    fontSize: 16,
    color: 'rgba(232, 25, 44, 1)',
    lineHeight: '24px',
  },
  btnGroupWrap: { flex: 1, padding: 20, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' },
  btnGroup: { margin: '0 10px' },
  container: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}));
