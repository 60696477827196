import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './campaign-detail-view.style';
import { Page } from 'src/app/common/components/page/page.component';
import { CampaignDetail } from '../../types/campaign-types';
import { Link } from '@mui/material';
import { DateFormat, formatDate } from 'src/app/common/utils';

type ComponentProps = ParamsProps & {
  campaignDetail: CampaignDetail;
  /* otherProp: string */
};

export const CampaignDetailViewComponent: React.FC<ComponentProps> = memo(({ campaignDetail }) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  // const {data} = useCampaignDetailView(props.campaignDetail)
  return (
    <>
      {campaignDetail && (
        <div className={classes.viewContainer}>
          <div className={classes.defaultPadding}>
            <div className={`${classes.banner} ${classes.marginBottom}`}>
              <img src={campaignDetail?.image?.url} alt="banner" width="600px" height="300px" loading="lazy" />
            </div>
            <div className={`${classes.detailTitleText} ${classes.marginBottom}`}>{campaignDetail.name}</div>
            <div className={`${classes.dateRangeText} ${classes.marginBottom}`}>{`${formatDate(
              campaignDetail.startDate,
              DateFormat.date,
            )} - ${formatDate(campaignDetail.endDate, DateFormat.date)} `}</div>
            <div className={classes.dateDivider} />

            <div>
              <div className={`${classes.descTitleText} ${classes.marginBottom}`}>
                {Translation('campaign.detail.details')}
              </div>
              <div className={`${classes.contentText} ${classes.marginBottom}`}>{campaignDetail.description}</div>
              <div className={classes.contentDivider} />
            </div>

            {campaignDetail?.faq?.length > 0 && (
              <div>
                <div className={`${classes.descTitleText} ${classes.marginBottom}`}>
                  {Translation('campaign.detail.faq')}
                </div>
                {campaignDetail.faq?.map((item) => {
                  return (
                    <div>
                      <div className={`${classes.questionTitleText} ${classes.marginBottom}`}>{item.question}</div>
                      <div className={`${classes.contentText} ${classes.marginBottom}`}>{item.answer}</div>
                    </div>
                  );
                })}
                <div className={classes.contentDivider} />
              </div>
            )}

            {campaignDetail.customerIncentive && (
              <div>
                <div className={`${classes.descTitleText} ${classes.marginBottom}`}>
                  {Translation('campaign.detail.incentives_for_customer')}
                </div>
                <div className={`${classes.contentText} ${classes.marginBottom}`}>
                  {campaignDetail.customerIncentive}
                </div>
                <div className={classes.contentDivider} />
              </div>
            )}

            {campaignDetail.agentIncentive && (
              <div>
                <div className={`${classes.descTitleText} ${classes.marginBottom}`}>
                  {Translation('campaign.detail.incentives_for_agent')}
                </div>
                <div className={`${classes.contentText} ${classes.marginBottom}`}>{campaignDetail.agentIncentive}</div>
                <div className={classes.contentDivider} />
              </div>
            )}

            {campaignDetail?.flyers?.length > 0 && (
              <div>
                <div className={`${classes.descTitleText} ${classes.marginBottom}`}>
                  {Translation('campaign.detail.flyers')}
                </div>
                {campaignDetail.flyers?.map((item) => {
                  return (
                    <Link
                      className={classes.flyText}
                      target="_blank"
                      rel="noopener"
                      color="#4386E5"
                      href={item.attachment.url}
                    >
                      {item.title}
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
});
