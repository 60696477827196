import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import moment from 'moment';

import { formateProsectCategory } from 'src/app/modules/Prospect/util/common.util';
import { fetchProspectCategories } from 'src/app/modules/Prospect/network/prospectCurd';
import { ProspectCategoryProps } from 'src/app/common/components/prospect-category/prospect-category.component';

export type categoryUpdatedLog = {
  currentCategory: string;
  originalCategory?: string;
  updatedAt: string;
};

type historyData = {
  updatedAt: string;
  changeDetail: string;
};

export const useCategoryHistory = (history: categoryUpdatedLog[]) => {
  const locale = useLang();
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (key: string, values?: Record<string, any>) => intl.formatMessage({ id: key }, values);

  const [prospectCategories, setProspectCategories] = useState<ProspectCategoryProps[]>([]);
  const [historyData, setHistoryData] = useState<historyData[]>();

  const { data, error, loading, run, runAsync } = useRequest(() => fetchProspectCategories(locale, dispatch), {
    manual: false,
  });

  useEffect(() => {
    if (data && data.prospectCategories) {
      const categorys = formateProsectCategory(data.prospectCategories, locale, intl);
      const result = categorys.sort((a, b) => a.type.localeCompare(b.type));
      setProspectCategories(result);
    }
  }, [data]);

  useEffect(() => {
    if (history && prospectCategories) {
      const formartedData: Array<historyData | null> = history
        .map((item: any, index) => {
          const currentCategory = prospectCategories.find((category) => category.id === item.currentCategory);
          const originalCategory = prospectCategories.find((category) => category.id === item.originalCategory);
          if (!currentCategory && !originalCategory) {
            return null;
          }
          return {
            updateTs: item.updatedAt,
            updatedAt: moment(item.updatedAt).format('YYYY-MM-DD HH:mm'),
            changeDetail: Translation('prospect.prospect_category_change_to', {
              origin: originalCategory?.type ?? originalCategory?.rawType,
              current: currentCategory?.type ?? originalCategory?.rawType,
            }),
          };
        })
        .filter((item) => item !== null)
        .sort((a, b) => {
          if (a && b) {
            return Date.parse(b.updateTs) - Date.parse(a.updateTs);
          }
          return 0;
        });
      setHistoryData(formartedData as historyData[]);
    }
  }, [history, prospectCategories]);

  return {
    historyData,
  };
};
