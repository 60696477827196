/* eslint-disable */
import React from 'react';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material';

// stepper connector: the lines connect eash-icon
export const StepConnectorComponent = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% )',
    right: 'calc(50% )',
    marginLeft: 0,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#38527E', // active (current following-up)
      marginLeft: 0,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#38527E', // completed
      marginLeft: 0,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#ccc', // not started
    borderTopWidth: 4,
    borderRadius: 2,
  },
}));
