import React, { ReactNode, memo, useMemo } from 'react';
import { Checkbox, Stack, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useStyles } from './pro-table-selected-action-bar.style';
import { useIntl } from 'react-intl';

type ComponentProps = ParamsProps & {
  numSelected?: number;
  total: number;
  onSelected?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  // onClear?: () => void;
  formatText?: (options: { numSelected?: number; total: number }) => string;
  actionNode?: ReactNode;
  indeterminate?: boolean;
  checked?: boolean;
  height: number;
};

export const ProTableSelectedActionBar: React.FC<ComponentProps> = memo(
  ({ numSelected, total, onSelected, formatText, actionNode, checked, indeterminate, height }: ComponentProps) => {
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);
    const intl = useIntl();
    const Translation = (key: string, num?: string) => intl.formatMessage({ id: key }, num ? { num } : undefined);

    if (!numSelected) {
      return null;
    } else {
      return (
        <Stack direction="row" alignItems="center" className={styles.wrap} style={{ height }}>
          <Checkbox
            className={styles.checkbox}
            icon={<CheckBoxOutlineBlankOutlinedIcon className={styles.icon} />}
            indeterminateIcon={<IndeterminateCheckBoxIcon className={styles.icon} />}
            indeterminate={indeterminate}
            checked={checked}
            onChange={onSelected}
            inputProps={{ 'aria-label': 'select all desserts', className: styles.checkInput }}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
          />
          <Typography className={styles.selectText}>
            {formatText
              ? formatText({ numSelected, total })
              : Translation('pro.table.action.selected', `${numSelected}`)}
          </Typography>
          {actionNode}
        </Stack>
      );
    }
  },
);
