import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    width: 392,
    // paddingTop: 8,
  },
  text: {
    fontSize: 14,
    fontWeight: 400,
    color: '#000000',
  },
}));
