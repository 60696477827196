import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { getCampaignAcceptedAgents, getCampaignAgents } from 'src/app/modules/AgencyCampaign/network/campaignCurd';
import { AgentData, DisplayName } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { Option } from '../cascading-selector/cascading-selector.component';
import { SelectDownlineProps } from './select-downline.component';
import { localeMapToBackend } from 'src/app/modules/AgentProfile/common/utils/locale-utils';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { GlobalHelper } from '../../helpers/GlobalHelpers';
import { AgentRole } from '../../types/common-types';
import { useAuth } from '../../hooks/auth/auth.hook';
import { CampaignTypeEnum } from 'src/app/modules/Campaign/types/campaign-types';
import { removeFirstLetterIfU } from 'src/app/modules/AgencyCampaign/utils/common-utils';

export enum ALL_OPTION {
  ALL_AGENT = 'allAgent',
  ALL_UNIT = 'allUnit',
}

function transformDataWithAllOptionAndSort(data: AgentData[], lang: string, t: (val: string) => string) {
  const result: Array<{
    label: string;
    value: string;
    child: Array<{ label: string; value: string }>;
  }> = [];
  const unitMap: {
    [key: string]: { label: string; value: string; child: Array<{ label: string; value: string }> };
  } = {};

  data.forEach((item) => {
    const { unit, agentCode, displayName } = item;
    const label = removeFirstLetterIfU(unit) as string;

    if (!unitMap[unit]) {
      unitMap[unit] = {
        label: label,
        value: unit,
        child: [],
      };
      result.push(unitMap[unit]);
    }

    unitMap[unit].child.push({
      label: displayName[lang as keyof DisplayName],
      value: agentCode,
    });
  });

  result.forEach((group) => {
    group.child.sort((a, b) => a.label.localeCompare(b.label));
  });

  const allUnit: any = {
    label: t('all.units'),
    value: ALL_OPTION.ALL_UNIT,
    child: [],
  };

  const allAgent: any = {
    label: t('all.agents'),
    value: ALL_OPTION.ALL_AGENT,
  };

  const auth = globalStore.getAgent();
  const name = get(auth, `displayName.${lang}`, '');
  const currentAgent = { label: name + ` (${t('common.label_me')})`, value: globalStore.getAgentCode() };

  result.forEach((group: any) => {
    allUnit.child.push(...group.child);
  });

  result.forEach((group) => {
    group.child.unshift(allAgent);
  });

  const currentAgentIndex = allUnit.child.findIndex((item: any) => item.value === currentAgent.value);
  if (currentAgentIndex == -1) {
    allUnit.child.push(currentAgent);
  } else {
    allUnit.child[currentAgentIndex].label += ` (${t('common.label_me')})`;
  }
  allUnit.child.sort((a: any, b: any) => a.label.localeCompare(b.label));
  allUnit.child.unshift(allAgent);

  const sortedResult = result.sort((a, b) => a.label.localeCompare(b.label));

  sortedResult.unshift(allUnit);

  return sortedResult;
}

export const useSelectDownline = (props: SelectDownlineProps) => {
  const { campaignId, isCampaignOwner = true, currentOption, onChange, campaignType } = props;
  // i18n
  const locale = useLang();
  const mappedLocale = localeMapToBackend[locale] || locale;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [cascaderData, setCascaderData] = useState<Option[]>([]);
  const [defaultSelected, setDefaultSelected] = useState<Option[] | undefined>(undefined);
  const { role, agentCode, agentInfo } = useAuth();
  const dispatch = useDispatch();

  const { data, error, loading, run, runAsync } = useRequest(
    () => {
      const payload = { campaignId: campaignId, isManager: role === AgentRole.MANAGER };
      if (campaignType === CampaignTypeEnum.normal) {
        return getCampaignAgents(
          {
            ...payload,
            agentCode: agentCode,
          },
          dispatch,
        );
      } else {
        return getCampaignAcceptedAgents(payload, dispatch);
      }
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    run();
  }, []);

  useEffect(() => {
    if (data) {
      const formatedData = transformDataWithAllOptionAndSort(data, mappedLocale, Translation);
      setCascaderData(formatedData);
    }
  }, [data]);

  useEffect(() => {
    let parentData: Option | undefined = undefined;
    let childData: Option | undefined = undefined;
    if (currentOption && cascaderData) {
      parentData = cascaderData.find((item) => {
        if (item.child) {
          return item.child.find((childItem) => {
            if (childItem.value === currentOption[1].value) {
              childData = childItem;
              return childItem;
            }
          });
        }
      });
    }
    if (parentData && childData) {
      setDefaultSelected([parentData, childData]);
    }
  }, [cascaderData]);

  return {
    loading,
    cascaderData,
    defaultSelected,
    run,
    role,
    agentCode,
    agentUnit: removeFirstLetterIfU((agentInfo as { unit?: string })?.unit || ''),
  };
};
