import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<void, 'checked'>()((theme, _params, classes) => ({
  container: {
    width: 392,
  },
  radio: {
    paddingTop: 16,
    paddingBottom: 16,
    [`&.${classes.checked}`]: {
      color: '#E8192C',
    },
  },
  checked: {},
  radioItem: {
    width: '100%',
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  formControlLabel: {
    fontSize: 14,
    fontWeight: 500,
  },
  optionsContainer: {
    paddingLeft: 7,
  },
}));
