import React, { FC, useState, useMemo, useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './add-prospect.style';
import { AddNewProspectPage } from './components/add-new-prospect/add-new-prospect.screen';
import { SOURCE_TYPE } from '../../constants';
import { ImportExistingCustomersPage } from './components/import-existing-customers/import-existing-customers.screen';
import { takeUIScreenViewEvent } from 'src/app/common/ga/ga';
import { GAData } from 'src/app/common/ga/redux/gaSlice';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

const singleAddProspectGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'Add Prospect',
  stage: 'Single Add Prospect',
  screen_name: 'Single Add Prospect',
};

const importExistingCustomerAsProspectGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'Import Existing Customer As Prospect',
  stage: 'Import Existing Customer As Prospect',
  screen_name: 'Import Existing Customer As Prospect',
};

export const AddProspectPage: React.FC<PageProps> = (props: PageProps) => {
  const { type } = useParams<{ type: string }>();
  const { location } = props;
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  useEffect(() => {
    if (type && type === SOURCE_TYPE.NEW_PROSPECT) {
      takeUIScreenViewEvent(singleAddProspectGaDataRoute);
    }
    if (type && type === SOURCE_TYPE.EXISTING_CUSTOMER) {
      takeUIScreenViewEvent(importExistingCustomerAsProspectGaDataRoute);
    }
  }, [type]);

  return (
    <div className={styles.wrap}>
      {type && type === SOURCE_TYPE.NEW_PROSPECT && <AddNewProspectPage {...props} />}
      {type && type === SOURCE_TYPE.EXISTING_CUSTOMER && <ImportExistingCustomersPage {...props} />}
    </div>
  );
};
