import { TranslationWithParams } from '../../../../../../i18n/utils/i18n-utils';
/**
 * format dead reason to string for display
 * @param t
 * @param deadReasons
 * @param deadReason
 * @returns
 */
export const getLeadDeadReasonToDisplay = (
  t: (val: string, value: any) => string,
  deadReasons?: string[],
  deadReason?: string,
) => {
  if (!Array.isArray(deadReasons)) {
    return [];
  }
  return deadReasons?.map((item) => {
    if (item === 'leadCantConvert') {
      return deadReason ?? '';
    }
    let i18nKey = '';
    const reasonArray = item.split('&');
    i18nKey = `lead_dead_reason_${reasonArray[0]}`;
    return TranslationWithParams(i18nKey, { days: reasonArray?.[1] });
  });
};
