import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { exportToTalkbot } from '../../../network/talkbot-export-leads-crud';
import { TalkBotExportType } from '../../../types/talkbot-export-leads-types';
import { groupedExportData, isBirthdayWithin30Days } from '../../utils/talkbot.utils';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import moment from 'moment';
import { CampaignStatusEnum } from 'src/app/modules/Campaign/types/campaign-types';
import { useProjectSelector } from 'src/redux/store';
import { OperationContext } from 'src/app/modules/Leads/components/lead-list-item/lead-operation-units/util/operation-context.util';

enum PromiseAllSettleStatus {
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export const useTalkbotExportButton = ({
  actions,
  items,
  type,
  flowId,
}: {
  actions?: (actions: ('clearRow' | 'refresh')[]) => void;
  items?: any[];
  type?: TalkBotExportType;
  flowId?: number;
}) => {
  // i18n
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { event } = useContext(OperationContext);

  const activeFlowIds = useProjectSelector((state) => state.talkbot.activeFlowIds);

  // redux
  const dispatch = useDispatch();

  const enableExport = useMemo(() => {
    const disabledArr = items?.filter((item) => {
      if (type === TalkBotExportType.prospect) {
        const { birthDate, talkbotExported } = item?.prospect ?? {};
        return !(birthDate && isBirthdayWithin30Days(birthDate) && !talkbotExported);
      }
      const { campaign, talkbotExported, followUp } = item;
      return !(
        !followUp?.length &&
        campaign?.status === CampaignStatusEnum.active &&
        activeFlowIds?.includes(campaign.talkbotRule?.flowId) &&
        !talkbotExported &&
        item.leadRaw &&
        moment().isBetween(campaign?.startDate, campaign?.endDate)
      );
    });
    return disabledArr?.length === 0;
  }, [items, type, activeFlowIds]);

  const onExport = useCallback(
    async (itemList: any[], type?: TalkBotExportType, showAcceptedToast?: boolean) => {
      if (loading) return;
      setLoading(true);
      const isBirthdayDialogue = type === TalkBotExportType.prospect;
      // group data by flowId
      const groupedData = groupedExportData(itemList);
      const promiseArr = groupedData?.map((group: any[]) => {
        const ids = isBirthdayDialogue ? group.map((item) => item.prospect?._id) : group.map((item) => item._id);
        return exportToTalkbot({
          ids: ids.filter((item) => !!item),
          flowId: isBirthdayDialogue ? flowId : group[0]?.campaign?.talkbotRule?.flowId,
          type: type || TalkBotExportType.lead,
        });
      });
      if (!promiseArr?.length) {
        setLoading(false);
        return;
      }
      // batch export leads or prospects to talkbot
      const res = await Promise.allSettled(promiseArr);
      setLoading(false);
      const failedData: number[] = [];
      res?.forEach((item) => {
        if (item.status !== PromiseAllSettleStatus.FULFILLED) {
          failedData.push(item.reason);
        }
      });
      if (failedData?.length > 0 && failedData?.length === res?.length) {
        // if all data failed to export, show failed toast
        dispatch(appendAlertItem([{ severity: AlertType.ERROR, title: 'Export failed', content: 'Please try again' }]));
      } else {
        dispatch(
          appendAlertItem([{ severity: AlertType.SUCCESS, title: 'Exported to AI Talkbot', content: 'successfully' }]),
        );
        if (showAcceptedToast) {
          dispatch(appendAlertItem([{ severity: AlertType.SUCCESS, title: 'Accepted successfully', content: '' }]));
        }
      }
      actions?.(['refresh', 'clearRow']);
      event && event.emit();
    },
    [loading, actions, event, flowId, dispatch],
  );

  return {
    onExport,
    loading,
    enableExport,
  };
};
