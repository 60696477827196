import React, { useMemo, useState } from 'react';

import { useTranslation } from 'src/app/common/hooks/translation.hook';

export interface ClientReferredByProps {
  defaultSelectedRows?: any[];
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
}

export const useClientReferredBy = ({ defaultSelectedRows }: ClientReferredByProps) => {
  const { t: Translation } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<any[] | undefined>(defaultSelectedRows);

  const rowSelection = useMemo(
    () => ({
      onChange: (rows: any[]) => {
        setSelectedRow(rows);
      },
      getCheckboxProps: () => ({
        disabled: false,
      }),
    }),
    [],
  );

  return {
    Translation,
    selectedRow,
    rowSelection,
  };
};
