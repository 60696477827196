export const styles = {
  feedback: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: 4,
    backgroundColor: '#EBF3FF',
    color: '#4386E5',
  },
};
