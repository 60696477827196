import React, { memo } from 'react';
import { SwitchProps, Switch, styled, Box, Typography } from '@mui/material';
import { useAcceptLeads } from './accept-leads.hook';
import { Dialog } from 'src/app/common/components/Dialog/dialog/dialog.component';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

type ComponentProps = {
  isAcceptLeads?: boolean;
};

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#E8192C',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const AcceptLeads: React.FC<ComponentProps> = memo(({ isAcceptLeads = false }: ComponentProps) => {
  const { t } = useTranslation();

  const { open, accept, loading, switchAcceptLeads, setOpen, onSwitch } = useAcceptLeads({ isAcceptLeads });

  return (
    <>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <IOSSwitch onChange={onSwitch} checked={accept} />
        <Typography fontSize={'12px'} fontWeight={500} color="#333333" marginLeft={'18px'}>
          {t('lead.accept_leads')}
        </Typography>
      </Box>
      <Dialog
        title={t('salesAlcTitle')}
        content={t('salesAlcDesc')}
        open={open}
        loading={loading}
        onConfirm={async () => {
          await switchAcceptLeads(false);
          setOpen(false);
        }}
        onClose={() => setOpen(false)}
      />
    </>
  );
});
