import { useMemo } from 'react';
import { DateRangePickerType } from '../../../simple-date-range-picker/simple-date-range-picker.component';

interface HookProps {
  input?: DateRangePickerType;
}

export const useListFilterDateRangePicker = ({ input }: HookProps) => {
  const fieldValue = useMemo(() => input || { startDate: '', endDate: '' }, [input]);

  return {
    fieldValue,
  };
};
