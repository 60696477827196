import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { findAll } from './util/highlight-matched-text.util';

export interface HighlightTextProps {
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  highLightText?: string;
  highLightColor?: string;
  children: React.ReactNode;
}

export const HighlightMatchedText: React.FC<HighlightTextProps> = memo((props: HighlightTextProps) => {
  const { style, highLightText, highLightColor, textStyle } = props;
  const fullText: string = props.children as string;

  if (!fullText) {
    return null;
  }
  if (!highLightText || !fullText.toLowerCase().includes(highLightText.toLowerCase())) {
    return (
      <Typography
        style={style}
        component="div"
        sx={{
          overflow: 'hidden',
        }}
      >
        <Typography
          style={{
            /* fontSize: 14, table font style */ fontWeight: 500,
            color: '#333333',
            wordBreak: 'break-word',
            ...textStyle,
          }}
        >
          {fullText}
        </Typography>
      </Typography>
    );
  }
  const chunks = findAll({
    searchWords: [highLightText],
    textToHighlight: fullText,
    autoEscape: true,
  });
  return (
    <div style={{ overflow: 'hidden', ...style }}>
      <Typography component="div">
        {chunks.map((chunk) => {
          const { end, highlight, start } = chunk;
          const text = fullText.substring(start, end);
          if (highlight) {
            return (
              <Typography
                key={start}
                style={{
                  fontWeight: 500,
                  ...textStyle,
                  wordBreak: 'break-word',
                  color: highLightColor || '#E8192C',
                }}
                component="span"
              >
                {text}
              </Typography>
            );
          }
          return (
            <Typography
              key={start}
              style={{
                fontWeight: 500,
                wordBreak: 'break-word',
                ...textStyle,
              }}
              component="span"
            >
              {text}
            </Typography>
          );
        })}
      </Typography>
    </div>
  );
});
