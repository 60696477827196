import { get } from 'lodash';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Translation, useLang } from 'src/app/i18n';
import { AGENCY_CAMPAIGN_LIST_PATH } from 'src/app/modules/AgencyCampaign/constants';
import {
  CAMPAIGN_BASE_PATH,
  MARKETING_CAMPAIGN_LIST_PATH,
  MARKETING_CAMPAIGN_DETAIL_PATH,
} from 'src/app/modules/Campaign/constants';
import { CampaignTypeEnum } from 'src/app/modules/Campaign/types/campaign-types';
import { LeadDetail } from 'src/app/modules/Leads/types/lead-detail-types';
import { AgentStatusEnum } from 'src/app/modules/Leads/types/types';
import { PROSPECT_BASE_PATH, PROSPECT_DETAIL_PATH } from 'src/app/modules/Prospect/constants';

export const useLeadBasicInfo = (detail: LeadDetail) => {
  const history = useHistory();
  const locale = useLang();
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const toCampaign = () => {
    if (detail?.campaign?._id) {
      if (detail?.campaign?.type !== CampaignTypeEnum.agency) {
        history.push(
          `${CAMPAIGN_BASE_PATH}${MARKETING_CAMPAIGN_LIST_PATH}${MARKETING_CAMPAIGN_DETAIL_PATH}/${detail.campaign._id}`,
        );
      } else {
        history.push(`${AGENCY_CAMPAIGN_LIST_PATH}/detail/${detail.campaign.campaignId}`);
      }
    }
  };
  const toProspect = () => {
    if (detail?.prospect?._id) {
      history.push(`${PROSPECT_BASE_PATH}${PROSPECT_DETAIL_PATH}/${detail.prospect._id}`);
    } else {
      setOpenPreview(true);
    }
  };

  const sourceName = useMemo(() => {
    return (
      get(detail, `source.parent.i18nData.${locale}.name`) ||
      get(detail, `source.parent.sourceName`) ||
      get(detail, `agentLeadSource.parent.i18nData.${locale}.name`) ||
      get(detail, `agentLeadSource.parent.sourceName`)
    );
  }, [detail, locale]);
  const subSourceName = useMemo(() => {
    return (
      get(detail, `source.i18nData.${locale}.name`) ||
      get(detail, `source.sourceName`) ||
      get(detail, `agentLeadSource.i18nData.${locale}.name`) ||
      get(detail, `agentLeadSource.sourceName`)
    );
  }, [detail, locale]);

  const campaignCategory = useMemo(() => {
    switch (detail?.campaign?.type) {
      case 'agency':
        return Translation('title.agencyCampaign');
      case 'servicing':
        return Translation('campaign.servicing.title');
      default:
        return Translation('campaign.marketing.title');
    }
  }, [detail]);

  const displayLeadName = useMemo(() => {
    return detail?.sourceName;
  }, [detail]);

  const displayLeadImage = useMemo(() => {
    return detail?.sourceImage?.url;
  }, [detail]);

  const status = useMemo(() => {
    if (detail?.lastFollowUpStatus) {
      return Translation(`campaign.lead.followUp.${detail.lastFollowUpStatus}`);
    } else {
      if (detail?.agentStatus === AgentStatusEnum.pending) {
        return Translation(`campaign.lead.followUp.pending`);
      } else {
        return Translation(`campaign.lead.followUp.pendingToContact`);
      }
    }
  }, [detail]);
  return {
    toCampaign,
    toProspect,
    sourceName,
    subSourceName,
    openPreview,
    campaignCategory,
    status,
    close: () => setOpenPreview(false),
    displayLeadName,
    displayLeadImage,
  };
};
