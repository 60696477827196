import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLang } from 'src/app/i18n';

import { RequestData } from 'src/app/common/components/ProTable';
import { languageConvertor } from 'src/app/common/utils';
import {
  Customer,
  getTableColumns,
  queryCustomerData,
} from 'src/app/modules/Prospect/pages/add-prospect/components/import-existing-customers/util/existing-customers.util';
import { fetchClientList } from '../../network/leadsCrud';
import { PolicyListSearchOptions } from '../../types/types';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';
import { useStyles } from './customer-filter-table.style';

export interface CustomerFilterTableProps {
  defaultSelectedRows?: SeleteCustomer[];
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
}

export interface SeleteCustomer {
  clientName: string;
  clientCode: string;
  policies: string[];
}

export const useCustomerFilterTable = ({ defaultSelectedRows, cacheParams }: CustomerFilterTableProps) => {
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const [keyword, setKeyword] = useState('');
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const getData = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<RequestData<Customer>> => {
      const { page, pageSize, keyword: searchkey } = params;
      const { types: filterTypes, sources: filterSourceTypes, categories: filterCategoryIds, dateRange } = filter ?? {};

      const queryParam = {
        lang: languageConvertor(locale),
        agentCode: user.username,
        searchOpt: PolicyListSearchOptions.ALL,
        policyNo: undefined,
        clientCode: undefined,
        idNo: undefined,
        submissionDtFrom: undefined,
        submissionDtTo: undefined,
      };

      // todo: check this is asc or desc
      let sortBy = '-nameWeight -firstName';
      let flag = '';

      if (sort && Object.keys(sort).length > 0) {
        const [key, val] = Object.entries(sort)[0];

        if (val === 'desc') {
          flag = '-';
        }

        if (key === 'displayName') {
          if (flag) {
            sortBy = '-nameWeight -firstName';
          } else {
            sortBy = 'nameWeight firstName';
          }
        } else {
          sortBy = `${flag}${key}`;
        }
      }

      const res = await fetchClientList(queryParam, dispatch);

      if (res) {
        let data = res;
        if (searchkey) {
          data = queryCustomerData(searchkey, res);
        }
        data = data.slice(params.page * params.pageSize, (params.page + 1) * params.pageSize);

        return {
          success: true,
          data: data ?? [],
          total: data.length,
        };
      }

      return {
        success: false,
        data: [],
        total: 0,
      };
    },
    [dispatch],
  );

  const tableColumns = useMemo(() => {
    return getTableColumns({ t: Translation, keyword: keyword, styles: styles, importedClientCodes: [] });
  }, [keyword]);

  const onFilterChange = useCallback(
    (filterState: FilterState) => {
      const { keyword } = filterState;
      cacheParams && (cacheParams.current = filterState);
      setKeyword(keyword);
    },
    [cacheParams],
  );

  return {
    tableColumns,
    getData,
    onFilterChange,
  };
};
