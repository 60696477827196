import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {},
  text: {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 500,
  },
  tnc: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: 500,
  },
}));
