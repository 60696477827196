/* eslint-disable */
import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { convertFollowUp } from '../../util/lead-status/lead-status.util';
import { FollowUp } from '../../util/lead-status/lead-status.enum';
import { StepConnectorComponent } from './step-connector/step-connector.component';
import { StepIconComponent } from './step-icon/step-icon.component';
import { AgentStatusEnum } from '../../types/types';

type ComponentProps = {
  followUp: Array<FollowUp>; // exactly the same as DB field:lead-coe-dev-encrypt.lead.followUp
  agentStatus: string;
};

// lead-status horizontal
export const LeadStatusStepperComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { stageBarList, activeStep, isClosed } = convertFollowUp(props.followUp, props.agentStatus);
  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorComponent />}>
      {stageBarList.map(({ status, icon }: FollowUp) => (
        <Step key={status}>
          <StepLabel StepIconComponent={(props) => <StepIconComponent {...props} icon={icon} isClosed={isClosed} />}>
            {Translation(`campaign.lead.followUp.${status}`)}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
});
