import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    borderRadius: 8,
    color: '#000000',
    border: '1px solid #000',
    display: 'flex',
    padding: 1,
  },
  selectedContainer: {
    border: '2px solid #E8192C',
    padding: 0,
  },
  selectedColor: {
    color: '#E8192C',
  },
  icon: {
    width: 24,
    height: 24,
  },
  text: {
    marginLeft: 10,
    fontSize: 16,
    fontWeight: 400,
  },
  retain: {
    fontSize: 16,
    fontWeight: 400,
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectOptionItemContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: 56,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 14,
    paddingRight: 24,
  },
  pd16: {
    padding: 16,
  },
}));
