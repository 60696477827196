import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get, isEmpty, set } from 'lodash';

import { LeadCategoryEnum, LeadForm, ReferredTypeEnum, RequestProspectLead } from '../../types/types';
import dayjs from 'dayjs';
import moment from 'moment';
import { DateFormat } from 'src/app/common/utils/date.util';
import { getLeadSource, getLeadSubSource, postProspectLead } from '../../network/leadsCrud';
import { APP_CONFIG, getAppConfigs } from 'src/app/common/utils/common-utils';
import { getProspectName } from '../../util/leads.util';
import { useHistory } from 'react-router-dom';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { takeUIScreenViewEvent } from 'src/app/common/ga/ga';
import { GAData } from 'src/app/common/ga/redux/gaSlice';

interface HookProps {
  id: string;
  // other params
}

export const useAddLead = ({ id }: HookProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<LeadForm>({
    dateTs: moment.utc(dayjs().toDate()).valueOf(),
  } as any);
  const [leadSourceData, setLeadSourceData] = useState<Array<{ key: string; value: string }>>([]);
  const [leadSubSourceData, setLeadSubSourceData] = useState<Array<{ key: string; value: string }>>([]);
  const [isReferredMandtory, setIsReferredMandtory] = useState(false);
  const leadSetting = get(getAppConfigs(APP_CONFIG.SALES_SDK), 'Sales.lead', {});
  const [selectedClient, setSelectedClient] = useState<{ clientName: string; clientCode: string }>();
  const [selectedCampaign, setSelectedCampaign] = useState<any>();
  const [showReferrby, setShowReferrby] = useState(false);
  const [selectedReferrBy, setSelectedReferrBy] = useState<any>();
  const [filedErrors, setFiledErrors] = useState<any>({}); // error message for each field

  /** is enable this feature: Add lead to campaign */
  const enableAddLeadToCampaign = leadSetting.defaultCreateMarketingLead && leadSetting.enableMarketingLeadCheckbox;
  /** is enable this feature: change referred source */
  const referredSource: string = leadSetting.referredSource;

  const onLeadDateChange = useCallback((date: dayjs.Dayjs | null) => {
    if (date) {
      const dateTs = moment.utc(date.toDate()).valueOf();
      // const ts = processDatetoUTCTIME(date.toDate().toString());

      setFormData((pre: any) => {
        return {
          ...pre,
          dateTs: dateTs,
        };
      });
    }
  }, []);

  const onClientChange = useCallback((value: any) => {
    setSelectedClient({
      clientName: value.label,
      clientCode: value.value,
    });
  }, []);

  const onCampaignChange = useCallback((campaign: any) => {
    if (campaign) {
      setSelectedCampaign(campaign);
      const campaignData = campaign.extraData;
      const { type, source, _id } = campaignData;
      let valueLeadSource = ''; // current selected parent-source
      let valueLeadSubSource = ''; // current selected sub-source
      if (type === 'agency') {
        valueLeadSource = get(campaignData, 'agencyCampaign.agentLeadSource');
        valueLeadSubSource = get(campaignData, 'agencyCampaign.agentLeadSubSource[0]');
      } else {
        valueLeadSource = source;
        valueLeadSubSource = get(campaignData, 'subSource[0]');
      }
      getLeadSource(campaignData._id).then((res) => {
        if (res && res.length > 0) {
          setLeadSourceData(res);
          setFormData((pre: any) => {
            return {
              ...pre,
              leadSource: valueLeadSource,
              leadSubSource: valueLeadSubSource,
            };
          });
        }
      });
    }
  }, []);

  const onSelectReferrBy = useCallback((value: any) => {
    setSelectedReferrBy(value[0]);
    setShowReferrby(false);
  }, []);

  useEffect(() => {
    getLeadSource('').then((res) => {
      if (res && res.length > 0) {
        setLeadSourceData(res);
        setFormData((pre: any) => {
          return {
            ...pre,
            leadSource: res[0].key,
          };
        });
      }
    });
    const gaEventData: GAData = {
      module: 'PRULeads',
      feature: 'Lead',
      journey: 'Add Lead',
      stage: 'Add Lead',
      screen_name: 'Add Lead',
    };
    takeUIScreenViewEvent(gaEventData);
  }, []);

  useEffect(() => {
    if (formData?.leadSource) {
      const data = {
        isCampaign: false,
        value: formData?.leadSource,
      };
      getLeadSubSource(data).then((res) => {
        if (res && res.length > 0) {
          setLeadSubSourceData(res);
          setFormData((pre: any) => {
            return {
              ...pre,
              leadSubSource: res[0].key,
            };
          });
        }
      });
    }
  }, [formData?.leadSource]);

  useEffect(() => {
    const leadSubSource = formData?.leadSubSource;
    if (leadSubSource && leadSubSourceData != undefined) {
      const tmp: any = leadSubSourceData.find((item: any) => {
        return item.key == leadSubSource;
      });
      if (!tmp) {
        return;
      }
      if (tmp && tmp.value.match(/child lead/gi)?.length > 0) {
        setIsReferredMandtory(true);
      } else {
        setIsReferredMandtory(false);
      }
    }
  }, [formData?.leadSubSource]);

  const updateFormData = useCallback((key: string, value: any) => {
    setFormData((pre: any) => {
      return {
        ...pre,
        [key]: value,
      };
    });
  }, []);

  const checkFormValid = (formData: LeadForm) => {
    const { leadSource, leadSubSource, dateTs } = formData;
    const errors: any = {};

    if (!dateTs || dateTs > new Date().getTime()) {
      errors.dateTs = { error: Translation('lead.addNewLead.invaliadCreateDate') };
    }
    if (!leadSource) {
      errors.leadSource = { error: Translation('lead.addNewLead.leadSourceRequest') };
    }

    if (leadSetting.mandatorySubSource) {
      if (!leadSubSource) {
        errors.leadSubSource = { error: Translation('lead.addNewLead.leadSubSourceRequest') };
      }
    }

    if (isReferredMandtory) {
      if (referredSource === ReferredTypeEnum.prospect) {
        if (selectedReferrBy._id == '') {
          errors.referredBy = { error: Translation('lead.addNewLead.referredClientRequest') };
        }
      } else {
        const { clientCode } = selectedClient || {};
        if (clientCode == '') {
          errors.referredBy = { error: Translation('lead.addNewLead.referredClientRequest') };
        }
      }
    }
    setFiledErrors(errors);
    if (!isEmpty(errors)) {
      return false;
    }

    return true;
  };

  const checkMandatoryFields = (formData: LeadForm) => {
    const { leadSource, leadSubSource, dateTs } = formData;
    if (!dateTs) {
      return false;
    }
    if (!leadSource) {
      return false;
    }

    if (leadSetting.mandatorySubSource) {
      if (!leadSubSource) {
        return false;
      }
    }

    if (isReferredMandtory) {
      if (referredSource === ReferredTypeEnum.prospect) {
        if (selectedReferrBy._id == '') {
          return false;
        }
      } else {
        const { clientCode } = selectedClient || {};
        if (clientCode == '') {
          return false;
        }
      }
    }
    return true;
  };

  const canSubmit = useMemo(() => {
    return formData && checkMandatoryFields(formData);
  }, [formData]);

  const submit = () => {
    if (formData && checkFormValid(formData)) {
      const { leadSource, leadSubSource } = formData;
      let currSource = leadSubSource || leadSource || '';
      const prospectId = id;

      let prospectLead: RequestProspectLead = {
        ...formData,
        leadDate: moment(formData.dateTs).format(DateFormat.utcTime),
        prospect: prospectId,
        category: LeadCategoryEnum.SALES_LEAD,
      };
      if (referredSource === ReferredTypeEnum.prospect) {
        if (selectedReferrBy?._id) {
          prospectLead = {
            ...prospectLead,
            referredProspectId: selectedReferrBy._id,
            referredProspectName: getProspectName(selectedReferrBy),
          };
        }
      } else {
        const { clientName, clientCode } = selectedClient || {};
        if (clientCode && clientName) {
          prospectLead = {
            ...prospectLead,
            referredClientId: clientCode,
            referredClientName: clientName,
          };
        }
      }

      if (selectedCampaign && selectedCampaign.extraData._id) {
        // type: agency/mass/normal/marketing
        const { type, _id } = selectedCampaign.extraData;
        if (type === 'agency') {
          prospectLead = {
            ...prospectLead,
            campaign: _id,
            agentLeadSource: currSource,
          };
        } else {
          prospectLead = {
            ...prospectLead,
            campaign: _id,
            source: currSource,
          };
        }
      } else {
        // none campaign related
        prospectLead = {
          ...prospectLead,
          agentLeadSource: currSource,
        };
      }

      setLoading(true);
      postProspectLead(prospectLead)
        .then((res) => {
          history.goBack();
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('global.submit.success'),
                content: '',
              },
            ]),
          );
        })
        .catch((err) => {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('common.pleaseTryAgain'),
                content: '',
              },
            ]),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return {
    formData,
    leadSourceData,
    leadSubSourceData,
    isReferredMandtory,
    referredSource,
    showReferrby,
    selectedReferrBy,
    filedErrors,
    canSubmit,
    enableAddLeadToCampaign,
    loading,
    setShowReferrby,
    updateFormData,
    onLeadDateChange,
    onClientChange,
    onCampaignChange,
    onSelectReferrBy,
    submit,
  };
};
