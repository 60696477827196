import { Columns, Lead } from 'src/app/modules/Leads/types/types';
import { ProColumns } from '../../../../../../../common/components/ProTable';
import { ProTableOperationBtnComponent } from '../../../../../../../common/components/ProTable/components/pro-table-operation-btn/pro-table-operation-btn.component';
import {
  renderLeadCampaignName,
  renderLeadOperation,
  renderLeadProspectName,
  renderLeadRemark,
  renderLeadText,
  renderLeadTime,
  renderLeadTypeOfLead,
  renderLeadStatus,
} from '../../../../../../Leads/util/table-columns-render.util';
import { COLUMN_ITEMS } from 'src/app/modules/Leads/util/column-items.util';
import { FollowUpStatusEnum } from '../../../../../../Campaign/types/campaign-types';

const noActionColumns = ({
  t,
  styles,
  keyword,
}: {
  t: (val: string) => string;
  styles: Record<string, any>;
  keyword?: string;
}): ProColumns<Lead>[] => {
  return [
    {
      title: t('component.formLabel.name'),
      dataIndex: 'displayName',
      sorter: true,
      width: '290px',
      render: (currVal, item) => renderLeadProspectName(currVal, item, keyword),
    },
    {
      title: t('agencyCampaign.formTitle.lead.leadStatus'),
      dataIndex: 'followUpStatus',
      width: '466px',
      render: renderLeadStatus,
    },
    {
      ...COLUMN_ITEMS[Columns.source](),
      title: t(COLUMN_ITEMS[Columns.source]().title as string),
    } as any,
    {
      ...COLUMN_ITEMS[Columns.leadSource](),
      title: t(COLUMN_ITEMS[Columns.leadSource]().title as string),
    } as any,
    {
      title: t('agencyCampaign.approval.remark'),
      dataIndex: 'description',
      width: '290px',
      render: renderLeadRemark,
    },
    {
      title: t('agencyCampaign.formTitle.lead.leadType'),
      dataIndex: 'labels',
      width: '176px',
      render: renderLeadTypeOfLead,
    },
    {
      title: t('agencyCampaign.common.leadSource'),
      dataIndex: 'leadSouce',
      width: '176px',
      sorter: true,
      render: renderLeadText,
    },
    {
      title: t('agencyCampaign.common.lastUpdatedTime'),
      sorter: true,
      dataIndex: 'updatedAt',
      width: '176px',
      render: renderLeadTime,
    },
    {
      title: t('agencyCampaign.formTitle.lead.lastView'),
      sorter: true,
      dataIndex: 'lastViewedAt',
      width: '176px',
      render: renderLeadTime,
    },
  ];
};

export const getNoActionColumns = ({
  t,
  styles,
}: {
  t: (val: string) => string;
  styles: Record<string, any>;
}): ProColumns<Lead>[] => {
  return noActionColumns({ t, styles });
};

export const getActionColumns = ({
  t,
  styles,
  onAppt,
  onUpdate,
  onAccept,
  onReject,
}: {
  t: (val: string) => string;
  styles: Record<string, any>;
  onReject?: (item: Lead) => void;
  onAccept?: (item: Lead) => void;
  onAppt?: (item: Lead) => void;
  onUpdate?: (item: Lead) => void;
}): ProColumns<Lead>[] => {
  return [
    ...noActionColumns({ t, styles }),
    {
      title: t('component.formLabel.operation'),
      dataIndex: '',
      width: '176px',
      sticky: true,
      render: (currValue, item, actions) => {
        const isActive = ![
          FollowUpStatusEnum.dead,
          FollowUpStatusEnum.issued,
          FollowUpStatusEnum.contactFailure,
        ].includes(item.followUpStatus as FollowUpStatusEnum);
        return isActive ? renderLeadOperation(item, { onReject, onAccept, onAppt, onUpdate }, actions) : <></>;
      },
    },
  ];
};

export const i18nKeyMapping = {
  contactSuccess: {
    i18nKey: 'Sales:contactSuccess',
    query: { agentStatus: 'accepted', lastFollowUpStatus: 'contactSuccess' },
  },
  met: { i18nKey: 'Sales:met', query: { agentStatus: 'accepted', lastFollowUpStatus: 'met' } },
  quote: { i18nKey: 'Sales:quote', query: { agentStatus: 'accepted', lastFollowUpStatus: 'quote' } },
  proposal: { i18nKey: 'Sales:proposal', query: { agentStatus: 'accepted', lastFollowUpStatus: 'proposal' } },
  policy: { i18nKey: 'Sales:policy', query: { agentStatus: 'accepted', lastFollowUpStatus: 'policy' } },
  dead: { i18nKey: 'Sales:dead_lead', query: { agentStatus: 'accepted', lastFollowUpStatus: ['dead','AUTO_DROPPED'] } }, // PACS Customization | [PACSPFAP-3072]
  pendingAccept: { i18nKey: 'Sales:pending_accept', query: { agentStatus: 'pending', lastFollowUpStatus: '' } },
  pendingContact: {
    i18nKey: 'Sales:follow_up_state_pending',
    query: { agentStatus: 'accepted', lastFollowUpStatus: 'null' },
  },
};
