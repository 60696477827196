import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { TranslationWithParams, useLang } from 'src/app/i18n';
import { isEmail } from 'src/app/common/utils/common-utils';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import {
  AddLeadSettingItem,
  CampaignStructureItem,
  ValidateLead,
} from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { LeadUploadErrorEnum, OperatorEnum } from 'src/app/modules/AgencyCampaign/types/common-types';
import { Button } from 'src/app/common/components/button/button.component';
import { useStyles } from './lead-upload-wizard.style';
import { getDisplayDate } from 'src/app/common/utils/time-utils';
import moment from 'moment';
import { AgencyCampaignTypeStatusEnum } from 'src/app/modules/AgencyCampaign/constants';
import { read, utils } from 'xlsx';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { validateLead } from 'src/app/modules/AgencyCampaign/network/campaignCurd';
import { isEmpty } from 'lodash';

export type ImportItemType = {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  euResident: string;
  countryCode: string;
};

export interface LeadUploadWizardHookProps {
  campaignTypeStructureData?: CampaignStructureItem;
  campaign: any;
  formDispatch: (data: any) => void;
  formState: any;
  sectionKey: string;
  acmAddLeadSetting:
    | AddLeadSettingItem
    | {
        selfAddEnabled: true;
        needACMApproved: false;
        startDateSetting: {};
        endDateSetting: {
          operator: OperatorEnum.GTE;
          dependsOn: string;
          value: number;
        };
        startDate: string;
        endDate: string;
      };
  onLeadUploadList: (data: any) => void;
  onLeadFileUpload: (data: any) => void;
  onLeadUploadError: (data: any) => void;
}

export const useLeadUploadWizard = ({
  acmAddLeadSetting,
  campaign,
  onLeadFileUpload,
  onLeadUploadError,
  onLeadUploadList,
}: LeadUploadWizardHookProps) => {
  // i18n
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => {
    return intl.formatMessage({ id });
  };
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const { t } = useTranslation();
  const [importFile, setImportFile] = useState<File>();
  const [importData, setImportData] = useState<any[]>();
  const [invalidField, setInvalidField] = useState<Array<{ reason: string; index: number }>>([]);
  const [duplicatedRows, setDuplicatedRows] = useState<number[]>([]);
  const [currPage, setCurrPage] = useState(0);
  const [currRowsPerPage, setCurrRowsPerPage] = useState(20);
  const [loading, setLoading] = useState(false);

  // redux
  const dispatch = useDispatch();

  // todo: hard to jest
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setLoading(true);
      if (e.target.files[0].type.indexOf('csv') < 0) {
        uploadError(Translation('agencyCampaign.lead.fileNotSupported'));
        onLeadUploadError(LeadUploadErrorEnum.FILE_NOT_SUPPORTED);
        return;
      }

      let reader = new FileReader();
      reader.onload = async (e) => {
        let csvData = e.target?.result;
        const workBook = read(csvData, { type: 'binary' });
        const workSheetName = workBook.SheetNames[0];
        const workSheet = workBook.Sheets[workSheetName];
        const data = utils.sheet_to_json(workSheet, { raw: false });
        if (data.length > 300) {
          uploadError(Translation('agencyCampaign.lead.maxRecord'));
          onLeadUploadError(LeadUploadErrorEnum.MAX_RECORDS);
          return;
        }
        let leadList: any[] = [];
        let arrInvalidField: Array<{ reason: string; index: number }> = [];
        if (hasDuplicateItem(data)) return;
        for (const [rowIndex, item] of data.entries()) {
          if (!item) {
            return;
          }
          // firstName	lastName	email	phoneNumber	euResident	pdpaConsent	countryCode	referredAgentCode	leadDate
          try {
            let { firstName, lastName, email, phoneNumber, euResident, countryCode, remarks } = item as any;
            let leadItem = {
              firstName: firstName?.trim(),
              lastName: lastName?.trim(),
              email: email?.trim(),
              mobile: isEmpty(phoneNumber) ? '' : String(phoneNumber)?.trim(),
              euResident: euResident?.toLocaleUpperCase()?.trim(),
              countryCode: isEmpty(countryCode) ? '' : countryCode?.trim(),
              remarks: remarks?.trim(),
            };
            const errMsg = validateField(leadItem);

            if (!errMsg && campaign) {
              const leadData: ValidateLead = {
                email: leadItem?.email,
                phoneNumber: leadItem?.mobile,
                campaignId: campaign?.campaignObjId,
                countryCode: leadItem?.countryCode,
                firstName: leadItem?.firstName,
                lastName: leadItem?.lastName,
              };

              try {
                const isDuplicateLead = await validateLead(leadData, dispatch);
                if (isDuplicateLead) {
                  let row = TranslationWithVariable('agencyCampaign.import.row', rowIndex + 1);
                  //arrInvalidField.push(row + Translation('common.duplicateLead'));
                  arrInvalidField.push({
                    reason: row + Translation('agencyCampaign.lead.duplicateLead'),
                    index: Number(rowIndex),
                  });
                }
              } catch (error) {
                console.log(error);
              }
            }

            if (errMsg) {
              let row = TranslationWithVariable('agencyCampaign.import.row', rowIndex + 1);
              arrInvalidField.push({ reason: row + errMsg, index: Number(rowIndex) });
            }
            leadList.push(leadItem);
          } catch (err) {
            console.log(err);
          }

          if (rowIndex === data.length - 1) {
            if (leadList.length > 200) {
              uploadError(Translation('agencyCampaign.lead.maxRecord'));
              onLeadUploadError(LeadUploadErrorEnum.MAX_RECORDS);
              return;
            }

            if (leadList.length === 0) {
              uploadError(Translation('agencyCampaign.lead.noRecord'));
              onLeadUploadError(LeadUploadErrorEnum.NO_RECORDS);
              return;
            }
            if (arrInvalidField.length > 0) {
              onLeadUploadError(LeadUploadErrorEnum.INVALID_RECORDS);
              return setInvalidField(arrInvalidField);
            }
            setImportData(leadList);
            onLeadUploadList(leadList);
          }
        }

        if (leadList.length > 200) {
          uploadError(Translation('agencyCampaign.lead.maxRecord'));
          onLeadUploadError(LeadUploadErrorEnum.MAX_RECORDS);
          return;
        }

        if (leadList.length === 0) {
          uploadError(Translation('agencyCampaign.lead.noRecord'));
          onLeadUploadError(LeadUploadErrorEnum.NO_RECORDS);
          return;
        }
        if (arrInvalidField.length > 0) {
          onLeadUploadError(LeadUploadErrorEnum.INVALID_RECORDS);
          return setInvalidField(arrInvalidField);
        }
        setImportData(leadList);
        onLeadUploadList(leadList);
      };
      if (e.target.files) {
        reader.readAsBinaryString(e.target.files[0]);
        setImportFile(e.target.files[0]);
        onLeadFileUpload(e.target.files[0]);
      }
      setLoading(false);
    }
  };

  const removeFile = () => {
    setImportData(undefined);
    setImportFile(undefined);
    setInvalidField([]);
    onLeadFileUpload(undefined);
    onLeadUploadError(null);
  };

  const validateField = (rowData: ImportItemType): string => {
    let errMsg = '';
    const { firstName, lastName, email, mobile, euResident, countryCode } = rowData;

    if (isEmpty(firstName) || isEmpty(lastName)) {
      errMsg = Translation('common.emptyField');
      return errMsg;
    }
    // both Email and Mobile are empty
    if (isEmpty(email) && isEmpty(mobile)) {
      errMsg = Translation('common.EitherMobileOrPhoneField');
      return errMsg;
    } else {
      // one of email or phone is mandatory
      if (!isEmpty(email) && !isEmail(email)) {
        errMsg += Translation('common.invalidEmail');
      }
      const regPhone = /^\d{8}$/;
      if (!isEmpty(mobile) && !regPhone.test(mobile)) {
        errMsg += Translation('common.invalidPhone');
      }

      if (!isEmpty(mobile) && isEmpty(countryCode)) {
        errMsg += Translation('common.MobileAndCountryField');
      }

      if (!isEmpty(countryCode) && !isEmpty(mobile)) {
        const regCountryCode1 = /^\d{2,3}$/; // 2-3 digit
        const fixedCountryCode = '65_SGP';
        if (countryCode !== fixedCountryCode && !regCountryCode1.test(countryCode)) {
          errMsg += Translation('common.invalidCountryCode');
        }
      }
    }
    // accept: alphabet, space, /, -, @, (, )
    const regName = /^([a-z ]|\/|-|@|\(|\))+$/i;
    if (!regName.test(firstName) || !regName.test(lastName)) {
      errMsg += Translation('common.invalidName');
    }

    let arrBool = ['YES', 'NO'];
    if (!arrBool.includes(euResident)) {
      errMsg += Translation('common.EuResidentYesorNoField');
    }
    arrBool = ['YES'];
    if (arrBool.includes(euResident)) {
      errMsg += Translation('common.EuResidentYesField');
      // errMsg += intl.formatMessage({ id: 'common.invalidBoolField' }, { field: 'euResident' });
    }
    // if (leadDate && !moment(leadDate, 'DD/MM/YYYY', true).isValid()) {
    //   errMsg += Translation('common.invalidDate');
    // }
    return errMsg;
  };

  const uploadError = (content: string) => {
    removeFile();
    const title = Translation('common.uploadFail');
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: title,
          content: content,
        },
      ]),
    );
  };

  const hasDuplicateItem = (data: any[]) => {
    const mapKey = ['firstName', 'lastName', 'email', 'phoneNumber', 'countryCode', 'euResident'];
    let duplicateIndexList = data.reduce((acc: any[], item: any, index, self) => {
      if (index !== self.findIndex((t: any) => mapKey.every((key) => t[key] === item[key]))) {
        acc.push(index);
      }
      return acc;
    }, []);
    if (duplicateIndexList.length > 0) {
      setDuplicatedRows(duplicateIndexList);
      onLeadUploadError(LeadUploadErrorEnum.DUPLICATE_RECORDS);
      return true;
    } else {
      setDuplicatedRows([]);
      return false;
    }
  };

  const formatFileSize = (bytes: number) => {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;

    if (bytes < kb) {
      return `${bytes} B`;
    } else if (bytes < mb) {
      return `${(bytes / kb).toFixed(2)} KB`;
    } else if (bytes < gb) {
      return `${(bytes / mb).toFixed(2)} MB`;
    } else {
      return `${(bytes / gb).toFixed(2)} GB`;
    }
  };

  const _renderLeadTable = () => {
    let errorState: Array<{ reason: string; index: number }> = [];
    if (invalidField.length > 0 || duplicatedRows.length > 0) {
      const dulicateRowsReason = duplicatedRows.map((i) => {
        return { reason: TranslationWithVariable('agencyCampaign.import.duplicateRow', i + 1), index: i };
      });
      let map: any = {};
      invalidField.forEach((item) => (map[item.index] = item));
      dulicateRowsReason.forEach((item) => (map[item.index] = item));
      errorState = Object.values(map);
    }

    if (errorState.length > 0) {
      return (
        <div className={classes.importFail}>
          <p className={classes.red}>*{Translation('agencyCampaign.import.failed')}</p>
          <ul className={classes.importFormat}>
            {errorState.map((item, index) => (
              <li key={item.index.toString()}>{item.reason}</li>
            ))}
          </ul>
        </div>
      );
    }
    return (
      <div style={{ marginTop: 24 }}>
        <PruTable
          hideListTitleRow={true}
          disableRefresh
          disableBulkSelect={true}
          operationDef={[]}
          onRefresh={() => {}}
          isLoading={false}
          dataSource={importData?.slice(
            currPage * currRowsPerPage,
            (currPage + 1) * currRowsPerPage > importData.length ? undefined : (currPage + 1) * currRowsPerPage,
          )}
          totalPages={Math.ceil((importData?.length || 1) / currRowsPerPage)}
          totalRecords={importData?.length}
          hideBulkSelectHeader={true}
          columnDef={[
            { keyIndex: 'firstName', align: 'center', displayName: 'First Name', renderData: (row) => row?.firstName },
            { keyIndex: 'lastName', align: 'center', displayName: 'Last Name', renderData: (row) => row?.lastName },
            { keyIndex: 'email', align: 'center', displayName: 'Email', renderData: (row) => row?.email },
            { keyIndex: 'mobile', align: 'center', displayName: 'Phone Number', renderData: (row) => row?.mobile },
            {
              keyIndex: 'euResident',
              align: 'center',
              displayName: 'EU Resident',
              renderData: (row) => row?.euResident,
            },
            {
              keyIndex: 'countryCode',
              align: 'center',
              displayName: 'Country Code',
              renderData: (row) => row?.countryCode,
            },
            { keyIndex: 'remarks', align: 'center', displayName: 'Remarks', renderData: (row) => row?.remarks },
          ]}
          onChangePage={(page, rowPerPage) => {
            // todo
            setCurrPage(page - 1);
            setCurrRowsPerPage(rowPerPage);
          }}
        />
      </div>
    );
  };

  const _renderUploadedFileDetail = () => {
    return (
      <>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            background: '#FFFFFF',
            padding: 24,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '30px',
              justifyContent: 'space-between',
            }}
          >
            <span style={{ fontSize: 14, fontWeight: 500, lineHeight: '20px', color: '#666666' }}>
              {Translation('agencyCampaign.lead.upload.fileName')}:
              <span style={{ fontWeight: 700, color: '#333333' }}> {importFile?.name}</span>
            </span>

            <div>
              <Button variant="outlined" color="primary" onClick={removeFile}>
                {Translation('app.button.delete')}
              </Button>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 12 }}>
            <span style={{ fontSize: 14, fontWeight: 500, lineHeight: '20px', color: '#666666' }}>
              {Translation('agencyCampaign.lead.upload.fileSize')}:
              <span style={{ fontWeight: 700, color: '#333333' }}> {formatFileSize(importFile?.size as number)}</span>
            </span>
          </div>
          <div style={{ marginTop: 12 }}>
            <span style={{ fontSize: 14, fontWeight: 500, lineHeight: '20px', color: '#666666' }}>
              {Translation('agencyCampaign.lead.upload.updateTime')}:
              <span style={{ fontWeight: 700, color: '#333333' }}>
                {` `}
                {importFile?.lastModified
                  ? getDisplayDate(new Date(importFile?.lastModified), 'DD-MM-YYYY HH:mm')
                  : getDisplayDate(new Date(), 'DD-MM-YYYY HH:mm')}
              </span>
            </span>
          </div>
          {_renderLeadTable()}
        </div>
      </>
    );
  };

  const getTranslations = (params?: any) => {
    return {
      leads_template_file: Translation('leads_template_file'),
      self_create_lead_is_not_available_for_this_campaign: Translation(
        'self_create_lead_is_not_available_for_this_campaign',
      ),
      campaign_should_be_approved_to_start_the_section: Translation('campaign_should_be_approved_to_start_the_section'),
      time_range_to_add_lead: TranslationWithParams('time_range_to_add_lead', params),
      campaign_has_been_cancelled: Translation('campaign_has_been_cancelled'),
    };
  };

  const nowDate = new Date();
  const btnDisabled =
    !campaign.campaignId ||
    importFile !== undefined ||
    [AgencyCampaignTypeStatusEnum.CANCELED + '', AgencyCampaignTypeStatusEnum.DRAFT + ''].includes(
      campaign.campaignStatus,
    ) ||
    !(
      moment(acmAddLeadSetting?.startDate).isBefore(moment(nowDate)) &&
      moment(acmAddLeadSetting?.endDate).isAfter(moment(nowDate)) &&
      (!acmAddLeadSetting?.needACMApproved ||
        [
          AgencyCampaignTypeStatusEnum.ACTIVE + '',
          AgencyCampaignTypeStatusEnum.COMPLETED + '',
          AgencyCampaignTypeStatusEnum.APPROVED + '',
        ].includes(campaign.campaignStatus))
    );

  return {
    acmAddLeadSetting,
    btnDisabled,
    importFile,
    loading,
    t,
    handleFile,
    getTranslations,
    _renderUploadedFileDetail,
    formatFileSize,
    hasDuplicateItem,
    validateField,
    setInvalidField,
    setDuplicatedRows,
  };
};
