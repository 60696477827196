import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const useTranslation = () => {
  const intl = useIntl();
  const t = useCallback((key: string, values?: Record<string, any>) => intl.formatMessage({ id: key }, values), [intl]);

  return {
    t,
  };
};
