import { get } from 'lodash';
import { APP_CONFIG, getAppConfigs } from 'src/app/common/utils';

class ProspectValidate {
  fakeProspect: any;
  constructor() {
    this.fakeProspect = {
      prospectCategory: 'category',
      firstName: 'firstName',
      lastName: 'lastName',
      regionCode: '+852',
      phoneNumber: '52111222',
      email: 'jerry@pru.com',
      referredBy: 'referredBy',
    };
  }
  /**
   *
   * @param dto object we want to verify the mandatory
   * @param fieldOrRule rule for validate the mandatory
   * @returns true or false, true means passed the rule
   */
  verifyMandatoryRule(dto: any, rule?: any) {
    const fieldOrRule = rule ?? get(getAppConfigs(APP_CONFIG.SALES_COMMON), 'Sales.prospect.mandatoryRule', []);
    // If fieldOrRule is a string, directly verify that the field exists in the dto
    if (typeof fieldOrRule === 'string') {
      // If there is a value, the verification is passed
      const value = get(dto, fieldOrRule);
      return !!value || value === false || value === 0;
    }
    if (Array.isArray(fieldOrRule)) {
      for (let index = 0; index < fieldOrRule.length; index++) {
        const _fieldOrRule = fieldOrRule[index];
        if (!this.verifyMandatoryRule(dto, _fieldOrRule)) {
          return false;
        }
      }
      return true;
    }
    // Otherwise the data format must be {$or: [...]} or {$and: [...]}
    if (fieldOrRule.or && Array.isArray(fieldOrRule.or)) {
      for (let index = 0; index < fieldOrRule.or.length; index++) {
        const _fieldOrRule = fieldOrRule.or[index];
        if (this.verifyMandatoryRule(dto, _fieldOrRule)) {
          return true;
        }
      }
      return false;
    }

    if (fieldOrRule.and && Array.isArray(fieldOrRule.and)) {
      for (let index = 0; index < fieldOrRule.and.length; index++) {
        const _fieldOrRule = fieldOrRule.and[index];
        if (!this.verifyMandatoryRule(dto, _fieldOrRule)) {
          return false;
        }
      }
      return true;
    }
  }

  getRequiredFields() {
    const requiredFields = {
      firstName: !this.verifyMandatoryRule({ ...this.fakeProspect, firstName: undefined }),
      lastName: !this.verifyMandatoryRule({ ...this.fakeProspect, lastName: undefined }),
      regionCode: !this.verifyMandatoryRule({ ...this.fakeProspect, phoneNumber: undefined }),
      phoneNumber: !this.verifyMandatoryRule({ ...this.fakeProspect, phoneNumber: undefined }),
      email: !this.verifyMandatoryRule({ ...this.fakeProspect, email: undefined }),
      referredBy: !this.verifyMandatoryRule({ ...this.fakeProspect, referredBy: undefined }),
    };
    return requiredFields;
  }
}
export const prospectValidator = new ProspectValidate();
