import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  tableContainer: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: 18,
  },
  templateContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 33,
    marginTop: 33,
  },
  templateLabel: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    marginRight: 24,
  },
  templateLink: {
    color: 'rgba(67, 134, 229, 1)',
  },
  uploadBtn: {
    width: '100%',
    height: 240,
    backgroundColor: 'rgba(240, 240, 240, 1)',
  },
  uploadBtnTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadBtnTitle: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '30px',
  },
  uploadBtnSubtitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: '#666666',
    marginTop: 8,
  },
  browser: {
    color: 'rgba(235, 58, 52, 1)',
  },
  importFormat: {
    border: '1px solid #ccc',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    padding: 24,
    borderRadius: 8,
    marginTop: 24,
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 72,
    minHeight: '318px',
  },
  mt_24: {
    marginTop: 24,
  },
  uploadBtnContainer: {
    paddingLeft: 24,
    paddingBottom: 35,
    paddingRight: 24,
  },
  importFailTitle: {
    color: '#E8192C',
    paddingLeft: 24,
    fontWeight: 500,
    fontSize: 16,
  },
  importFailItem: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  importBtn: {
    flex: 1,
    paddingTop: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 24,
  },
  uploadFileDetailContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    background: '#FFFFFF',
    padding: 24,
  },
  uploadFileDetailButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '30px',
    justifyContent: 'space-between',
  },
  uploadFileDetailTitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: '#666666',
  },
  uploadFileDetailContent: {
    fontWeight: 700,
    color: '#333333',
  },
  uploadFileSize: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
  },
  mt_12: {
    marginTop: 12,
  },
}));
