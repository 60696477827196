import React, { memo, useMemo } from 'react';
import { FormLabel } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './list-filter-date-radio-group.style';
import { ListFilterRadioGroupComponent } from '../list-filter-radio-group/list-filter-radio-group.component';
import { dateType, getConfigs, getDateConfigsByType } from './util/list-filter-date-radio.util';
import { SimpleDateRangePickerComponent } from '../../../simple-date-range-picker/simple-date-range-picker.component';
import { ListFilterConfig } from '../../list-filter.component';

export type DateRadioGroupType = {
  key: string;
  value: { label: string; startDate: string; endDate: string };
};

type ComponentProps = ParamsProps & {
  input?: DateRadioGroupType;
  rangeType?: dateType;
  fieldTitle: string;
  onChange?: (value: DateRadioGroupType) => void;
};

export const ListFilterDateRadioGroupComponent: React.FC<ComponentProps> = memo(
  ({ input, rangeType, fieldTitle, onChange }: ComponentProps) => {
    // config
    const itemConfigs = !rangeType ? getConfigs() : getDateConfigsByType(rangeType);

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    const fieldValue = input || { key: '', value: { label: '', startDate: '', endDate: '' } };

    const configs: Array<{ itemKey: string; itemLabel: string }> = itemConfigs.map(
      ({ key: itemKey, title: itemLabel }) => {
        return { itemKey, itemLabel };
      },
    );

    return (
      <div className={styles.mt_24}>
        <FormLabel classes={{ root: styles.formLabel }}>{fieldTitle || ''}</FormLabel>
        <ListFilterRadioGroupComponent
          value={fieldValue.key}
          itemsConfig={configs}
          onChange={(selectedKey: string) => {
            const itemDateConfig = itemConfigs.find((item) => item.key === selectedKey)?.value() || {
              startDate: '',
              endDate: '',
            };
            onChange?.({
              ...fieldValue,
              key: selectedKey,
              value: {
                ...itemDateConfig,
                label: itemConfigs.find((item) => item.key === selectedKey)?.title || '',
              },
            });
          }}
        />
        <div className={`${styles.fullWidth} ${styles.mt_8} ${styles.ml_8}`}>
          {fieldValue.key === dateType.customize ? (
            <SimpleDateRangePickerComponent
              value={fieldValue.value}
              onChange={(dateValue) => {
                onChange?.({
                  ...fieldValue,
                  value: {
                    ...fieldValue.value,
                    ...dateValue,
                  },
                });
              }}
            />
          ) : null}
        </div>
      </div>
    );
  },
);

export type RadioGroupType = string;

export type RadioGroupProps = ParamsProps & {
  value: RadioGroupType;
  filterConfig: ListFilterConfig;
  onChange?: (value: RadioGroupType) => void;
};

export const RadioGroupComponent: React.FC<RadioGroupProps> = memo(
  ({ value, filterConfig, onChange }: RadioGroupProps) => {
    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    return (
      <div className={styles.mt_24}>
        <FormLabel classes={{ root: styles.formLabel }}>{filterConfig.title}</FormLabel>
        <ListFilterRadioGroupComponent value={value} itemsConfig={filterConfig.items} onChange={onChange} />
      </div>
    );
  },
);
