import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './tab-lead.style';
import { useTabLead } from './tab-lead.hook';
import { TabLeadFilterComponent } from './tab-lead-filter/tab-lead-filter.component';
import { AddLeadSettingItem, CampaignStructureItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { CampaignLeadFilterTableComponent } from '../campaign-lead-filter-table/campaign-lead-filter-table.component';
import { FullScreenDialog } from 'src/app/common/components/Dialog/full-screen-dialog/full-screen-dialog.component';
import { LeadUploadWizardComponent } from './lead-upload-wizard/lead-upload-wizard.component';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { OperatorEnum } from 'src/app/modules/AgencyCampaign/types/common-types';
import { OperationContext } from 'src/app/modules/Leads/components/lead-list-item/lead-operation-units/util/operation-context.util';

type ComponentProps = ParamsProps & {
  campaignTypeStructureData?: CampaignStructureItem;
  campaign: any;
  campaignOwner: string;
  formDispatch: (data: any) => void;
  formState: any;
  cacheParams: React.MutableRefObject<Record<string, any> | undefined>;
  toggleUploadWizard: boolean;
  setToggleUploadWizard: React.Dispatch<React.SetStateAction<boolean>>;
  isACMApproved: boolean;
  isInSchedule: boolean;
  acmAddLeadSetting:
    | AddLeadSettingItem
    | {
        selfAddEnabled: true;
        needACMApproved: false;
        startDateSetting: {};
        endDateSetting: {
          operator: OperatorEnum.GTE;
          dependsOn: string;
          value: number;
        };
        startDate: string;
        endDate: string;
      };
};

export const TabLeadComponent: React.FC<ComponentProps> = memo(
  ({
    campaignTypeStructureData,
    campaign,
    cacheParams,
    formState,
    toggleUploadWizard,
    campaignOwner,
    acmAddLeadSetting,
    isACMApproved,
    isInSchedule,
    setToggleUploadWizard,
    formDispatch,
  }: ComponentProps) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style

    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    // custom-hook
    const {
      tableRef,
      confirmDisable,
      loading,
      setConfirmDisable,
      onImportLead,
      setLeadUploadError,
      setLeadUploadList,
      setLeadUploadFile,
      oppProviderValue,
    } = useTabLead({
      campaign,
      campaignTypeStructureData,
      formState,
      formDispatch,
      cacheParams,
      setToggleUploadWizard,
    });

    return (
      <div className={styles.mt_24}>
        <OperationContext.Provider value={oppProviderValue}>
          <CampaignLeadFilterTableComponent
            filter={TabLeadFilterComponent}
            campaignId={campaign.campaignObjId}
            agentCode={globalStore.getAgentCode()}
            cacheParams={cacheParams}
            campaignOwner={campaignOwner}
            tableRef={tableRef}
            acmAddLeadSetting={acmAddLeadSetting}
            isACMApproved={isACMApproved}
            isInSchedule={isInSchedule}
          />
        </OperationContext.Provider>
        <FullScreenDialog
          title={Translation('agencyCampaign.leadUploadWizard.title')}
          children={
            <LeadUploadWizardComponent
              campaignTypeStructureData={campaignTypeStructureData}
              campaign={campaign}
              formState={formState}
              formDispatch={formDispatch}
              sectionKey={'approval'}
              acmAddLeadSetting={acmAddLeadSetting}
              onLeadUploadList={(data) => {
                setLeadUploadList(data);
                setConfirmDisable(false);
              }}
              onLeadFileUpload={(data) => {
                setLeadUploadFile(data);
              }}
              onLeadUploadError={(data) => {
                setLeadUploadError(data);
                setConfirmDisable(true);
              }}
            />
          }
          open={toggleUploadWizard}
          onClose={() => {
            setToggleUploadWizard(false);
          }}
          onConfirm={async () => onImportLead()}
          disableConfirm={confirmDisable}
          loading={loading}
        />
      </div>
    );
  },
);
