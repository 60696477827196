import { Analytics, getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import {
  GAEventName,
  GAEventSource,
  GAFeature,
  GAModule,
  GAObjectType,
  GAScreenName,
  objectTypeShortFormMap,
} from './types/ga-parameter-type';
import {
  GAEventParameters,
  GAUserProperties,
  TakeUIScreenViewEventParameters,
  TakeUIClickEventParameters,
  GALoginEventParameters,
} from './types/ga-general-type';
import store from '../../../redux/store';
import { getScreenNameShortForm, getShortForm } from './utils';
import packageJson from '../../../../package.json';

export const ROLLOUT_VERSION = packageJson['rollout'];

export const takeLoginEvent = (agentCode: string, parameters: GALoginEventParameters) => {
  let analytics: Analytics;
  try {
    analytics = getAnalytics();
  } catch {
    return;
  }
  const gaData = store.getState().ga.data;

  const module = parameters.module || gaData?.module || undefined;
  const feature = parameters.feature || gaData?.feature || undefined;
  const screen_name = parameters.screen_name || gaData?.screen_name || undefined;
  const journey = parameters.journey || gaData?.journey || undefined;
  if (!module || !feature || !screen_name || !journey)
    throw new Error('Missing module or feature or screen_name or journey');

  const stage = parameters.stage;
  const screen_id = parameters.screen_id || getScreenId(module, feature, screen_name);

  const event_source = `PRUForce-AgentPortal-IN-${window.ReactNativeWebView ? 'webapp' : 'web'}` as GAEventSource;

  logEvent(analytics, 'login', {
    event_type: 'Backend',
    module,
    feature,
    journey,
    stage,
    screen_name,
    screen_id,

    method: parameters.method,
    status: parameters.status,

    agent_code: agentCode,
    event_source: event_source,
    created_at: new Date(),
  });
};

export const takeUIScreenViewEvent = (parameters: TakeUIScreenViewEventParameters) => {
  const module = parameters.module;
  const feature = parameters.feature;
  const screen_name = parameters.screen_name;

  const journey = parameters.journey;
  const stage = parameters.stage;
  const screen_id = parameters.screen_id || getScreenId(module, feature, screen_name);

  takeGAEvent('ui_screen_visit', {
    event_type: 'Screen',
    module,
    feature,
    journey,
    stage,
    screen_name,
    screen_id,
  });
};

export const takeUIClickEvent = (parameters: TakeUIClickEventParameters, eventName?: GAEventName) => {
  const gaData = store.getState().ga.data;

  const module = parameters.module || gaData?.module || undefined;
  const feature = parameters.feature || gaData?.feature || undefined;
  const screen_name = parameters.screen_name || gaData?.screen_name || undefined;
  const journey = parameters.journey || gaData?.journey || undefined;
  if (!module || !feature || !screen_name || !journey)
    throw new Error('Missing module or feature or screen_name or journey');

  const stage = parameters.stage;
  const screen_id = parameters.screen_id || getScreenId(module, feature, screen_name);
  const object_name = parameters.object_name;
  const object_type = parameters.object_type;
  const object_id =
    parameters.object_id ||
    getObjectId(module, feature, screen_name, { type: object_type, name: parameters.object_name });

  takeGAEvent(eventName ?? 'ui_click', {
    event_type: 'Click',
    module,
    feature,
    journey,
    stage,
    screen_name,
    screen_id,
    object_name,
    object_type,
    object_id,
    extraInfo: parameters.extraInfo,
  });
};

const takeGAEvent = (eventName: GAEventName, parameters: GAEventParameters) => {
  let analytics: Analytics;
  if (window.envConfig.REACT_APP_ENV === 'dev') {
    console.log(
      'Log GA: ',
      'eventName',
      eventName,
      JSON.stringify(
        {
          ...parameters,
          created_at: new Date(),
        },
        undefined,
        '  ',
      ),
    );
  }
  try {
    analytics = getAnalytics();
  } catch {
    return;
  }
  const agentCode = store.getState().auth.user?.sub;
  const event_source = `PRUForce-AgentPortal-IN-${window.ReactNativeWebView ? 'webapp' : 'web'}` as GAEventSource;
  const agent_code = agentCode || undefined;
  const extraInfo = parameters.extraInfo;
  delete parameters.extraInfo;
  logEvent(analytics, eventName as string, {
    ...parameters,
    ...(extraInfo || {}),
    agent_code,
    created_at: new Date(),
    event_source: event_source,
  });
};

const getScreenId = (module: GAModule, feature: GAFeature, screenName: GAScreenName) => {
  return `SRC${getShortForm(module)}${getShortForm(feature)}${getScreenNameShortForm(screenName)}`;
};

const getObjectId = (
  module: GAModule,
  feature: GAFeature,
  screenName: GAScreenName,
  object: {
    name: string;
    type: GAObjectType;
  },
) => {
  return `${objectTypeShortFormMap[object.type]}${getShortForm(module)}${getShortForm(feature)}${getScreenNameShortForm(
    screenName,
  )}${getShortForm(object.name)}`;
};
