import React, { useCallback, useMemo, useState } from 'react';
import { getAutoDropListColumns } from '../leads-list/util/list.util';
import { getDropList } from '../leads-list/components/lead-list-reminder/components/auto-drop-reminder/util/api.util';
import {
  leadAutoDropListGaDataClick,
  leadAutoDropListGaDataClick1,
  leadAutoDropListGaDataClick2,
  leadAutoDropListGaDataClick3,
} from 'src/app/common/ga/types/ga-click-lead';

export const useLeadAutoDropList = () => {
  const [reminderAutoDropLeadDay, setReminderAutoDropLeadDay] = useState<number>();

  const autoDropListColumns = useMemo(() => () => getAutoDropListColumns({}), []);

  const getData = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
      dispatch?: React.Dispatch<any>,
    ) => {
      const res = await getDropList(params, sort, filter, dispatch);
      if (res) {
        setReminderAutoDropLeadDay(res.reminderAutoDropLeadDay);
      }
      return res;
    },
    [],
  );

  const oppProviderValue = useMemo(
    () => ({
      gaData: {
        accept: leadAutoDropListGaDataClick2,
        reject: leadAutoDropListGaDataClick1,
        update: leadAutoDropListGaDataClick,
        newApp: leadAutoDropListGaDataClick3,
      },
    }),
    [],
  );

  return { autoDropListColumns, reminderAutoDropLeadDay, getData, oppProviderValue };
};
