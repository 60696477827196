import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ComponentProps, Option } from './cascading-selector.component';
import { debounce, set, throttle } from 'lodash';

function searchChild(data: any, searchLabel: string) {
  function searchNode(node: any) {
    if (node.label.toLowerCase().includes(searchLabel.toLowerCase())) {
      return { ...node };
    }

    if (node.child) {
      const filteredChildren = node.child.map(searchNode).filter((child: any) => child !== undefined);

      if (filteredChildren.length > 0) {
        return { ...node, child: filteredChildren };
      }
    }

    return undefined;
  }

  const result = data.map(searchNode).filter((node: any) => node !== undefined);
  return result;
}

const getExactMatch = (data: Option[]) => {
  const selectedOption = [];
  let isOnlyOne = false;
  const getChild = (data: Option) => {
    if (data.child && data.child.length === 1) {
      const option = data.child[0];
      selectedOption.push(option);
      getChild(option);
      isOnlyOne = true;
    }
  };

  if (data.length === 1) {
    const option = data[0];
    selectedOption.push(option);
    getChild(option);
  } else {
    isOnlyOne = false;
  }
  if (isOnlyOne) {
    return selectedOption;
  }
  return null;
};

export const useCascadingSelector = (props: ComponentProps) => {
  const { onSelectComplete, data, splitChar, defaultSelected, closeLevel, getChildData } = props;
  const [sourceData, setSourceData] = useState(data);
  const [mainAnchorEl, setMainAnchorEl] = useState(null);
  const mainAnchorElRef = React.useRef(null);
  const [selectedItem, setSelectedItem] = useState<Array<Option> | null>(null);
  const [currentSelected, setCurrentSelected] = useState<Array<Option> | null>(null);
  const [showInput, setShowInput] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [clickedItem, setClickedItem] = useState<any[]>([]);

  const mainOpen = Boolean(mainAnchorEl);

  const displayText = useMemo(() => {
    if (currentSelected && currentSelected.length > 0) {
      return currentSelected.map((item) => item.label).join(splitChar || ' ');
    }
    return '';
  }, [currentSelected]);

  const showDisplayText = useMemo(() => {
    return Boolean(displayText && !showInput);
  }, [displayText, showInput]);

  const resetStatus = () => {
    setSourceData(data);
    setSelectedItem(null);
    setMainAnchorEl(null);
  };

  const search = (value: string) => {
    const result = searchChild(data, value);
    setSelectedItem(null);
    if (result && result.length > 0) {
      const exactMatch = getExactMatch(result);
      setSourceData(result);
      setMainAnchorEl(mainAnchorElRef.current);
      if (exactMatch) {
        setSelectedItem(exactMatch);
      }
    } else {
      resetStatus();
    }
  };

  const handleMainClick = (event: any) => {
    setMainAnchorEl(event.currentTarget);
    if (!inputValue || inputValue === '') {
      const formatedData: Option[] = [];
      let parentData: Option | undefined = undefined;
      currentSelected?.map((item, index) => {
        if (parentData) {
          if (item.child) {
            parentData = parentData.child?.find((dataItem) => dataItem.value === item.value);
            parentData && formatedData.push(parentData);
          } else {
            formatedData.push(item);
            return;
          }
        } else {
          parentData = data.find((dataItem) => dataItem.value === item.value);
          parentData && formatedData.push(parentData);
        }
      });
      setSelectedItem(formatedData);
      setSourceData(data);
    } else {
      search(inputValue);
    }
  };

  const handleMainClose = () => {
    setMainAnchorEl(null);
  };

  const handleListItemClick = async (item: Option, level = 1) => {
    setClickedItem([item.value, level]);
    const _item = { ...item };
    let data: Option[] = selectedItem || [];
    if (!_item.child && getChildData && closeLevel && level < closeLevel) {
      const childData = await getChildData(_item);
      if (childData && childData.length > 0) _item['child'] = childData;
    }
    if (level === 1) {
      data = [_item];
    } else if (data && data.length >= level) {
      const newData = data.slice(0, level - 1);
      data = [...newData, _item];
    } else {
      data = [...(data || []), _item];
    }
    setSelectedItem(data);
    if (!_item.child) {
      setCurrentSelected(data);
      setShowInput(false);
      setInputValue('');
      onSelectComplete && onSelectComplete(data);
      handleMainClose();
    }
  };

  const onClickDisplayText = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowInput(true);
    inputRef.current?.focus();
  };

  const onInputBlur = () => {
    if (!inputValue) {
      setShowInput(false);
      setSourceData(data);
      setSelectedItem(currentSelected);
    } else {
      // setSelectedItem(null);
    }
  };

  const onInputChangedCallback = useCallback(
    debounce((value: string) => {
      setInputValue(value);
      if (value) {
        search(value);
      } else {
        resetStatus();
      }
    }, 300),
    [data],
  );

  const onInputChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setInputValue(value);
    },
    [data],
  );

  useEffect(() => {
    onInputChangedCallback(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (defaultSelected) {
      setCurrentSelected(defaultSelected);
      setShowInput(false);
      setInputValue('');
    }
  }, [defaultSelected]);

  return {
    sourceData,
    showInput,
    mainOpen,
    mainAnchorEl,
    selectedItem,
    displayText,
    inputRef,
    mainAnchorElRef,
    showDisplayText,
    handleMainClick,
    handleMainClose,
    handleListItemClick,
    onClickDisplayText,
    onInputBlur,
    onInputChanged,
    inputValue,
    clickedItem,
    currentSelected,
  };
};
