export enum TopDashboardsProd {
  dashboardLeft = '63477184-91e0-45e4-89f6-b5c01006fa2d',
}

export const DashboardLibraryProd = {
  report: [
    {
      chartID: '63477184-91e0-45e4-89f6-b5c01006fa2d',
      chartType: 'Table',
    },
    {
      chartID: '63477184-91e0-423f-8823-b5c01006fa2b',
      chartType: 'Chart',
    },
    {
      chartID: '63477184-91e0-4708-8b49-b5c01006fa27',
      chartType: 'Chart',
    },
    {
      chartID: '63477184-91e0-4922-84c7-b5c01006fa23',
      chartType: 'Chart',
    },
    {
      chartID: '63477184-91e0-4725-8cf5-b5c01006fa1a',
      chartType: 'Chart',
    },
    {
      chartID: '63477184-91e0-43c3-87d0-b5c01006fa21',
      chartType: 'Chart',
    },
    {
      chartID: '63477184-91e0-4733-89db-b5c01006fa1d',
      chartType: 'Chart',
    },
    {
      chartID: '63477184-91e0-4668-8d9f-b5c01006fa25',
      chartType: 'Chart',
    },
    {
      chartID: '63477184-91e0-45a3-80cb-b5c01006fa29',
      chartType: 'Chart',
    },
  ],
};

export enum AlTopDashboardsProd {
  dashboardLeft = '645e11f7-5de9-4828-8b14-72f7cb3ac34b',
}

export const AlChartLibraryProd = {
  report: [
    {
      // al table
      chartID: '645e11f7-5de9-4828-8b14-72f7cb3ac34b',
      chartType: 'Table',
    },
    {
      //Overall risk
      chartID: '645e11f7-5de9-4576-829d-72f7cb3ac34d',
      chartType: 'Chart',
    },
    {
      // BSC Grade
      chartID: '645e11f7-5de9-491d-8c04-72f7cb3ac34f',
      chartType: 'Chart',
    },
    {
      // average pre paying rate
      chartID: '645e11f7-5de9-4ff3-82ad-72f7cb3ac351',
      chartType: 'Chart',
    },
    {
      //single premium presistency
      chartID: '645e11f7-5de9-4bbf-83c8-72f7cb3ac353',
      chartType: 'Chart',
    },
    {
      // Net ROP unsuccessful appeals(Comulative YTD)
      chartID: '645e11f7-5de9-4c0d-8ba0-72f7cb3ac355',
      chartType: 'Chart',
    },
    {
      // CFI own life
      chartID: '645e11f7-5de9-4353-8d8d-72f7cb3ac357',
      chartType: 'Chart',
    },
    {
      // Forfeiture
      chartID: '645e11f7-5de9-4b13-85f3-72f7cb3ac359',
      chartType: 'Chart',
    },
    {
      //Market conduct (Comulative YTD)
      chartID: '645e11f7-5de9-423d-8bf4-72f7cb3ac35b',
      chartType: 'Chart',
    },
    {
      //Market conduct (Comulative YTD)
      chartID: '645e11f7-5de9-4996-8815-72f7cb3ac35d',
      chartType: 'Chart',
    },
  ],
};
