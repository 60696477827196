import React, { memo } from 'react';
import { Box } from '@mui/material';
import { ProspectCategory } from '../../../types/prospect-types';
import {
  ProspectCategoryLabel,
  ProspectCategoryProps,
  SIZE_TYPE,
} from 'src/app/common/components/prospect-category/prospect-category.component';

type ComponentProps = {
  currValue: string;
  prospectCategories?: ProspectCategory[];
};

export const Category: React.FC<ComponentProps> = memo(({ prospectCategories, currValue }: ComponentProps) => {
  if (!prospectCategories) return null;
  const item = prospectCategories.find(({ _id }) => _id === currValue);
  if (!item) return null;
  const { type, colorLabel: color, backgroundColorLabel: backgroundColor } = item;
  return (
    <Box sx={{ display: 'inline-block', flex: 0 }}>
      <ProspectCategoryLabel data={{ color, backgroundColor, type } as ProspectCategoryProps} size={SIZE_TYPE.small} />
    </Box>
  );
});
