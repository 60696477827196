// import moment from 'moment';
import { Columns, TabValues } from './types/types';

export const LEADS_TITLE = 'menu.title.leads';

export const LEADS_NAMESPACE = 'LEADS';

export const LEADS_LIST_NAMESPACE = 'LEADS_LIST';

export const LEADS_REASSIGN_NAMESPACE = 'LEADS_REASSIGN_LIST';

export const LEADS_BASE_PATH = '/leads';
export const LEADS_LIST_PATH = `${LEADS_BASE_PATH}/list`;
export const REASSIGN_LEAD_PATH = `${LEADS_LIST_PATH}/reassign`;

export const LIST_DEFAULT_PARAMS = () => ({
  selectedTab: TabValues.opportunities,
  [TabValues.opportunities]: {
    sorter: { [Columns.updatedAt]: 'desc' },
  },
  [TabValues.followUp]: {
    sorter: { [Columns.updatedAt]: 'desc' },
  },
  [TabValues.closed]: {
    sorter: { [Columns.updatedAt]: 'desc' },
  },
});
export const LEADS_DETAIL_PATH = `${LEADS_LIST_PATH}/detail`;

export enum LEAD_DETAIL_TABS {
  INFORMATION,
  APPOINTMENT,
  STATUS_HISTORY,
}
