/* eslint-disable */
import React, { FC, memo, useMemo } from 'react';
import { StepIconProps, TextField } from '@mui/material';

import Check from '@mui/icons-material/Check'; // completed
import ClearIcon from '@mui/icons-material/Clear'; // dead
import TripOriginIcon from '@mui/icons-material/TripOrigin'; // not started
import { styled } from '@mui/material';
import { ProgressIcon } from 'src/app/common/components/svg-icon/svg-icon.component';

type ComponentProps = StepIconProps & { icon: string | undefined; isClosed: boolean };

/** stepper icons */
export const StepIconComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { active, className, icon, isClosed } = props;
  const iconClosed = isClosed ? 'icon-closed' : '';
  let iconToRender;

  switch (icon) {
    case 'active':
      iconToRender = <ProgressIcon className={`custom-icon-active icon-active ${iconClosed}`} />;
      break;
    case 'completed':
      iconToRender = <Check className={`custom-icon-active ${iconClosed}`} />;
      break;
    case 'dead':
      iconToRender = <ClearIcon className={`custom-icon-active ${iconClosed}`} />;
      break;
    default:
      // pending: not-started
      iconToRender = <TripOriginIcon className={`custom-icon-circle`} />;
  }

  return (
    <StepIconRoot ownerState={{ active }} className={className}>
      {iconToRender}
    </StepIconRoot>
  );
});

const StepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ ownerState }) => ({
  display: 'flex',
  height: 22,
  alignItems: 'center',
  '& .custom-icon-active': {
    color: '#fff',
    zIndex: 1,
    fontSize: 12,
    borderRadius: '50%',
    backgroundColor: '#38527E',
    width: 18,
    height: 18,
    padding: 2,
  },
  '& .icon-active': {
    backgroundColor: 'transparent',
    width: 24,
    height: 24,
  },
  '& .custom-icon-circle': {
    width: 16,
    height: 16,
    borderRadius: '50%',
    color: '#ccc',
    zIndex: 2,
    backgroundColor: '#fff',
  },
  '& .icon-closed': {
    backgroundColor: '#ccc',
  },
}));
