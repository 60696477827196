import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    display: 'flex',
    padding: '24px 0px',
    alignItems: 'center',
    flex: 1,
  },
  icon: {
    width: '24px',
    height: '24px',
    marginLeft: '16px',
    color: '#EB3B4C',
  },
  maleIcon: {
    width: '24px',
    height: '24px',
    marginLeft: '16px',
  },
  cakeIcon: {
    width: '24px',
    height: '24px',
    marginLeft: '16px',
    color: '#FF872E',
  },
  flex_row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  prospectBox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  sectionBox: {
    width: '100%',
    height: 164,
    padding: 24,
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
}));
