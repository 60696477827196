import React, { useState, PropsWithChildren } from 'react';
import { FilterDropdownParams } from '.';
import { PruTableHeader } from '../Table/PruTableHeader';
import { TableSortLabel, Popover } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
// import Dialog from '@mui/material/Dialog';

interface ProTableCellProps {
  direction?: 'asc' | 'desc';
  sorter?: boolean;
  handleSort?: (sorter: { [key: string]: 'asc' | 'desc' }) => void;
  dataIndex?: string;
  padding?: any;
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
  filterDropdown?: (params: FilterDropdownParams) => React.ReactNode;
  width?: string | number;
  minWidth?: string | number;
  maxWidth?: string | number;
}

const useStyles = makeStyles()((theme) => ({
  TabelCellLabel: {
    // color: `${theme.palette.common.white} !important`,
    color: '#666666 !important',
  },
  activeIcon: {
    // color: `${theme.palette.common.white} !important`,
    color: '#666666 !important',
    opacity: 1,
  },
  icon: {
    // color: `${theme.palette.common.white} !important`,
    color: '#CCCCCC !important',
    opacity: 1,
  },
}));

const AdvanceSort: React.FC<PropsWithChildren<{ title: React.ReactNode }>> = ({ title, children }) => {
  const [open, openDialog] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
    openDialog(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    openDialog(false);
  };

  return (
    <>
      {title}
      <SearchIcon onClick={handleClick} />
      {/* <Dialog open={open} onClose={() => openDialog(false)}>{ children }</Dialog> */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children}
      </Popover>
    </>
  );
};

const ProTableCell: React.FC<PropsWithChildren<ProTableCellProps>> = ({
  sorter,
  direction: defaultDirection,
  // eslint-disable-next-line
  handleSort = () => {},
  dataIndex,
  children,
  padding,
  align,
  filterDropdown,
  width,
  minWidth,
  maxWidth,
}) => {
  const { classes } = useStyles();
  const [direction, setDirection] = useState<'asc' | 'desc'>(defaultDirection ?? 'desc');

  const onSorter = (e: any) => {
    const newDirection: 'asc' | 'desc' = direction === 'asc' ? 'desc' : 'asc';
    setDirection(newDirection);
    const sorter = {
      [dataIndex!]: newDirection,
    };
    handleSort(sorter);
  };

  return (
    <PruTableHeader
      component="th"
      scope="row"
      sortDirection={sorter ? direction : false}
      // sortDirection={'asc'}
      padding={padding}
      align={align}
      // width={width}
      sx={{ minWidth, width, maxWidth }}
    >
      {sorter ? (
        <TableSortLabel
          active={!!defaultDirection}
          className={classes.TabelCellLabel}
          // active={true}
          // direction={'asc'}
          direction={direction || 'asc'}
          onClick={onSorter}
          classes={{
            icon: !defaultDirection ? classes.icon : classes.activeIcon,
          }}
        >
          {children}
        </TableSortLabel>
      ) : filterDropdown ? (
        <AdvanceSort title={children}>{filterDropdown({})}</AdvanceSort>
      ) : (
        children
      )}
    </PruTableHeader>
  );
};

export default ProTableCell;
