import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get, set } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { fetchProspectCategories, getProspectById } from '../../network/prospectCurd';
import { formateProsectCategory } from '../../util/common.util';
import { fetchProspectAppointments } from '../../network/appointmentCrud';
import {
  prospectDetailGaDataClick,
  prospectDetailGaDataClick1,
  prospectDetailGaDataClick2,
  prospectDetailGaDataClick3,
} from 'src/app/common/ga/types/ga-click-lead';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { prospectDetailsGaDataClick32, prospectDetailsGaDataClick47 } from 'src/app/common/ga/types/ga-click';

interface HookProps {
  id: string;
}

type ProspectInfomationTabState = {
  prospectInfoReadOnly: boolean;
  saving: boolean;
};

type Context = {
  prospectInfomationTabState: ProspectInfomationTabState;
  updateProspectInfoTabState: (state: ProspectInfomationTabState) => void;
  updateProspectDetail: (data: any) => void;
};

const initialContext: Context = {
  prospectInfomationTabState: {
    prospectInfoReadOnly: true,
    saving: false,
  },
  updateProspectInfoTabState: () => {},
  updateProspectDetail: () => {},
};

export const ProspectDetailContext = React.createContext<Context>(initialContext);

export const useProspectDetail = ({ id }: HookProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const dispatch = useDispatch();
  const locale = useLang();

  const [prospectDetail, setProspectDetail] = useState<any>();
  const [prospectInfoTabState, setProspectInfoTabState] = useState<ProspectInfomationTabState>({
    prospectInfoReadOnly: true,
    saving: false,
  });

  const [totalAppointments, setTotalAppointments] = useState<number>(0);
  const [totalLeads, setTotalLeads] = useState<number>(0);

  const informationTabRef = useRef<any>(null);
  const appointmentTabRef = useRef<any>(null);

  const {
    data: prospectDetailResponse,
    error,
    loading,
    run,
    runAsync,
  } = useRequest(() => getProspectById(id, dispatch), { manual: false });

  const prospectCategoriesData = useRequest(() => fetchProspectCategories(locale, dispatch), {
    manual: false,
  });

  useEffect(() => {
    if (prospectDetailResponse) {
      setProspectDetail(prospectDetailResponse);
    }
  }, [prospectDetailResponse]);

  const prospectCategories = useMemo(() => {
    return formateProsectCategory(prospectCategoriesData.data?.prospectCategories ?? [], locale, intl);
    // return prospectCategoriesData.data?.prospectCategories;
  }, [prospectCategoriesData.data]);

  const prospectCategory = useMemo(() => {
    return prospectCategories.find((item) => item.id === prospectDetail?.prospectCategory);
  }, [prospectCategories, prospectDetail]);

  //click edit button in prospect information tab
  const onClickEdit = useCallback(() => {
    takeUIClickEvent(prospectDetailsGaDataClick47 as any);
    setProspectInfoTabState((state) => ({
      ...state,
      prospectInfoReadOnly: !state.prospectInfoReadOnly,
      saving: false,
    }));
    if (!prospectInfoTabState.prospectInfoReadOnly) {
      informationTabRef.current && informationTabRef.current.resetForm();
    }
  }, [informationTabRef.current]);

  const onSubmitProspectInfo = () => {
    if (prospectInfoTabState.saving) return;
    informationTabRef.current && informationTabRef.current.submitForm();
  };

  const updateProspectInfoTabState = (state: ProspectInfomationTabState) => {
    setProspectInfoTabState(state);
  };

  const updateProspectDetail = (data: any) => {
    setProspectDetail({ ...prospectDetail, ...data });
  };

  const onClickAddAppointment = () => {
    takeUIClickEvent(prospectDetailsGaDataClick32 as any);
    appointmentTabRef.current && appointmentTabRef.current.addAppointment();
  };

  const totalAppointmentsLabel = useMemo(() => {
    if (totalAppointments > 0) {
      return `${Translation('appointment')} ${totalAppointments > 99 ? '99+' : totalAppointments.toString()}`;
    }
    return Translation('appointment');
  }, [totalAppointments]);

  const leadLabel = useMemo(() => {
    if (totalLeads > 0) {
      return `${Translation('lead')} ${totalLeads > 99 ? '99+' : totalLeads.toString()}`;
    }
    return Translation('lead');
  }, [totalLeads]);

  const onAppointmentLoaded = (total: number) => {
    setTotalAppointments(total);
  };

  useEffect(() => {
    fetchProspectAppointments(id, dispatch).then((res) => {
      if (res) {
        setTotalAppointments(res?.length ?? 0);
      }
    });
  }, []);

  const onLoad = useCallback(
    (
      record: any[],
      pageInfo?: {
        page?: number;
        pageSize?: number;
        total?: number;
      },
    ) => {
      setTotalLeads(pageInfo?.total ?? 0);
    },
    [],
  );
  const oppProviderValue = useMemo(
    () => ({
      gaData: {
        accept: prospectDetailGaDataClick,
        reject: prospectDetailGaDataClick1,
        update: prospectDetailGaDataClick2,
        newApp: prospectDetailGaDataClick3,
      },
    }),
    [],
  );

  return {
    prospectDetail,
    prospectCategory,
    prospectInfoTabState,
    informationTabRef,
    appointmentTabRef,
    totalAppointmentsLabel,
    onClickEdit,
    onSubmitProspectInfo,
    onClickAddAppointment,
    updateProspectInfoTabState,
    updateProspectDetail,
    onAppointmentLoaded,
    onLoad,
    leadLabel,
    oppProviderValue,
  };
};
