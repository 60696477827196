import { makeStyles } from 'tss-react/mui';
import * as React from 'react';
import { chartOptions, pieChartType } from '../../types/chart-types';
import Chart from './components/Chart';
import { DashboardDetailPage } from './components/DashboardDetailPage';
import { barProgressOption, pieOption as pieLocalOption, reverseNum } from '../../constants';
import { useIntl } from 'react-intl';
import moment from 'moment';

type DashboardPreViewPageProps = {
  options: chartOptions;
  isExport: boolean;
};

export const DashboardPreViewPage: React.FC<DashboardPreViewPageProps> = (props) => {
  //tab bar
  const { classes } = styles();

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { PUBLIC_URL } = process.env;
  const [barPercent, setBarPercent] = React.useState<Array<Record<string, string>>>([{}, {}, {}]);

  const getInitWholeOption = () => {
    let pieOption = props.options.pieOption;

    let pieWholeOption = [];

    for (var j = 0; j < pieOption.length; j++) {
      let tmpOption = JSON.parse(JSON.stringify(pieLocalOption));
      tmpOption.title[0].text =
        j === 0 ? Translation('summary.dashboard.pie.totalYear') : Translation('summary.dashboard.pie.total');
      tmpOption.title[1].text =
        j === 2 ? reverseNum(pieOption[j].total ?? 0) : '$' + reverseNum(pieOption[j].total ?? 0);
      tmpOption.title[2].text =
        j === 2
          ? (pieOption[j].total ?? 0) > 1
            ? Translation('summary.dashboard.pie.chart.policies')
            : Translation('summary.dashboard.pie.chart.policy')
          : 'HKD';

      let colors = [];
      type nameType = keyof typeof barProgressOption;
      for (var i = 0; i < pieOption[j].data.length; i++) {
        const tmpName = pieOption[j].data[i].name.split(' ');
        tmpName[0] = tmpName[0].toLocaleLowerCase();
        colors.push(barProgressOption[tmpName.join('') as nameType]?.splitLineColor[0]);

        let transName = Translation(
          'summary.dashboard.page.category.' + pieOption[j].data[i].name.toLocaleLowerCase().split(' ').join(''),
        );
        if (transName !== pieOption[j].data[i].name) {
          pieOption[j].data[i].name = transName;
        }
      }
      tmpOption.series[0].color = colors;
      tmpOption.series[0].data = pieOption[j].data;
      const index = j;
      tmpOption.series[0].label.formatter = function (data: any) {
        let barArray: Array<Record<string, string>> = barPercent;
        let obj: Record<string, string> = barPercent[index];
        obj[data.name] = data.percent.toFixed(1);
        barArray[index] = obj;
        setBarPercent(barArray);
        var name = data.name.split(' ');
        name[1] = name[1] ? '\n' + name[1] : '';
        return data.percent.toFixed(1) + '%\n' + name[0] + name[1];
      };
      pieWholeOption.push(tmpOption);
    }

    return pieWholeOption;
  };

  const [pieWholeOption, setPieWholeOption] = React.useState<pieChartType[]>(getInitWholeOption);

  console.log(pieWholeOption);

  return (
    <div className={classes.pageView}>
      <div
        className="last-export-time"
        style={{
          zIndex: 99,
          position: 'relative',
          padding: 16,
          paddingBottom: 0,
          display: 'flex',
          flexDirection: 'row-reverse',
          color: '#858585',
        }}
      >
        {Translation('summary.dashboard.exported.lastUpdated')}{' '}
        {moment(props.options.lastUpdated).format('DD/MM/YYYY HH:mm')}
      </div>
      <div
        className="shadow-circle-top"
        style={{
          zIndex: 1,
          position: 'absolute',
          height: 72,
          width: window.innerWidth * 0.32,
          top: 0,
          right: '8%',
          backgroundColor: 'rgba(242,242,242,0.87)',
          borderRadius: 63,
        }}
      />
      <div
        className="card"
        style={{
          margin: 16,
          padding: 16,
          display: 'flex',
          flexDirection: 'row',
          boxShadow: '0px 5px 10px rgb(0 0 0 / 10%)',
        }}
      >
        <div className="name" style={{ flex: 'auto' }}>
          <div className="user-name" style={{ fontSize: 16, fontWeight: 600, color: '#000000' }}>
            {props.options.customerName}
          </div>
          <div className="agent-name" style={{ color: '#999999', fontSize: 12 }}>
            {intl.locale === 'en'
              ? Translation('summary.dashboard.exported.prepared') +
                ' ' +
                Translation('summary.dashboard.exported.by') +
                ' ' +
                props.options.agentName
              : Translation('summary.dashboard.exported.by') +
                ' ' +
                props.options.agentName +
                ' ' +
                Translation('summary.dashboard.exported.prepared')}
          </div>
        </div>
        <img
          src={PUBLIC_URL + '/media/logos/favicon.png'}
          alt=""
          width={84}
          style={{ zIndex: 99, height: '100%', alignSelf: 'center' }}
        />
      </div>
      <div
        className="summary-dashboard-title"
        style={
          props.isExport
            ? {
                paddingInline: 30,
                paddingBottom: 16,
                fontSize: 16,
                fontWeight: 600,
              }
            : {
                paddingInline: 16,
                paddingBottom: 16,
                fontSize: 16,
                fontWeight: 600,
              }
        }
      >
        {Translation('title.summary.dashboard')}
      </div>
      <div style={{ backgroundColor: '#FFFFFF' }}>
        <div className="bar-page-premium">
          <div className="title" style={{ padding: 20, display: 'flex', flexDirection: 'row' }}>
            <div className={classes.policyTitlePoint} />
            <div style={{ fontSize: 16, fontWeight: 600, marginLeft: 12 }}>
              {Translation('summary.dashboard.page.tab.premium')}
            </div>
          </div>
          <Chart
            options={pieWholeOption[0]}
            style={props.isExport ? { height: 400, width: 600, display: 'table', margin: 'auto' } : { height: 300 }}
            isExport={props.isExport}
          ></Chart>
          <DashboardDetailPage
            barCharts={props.options.tabData[0]}
            percentage={barPercent[0]}
            type={true}
            isExport={props.isExport}
          ></DashboardDetailPage>
        </div>
        {!props.isExport && (
          <div
            style={{
              height: 8,
              backgroundColor: '#f4f4f4',
            }}
          ></div>
        )}
        <div className="pageBreak"></div>
        <div className="bar-page-sumAnsured">
          <div className="title" style={{ padding: 20, display: 'flex', flexDirection: 'row' }}>
            <div className={classes.policyTitlePoint} />
            <div style={{ fontSize: 16, fontWeight: 600, marginLeft: 12 }}>
              {Translation('summary.dashboard.page.tab.sumInsured')}
            </div>
          </div>
          <Chart
            options={pieWholeOption[1]}
            style={props.isExport ? { height: 400, width: 600, display: 'table', margin: 'auto' } : { height: 300 }}
            isExport={props.isExport}
          ></Chart>
          <DashboardDetailPage
            barCharts={props.options.tabData[1]}
            percentage={barPercent[1]}
            type={true}
            isExport={props.isExport}
          ></DashboardDetailPage>
        </div>
        {!props.isExport && (
          <div
            style={{
              height: 8,
              backgroundColor: '#f4f4f4',
            }}
          ></div>
        )}
        <div className="pageBreak"></div>
        <div className="bar-page-quantity">
          <div className="title" style={{ padding: 20, display: 'flex', flexDirection: 'row' }}>
            <div className={classes.policyTitlePoint} />
            <div style={{ fontSize: 16, fontWeight: 600, marginLeft: 12 }}>
              {Translation('summary.dashboard.page.tab.quantity')}
            </div>
          </div>
          <Chart
            options={pieWholeOption[2]}
            style={props.isExport ? { height: 400, width: 600, display: 'table', margin: 'auto' } : { height: 300 }}
            isExport={props.isExport}
          ></Chart>
          <DashboardDetailPage
            barCharts={props.options.tabData[2]}
            percentage={barPercent[2]}
            type={false}
            isExport={props.isExport}
          ></DashboardDetailPage>
        </div>
      </div>
    </div>
  );
};

const styles = makeStyles()(() => ({
  pageView: {
    backgroundColor: '#F4F4F4',
  },
  tabView: {
    margin: 16,
  },
  tabsContainor: {
    fontWeight: 600,
    backgroundColor: '#F5F5F5',
    borderRadius: 10,
    padding: 3,
    color: '#999999',
  },
  tabItem: {
    flex: 1,
  },
  policyTitlePoint: {
    width: 4,
    height: 20,
    backgroundColor: '#E1001F',
    alignSelf: 'center',
  },
}));
