import { getDayEnd, getDayStart } from 'src/app/common/utils';
import {
  getCommingBirthdayReminders,
  getProspectsByIds,
} from 'src/app/modules/ai-talkbot/network/talkbot-export-leads-crud';
import { fetchMarketingCampaignList, fetchServicingCampaignList } from 'src/app/modules/Campaign/network/campaignCurd';
import { CampaignStatusEnum } from 'src/app/modules/Campaign/types/campaign-types';
import { generateCompareFunction } from 'src/app/modules/Campaign/util/campaign-list.util';

enum ProspectSortKeyMap {
  displayName = 'content.clientName',
  birthDate = 'bd',
}

const formatCampaignData = (
  res: any,
  params: {
    page: number;
    pageSize: number;
    current?: number | undefined;
  },
  sort?: { [key: string]: any },
) => {
  if (res) {
    let data = res.campaigns;
    if (sort) {
      const compareFunction = generateCompareFunction(sort);
      data.sort(compareFunction);
    }
    data = data.slice(params.page * params.pageSize, (params.page + 1) * params.pageSize);
    return {
      success: true,
      data: data,
      total: res.totalDocs,
    };
  } else {
    return {
      success: false,
      data: [],
      total: 0,
    };
  }
};

export const getEligibleMarketingCampaignList = async (
  params: {
    page: number;
    pageSize: number;
    current?: number | undefined;
    flowId: number;
  },
  sort: { [key: string]: any },
): Promise<any> => {
  const today = new Date().toISOString();
  const query = {
    flowId: params.flowId,
    status: CampaignStatusEnum.active,
    startDateTo: today,
    endDateFrom: today,
  };
  const res = await fetchMarketingCampaignList(query);
  return formatCampaignData(res, params, sort);
};

export const getEligibleServicingCampaignList = async (
  params: {
    page: number;
    pageSize: number;
    current?: number | undefined;
    flowId: number;
  },
  sort?: { [key: string]: any },
  dispatch?: any,
): Promise<any> => {
  const today = new Date().toISOString();
  const query = {
    flowId: params.flowId,
    status: CampaignStatusEnum.active,
    startDateTo: today,
    endDateFrom: today,
  };
  const res = await fetchServicingCampaignList(query, dispatch);
  return formatCampaignData(res, params, sort);
};

export const getTalkbotProspect = async (
  dispatch: React.Dispatch<any>,
  params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
  sort?: { [key: string]: any },
): Promise<any> => {
  const { pageSize, page } = params;
  const sortItemArr = sort ? Object.entries(sort)?.[0] : [];
  const now = new Date();
  const result = await getCommingBirthdayReminders({
    startDate: getDayStart(now).toISOString(),
    endDate: getDayEnd(now).toISOString(),
    pageNum: page + 1,
    pageSize,
    sortItem: sortItemArr?.[0]
      ? ProspectSortKeyMap[sortItemArr[0] as keyof typeof ProspectSortKeyMap]
      : sortItemArr?.[0],
    sortOrder: sortItemArr?.[1]?.toLocaleUpperCase(),
  });

  if (result) {
    const ids = result.values
      ?.filter((item: any) => item.content.prospectId)
      ?.map((item: any) => item.content.prospectId);
    let newValues = result.values ?? [];
    if (ids?.length > 0) {
      // get prospects to fill in result values
      const res = await getProspectsByIds({ ids }, dispatch);
      newValues = result.values.map((item: any) => {
        const curProspect = res?.find((prospect: any) => prospect._id === item.content.prospectId);
        return { ...item, prospect: { ...curProspect } };
      });
    }
    return {
      success: true,
      data: newValues,
      total: result.total,
    };
  }
  return {
    success: false,
    data: [],
    total: 0,
  };
};
