import { AppConfigs } from '../../types/app-configs';
import { configManager } from '../../utils/app-configs.util';
import { apiErrorHandler } from '../apiErrorHandler';
import apiClient from '../axios';
import { Dispatch } from 'react';
import { CORE_URL } from 'src/app/common/network';

export type AppConfigData = AppConfigs;

export const fetchAppConfigs = async (
  accessToken: string,
  dispatch?: Dispatch<any>,
): Promise<AppConfigData[] | null> => {
  // const url = `${CORE_URL}/configs/global-system?group=pruforce.Global&access=public`;
  const url = `${CORE_URL}/configs/appConfigs?app=agentPortal`;
  return apiClient
    .get<{ data: AppConfigData[] }>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((result) => {
      const data = result.data.data;
      configManager.setConfigs(data);
      return data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
