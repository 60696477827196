import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getCampaigns } from '../../network/leadsCrud';

export const useSelectCampaign = () => {
  const dispatch = useDispatch();

  const fetchCampaigns = useCallback(
    async ({ page, keyWords: searchKey = '', size: limit }: { page: number; keyWords?: string; size: number }) => {
      const res = await getCampaigns({ searchKey, sortBy: 'name', sortOrder: 1, page, limit }, dispatch);
      if (res) {
        const { campaigns, totalDocs: total } = res;

        return {
          data:
            campaigns.map(({ campaignId: value, name: label, image, ...restData }) => ({
              label,
              value,
              url: image?.url,
              extraData: restData,
            })) ?? [],
          total,
        };
      }
      return {
        data: [],
        total: 0,
      };
    },
    [dispatch],
  );

  return { fetchCampaigns };
};
