import { ReactElement } from 'react';
import { createSlice } from '@reduxjs/toolkit';

export enum AlertType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export type AlertItem = {
  severity: AlertType;
  title?: string;
  content: string;
  action?: (onClose: () => void) => ReactElement;
};

export type CommonState = {
  alertState: AlertItem[];
  menuBadge?: { [k: string]: number };
};

const commonState: CommonState = {
  alertState: [],
  menuBadge: {},
};

type AddAlertAction = {
  payload: AlertItem[];
};

type DismissAlertAction = {
  payload: {
    index: number;
  };
};

export const commonSlice = createSlice({
  name: 'common',
  initialState: commonState,
  reducers: {
    authenticateFailed: (state: CommonState) => {
      return {
        ...state,
        alertState: [
          ...state.alertState,
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: 'Authenciation failed, please login again',
          },
        ],
      };
    },
    appendAlertItem: (state: CommonState, action: AddAlertAction) => {
      return {
        ...state,
        alertState: [...state.alertState, ...action.payload],
      };
    },
    dismissAlertItem: (state: CommonState, action: DismissAlertAction) => {
      const currentAlert = [...state.alertState];
      currentAlert.splice(action.payload.index, 1);
      return {
        ...state,
        alertState: currentAlert,
      };
    },
    updateMenuBadge: (state: CommonState, action: { payload: { [k: string]: number } }) => {
      return {
        ...state,
        menuBadge: action.payload,
      };
    },
  },
});

export const { authenticateFailed, appendAlertItem, dismissAlertItem, updateMenuBadge } = commonSlice.actions;
