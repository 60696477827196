import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './list.style';
import { useList } from './list.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import ProFilterTable from 'src/app/common/components/ProTable';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components/pro-search-filter/pro-search-filter.component';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { massCampaignListGaDataClick, massCampaignListGaDataClick59 } from 'src/app/common/ga/types/ga-click';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const ListPage: React.FC<PageProps> = (props: PageProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { getData, columns, params, toDetail, onFilterChange } = useList({});

  return (
    <Page>
      <HeaderBreadCrumbs />
      {/* PACS Customization: PACSPFAP-3123 */}
      <PageTitle title={Translation('campaign.mass.title')} rightNode={null} />
      <ProFilterTable
        Filter={ProSearchFilter}
        filterPlaceholder={Translation('campaign.marketing.search.placeholder')}
        filterConfigs={[]}
        filterState={params}
        showFilterIcon={false}
        showFilter={true}
        rowKey="_id"
        columns={columns}
        request={getData}
        enableRefresh={false}
        showSelect={false}
        onClickRow={toDetail}
        onFilterChange={onFilterChange}
        onSort={() => {
          takeUIClickEvent(massCampaignListGaDataClick59 as any);
        }}
        onFocus={() => {
          takeUIClickEvent(massCampaignListGaDataClick as any);
        }}
      />
    </Page>
  );
};
