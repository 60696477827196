import React, { FC, memo, useMemo, useState } from 'react';
import { CircularProgress, Grid, List, ListItemButton, ListItemText, Popover, TextField } from '@mui/material';
import { ChevronRight, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useStyles } from './cascading-selector.style';
import { useCascadingSelector } from './cascading-selector.hook';
import { get } from 'lodash';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

export type Option = {
  label: string;
  value: string;
  type?: string;
  child?: Option[];
};

export type ComponentProps = {
  data: Option[];
  placeholder?: string;
  onSelectComplete?: (selected: Option[]) => void;
  splitChar?: string;
  defaultSelected?: Option[];
  closeLevel?: number; // only close dropdown when click-item-level >= closeLevel
  showLoadingChild?: boolean; // if need to display CircularProgress on loading child data
  getChildData?: (selected: Option) => Promise<Option[]>;
  renderNode?: (node: Option) => React.ReactNode;
  renderDisplayText?: (selected: Option[]) => string;
};

export const CascadingSelector = memo((props: ComponentProps) => {
  const {
    sourceData,
    mainOpen,
    mainAnchorEl,
    selectedItem,
    displayText,
    inputRef,
    showDisplayText,
    handleMainClick,
    handleMainClose,
    handleListItemClick,
    onClickDisplayText,
    onInputBlur,
    onInputChanged,
    mainAnchorElRef,
    inputValue,
    clickedItem,
    currentSelected,
  } = useCascadingSelector(props);

  const { renderNode, renderDisplayText } = props;

  const { classes, cx } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const { t } = useTranslation();

  return (
    <div>
      <div aria-describedby="main-popover" ref={mainAnchorElRef} onClick={handleMainClick} className={styles.container}>
        {showDisplayText ? (
          <div onClick={onClickDisplayText} className={styles.displayText}>
            {renderDisplayText && currentSelected ? renderDisplayText(currentSelected) : displayText}
          </div>
        ) : null}
        <TextField
          value={inputValue}
          inputRef={inputRef}
          placeholder={props.placeholder || t('app.button.search')}
          id="outlined-basic"
          variant="outlined"
          onClick={(event) => event.stopPropagation()}
          className={cx(styles.input, { [styles.hideInput]: showDisplayText })}
          onBlur={onInputBlur}
          onChange={onInputChanged}
          autoComplete="off"
          inputProps={{ style: { padding: '0px' } }}
        />
        {mainOpen ? <ExpandLess /> : <ExpandMore />}
      </div>
      <Popover
        id="main-popover"
        open={mainOpen}
        anchorEl={mainAnchorEl}
        onClose={handleMainClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={styles.popover}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <Grid container>
          <Grid item className={styles.parentGrid}>
            <List className={styles.list}>
              {sourceData &&
                sourceData.map((item) => {
                  const isSelectedItem = get(selectedItem, '[0].value') === item.value;
                  const className = `${styles.selectItem} ${isSelectedItem ? styles.active : ''}`;
                  const [value, level] = clickedItem;
                  const isClickedItem = value === item.value && level === 1;
                  return (
                    <ListItemButton onClick={() => handleListItemClick(item)} key={item.value} className={className}>
                      <span className={styles.selectContent}>
                        <ListItemText primary={item.label} />
                        {props.showLoadingChild && isClickedItem && <CircularProgress size={14} color="secondary" />}
                        <ChevronRight />
                      </span>
                    </ListItemButton>
                  );
                })}
            </List>
          </Grid>
          <>
            {selectedItem &&
              selectedItem.map((subItem: Option, index) => {
                const level = index + 1 + 1;
                if (subItem.child) {
                  return (
                    <Grid key={subItem.value} item className={styles.parentGrid}>
                      <List className={styles.list}>
                        {subItem &&
                          subItem.child.map((child: Option) => {
                            const isSelectedChild = get(selectedItem, `[${level - 1}].value`) === child.value;
                            const className = `${styles.selectItem} ${isSelectedChild ? styles.active : ''}`;
                            return (
                              <ListItemButton
                                key={child.value}
                                onClick={() => handleListItemClick(child, level)}
                                className={className}
                              >
                                <span className={styles.selectContent}>
                                  {renderNode ? renderNode(child) : <ListItemText primary={child.label} />}
                                  {child.child && <ChevronRight />}
                                </span>
                              </ListItemButton>
                            );
                          })}
                      </List>
                    </Grid>
                  );
                }
              })}
          </>
        </Grid>
      </Popover>
    </div>
  );
});
