import React, { FC, useRef } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import { AI_TALKBOT_BASE_PATH, AI_TALKBOT_EXPORT_HISTORY_PATH, DETAIL_PATH } from '../../constants';
import { ListPage } from './list/list.screen';
import { DetailPage } from './detail/detail.screen';

const ExportHistoryRoutes: FC<RouteComponentProps> = (props) => {
  const cacheParams = useRef();
  return (
    <Switch>
      <PruRoute exact path={`${AI_TALKBOT_BASE_PATH}${AI_TALKBOT_EXPORT_HISTORY_PATH}`} component={ListPage} />
      <PruRoute
        exact
        path={`${AI_TALKBOT_BASE_PATH}${AI_TALKBOT_EXPORT_HISTORY_PATH}${DETAIL_PATH}/:date`}
        component={DetailPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default ExportHistoryRoutes;
