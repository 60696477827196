import React, { useState, Dispatch, SetStateAction } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button } from '@mui/material';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgentAuthInfo, OtpVerificationMethodEnum } from './Login';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    textAlign: 'center',
    flexDirection: 'column',
    display: 'flex',
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: 'center',
  },
  circle: {
    display: 'inline-block',
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    textAlign: 'center',
    lineHeight: '24px',
    marginRight: theme.spacing(1),
  },
  btnSubmit: {
    height: 46,
    flex: 1,
  },
  btnResend: {
    height: 46,
    flex: 1,
    marginLeft: 10,
  },
  otpContent1: {
    fontWeight: 600,
    color: '#333333',
    fontSize: '1.5rem',
    marginTop: 20,
  },
  otpContent2: {
    fontSize: '1.1rem',
    display: 'flex',
    justifyContent: 'center',
  },
  actionWrap: {
    display: 'flex',
    justifyContent: 'center',
  },
  switchOtpBtn: {
    color: '#4386E5'
  }
}));

interface DialogOtpProps {
  displayOtp: boolean;
  otpError: boolean;
  otpCode: string;
  onOtpChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRetryOtp: () => void;
  count: number;
  resendText: string;
  btnDisabled: boolean;
  onSubmitOtp: () => void;
  handleOnCloseDialog: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason: string) => void;
  agentAuthInfo: AgentAuthInfo;
  formik: any;
  otpVerificationMethod: OtpVerificationMethodEnum;
  setOtpVerificationMethod: Dispatch<SetStateAction<OtpVerificationMethodEnum>>;
  isStaffLoginAccount: boolean;
}

const getTitleText = (otpVerificationMethod: OtpVerificationMethodEnum, agentAuthInfo: AgentAuthInfo) => {
  if (otpVerificationMethod === OtpVerificationMethodEnum.EMAIL) {
    const maskedText = agentAuthInfo?.email;
    return `Enter OTP sent to your email ${maskedText}`;
  }
  const maskedText = agentAuthInfo?.mobileNumber;
  return `Enter OTP sent to your mobile  ${maskedText}`;
};

const DialogOtp: React.FC<DialogOtpProps> = ({
  displayOtp,
  otpError,
  otpCode,
  onOtpChange,
  onRetryOtp,
  count,
  resendText,
  btnDisabled,
  onSubmitOtp,
  handleOnCloseDialog,
  agentAuthInfo,
  formik,
  otpVerificationMethod,
  setOtpVerificationMethod,
  isStaffLoginAccount,
}) => {
  const classes = useStyles();

  const handleClickOtpMethodChange = () => {
    if (otpVerificationMethod === OtpVerificationMethodEnum.EMAIL) {
      setOtpVerificationMethod(OtpVerificationMethodEnum.SMS);
    } else if (otpVerificationMethod === OtpVerificationMethodEnum.SMS) {
      setOtpVerificationMethod(OtpVerificationMethodEnum.EMAIL);
    }
    formik.submitForm();
  };

  return (
    <Dialog open={displayOtp} onClose={handleOnCloseDialog} disableEscapeKeyDown maxWidth="sm" fullWidth>
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {getTitleText(isStaffLoginAccount ? OtpVerificationMethodEnum.SMS : otpVerificationMethod, agentAuthInfo)}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={`${classes.otpContent1} ${classes.otpContent2}`}>Enter OTP:</DialogContentText>
        <div>
          <TextField
            id="outlined-basic"
            variant="outlined"
            color="secondary"
            type="tel"
            error={otpError}
            helperText={otpError ? 'Incorrect OTP' : ''}
            value={otpCode}
            onChange={onOtpChange}
            size="small"
          />
        </div>
        <br />
        <div>
          Did not receive OTP?
          <Button
            variant="contained"
            size="small"
            color="secondary"
            className={classes.btnResend}
            onClick={onRetryOtp}
            disabled={count > 0}
          >
            {resendText}
          </Button>
        </div>
        {!isStaffLoginAccount ? (
          <div>
            <strong>Having trouble?</strong>
            <Button variant="text" size="small" className={classes.switchOtpBtn} onClick={handleClickOtpMethodChange}>
              Switch to {otpVerificationMethod === OtpVerificationMethodEnum.EMAIL ? 'SMS' : 'email'} OTP
            </Button>
          </div>
        ) : null}
      </DialogContent>
      <DialogActions className={classes.actionWrap}>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          className={classes.btnSubmit}
          disabled={btnDisabled}
          onClick={onSubmitOtp}
        >
          {`Continue`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogOtp;
