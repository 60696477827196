import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useContext } from 'react';
import { ModuleContext } from 'src/app/common/module/ModuleProvider';
import PruSwitch from 'src/app/PruSwitch';
import { useProjectSelector } from 'src/redux/store';
import { ASIDE_WIDTH, COLLAPSE_ASIDE_WIDTH, HEADER_HEIGHT, MOBILE_HEADER_HEIGHT, FOOTER_HEIGHT } from '../../const';
import ContentFooter from './content-footer';
import ContentHeader from './content-header';
import ScrollTop from './components/scroll-top';
import { COLLAPSE_WIDTH } from '../../../ManagerDashboard';

export const ContentContainer = ({
  showDashboardSideMenu,
  showHeader,
}: {
  showDashboardSideMenu: boolean;
  showHeader: boolean;
}) => {
  const { classes } = useStyles();
  const { moduleMap } = useContext(ModuleContext);
  const isAsideMenuCollapse = useProjectSelector((state) => state.layout.isAsideMenuCollapse);
  const contentMargin = showDashboardSideMenu ? COLLAPSE_WIDTH : 0;
  const containerPadding = isAsideMenuCollapse ? COLLAPSE_ASIDE_WIDTH : ASIDE_WIDTH;

  return (
    <div
      className={`${classes.contentContainer} ${showHeader ? 'headerContentPadding' : 'noHeaderContentPadding'}`}
      style={{ paddingLeft: containerPadding }}
    >
      {showHeader && <ContentHeader />}
      <div
        className={showHeader ? (showDashboardSideMenu ? classes.dashboardContent : classes.content) : ''}
        style={{ marginLeft: contentMargin }}
      >
        <div className="tw-flex tw-flex-col tw-flex-1 tw-h-full tw-overflow-auto tw-items-center">
          <div className={classes.container}>
            <PruSwitch isRoot nodeList={moduleMap} />
          </div>
        </div>
      </div>
      {!showDashboardSideMenu && <ContentFooter />}
      <ScrollTop />
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  contentContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    background: '#FFFFFF',
    '&.headerContentPadding': {
      [theme.breakpoints.down('md')]: {
        paddingTop: `${MOBILE_HEADER_HEIGHT}px !important`,
        paddingLeft: '0 !important',
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: HEADER_HEIGHT,
        transition: theme.transitions.create('padding-left', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    '&.noHeaderContentPadding': {
      paddingLeft: '0 !important',
      paddingTop: '0 !important',
    },
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: '15px 0',
      paddingTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      height: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
      padding: '25px 0',
    },
  },
  container: {
    width: '100%',
    height: '100%',
    maxWidth: 1700,
  },
  dashboardContent: {
    padding: 0,
    flex: 1,
  },
}));
