import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { fetchClientList } from 'src/app/modules/Leads/network/leadsCrud';
import { languageConvertor } from 'src/app/common/utils/common-utils';
import { PolicyListSearchOptions } from 'src/app/modules/Leads/types/types';

interface HookProps {
  id: number;
  // other params
}

const defaultParam = {
  page: 1,
  pageSize: 20,
  limit: 5,
};

export const useClientSelect = ({ id }: HookProps) => {
  const locale = useLang();
  const { user } = useSelector((state: any) => state.auth);

  const getClients = useCallback(
    async ({ page, keyWords: searchKey = '', size: limit }: { page: number; keyWords?: string; size: number }) => {
      const params = {
        lang: languageConvertor(locale),
        agentCode: user.username,
        searchOpt: PolicyListSearchOptions.ALL,
        policyNo: undefined,
        clientCode: undefined,
        idNo: undefined,
        submissionDtFrom: undefined,
        submissionDtTo: undefined,
      };
      const result = await fetchClientList(params);
      const options = result
        ? result.map((item: any) => ({
            label: item.clientName,
            value: item.clientCode,
          }))
        : [];
      if (result) {
        return {
          data: options,
          total: 0,
        };
      }
      return {
        data: [],
        total: 0,
      };
    },
    [],
  );

  return {
    getClients,
  };
};
