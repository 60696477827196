import React, { memo, useMemo } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './lead-drop-date.style';
import { DateFormat, formatDate } from 'src/app/common/utils';

type ComponentProps = ParamsProps & {
  currValue: string;
};

export const LeadDropDateComponent: React.FC<ComponentProps> = memo(({ currValue }: ComponentProps) => {
  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  return <div className={styles.content}>{formatDate(currValue, DateFormat.date)}</div>;
});
