import { I18nGenericData } from 'src/app/i18n';

export enum DialogueKeyEnum {
  'careshield' = 'careshield',
  'savings-call' = 'savings-call',
  'retirement-call' = 'retirement-call',
  'general-policy-review-call' = 'general-policy-review-call',
  'annual-check-in-servicing-virtual-agent-mentioned' = 'annual-check-in-servicing-virtual-agent-mentioned',
  'annual-check-in-servicing-virtual-agent-not-mentioned' = 'annual-check-in-servicing-virtual-agent-not-mentioned',
  'birthday-call' = 'birthday-call',
}

export type DialogueConfigItemType = {
  dialogueKey: DialogueKeyEnum;
  flowId: number;
};

export enum TalkBotExportType {
  prospect = 'prospect',
  lead = 'lead',
}

export type DialogueItemType = {
  flowId: number;
  name: I18nGenericData<string>;
  description?: I18nGenericData<string>;
  isActive: boolean;
  image: I18nGenericData<{ blobId: string; url: string; filename: string }>;
  weight: number;
  _id: string;
  updatedBy?: string;
  updatedAt?: Date;
};

export type ExportLeadOverviewType = {
  pendingCount: number;
  contactCount: number;
  dropCount: number;
};
