import React, { memo } from 'react';
import { Box } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { ProspectTypeTag } from '../prospect-type-tag/prospect-type-tag.component';

type ComponentProps = ParamsProps & {
  currValue: string[];
};

export const ProspectType: React.FC<ComponentProps> = memo(({ currValue }: ComponentProps) => {
  return (
    <Box display="inline-block">
      {currValue
        ? currValue.map((v, index) => (
            <Box key={index} marginTop={index > 0 ? '4px' : '0px'}>
              <ProspectTypeTag status={v} />
            </Box>
          ))
        : ''}
    </Box>
  );
});
