import moment from 'moment';
import React, { Dispatch } from 'react';
import { RequestData } from 'src/app/common/components/ProTable';
import {
  getAgentSourceList,
  getCampaignList,
  getClosedList,
  getFollowUpList,
  getOpportunityList,
  getReassignLeadCount,
  getSourceList,
  patchFollowUp,
  patchOpportunities,
  patchOpportunity,
} from 'src/app/modules/Leads/network/leadsCrud';
import { AgentStatusEnum, FollowUpStatusEnum, Lead, TabValues } from 'src/app/modules/Leads/types/types';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { formatFollowRejectData } from './helper.util';
import { sortHelper } from 'src/app/common/utils/display.util';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { PermissionRole } from 'src/app/modules/Auth/types/auth-types';

export const getOpportunities = async (
  params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
  sort?: { [key: string]: any },
  filter?: { [key: string]: any },
  dispatch?: React.Dispatch<any>,
  talkbot?: { isTalkbotLead?: boolean; isExportToTalkbot?: boolean },
): Promise<RequestData<Lead>> => {
  const { page, pageSize: limit, keyword: displayName } = params;

  const formatParams: any = { page: page + 1, limit, displayName: displayName || undefined };

  if (sort) {
    const sortItem = Object.entries(sort)?.[0];
    if (sortItem) {
      formatParams.sortBy = sortItem[0];
      formatParams.sortOrder = sortItem[1] === 'asc' ? 1 : -1;
    }
  }

  if (!formatParams.sortOrder) {
    formatParams.sortBy = 'updatedAt';
    formatParams.sortOrder = -1;
  }

  if (filter) {
    const { dateRange, marketingLeadSource, campaign, typeOfLead, createDate, talkbotFlowIds } = filter;

    if (dateRange) {
      formatParams.receiveDateFrom = dateRange.startDate || moment('2000').toISOString();
      formatParams.receiveDateTo = dateRange.endDate || moment().toISOString();
    }

    // just for search cold opportunity
    if (createDate) {
      formatParams.createdAtLt = createDate;
    }

    if (marketingLeadSource) {
      formatParams.sourceList = marketingLeadSource;
    }

    if (campaign) {
      formatParams.campaignList = campaign;
    }

    if (typeOfLead) {
      formatParams.leadType = typeOfLead;
    }

    if (talkbotFlowIds) {
      formatParams.talkbotFlowIds = talkbotFlowIds;
    }
  }

  if (talkbot) {
    formatParams.isTalkbotLead = talkbot.isTalkbotLead;
    formatParams.isExportToTalkbot = talkbot.isExportToTalkbot;
  }

  const res = await getOpportunityList(formatParams, dispatch);
  const { sections = [], totalDocs, ...rest } = res ?? {};

  const section = sections.filter(({ type }) => type === 'new');

  if (res && section.length) {
    return {
      success: true,
      data: sections[0].leads ?? [],
      total: totalDocs,
      ...rest, // need isAcceptLeads in getTotalOpportunities
    };
  }

  return {
    success: false,
    data: [],
    total: 0,
  };
};

export const getTotalOpportunities = async (
  params?: { current?: number | undefined; keyword?: string | undefined },
  sort?: { [key: string]: any },
  filter?: { [key: string]: any },
  dispatch?: React.Dispatch<any>,
): Promise<{ total: number | undefined; isAcceptLeads: boolean } | undefined> => {
  const res = await getOpportunities({ ...params, page: 0, pageSize: 1 }, sort, filter, dispatch);

  if (res) {
    const { total, isAcceptLeads } = res;
    return { total, isAcceptLeads };
  }
};

export const getFollowUps = async (
  params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
  sort?: { [key: string]: any },
  filter?: { [key: string]: any },
  dispatch?: React.Dispatch<any>,
  talkbot?: { isTalkbotLead?: boolean; isExportToTalkbot?: boolean },
): Promise<RequestData<Lead>> => {
  const { page, pageSize: limit, keyword: displayName } = params;

  const formatParams: any = { page: page + 1, limit };

  if (displayName && displayName.match(/^\+?\d/)) {
    formatParams.phoneNumber = displayName;
  } else {
    formatParams.displayName = displayName || undefined;
  }

  if (sort) {
    const sortItem = Object.entries(sort)?.[0];
    if (sortItem) {
      formatParams.sortBy = sortItem[0];
      formatParams.sortOrder = sortItem[1] === 'asc' ? 1 : -1;
    }
  }

  if (filter) {
    const {
      dateRange,
      marketingLeadSource,
      campaign,
      typeOfLead,
      typeOfLeadSource,
      addLeadCreateDateDateRange,
      agingLead,
      status,
      agentLeadSource,
      talkbotFlowIds,
    } = filter;

    if (dateRange) {
      dateRange.startDate && (formatParams.updatedAtGreaterThenEqual = dateRange.startDate);
      dateRange.endDate && (formatParams.updatedAtLessThenEqual = dateRange.endDate);
    }

    if (addLeadCreateDateDateRange) {
      addLeadCreateDateDateRange.startDate && (formatParams.leadDateFrom = addLeadCreateDateDateRange.startDate);
      addLeadCreateDateDateRange.endDate && (formatParams.leadDateTo = addLeadCreateDateDateRange.endDate);
    }

    if (agingLead) {
      formatParams.isAligning = agingLead;
    }

    if (marketingLeadSource) {
      formatParams.sourceList = marketingLeadSource;
    }

    if (campaign) {
      formatParams.campaignList = campaign;
    }

    if (typeOfLead) {
      formatParams.leadLabelType = typeOfLead;
    }

    if (agentLeadSource) {
      formatParams.agentSourceList = agentLeadSource;
    }

    if (status) {
      formatParams.lastFollowUpStatus = status;
    }

    if (typeOfLeadSource) {
      formatParams.leadSourceType = typeOfLeadSource;
    }

    if (agingLead === 'active') {
      Object.assign(formatParams, { updatedAtGreaterThenEqual: moment().subtract(7, 'd').toISOString() });
    }

    if (talkbotFlowIds) {
      formatParams.talkbotFlowIds = talkbotFlowIds;
    }
  }

  if (talkbot) {
    formatParams.isTalkbotLead = talkbot.isTalkbotLead;
    formatParams.isExportToTalkbot = talkbot.isExportToTalkbot;
  }

  const res = await getFollowUpList(formatParams, dispatch);

  if (res) {
    const { leads, totalDocs, ...rest } = res;

    return {
      success: true,
      data: (leads ?? []).map(({ prospect, prospectMaster, ...rest }) => ({
        ...rest,
        prospect: prospect ?? prospectMaster,
      })),
      total: totalDocs,
      ...rest,
    };
  }

  return {
    success: false,
    data: [],
    total: 0,
  };
};

export const getTotalFollowUps = async (
  params: { current?: number | undefined; keyword?: string | undefined },
  sort?: { [key: string]: any },
  filter?: { [key: string]: any },
  dispatch?: React.Dispatch<any>,
  talkbot?: { isTalkbotLead?: boolean; isExportToTalkbot?: boolean },
): Promise<number | undefined> => {
  const res = await getFollowUps({ ...params, page: 0, pageSize: 1 }, sort, filter, dispatch, talkbot);

  if (res) {
    const { total } = res;
    return total;
  }
};

export const getClosed = async (
  params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
  sort?: { [key: string]: any },
  filter?: { [key: string]: any },
  dispatch?: React.Dispatch<any>,
): Promise<RequestData<Lead>> => {
  const { page, pageSize: limit, keyword: displayName } = params;

  const formatParams: any = { page: page + 1, limit };

  if (displayName && displayName.match(/^\+?\d/)) {
    formatParams.phoneNumber = displayName;
  } else {
    formatParams.displayName = displayName || undefined;
  }

  if (sort) {
    const sortItem = Object.entries(sort)?.[0];
    if (sortItem) {
      formatParams.sortBy = sortItem[0];
      formatParams.sortOrder = sortItem[1] === 'asc' ? 1 : -1;
    }
  }

  if (filter) {
    const { dateRange, marketingLeadSource, campaign, status, agentLeadSource } = filter;

    if (dateRange && dateRange.value) {
      dateRange.value.startDate && (formatParams.leadDateFrom = dateRange.value.startDate);
      dateRange.value.endDate && (formatParams.leadDateTo = dateRange.value.endDate);
    }

    if (marketingLeadSource) {
      formatParams.sourceList = marketingLeadSource;
    }

    if (campaign) {
      formatParams.campaignList = campaign;
    }

    if (agentLeadSource) {
      formatParams.agentSourceList = agentLeadSource;
    }

    if (status) {
      formatParams.lastFollowUpStatus = [status, status]; // or api will report error
    }
  }
  const res = await getClosedList(formatParams, dispatch);

  if (res) {
    const { leads, totalDocs, ...rest } = res;

    return {
      success: true,
      data: (leads ?? []).map(({ prospect, prospectMaster, ...rest }) => ({
        ...rest,
        prospect: prospect ?? prospectMaster,
      })),
      total: totalDocs,
      ...rest,
    };
  }

  return {
    success: false,
    data: [],
    total: 0,
  };
};

export const getTotalClosed = async (
  params: { current?: number | undefined; keyword?: string | undefined },
  sort?: { [key: string]: any },
  filter?: { [key: string]: any },
  dispatch?: React.Dispatch<any>,
): Promise<number | undefined> => {
  const res = await getClosed({ ...params, page: 0, pageSize: 1 }, sort, filter, dispatch);

  if (res) {
    const { total } = res;
    return total;
  }
};

export const getMarketSource = async (
  tab: TabValues,
  dispatch: React.Dispatch<any>,
): Promise<{ itemKey: string; itemLabel: string }[]> => {
  const res = await getSourceList({ tab }, dispatch);

  if (res) {
    const result = res.map(({ sourceName, _id }) => ({ itemKey: _id, itemLabel: sourceName }));
    result.sort((a, b) => sortHelper(a, b, 'itemLabel'));
    return result;
  }

  return [];
};

export const getCampaignsFilter = async (
  tab: TabValues,
  dispatch: React.Dispatch<any>,
  isTalkbotEligible?: boolean,
): Promise<{ itemKey: string; itemLabel: string }[]> => {
  const res = await getCampaignList({ tab, isTalkbotEligible }, dispatch);

  if (res) {
    const result = res.map(({ name, _id }) => ({ itemLabel: name, itemKey: _id }));
    result.sort((a, b) => sortHelper(a, b, 'itemLabel'));
    return result;
  }

  return [];
};

export const getAgentSourceFilter = async (
  dispatch: React.Dispatch<any>,
): Promise<{ itemKey: string; itemLabel: string }[]> => {
  const res = await getAgentSourceList(dispatch);

  if (res) {
    const result = res.map(({ sourceName, id }) => ({ itemLabel: sourceName, itemKey: id }));
    result.sort((a, b) => sortHelper(a, b, 'itemLabel'));
    return result;
  }

  return [];
};

export const accept = async (
  id: string,
  dispatch: Dispatch<any>,
  Translation: (val: string) => string,
): Promise<boolean> => {
  try {
    await patchOpportunity(id, { agentStatus: AgentStatusEnum.accepted, rejectedReason: null });
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: Translation('lead.accepted.successfully'),
          content: '',
        },
      ]),
    );
    return true;
  } catch (err) {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('app.button.submitError'),
          content: Translation('common.pleaseTryAgain'),
        },
      ]),
    );
    return false;
  }
};

export const batchAccept = async (
  ids: string[],
  dispatch: Dispatch<any>,
  Translation: (val: string) => string,
): Promise<boolean> => {
  try {
    await patchOpportunities({ ids, agentStatus: AgentStatusEnum.accepted, rejectedReason: null });
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: Translation('lead.accepted.successfully'),
          content: '',
        },
      ]),
    );
    return true;
  } catch (err) {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('app.button.submitError'),
          content: Translation('common.pleaseTryAgain'),
        },
      ]),
    );
    return false;
  }
};

export const rejectOpportunity = async (
  id: string,
  rejectedReason: string[],
  dispatch: Dispatch<any>,
  Translation: (val: string) => string,
): Promise<boolean> => {
  try {
    await patchOpportunity(id, {
      agentStatus: AgentStatusEnum.rejected,
      rejectedReason: rejectedReason.join(','),
    });
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: Translation('common.updateSuccessfully'),
          content: '',
        },
      ]),
    );
    return true;
  } catch (err) {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('app.button.submitError'),
          content: Translation('common.pleaseTryAgain'),
        },
      ]),
    );
    return false;
  }
};

export const rejectOpportunities = async (
  ids: string[],
  rejectedReason: string[],
  dispatch: Dispatch<any>,
  Translation: (val: string) => string,
): Promise<boolean> => {
  try {
    await patchOpportunities({
      ids,
      agentStatus: AgentStatusEnum.rejected,
      rejectedReason: rejectedReason.join(','),
    });
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: Translation('common.updateSuccessfully'),
          content: '',
        },
      ]),
    );
    return true;
  } catch (err) {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('app.button.submitError'),
          content: Translation('common.pleaseTryAgain'),
        },
      ]),
    );
    return false;
  }
};

export const rejectFollowUp = async (
  id: string,
  rejectedReason: string[],
  dispatch: Dispatch<any>,
  Translation: (val: string) => string,
): Promise<boolean> => {
  try {
    const { reason, reasons } = formatFollowRejectData(rejectedReason, Translation);
    await patchFollowUp(id, {
      status: FollowUpStatusEnum.dead,
      submissionDate: moment().format(),
      reason,
      reasons,
    });
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: Translation('common.updateSuccessfully'),
          content: '',
        },
      ]),
    );
    return true;
  } catch (err) {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('app.button.submitError'),
          content: Translation('common.pleaseTryAgain'),
        },
      ]),
    );
    return false;
  }
};

export const getContactedLeadsList = async (
  params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
  sort?: { [key: string]: any },
  filter?: { [key: string]: any },
  dispatch?: React.Dispatch<any>,
) => {
  return getFollowUps(params, sort, { ...filter, status: [''] }, dispatch);
};

export const getContactedLeadsCount = async (dispatch: React.Dispatch<any>) => {
  const result = await getContactedLeadsList({ page: 0, pageSize: 1 }, {}, {}, dispatch);
  return result?.total ?? 0;
};

export const getColdLeads = async (
  params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
  sort?: { [key: string]: any },
  filter?: { [key: string]: any },
  dispatch?: React.Dispatch<any>,
) => {
  return getOpportunities(
    params,
    sort,
    {
      ...filter,
      createDate: moment().subtract(14, 'days').toISOString(),
    },
    dispatch,
  );
};

export const getColdLeadsCount = async (dispatch: React.Dispatch<any>) => {
  const result = await getColdLeads({ page: 0, pageSize: 1 }, {}, {}, dispatch);
  return result?.total ?? 0;
};

export const getReassignLeadOverview = async (dispatch: React.Dispatch<any>) => {
  const role = globalStore.getAgent()?.role;
  if (role === PermissionRole.MANAGER) {
    const data = await getReassignLeadCount(dispatch);
    const totalCount = data.reduce((total: number, item: any) => {
      return total + item.count;
    }, 0);
    data.sort((a: any, b: any) => {
      return b.groupCode > a.groupCode ? -1 : 1;
    });
    return {
      reassignLeadsCount: totalCount as number,
      groupsString: data.map((item: any) => item.groupCode).join(',') as string,
    };
  } else {
    return {};
  }
};
