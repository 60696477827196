import React, { memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { CircularProgress, IconButton } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './attachment-uploader.style';
import { convertArrayToObject } from 'src/app/common/utils/common-utils';
import { campaignSectionFieldItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { Cancel } from '@mui/icons-material';
import { ExcelCsvIcon, FileIcon, PDFIcon } from '../tab-application/attachment/util/attachment-icon.util';
import { get as lodashGet } from 'lodash';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Button } from 'src/app/common/components/button/button.component';
import { useAttachmentUploader } from './attachment-uploader.hook';

type ComponentProps = ParamsProps & {
  fieldItem: campaignSectionFieldItem;
  onUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  removeFile: (fieldId: string, index: number) => void;
  viewFile: (url: string) => void;
  sectionKey: string;
  formState: any;
  errorState: any;
  disabledEdit?: boolean;
  disabled?: boolean;
  onSave?: (fieldKey: string) => void;
  headerNode?: React.ReactNode;
  isCampaignFilesUpdated?: { [section: string]: boolean };
};

export const AttachmentUploaderComponent: React.FC<ComponentProps> = memo(
  ({
    fieldItem,
    sectionKey,
    formState,
    errorState,
    disabled,
    disabledEdit,
    headerNode,
    isCampaignFilesUpdated,
    onUpload,
    removeFile,
    viewFile,
    onSave,
  }: ComponentProps) => {
    // i18n
    const intl = useIntl();
    const { t: Translation } = useTranslation();

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    // custom-hook
    const { uploading, ruleObject, source, hasChange, setUploading } = useAttachmentUploader({
      formState,
      fieldItem,
      sectionKey,
      isCampaignFilesUpdated,
    });

    return (
      <div className={classes.attachmentContainer} key={fieldItem.key}>
        <div className={classes.sectionTitleContainer}>
          {headerNode ?? (
            <div className={classes.attachmentTitle}>
              {Translation(`agencyCampaign.field.${fieldItem.key}`)}
              {ruleObject.Mandatory && ruleObject.Mandatory.value ? <span className={classes.mandatory}>*</span> : null}
            </div>
          )}
          {!(disabledEdit || disabled) && onSave && hasChange && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (errorState.mandatory[`${sectionKey}_${fieldItem.key}`]) return;
                onSave(fieldItem.key);
              }}
              key={fieldItem.key}
            >
              {Translation('agencyCampaign.common.save')}
            </Button>
          )}
        </div>
        <div className={classes.card}>
          {!disabledEdit && !disabled && (
            <div className={classes.uploadBtnContainer} key={fieldItem.title}>
              <div
                className={
                  errorState.mandatory[`${sectionKey}_${fieldItem.key}`] ? classes.uploadAreaError : classes.uploadArea
                }
              >
                <input
                  id={`${fieldItem.key}`}
                  hidden
                  type="file"
                  accept="audio/*,video/*,image/*,application/*, application/pdf,.pdf,application/csv, .csv, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onClick={(e) => {
                    const element = e.target as HTMLInputElement;
                    element.value = '';
                  }}
                  onChange={async (e) => {
                    setUploading(true);
                    await onUpload(e);
                    setUploading(false);
                  }}
                />
                <Button
                  className={classes.uploadBtn}
                  onClick={() => document.getElementById(`${fieldItem.key}`)!.click()}
                >
                  <div className={classes.uploadBtnTextContainer}>
                    <div className={classes.uploadBtnTitle}>
                      {uploading && <CircularProgress sx={{ marginLeft: '8px', color: 'black' }} size={14} />}{' '}
                      {Translation('agencyCampaign.upload.selectFiles')}
                    </div>
                    <div className={classes.uploadBtnSubtitle}>
                      {Translation('agencyCampaign.upload.selectFrom', {
                        child: <span className={classes.browser}>{Translation('agencyCampaign.upload.browse')}</span>,
                      })}
                    </div>
                    <div className={classes.uploadBtnFormat}>
                      {Translation('agencyCampaign.upload.supportTypes')}: png/jpg/jpeg/xlsx/docx/pdf
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          )}
          {source
            ? source.map((item: any, index: number) => {
                const fileNameSlices = item.blobName ? item.blobName.split('.') : null;
                const itemExtendName = fileNameSlices ? fileNameSlices[fileNameSlices.length - 1].toLowerCase() : '';
                let filePreview = null;
                if (item.mimeType) {
                  filePreview =
                    item.mimeType.indexOf('image') > -1 ? (
                      <div className={classes.previewContainer}>
                        {!disabledEdit && !disabled && (
                          <IconButton
                            className={classes.previewDelBtn}
                            onClick={() => removeFile(fieldItem.key, index)}
                          >
                            <Cancel />
                          </IconButton>
                        )}
                        <img
                          src={item.url}
                          alt={item.originalFilename ?? item.blobName}
                          className={classes.imgPreview}
                          onClick={() => viewFile(item.url)}
                        />
                      </div>
                    ) : (
                      <div className={classes.previewContainer}>
                        {!disabledEdit && !disabled && (
                          <IconButton
                            className={classes.previewDelBtn}
                            onClick={() => removeFile(fieldItem.key, index)}
                          >
                            <Cancel />
                          </IconButton>
                        )}
                        <Button onClick={() => viewFile(item.url)} sx={{ padding: 0 }}>
                          {itemExtendName === 'xlsx' ? <ExcelCsvIcon sx={{ width: 72, height: 72 }} /> : null}
                          {itemExtendName === 'pdf' ? <PDFIcon sx={{ width: 72, height: 72 }} /> : null}
                          {itemExtendName !== 'xlsx' && itemExtendName !== 'pdf' ? (
                            <FileIcon sx={{ width: 72, height: 72 }} />
                          ) : null}
                        </Button>
                      </div>
                    );
                }

                return (
                  <div className={classes.previewSpacing} key={index}>
                    {filePreview}
                  </div>
                );
              })
            : null}
        </div>
        {errorState.mandatory[`${sectionKey}_${fieldItem.key}`] && (
          <span className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</span>
        )}
      </div>
    );
  },
);
