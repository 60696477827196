import React, { FC, useRef } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
// import CampaignDetailPage from './Details/CampaignDetailPage';
import { GAData } from '../../../../common/ga/redux/gaSlice';
import { LeadsListPage } from './leads-list/leads-list.screen';
import {
  LEADS_BASE_PATH,
  LEADS_LIST_PATH,
  LEADS_DETAIL_PATH,
  LIST_DEFAULT_PARAMS,
  REASSIGN_LEAD_PATH,
} from '../../constants';
// import { DemoPage } from './demo/demo.screen';
import { TabValues } from '../../types/types';
import { LeadDetailPage } from './lead-detail/lead-detail.screen';
import { LeadAutoDropListPage } from './lead-auto-drop-list/lead-auto-drop-list.screen';
import { ReassignLeadPage } from '../reassign/reassign-lead/reassign-lead.screen';

const pRULeadsHomeGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'PRULeads Home',
  stage: 'PRULeads Home',
  screen_name: 'PRULeads Home',
};
const salesLeadDetailGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'View Sales Lead',
  stage: 'View Lead Detail',
  screen_name: 'Sales Lead Detail',
};
const reassignLeadGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Reassign Lead',
  stage: 'Select Lead',
  screen_name: 'Reassign Lead',
};

const LeadsListRoutes: FC<RouteComponentProps> = (props) => {
  const cacheParams = useRef({ ...LIST_DEFAULT_PARAMS() });

  return (
    <Switch>
      <Redirect exact from={`${LEADS_BASE_PATH}`} to={LEADS_LIST_PATH} />
      <PruRoute
        exact
        gaData={pRULeadsHomeGaDataRoute}
        path={LEADS_LIST_PATH}
        render={(props) => <LeadsListPage {...props} cacheParams={cacheParams} />}
      />
      <PruRoute
        exact
        gaData={salesLeadDetailGaDataRoute}
        path={`${LEADS_DETAIL_PATH}/:id`}
        render={(props) => <LeadDetailPage {...props} cacheParams={cacheParams} />}
      />
      {/* <PruRoute exact path={`${LEADS_BASE_PATH}/list/demo`} component={DemoPage} /> */}

      <PruRoute exact path={`${LEADS_BASE_PATH}/list/auto-drop`} component={LeadAutoDropListPage} />
      <PruRoute exact gaData={reassignLeadGaDataRoute} path={`${REASSIGN_LEAD_PATH}`} component={ReassignLeadPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default LeadsListRoutes;
