import React, { memo } from 'react';
import { RejectOpportunityButton } from '../components/reject-opportunity-button/reject-opportunity-button.component';
import { AcceptOpportunityButton } from '../components/accept-opportunity-button/accept-opportunity-button.component';
import { Lead } from 'src/app/modules/Leads/types/types';
import { TalkbotOperationType } from 'src/app/modules/Leads/util/table-columns-render.util';

type ComponentProps = {
  item: Lead;
  actions: (actions: ('clearRow' | 'refresh')[]) => void;
  operations?: TalkbotOperationType;
  rejectButton?: boolean; // PACS customization PACSPFAP-3691 | rejectButton should be visible for Prospect Tab
};

const getRejectConditionSatisfied = (item: any) => {
  /**
   * PACS customization PACSPFAP-2657
   */

  const labels = item?.labels;
  const potentialCustomer =labels?.indexOf('potentialCustomer') > -1 && labels?.indexOf('referralCode') == -1
  const massLeadExistingCustomer=labels?.indexOf('existingCustomer') > -1 && item?.fileUploadType === 'massLead';
  return  potentialCustomer || massLeadExistingCustomer;
};

export const LeadOpportunityOperation: React.FC<ComponentProps> = memo(
  ({ item, actions, operations, rejectButton }: ComponentProps) => {
    // PACS customization PACSPFAP-3691 | rejectButton should be visible for Prospect Tab
    const rejectShow = rejectButton ? rejectButton : getRejectConditionSatisfied(item)
    return (
      <>
       {rejectShow && <RejectOpportunityButton item={item} actions={actions} />}
        <AcceptOpportunityButton item={item} actions={actions} operations={operations} />
      </>
    );
  },
);
