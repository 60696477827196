import React, { useState } from 'react';

export const useParticipantEligibility = () => {
  const [processing, setProcessing] = useState(false);

  return {
    processing,
    setProcessing,
  };
};
