import { ComponentProps, ComponentType, useCallback, useMemo, useRef, useState } from 'react';
import { Box, CircularProgress, TextFieldProps } from '@mui/material';
import { SearchSelect, SearchSelectProps } from '../search-select/search-select.component';
import React from 'react';
import debounce from 'lodash/debounce';

interface Props {
  requestErrorInfo?: string;
  error?: boolean;
}

export type AsyncSearchSelectHookProps = Props & {
  onChange?: (value: any) => void;
  query: (params: { page: number; keyWords?: string; size: number }) => Promise<any>;
  /**
   * @description: backend api support pagination, so can load more.
   */
  pagination?: boolean;
  clientFilter?: boolean;
  renderInputProps?: TextFieldProps;
  limit?: number;
  OptionItem?: ComponentType<{ option: any }>;
} & Omit<SearchSelectProps, 'options'>;

export const useRefresh = () => {
  const [, set] = useState(0);
  return useCallback(() => set(Date.now()), []);
};

export const useAsyncSearchSelect = ({
  requestErrorInfo,
  error = false,
  onChange,
  query,
  renderInputProps,
  pagination = false,
  clientFilter = true,
  limit = 50,
  renderOption: customizeRenderOption,
  OptionItem,
  ...rest
}: AsyncSearchSelectHookProps) => {
  const [open, setOpen] = useState(false);
  const size = limit;
  const refresh = useRefresh();
  const totalRef = useRef(0);
  const pageRef = useRef(0);
  const loadingFirstRef = useRef(false);
  const keyWordsRef = useRef('');
  const listRef = useRef<any[]>([]);
  const optsRef = useRef<ComponentProps<typeof SearchSelect>['options']>([]);
  const itemMapRef = useRef(new Map<string, any>());
  const listBoxRef = useRef<HTMLUListElement | null>(null);
  const queryData = useCallback(
    async (page: number, keyWords: string) => {
      const data = await query({
        page,
        size,
        keyWords: keyWords || undefined,
      });
      console.log('onSearchSelectOpen: ', data);
      const startIndex = (page - 1) * size;
      optsRef.current.length = startIndex;
      listRef.current.length = startIndex;
      data?.data?.forEach((item: any, index: number) => {
        const absIndex = startIndex + index;
        listRef.current[absIndex] = item;
        optsRef.current[absIndex] = item;
        itemMapRef.current.set(item.value, item);
      });
      pageRef.current = page;
      totalRef.current = data?.total || data?.data?.length || 0;
      refresh();
    },
    [query, refresh, size],
  );

  const queryKeyWords = useCallback(
    async (keyWords: string) => {
      if (listBoxRef.current) {
        listBoxRef.current.scrollTop = 0;
      }
      loadingFirstRef.current = true;
      await queryData(1, keyWords);
      loadingFirstRef.current = false;
    },
    [queryData],
  );

  const nextPage = useCallback(async () => {
    if (totalRef.current > listRef.current.length) {
      await queryData(pageRef.current + 1, keyWordsRef.current);
    }
  }, [queryData]);

  const onListScroll = useCallback(
    async (e: any, loadingMore: boolean, setLoadingMore: any) => {
      if (!pagination) return;
      const target = e.target as any;
      if (target.scrollHeight - (target.scrollTop + target.offsetHeight) < 10 && !loadingMore) {
        setLoadingMore(true);
        await nextPage();
        setLoadingMore(false);
      }
    },
    [nextPage, pagination],
  );

  const ListBoxComponent: ComponentProps<typeof SearchSelect>['ListboxComponent'] = useMemo(
    () =>
      React.forwardRef((props, ref) => {
        const { children, ...rest } = props;
        const [loadingMore, setLoadingMore] = useState(false);
        return (
          <ul ref={listBoxRef} onScroll={(e) => onListScroll(e, loadingMore, setLoadingMore)} {...rest}>
            {children}
            {/* {pagination && listRef.current.length < totalRef.current && ( */}
            {pagination && loadingMore ? (
              <Box
                sx={{
                  width: '100%',
                  height: '32px',
                  paddingBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  boxSizing: 'border-box',
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : null}
          </ul>
        );
      }),
    [pagination, onListScroll],
  );

  const renderOption = useMemo(() => {
    if (customizeRenderOption) return customizeRenderOption;
    if (!OptionItem) return undefined;
    return (props: any, option: any, { selected }: any) => {
      return (
        <Box
          component={'li'}
          {...props}
          style={{ backgroundColor: selected ? '#FFE4E6' : undefined }}
          sx={{
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#F5F5F5',
            },
          }}
        >
          <Box
            display={'flex'}
            flex={1}
            flexDirection={'row'}
            alignItems={'center'}
            color={selected ? '#E8192C' : '#333333'}
          >
            <OptionItem option={option} />
          </Box>
        </Box>
      );
    };
  }, [OptionItem, customizeRenderOption]);

  const onSearchSelectOpen = useCallback(() => {
    setOpen(true);
    if (listRef.current?.length > 0) return;
    queryKeyWords(keyWordsRef.current);
  }, [queryKeyWords]);

  const onSearchSelectChange = useCallback(
    (event: any, newValue: any) => {
      onChange?.(newValue);
      if (!newValue) {
        keyWordsRef.current = '';
        if (!clientFilter) {
          queryKeyWords(keyWordsRef.current);
        }
      }
    },
    [clientFilter, onChange, queryKeyWords],
  );

  const onInputChange = useCallback(
    async (e: any) => {
      renderInputProps?.onChange?.(e);
      keyWordsRef.current = e.target.value ?? '';
      if (!clientFilter) {
        await queryKeyWords(keyWordsRef.current);
      }
    },
    [clientFilter, queryKeyWords, renderInputProps],
  );

  const debounceInputChange = useMemo(() => {
    return debounce(onInputChange, 500);
  }, [onInputChange]);

  return {
    open,
    listRef,
    queryData,
    loadingFirstRef,
    onSearchSelectOpen,
    setOpen,
    optsRef,
    onInitInputChange: onInputChange,
    onInputChange: debounceInputChange,
    onSearchSelectChange,
    ListBoxComponent,
    renderOption,
    queryKeyWords,
    keyWordsRef,
    totalRef,
    nextPage,
    onListScroll,
    listBoxRef,
  };
};
