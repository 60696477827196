import React, { memo, useRef } from 'react';
import { useFilterTableContainer, UseFilterTableContainerProps } from './filter-table-container.hook';
import ProFilterTable, { ActionNodeTypeProps, CheckType, ParamsType, ProTableProps, RowSelection } from '../ProTable';
import { ProSearchFilter } from '../ProTable/components/pro-search-filter/pro-search-filter.component';
import { useTranslation } from '../../hooks/translation.hook';

export interface FilterTableContainerProps<T, U extends ParamsType> extends UseFilterTableContainerProps<T> {
  rowSelection?: RowSelection<T>;
  ActionNode?: React.ComponentType<ActionNodeTypeProps<T>>;
  defaultSelectedRows?: T[];
  checkType?: CheckType;
  showActionBar?: boolean;
  onClickRow?: (row: T) => void;
  rowKey: string;
  getData: ProTableProps<T, U>['request'];
  actionRef?: ProTableProps<T, U>['actionRef'];
  filterConfigs?: ProTableProps<T, U>['filterConfigs'];
  showFilter?: ProTableProps<T, U>['showFilter'];
  showSelect?: boolean;
  onLoad?: (
    record: T[],
    pageInfo?: {
      page?: number;
      pageSize?: number;
      total?: number;
    },
  ) => void;
}

export const FilterTableContainerComp = <T extends Record<string, any>, U extends Record<string, any>>(
  props: FilterTableContainerProps<T, U>,
) => {
  const {
    ActionNode,
    onClickRow,
    rowSelection,
    defaultSelectedRows,
    rowKey,
    checkType,
    showActionBar,
    filterConfigs,
    cacheParams,
    getColumns,
    getData,
    onLoad,
    showSelect,
    actionRef,
    showFilter,
  } = props;

  const { onFilterChange, columns } = useFilterTableContainer({
    cacheParams,
    getColumns,
  });

  const { t } = useTranslation();

  return (
    <ProFilterTable
      showFilter={showFilter}
      actionRef={actionRef}
      filterConfigs={filterConfigs}
      filterState={cacheParams?.current}
      rowKey={rowKey}
      rowSelection={rowSelection}
      Filter={ProSearchFilter}
      columns={columns}
      request={getData}
      enableRefresh={false}
      ActionNode={ActionNode}
      onFilterChange={onFilterChange}
      defaultSelectedRows={defaultSelectedRows}
      checkType={checkType}
      showActionBar={showActionBar}
      onClickRow={onClickRow}
      showSelect={showSelect}
      emptyText={t('no_prospect_matched')}
      onLoad={onLoad}
      formatTitle={t}
    />
  );
};

export const FilterTableContainer = memo(FilterTableContainerComp) as typeof FilterTableContainerComp;
