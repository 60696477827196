import { get, set } from 'lodash';
import { DateFormat, formatDate } from 'src/app/common/utils';
import { ProColumns } from 'src/app/common/components/ProTable';
import { Button } from '@mui/material';
import { CampaignLeadItem, FollowUpStatusEnum } from 'src/app/modules/Campaign/types/campaign-types';
import { FilterComponentsEnum } from 'src/app/common/components/list-filter/filter-components.enum';
import { ListFilterConfig } from 'src/app/common/components/list-filter/list-filter.component';
import { styles } from './lead-column.style';
import { ProTableOperationBtnComponent } from '../../../../../../../common/components/ProTable/components/pro-table-operation-btn/pro-table-operation-btn.component';
import { COLUMN_ITEMS } from 'src/app/modules/Leads/util/column-items.util';
import { Columns } from 'src/app/modules/Leads/types/types';
import { renderExpoButton } from 'src/app/modules/ai-talkbot/common/components/talkbot-export-button/talkbot-export-button.component';

export const getColumns = ({
  t,
  update,
  refresh,
}: {
  t: (val: string) => string;
  update?: (item: CampaignLeadItem) => void;
  refresh: () => void;
}): ProColumns<CampaignLeadItem>[] => [
  {
    title: t('campaign.list.name'),
    dataIndex: 'name',
    width: '290px',
    sorter: true,
  },
  {
    title: t('campaign.list.servicingStatus'),
    dataIndex: 'feedback',
    width: '176px',
    sorter: true,
    render: (currValue: string, item) => {
      return <span style={styles.feedback}>{currValue}</span>;
    },
  },
  {
    ...COLUMN_ITEMS[Columns.source](),
    title: t(COLUMN_ITEMS[Columns.source]().title as string),
  } as any,
  {
    ...COLUMN_ITEMS[Columns.leadSource](),
    title: t(COLUMN_ITEMS[Columns.leadSource]().title as string),
  } as any,
  {
    title: t('campaign.list.lastUpdated'),
    dataIndex: 'updatedAt',
    width: '176px',

    render: (currValue: string) => {
      return formatDate(currValue, DateFormat.datetime);
    },
  },
  {
    title: t('prospect.appoinment.title.operation'),
    width: '176px',
    dataIndex: 'op',
    sticky: true,
    render: (currValue: string, item) => {
      return (
        <>
        {/* PACS Customization | [PACSPFAP-3691] | END */}
          {/* <ProTableOperationBtnComponent
            onClick={() => {
              update && update(item);
            }}
            label={t('app.button.update')}
          /> */}
          {/* PACS Customization | [PACSPFAP-3691] | END */}
          {renderExpoButton({ item, actions: refresh })}
        </>
      );
    },
  },
];

export const getFilterConfig: (t: (val: string) => string, activityStatusFeedback: any) => Array<ListFilterConfig> = (
  t,
  activityStatusFeedback,
) => [
  {
    key: 'feedback',
    title: t('Servicing Activity Status'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    // items: [
    //   { itemKey: 'pedding', itemLabel: t('peddingCall') },
    //   {
    //     itemKey: 'contacted',
    //     itemLabel: t('contacted'),
    //   },
    // ],
    items: activityStatusFeedback
      ? activityStatusFeedback.map((item: any) => ({
          itemKey: item.key,
          itemLabel: item.value,
        }))
      : [],
  },
];
