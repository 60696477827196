import React, { FC, memo, useMemo, useState } from 'react';
import { SxProps, Tab, Tabs, Theme } from '@mui/material';
import { useStyles } from './tab-bar.style';

type TabData = {
  value: string;
  label: string;
};

type TabBarProps = {
  tabs: TabData[];
  currentValue: string;
  onChange?: (value: string) => void;
  rightActions?: React.ReactNode;
  tabStyle?: SxProps<Theme>;
};

export const TabBar: React.FC<TabBarProps> = memo((props: TabBarProps) => {
  const { tabs, currentValue, onChange, rightActions, tabStyle } = props;

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={styles.tabContainer}>
      <Tabs
        value={currentValue}
        onChange={handleChange}
        TabIndicatorProps={{ style: { backgroundColor: '#E8192C' } }}
        sx={{
          '.Mui-selected': {
            color: '#E8192C !important',
          },
        }}
      >
        {tabs &&
          tabs.map((tab, index) => {
            return <Tab key={index} value={tab.value} label={tab.label} sx={tabStyle} />;
          })}
      </Tabs>
      {rightActions && <div>{rightActions}</div>}
    </div>
  );
});
