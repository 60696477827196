import React, { FC, useRef } from 'react';
import { Switch, Redirect, RouteComponentProps, Route } from 'react-router-dom';
import PruRoute from '../../../common/components/PruRoute';
import {
  AUTO_PURGE_LIST,
  PROSPECT_ADD_LEAD_PATH,
  PROSPECT_BASE_PATH,
  PROSPECT_DETAIL_PATH,
  PROSPECT_LIST_PATH,
  PROSPECT_REFERRER,
} from '../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../common/constants';
import { ListPage } from './list/list.screen';
import { AddProspectPage } from './add-prospect/add-prospect.screen';
// import CampaignDetailPage from './Details/CampaignDetailPage';
import { GAData } from '../../../common/ga/redux/gaSlice';
import { AutoPurgeListPage } from './auto-purge-list/auto-purge-list.screen';
import { ProspectDetailPage } from './prospect-detail/prospect-detail.screen';
import { AddLeadPage } from '../../Leads/pages/add-lead/add-lead.screen';

export const listPath = `${PROSPECT_BASE_PATH}${PROSPECT_LIST_PATH}`;

const prospectListGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'View Prospect',
  stage: 'Prospect List',
  screen_name: 'Prospect List',
};
const prospectDetailsGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'View Prospect',
  stage: 'Prospect Details',
  screen_name: 'Prospect Details',
};

const ProspectRoutes: FC<RouteComponentProps> = (props) => {
  const cacheParams = useRef({ sorter: { displayName: 'asc' } });
  const { history } = props;

  return (
    <Switch>
      <Redirect exact from={`${PROSPECT_BASE_PATH}`} to={listPath} />
      <PruRoute
        exact
        // gaData={createCampaignPageGAData}
        path={`${PROSPECT_BASE_PATH}${AUTO_PURGE_LIST}`}
        component={AutoPurgeListPage}
      />
      <PruRoute path={`${PROSPECT_BASE_PATH}/create/:type`} render={(props) => <AddProspectPage {...props} />} />
      <PruRoute
        exact
        gaData={prospectDetailsGaDataRoute}
        path={`${PROSPECT_BASE_PATH}${PROSPECT_DETAIL_PATH}/:id`}
        render={(props) => <ProspectDetailPage {...props} key={`detail_${history.location.key}`} />}
      />
      <PruRoute
        exact
        // gaData={viewCampaignGPageGAData}
        path={`${PROSPECT_BASE_PATH}${PROSPECT_DETAIL_PATH}${PROSPECT_ADD_LEAD_PATH}/:id`}
        render={(props) => <AddLeadPage {...props} />}
      />
      <PruRoute
        // gaData={viewCampaignGPageGAData}
        path={`${PROSPECT_BASE_PATH}${PROSPECT_REFERRER}${PROSPECT_DETAIL_PATH}/:id`}
        render={(props) => <ProspectDetailPage {...props} key={`referrer_${history.location.key}`} />}
      />

      <PruRoute
        exact
        gaData={prospectListGaDataRoute}
        path={`${listPath}`}
        render={(props) => <ListPage {...props} cacheParams={cacheParams} />}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default ProspectRoutes;
