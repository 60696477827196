import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { LEADS_DETAIL_PATH } from 'src/app/modules/Leads/constants';
import { AgentStatusEnum, Lead } from 'src/app/modules/Leads/types/types';

export const useCommonLeadList = () => {
  const history = useHistory();

  const onClickRow = useCallback(
    (record: Lead) => {
      history.push(`${LEADS_DETAIL_PATH}/${record._id}`, {});
    },
    [history],
  );

  const defaultRowSelection = useMemo(
    () => ({
      onChange: (rows: Lead[]) => {},
      getCheckboxProps: (row: Lead) => ({
        // disabled: row.agentStatus !== AgentStatusEnum.pending, // PACS Customization | [PACSPFAP-3691]
      }),
    }),
    [],
  );

  return {
    onClickRow,
    defaultRowSelection,
  };
};
