import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChartListParam } from '../../../types/agent-types';
import { map } from 'lodash';
import { RootState } from 'src/redux/store';
import { TokenInfo } from '../../../../Auth/types/auth-types';
import { DashboardItem, AlDashboardName, AlDashboardType, FCDashboardFilter } from '../../../types/dashboard-types';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils'; 
import { fetchChartList, fetchAlAgentList } from '../../../network/agentCrud';
import DownlineAlList from './DownlineAlList';
import { RouteComponentProps } from 'react-router';
import { useIntl } from 'react-intl';


type FinancialAgentListingPageProps = {} & ParamsProps & RouteComponentProps;

const initialState: ChartListParam = {
  periodRange: 'All',
  chartType: 'Table',
  filter: '',
  value: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): ChartListParam => {
  return initialParams
    ? {
        periodRange: 'All',
        chartType: 'Table',
        filter: '',
        value: '',
      }
    : initialState;
};

const DownlineAlListingPage: FC<FinancialAgentListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
  history,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [filterState, setFilterState] = useState<ChartListParam>(paramsInitiator(initialParams));
  const [, setDashboardList] = useState<DashboardItem[]>();
  const [downlineAgent, setDownlineAgent] = useState<any>('');
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const user = useSelector<RootState, TokenInfo | undefined>(state => state.auth.user);
  const [myFilter, setMyFilter] = useState({
    monthly_dates__c: filterState.value,
    type: filterState.value,
  });
  const { isLoading, refreshData } = useDataProvider<DashboardItem[]>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        let res = await fetchChartList(filterState, dispatch);
        return res
      } catch (err) {}
    },
    setDashboardList,
    false,
  );

  useEffect(() => {
    //refreshData();
     refreshData()
  }, [filterState, sortKey]);

  const agent_code__c = user?.sub || '';


const downlineAls: string[] = []
useEffect(() => {
    fetchAlAgentList().then((results) => {
     let allAgentsList: any = results
     allAgentsList && allAgentsList.map((item: any) => {
        downlineAls.push(item.groupLeader)
     })
     setDownlineAgent(downlineAls)
    })
},[])

  return (
    <>
      <PruFilter
        title={Translation('dashboard.common.controlPanel')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN_PERIOD,
            field: 'periodRange',
            initialValue: filterState.periodRange,
            defaultValue: initialState.periodRange,
            displayName: Translation('kpiDashboard.dashboard.period'),
            choices: [...map(AlDashboardName, (option: string) => ({ displayName: option, value: option }))],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'chartType',
            initialValue: filterState.chartType,
            defaultValue: initialState.chartType,
            displayName: Translation('dashboard.common.type'),
            choices: [...map(AlDashboardType, (option: string) => ({ displayName: option, value: option }))],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'filter',
            initialValue: filterState.filter,
            displayName: Translation('dashboard.common.filter'),
            choices: [
              { displayName: "", value: "" },
              ...map(FCDashboardFilter.get(filterState.chartType), (option: string) => ({ displayName: option, value: option }))
            ],
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'value',
            initialValue: filterState.value,
            displayName: Translation('dashboard.common.value'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            periodRange: data.periodRange,
            chartType: data.chartType,
            filter: data.filter,
            value: data.value,
          })
        }
        fetchData={refreshData}
      />
    {downlineAgent && (
      <DownlineAlList
        filter={filterState}
        myFilter={myFilter}
        onRefresh={true}
        refreshdata={refreshData}
        setMyFilter={setMyFilter}
        agentList={downlineAgent}
      />)}
    </>
  );
};

export default DownlineAlListingPage;
