import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelectCampaign } from './select-campaign.hook';
import {
  AsyncSearchSelect,
  AsyncSearchSelectProps,
} from 'src/app/common/components/async-search-select/async-search-select.component';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

type ComponentProps = Omit<AsyncSearchSelectProps, 'query'>;

const OptionItem = ({ option }: { option: { label: string; value: string; url?: string } }) => (
  <>
    <Box display={'flex'} marginLeft={'15px'} flexDirection={'column'}>
      <Typography>{option.label}</Typography>
      <Typography>{option.value}</Typography>
    </Box>
  </>
);

export const SelectCampaign: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { t } = useTranslation();

  // custom-hook
  const { fetchCampaigns } = useSelectCampaign();

  return (
    <AsyncSearchSelect
      pagination
      renderInputProps={{ label: t('campaignSelect') }}
      {...props}
      clientFilter={false}
      query={fetchCampaigns}
      OptionItem={OptionItem}
    />
  );
});
