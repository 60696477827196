import React, { memo, useMemo } from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './list-filter-radio-group.style';

type ComponentProps = ParamsProps & {
  value?: string;
  itemsConfig: Array<{ itemKey: string; itemLabel: string }>;
  onChange?: (value: string) => void;
};

export const ListFilterRadioGroupComponent: React.FC<ComponentProps> = memo(
  ({ itemsConfig, value, onChange }: ComponentProps) => {
    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    return (
      <RadioGroup value={value === undefined ? null : value}>
        <Grid container spacing={1} classes={{ root: `${styles.fullWidth} ${styles.mt_8} ${styles.ml_8}` }}>
          {itemsConfig.map((item) => {
            return (
              <Grid item xs={6} key={item.itemKey}>
                <FormControlLabel
                  value={item.itemKey}
                  control={
                    <Radio
                      onChange={() => {
                        onChange?.(item.itemKey);
                      }}
                    />
                  }
                  label={item.itemLabel}
                  sx={{ wordBreak: 'break-word' }}
                />
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    );
  },
);
