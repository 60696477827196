import * as React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {  useParams } from 'react-router-dom';
import { setLanguage } from 'src/app/i18n';
import { getSummaryDashboardDetail } from '../../network/crud';
import { chartOptions, dataType, pageType } from '../../types/chart-types';
import { SummaryDetailPage } from './components/SummaryDetailPage';

export const SummaryListingPage = () => {
  //export

  const [type, setType] = React.useState<pageType>('default');
  const { resultId } = useParams<{ resultId: string }>();
  const { PUBLIC_URL } = process.env;

  const intl = useIntl();

  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [options, setOptions] = React.useState<chartOptions>();
  const [error, setError] = React.useState<object>();
  const [data, setData] = React.useState<dataType>({
    isCommentary: true,
    isRecommendations: true,
    resultId: resultId,
  });

  var url = window.location.href;
  var params = url?.split('?')[1]?.split('&');
  console.log(params);

  if (params) {
    for (var i = 0; i < params.length; i++) {
      var param = params[i].split('=');
      if (param[0] === 'type' && param[1] !== type) {
        if (param[1] === 'default') setType('default');
        else if (param[1] === 'preview') setType('preview');
      }
      if (param[0] === 'language') {
        console.log(param, intl.locale);
      }

      if (param[0] === 'language' && param[1] !== intl.locale) {
        if (param[1] === 'en') {
          setLanguage('en');
        } else if (param[1] === 'zh' && intl.locale !== 'zh-Hant') {
          setLanguage('zh-Hant');
        }
      }
      if (param[0] === 'isCommentary' && data.isCommentary !== (param[1] === 'true')) {
        setData({
          ...data,
          isCommentary: param[1] === 'true',
        });
      }
      if (param[0] === 'isRecommendations' && data.isRecommendations !== (param[1] === 'true')) {
        setData({
          ...data,
          isRecommendations: param[1] === 'true',
        });
      }
      if (param[0] === 'isExport' && data.isExport !== (param[1] === 'true')) {
        setData({
          ...data,
          isExport: param[1] === 'true',
        });
      }
    }
  }
  const getSummaryData = async () => {
    await getSummaryDashboardDetail(resultId, intl.locale, dispatch)
      .then((result: any) => {
        var options: chartOptions = result.result.data;
        options.tabData[2].type = 'number';
        setOptions(options);
        console.log('------getSummaryData--------');
        console.log(result.result.data);
      })
      .catch((e) => {
        setError(e);
        throw new Error('load data failed');
      });
  };

  if (!options && !error) {
    getSummaryData();
  }

  return options ? (
    <SummaryDetailPage type={type} options={options} data={data} />
  ) : error ? (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={PUBLIC_URL + '/media/svg/icons/Error/file-flies.svg'} alt="" width={180} />
      <div style={{ fontSize: 16, fontWeight: 600, color: '#333333' }}>
        {error.toString().includes('timeout') || error.toString().includes('Network')
          ? Translation('summary.dashboard.page.error.timeout.title')
          : Translation('summary.dashboard.page.error.others.title')}
      </div>
      <div style={{ fontSize: 14, fontWeight: 500, color: '#999999' }}>
        {Translation('summary.dashboard.page.error.content')}
      </div>
    </div>
  ) : (
    <></>
  );
};
