import { Menu, MenuItem, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { regionLocale, regionLocaleMap, setLanguage, useLang } from 'src/app/i18n';
import { Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { updateUserProperties } from 'src/app/common/ga/ga-helper';

export default function LanguageSwitch() {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const lang = useLang();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const filteredLanguage = useMemo(
    () =>
      regionLocale.map((language) => ({
        lang: language,
        ...regionLocaleMap[language],
      })),
    [],
  );

  const currentLanguage = useMemo(() => {
    return regionLocale
      .map((language) => ({
        lang: language,
        ...regionLocaleMap[language],
      }))
      .find((locale) => locale.lang === lang);
  }, [lang]);

  const handleChange = useCallback((language: any) => {
    updateUserProperties({
      language: language.lang,
    });
    setLanguage(language.lang);
  }, []);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div className={classes.wrapper}>
      <Tooltip arrow title={Translation('navBar.lang.select')}>
        {/* <img
          className={classes.image}
          src={currentLanguage ? currentLanguage.flag : ''}
          alt={currentLanguage ? Translation(currentLanguage.name) : ''}
          onClick={handleClick}
        /> */}
        <Button variant="text" onClick={handleClick} endIcon={<ExpandMoreIcon />}>
          {currentLanguage?.name ? Translation(currentLanguage.name).toUpperCase() : ''}
        </Button>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {filteredLanguage.map((language) => {
          return (
            <MenuItem value={language.lang} key={language.name} onClick={() => handleChange(language)}>
              {/* <img className={classes.image} src={language.flag} alt={Translation(language.name)} /> */}
              <Typography className={classes.text}>{Translation(language.name)}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    marginLeft: 10,
    marginRight: 20,
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      marginRight: 80,
    },
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 6,
  },
  text: {},
}));
