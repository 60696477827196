import React, { FC, memo, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Card, CircularProgress } from '@mui/material';
import { Button } from 'src/app/common/components/button/button.component';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './lead-upload-wizard.style';
import { LeadUploadWizardHookProps, useLeadUploadWizard } from './lead-upload-wizard.hook';
import { AgencyCampaignTypeStatusEnum } from 'src/app/modules/AgencyCampaign/constants';
import moment from 'moment';
import { constants } from 'src/app/common/constants';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import { downloadLeadsTemplateCsv } from 'src/app/modules/AgencyCampaign/network/campaignCurd';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { takeUIScreenViewEvent } from 'src/app/common/ga/ga';
import { GAData } from 'src/app/common/ga/redux/gaSlice';

type ComponentProps = ParamsProps & LeadUploadWizardHookProps & {};

const addLeadGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Add Lead',
  stage: 'Add Lead',
  screen_name: 'Add Lead',
};

export const LeadUploadWizardComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const { classes: commonStyle } = useCommonStyles();

  // custom-hook
  const { campaign } = props;
  const { acmAddLeadSetting, importFile, loading, t, handleFile, getTranslations, _renderUploadedFileDetail } =
    useLeadUploadWizard(props);

  useEffect(() => {
    takeUIScreenViewEvent(addLeadGaDataRoute);
  }, []);

  return (
    <div>
      <div>
        {acmAddLeadSetting?.selfAddEnabled &&
          (campaign.campaignStatus !== AgencyCampaignTypeStatusEnum.CANCELED ? (
            <div style={{ marginLeft: 24, marginTop: 24 }}>
              <div
                style={{
                  display: 'flex',
                  color: '#666666',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '20px',
                  alignItems: 'center',
                }}
              >
                {Translation('agencyCampaign.lead.schdule')}:{' '}
                <div style={{ fontSize: 14, marginLeft: 12 }}>
                  {moment(acmAddLeadSetting?.startDate).format(constants.Default_Date_Format)} -{' '}
                  {moment(acmAddLeadSetting?.endDate).format(constants.Default_Date_Format)}
                </div>
              </div>
              {acmAddLeadSetting?.needACMApproved &&
                ![
                  AgencyCampaignTypeStatusEnum.ACTIVE + '',
                  AgencyCampaignTypeStatusEnum.COMPLETED + '',
                  AgencyCampaignTypeStatusEnum.APPROVED + '',
                ].includes(campaign.campaignStatus) && (
                  <span
                    style={{
                      display: 'flex',
                      color: '#666666',
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: '20px',
                      marginTop: 12,
                    }}
                  >
                    {getTranslations().campaign_should_be_approved_to_start_the_section}
                  </span>
                )}
            </div>
          ) : (
            <span>{getTranslations().campaign_has_been_cancelled}</span>
          ))}
        {!acmAddLeadSetting?.selfAddEnabled && (
          <div className={classes.notAvailableTip}>
            {getTranslations().self_create_lead_is_not_available_for_this_campaign}
          </div>
        )}
      </div>
      <Card elevation={3} style={{ marginTop: 24, marginLeft: 24, marginRight: 24 }}>
        {!importFile && (
          <div style={{ paddingLeft: 24, paddingBottom: 35, paddingRight: 24 }}>
            <div className={styles.templateContainer}>
              <span className={styles.templateLabel}>{Translation('agencyCampaign.addLead.templateFile')}</span>
              <AsyncCsvLink
                isDisabled={false}
                filename={`LeadUpload.csv`}
                dataParser={(str) => str}
                asyncCall={() => downloadLeadsTemplateCsv()}
              >
                <a className={`${commonStyle.link} ${styles.templateLink}`}>
                  {Translation('agencyCampaign.create.downloadTemplate')}
                </a>
              </AsyncCsvLink>
            </div>

            <div>
              <input
                id="upload-lead-participant-list-csv"
                hidden
                type="file"
                accept=".csv"
                onClick={(e) => {
                  const element = e.target as HTMLInputElement;
                  element.value = '';
                }}
                onChange={handleFile}
              />
              <Button
                className={styles.uploadBtn}
                onClick={() => document.getElementById('upload-lead-participant-list-csv')!.click()}
                // disabled={btnDisabled} // todo: remove after dev done
              >
                <div className={classes.uploadBtnTextContainer}>
                  <div className={classes.uploadBtnTitle}>
                    {loading && <CircularProgress sx={{ marginLeft: '8px', color: 'black' }} size={14} />}{' '}
                    {Translation('agencyCampaign.upload.selectFiles')}
                  </div>
                  <div className={classes.uploadBtnSubtitle}>
                    {t('agencyCampaign.upload.selectFile', {
                      child: <span className={classes.browser}>{Translation('agencyCampaign.upload.browse')}</span>,
                    })}
                  </div>
                </div>
              </Button>
            </div>
            <ul className={styles.ul}>
              <li>{Translation('agencyCampaign.leadUploadWizard.hint1')}</li>
              <li>{Translation('agencyCampaign.leadUploadWizard.hint2')}</li>
              <li>{Translation('agencyCampaign.leadUploadWizard.hint3')}</li>
              <li>{Translation('agencyCampaign.leadUploadWizard.hint4')}</li>
              <li>{Translation('agencyCampaign.leadUploadWizard.hint5')}</li>
              <li>{Translation('agencyCampaign.leadUploadWizard.hint6')}</li>
              <li>{Translation('agencyCampaign.leadUploadWizard.hint7')}</li>
            </ul>
          </div>
        )}
        {importFile && <>{_renderUploadedFileDetail()}</>}
      </Card>
    </div>
  );
});
