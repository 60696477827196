import axios from 'axios';

export const fileUpload = (url: string, file: File, setProgress?: (progress: number) => void) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        const buffer = reader.result;
        axios
          .put(url, buffer, { headers: { 'x-ms-blob-type': 'BlockBlob', 'Content-Type': file.type } })
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      };
      reader.readAsArrayBuffer(file);
    } catch (err) {
      reject(err);
    }
  });
};

/**
 * download file by url, and rename file with provided name
 * @param url file url for download
 * @param name rename name
 */
export function downloadAs(url: string, name: string, openInNewTab = false) {
  // do NOT use apiClient(axios-utils) here
  axios
    .get(url, {
      headers: { 'Content-Type': 'application/octet-stream' },
      responseType: 'blob',
    })
    .then((response) => {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      if (openInNewTab) {
        a.target = '_blank';
      } else {
        a.download = name;
      }
      a.click();
    })
    .catch((err) => {
      console.log('download error: ', err);
    });
}
