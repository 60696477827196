import { GAData as RouteGA } from '../redux/gaSlice';
import { GAObjectType } from './ga-parameter-type';

type GAData = RouteGA & { object_name: string; object_type: GAObjectType };

export const PruLeadsHomeScreenGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'new lead reminder',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Check',
  object_type: 'BUTTON',
};
export const PruLeadsHomeScreenGaDataClick1: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'new lead reminder',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Check',
  object_type: 'BUTTON',
};
const PruLeadsHomeScreenGaDataClick2: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'aging pending accept lead reminder',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Check',
  object_type: 'BUTTON',
};
const youGotANewOpportunityGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'new lead reminder',
  screen_name: 'you got a new opportunity',
  object_name: 'Accept',
  object_type: 'BUTTON',
};
const youGotANewOpportunityGaDataClick4: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'new lead reminder',
  screen_name: 'you got a new opportunity',
  object_name: 'Reject',
  object_type: 'BUTTON',
};
const theseLeadAreGettingColdGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'aging pending accept lead reminder',
  screen_name: 'these lead are getting cold',
  object_name: 'accept all',
  object_type: 'BUTTON',
};
const theseLeadAreGettingColdGaDataClick6: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'aging pending accept lead reminder',
  screen_name: 'these lead are getting cold',
  object_name: 'Remove all',
  object_type: 'BUTTON',
};
const theseLeadAreGettingColdGaDataClick7: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'aging pending accept lead reminder',
  screen_name: 'these lead are getting cold',
  object_name: 'accept',
  object_type: 'BUTTON',
};
const theseLeadAreGettingColdGaDataClick8: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'aging pending accept lead reminder',
  screen_name: 'these lead are getting cold',
  object_name: 'Reject',
  object_type: 'BUTTON',
};
const theseLeadAreGettingColdGaDataClick9: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Aging Pending Accept Lead Reminder',
  screen_name: 'these lead are getting cold',
  object_name: 'Accept',
  object_type: 'BUTTON',
};
const theseLeadAreGettingColdGaDataClick10: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Aging Pending Accept Lead Reminder',
  screen_name: 'these lead are getting cold',
  object_name: 'Reject',
  object_type: 'BUTTON',
};
const pRULeadsOpportunityListGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'PRULeads Opportunity List',
  screen_name: 'PRULeads Opportunity List',
  object_name: 'Accept',
  object_type: 'BUTTON',
};
const prospectDetailGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Prospect Detail Lead List',
  screen_name: 'Prospect Detail',
  object_name: 'Accept',
  object_type: 'BUTTON',
};
const pRULeadsHomeScreenGaDataClick13: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Confirm Contact Lead',
  stage: 'Contact Lead Reminder',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Check',
  object_type: 'BUTTON',
};
const successfullyContactedLeadYesterdayGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Confirm Contact Lead',
  stage: 'contact lead reminder',
  screen_name: 'Successfully Contacted Lead Yesterday',
  object_name: 'Yes',
  object_type: 'BUTTON',
};
const successfullyContactedLeadYesterdayGaDataClick15: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Confirm Contact Lead',
  stage: 'contact lead reminder',
  screen_name: 'Successfully Contacted Lead Yesterday',
  object_name: 'No',
  object_type: 'BUTTON',
};
const pRULeadsHomeScreenGaDataClick16: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Confirm Contact Lead',
  stage: 'contact lead reminder',
  screen_name: 'PRULeads Home Screen',
  object_name: 'close',
  object_type: 'BUTTON',
};
const pRULeadsHomeScreenGaDataClick17: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Lead Auto Drop Reminder',
  screen_name: 'PRULeads Home Screen',
  object_name: 'Check',
  object_type: 'BUTTON',
};
const pRULeadsFollowUpListGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'PRULeads Follow Up List',
  screen_name: 'PRULeads Follow Up List',
  object_name: 'FollowUp',
  object_type: 'BUTTON',
};
const successfullyContactedLeadYesterdayGaDataClick19: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Contact Lead Reminder',
  screen_name: 'Successfully Contacted Lead Yesterday',
  object_name: 'FollowUp',
  object_type: 'BUTTON',
};
const prospectDetailGaDataClick20: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Prospect Detail Lead List',
  screen_name: 'Prospect Detail',
  object_name: 'Update Lead',
  object_type: 'BUTTON',
};
const marketingCampaignDetailsGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Marketing Campaign Lead List',
  screen_name: 'Marketing Campaign Details',
  object_name: 'Update Lead',
  object_type: 'BUTTON',
};
const agencyCampaignDetailsGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Agency Campaign Lead List',
  screen_name: 'Agency Campaign Details',
  object_name: 'Update Lead',
  object_type: 'BUTTON',
};
const insightLeadDetailsGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Insight Lead Detail Lead List',
  screen_name: 'Insight Lead Details',
  object_name: 'Update Lead',
  object_type: 'BUTTON',
};
const leadAutoDropListGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Update Lead',
  stage: 'Lead Auto Drop Reminder',
  screen_name: 'Lead Auto Drop List',
  object_name: 'Update Lead',
  object_type: 'BUTTON',
};
const pRULeadsCloseLeadListGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Check Close Lead',
  stage: 'PRULeads Close Lead List',
  screen_name: 'PRULeads Close Lead List',
  object_name: 'Closed',
  object_type: 'BUTTON',
};
const allGaDataClick: GAData = {
  module: 'PRULeads',
  feature: '“Lead”',
  journey: 'opportunity',
  stage: 'accept',
  screen_name: 'All',
  object_name: 'Accept',
  object_type: 'BUTTON',
};

// const pRULeadsFollowUpListGaDataClick29: GAData = {
//   "module": "PRULeads",
//   "feature": "Lead",
//   "journey": "Call Lead",
//   "stage": "Click Call Lead",
//   "screen_name": "PRULeads Follow Up List",
//   "object_name": "CallLead",
//   "object_type": "BUTTON"
// }
const prospectDetailsGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Appointment',
  journey: 'View Appointment',
  stage: 'Prospect Details',
  screen_name: 'Prospect Details',
  object_name: 'Appointment',
  object_type: 'Tab',
};
const pRULeadsHomePageGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Add Appointment',
  stage: 'PRULeads Follow Up List',
  screen_name: 'PRULeads Home Page',
  object_name: 'AddLeadApointment',
  object_type: 'BUTTON',
};
const prospectDetailsGaDataClick32: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Add Appointment',
  stage: 'Prospect Details',
  screen_name: 'Prospect Details',
  object_name: 'AddLeadApointment',
  object_type: 'BUTTON',
};
const marketingCampaignDetailsGaDataClick33: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Add Appointment',
  stage: 'Marketing Campaign Lead List',
  screen_name: 'Marketing Campaign Details',
  object_name: 'AddLeadApointment',
  object_type: 'BUTTON',
};
const agencyCampaignDetailsGaDataClick34: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Add Appointment',
  stage: 'Agency Campaign Lead List',
  screen_name: 'Agency Campaign Details',
  object_name: 'AddLeadApointment',
  object_type: 'BUTTON',
};
const insightLeadDetailsGaDataClick35: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Add Appointment',
  stage: 'Insight Lead Detail Lead List',
  screen_name: 'Insight Lead Details',
  object_name: 'Update Lead',
  object_type: 'BUTTON',
};
const leadDetailsGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Edit Sales Lead',
  stage: 'Edit Sales Lead',
  screen_name: 'Lead Details',
  object_name: 'Edit',
  object_type: 'BUTTON',
};
const reassignLeadGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Reassign Lead',
  stage: 'Select Unit',
  screen_name: 'Reassign Lead',
  object_name: 'Select Unit',
  object_type: 'BUTTON',
};
const reassignLeadGaDataClick38: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Reassign Lead',
  stage: 'Edit Lead Status',
  screen_name: 'Reassign Lead',
  object_name: 'Edit Lead Status',
  object_type: 'BUTTON',
};
const reassignLeadGaDataClick39: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Reassign Lead',
  stage: 'Select Agent',
  screen_name: 'Reassign Lead',
  object_name: 'Continue',
  object_type: 'BUTTON',
};
const agencyCampaignDetailGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Leads',
  journey: 'Add Lead',
  stage: 'Agency Campaign Detail',
  screen_name: 'Agency Campaign Detail',
  object_name: 'Add Lead',
  object_type: 'BUTTON',
};
const prospectDetailsGaDataClick42: GAData = {
  module: 'PRULeads',
  feature: 'Leads',
  journey: 'View Lead',
  stage: 'Prospect Details',
  screen_name: 'Prospect Details',
  object_name: 'Leads',
  object_type: 'Tab',
};
const salesViewProspectGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'Prospect Overview',
  stage: 'View Prospect Overview',
  screen_name: 'Sales View Prospect',
  object_name: 'Engagement',
  object_type: 'BUTTON',
};
const salesViewProspectGaDataClick44: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'Prospect Overview',
  stage: 'View Prospect Overview',
  screen_name: 'Sales View Prospect',
  object_name: 'Survey',
  object_type: 'BUTTON',
};
const salesViewProspectGaDataClick45: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'Prospect Overview',
  stage: 'View Prospect Overview',
  screen_name: 'Sales View Prospect',
  object_name: 'Activity',
  object_type: 'BUTTON',
};
const prospectDetailsGaDataClick46: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'View Prospect',
  stage: 'Prospect Details',
  screen_name: 'Prospect Details',
  object_name: 'Information',
  object_type: 'Tab',
};
const prospectDetailsGaDataClick47: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'Edit Prospect',
  stage: 'Prospect Details',
  screen_name: 'Prospect Details',
  object_name: 'Edit',
  object_type: 'BUTTON',
};
const searchProspectGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'Search Prospect',
  stage: 'Search Prospect',
  screen_name: 'Search Prospect',
  object_name: 'Search',
  object_type: 'BUTTON',
};
const prospectDetailsGaDataClick49: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'Delete Prospect',
  stage: 'Single Delete Prospect',
  screen_name: 'Prospect Details',
  object_name: 'Delete',
  object_type: 'BUTTON',
};
const prospectListGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'Delete Prospect',
  stage: 'Auto Purge Prospect Reminder',
  screen_name: 'Prospect List',
  object_name: 'Check',
  object_type: 'BUTTON',
};
const prospectListGaDataClick51: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'Merge Prospect',
  stage: 'Merge Prospect',
  screen_name: 'Prospect List',
  object_name: 'Merge',
  object_type: 'BUTTON',
};
const prospectListGaDataClick52: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'Edit Category',
  stage: 'Bulk Edit Category',
  screen_name: 'Prospect List',
  object_name: 'Edit Category',
  object_type: 'BUTTON',
};
const prospectListGaDataClick53: GAData = {
  module: 'PRULeads',
  feature: 'Prospect',
  journey: 'Delete Prospect',
  stage: 'Bulk Delete Prospect',
  screen_name: 'Prospect List',
  object_name: 'Delete',
  object_type: 'BUTTON',
};
// const salesAddEngagementGaDataClick: GAData = {
//   "module": "PRULeads",
//   "feature": "Engagement",
//   "journey": "“add_engagement”",
//   "stage": "“add”",
//   "screen_name": "Sales Add Engagement",
//   "object_name": "\"Submit\"",
//   "object_type": "\"BUTTON\""
// }
// const 1.PRULeads->Engagement->Today->AddEngagement2.PRULeads->Engagement->Overdue->AddEngagement3.PRULeads->Engagement->Upcoming->AddEngagement4.PRULeads->Prospect->AddEngagement5.PRULeads->Prospect->ChooseAProspect->Engagement->NewGaDataClick: GAData = {
//   "module": "PRULeads",
//   "feature": "“Engagement”",
//   "journey": "“add_engagement”",
//   "stage": "“add”",
//   "screen_name": "1.PRULeads-> engagement->today->add engagement\r\n\r\n2.PRULeads-> engagement->overdue->add engagement\r\n\r\n3.PRULeads-> engagement->upcoming->add engagement\r\n\r\n4.PRULeads-> prospect->add engagement\r\n\r\n5.PRULeads-> prospect->choose a prospect-> engagement->New",
//   "object_name": "\"Submit\"",
//   "object_type": "\"BUTTON\""
// }
const marketingCampaignListGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'Search Marketing Campaign',
  stage: 'Search',
  screen_name: 'Marketing Campaign List',
  object_name: 'Search',
  object_type: 'BUTTON',
};
const marketingCampaignListGaDataClick57: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'Search Marketing Campaign',
  stage: 'Sorting',
  screen_name: 'Marketing Campaign List',
  object_name: 'Sorting',
  object_type: 'BUTTON',
};
const massCampaignListGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'Search Mass Campaign',
  stage: 'Search',
  screen_name: 'Mass Campaign List',
  object_name: 'Search',
  object_type: 'BUTTON',
};
const massCampaignListGaDataClick59: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'Search Mass Campaign',
  stage: 'Sorting',
  screen_name: 'Mass Campaign List',
  object_name: 'Sorting',
  object_type: 'BUTTON',
};
const massCampaignDetailsGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'Accept New Lead',
  stage: 'Mass Campaign Lead List',
  screen_name: 'Mass Campaign Details',
  object_name: 'Accept',
  object_type: 'BUTTON',
};
const agencyCampaignListGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'Search Agency Campaign',
  stage: 'Search',
  screen_name: 'Agency Campaign List',
  object_name: 'Search',
  object_type: 'BUTTON',
};
const agencyCampaignListGaDataClick62: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'Search Agency Campaign',
  stage: 'Sorting',
  screen_name: 'Agency Campaign List',
  object_name: 'Sorting',
  object_type: 'BUTTON',
};
const agencyCampaignListGaDataClick63: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'Search Agency Campaign',
  stage: 'Filter',
  screen_name: 'Agency Campaign List',
  object_name: 'Filter',
  object_type: 'BUTTON',
};
const agencyCampaignDetailGaDataClick64: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'Host Agency Campaign',
  stage: 'View Approval',
  screen_name: 'Agency Campaign Detail',
  object_name: 'Approval',
  object_type: 'Tab',
};
const agencyCampaignDetailGaDataClick65: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'Host Agency Campaign',
  stage: 'Post Event Document',
  screen_name: 'Agency Campaign Detail',
  object_name: 'Post Event Document',
  object_type: 'Tab',
};
const agencyCampaignDetailGaDataClick66: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'Host Agency Campaign',
  stage: 'Lead Management',
  screen_name: 'Agency Campaign Detail',
  object_name: 'Lead',
  object_type: 'Tab',
};
const servicingCampaignListGaDataClick: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'Search Servicing Campaign',
  stage: 'Search',
  screen_name: 'Servicing Campaign List',
  object_name: 'Search',
  object_type: 'BUTTON',
};
const servicingCampaignListGaDataClick68: GAData = {
  module: 'PRULeads',
  feature: 'Campaign',
  journey: 'Search Servicing Campaign',
  stage: 'Sorting',
  screen_name: 'Servicing Campaign List',
  object_name: 'Sorting',
  object_type: 'BUTTON',
};

export {
  youGotANewOpportunityGaDataClick,
  youGotANewOpportunityGaDataClick4,
  theseLeadAreGettingColdGaDataClick,
  theseLeadAreGettingColdGaDataClick6,
  theseLeadAreGettingColdGaDataClick7,
  theseLeadAreGettingColdGaDataClick8,
  theseLeadAreGettingColdGaDataClick9,
  theseLeadAreGettingColdGaDataClick10,
  pRULeadsOpportunityListGaDataClick,
  prospectDetailGaDataClick,
  pRULeadsHomeScreenGaDataClick13,
  successfullyContactedLeadYesterdayGaDataClick,
  successfullyContactedLeadYesterdayGaDataClick15,
  pRULeadsHomeScreenGaDataClick16,
  pRULeadsHomeScreenGaDataClick17,
  pRULeadsFollowUpListGaDataClick,
  successfullyContactedLeadYesterdayGaDataClick19,
  prospectDetailGaDataClick20,
  marketingCampaignDetailsGaDataClick,
  agencyCampaignDetailsGaDataClick,
  insightLeadDetailsGaDataClick,
  leadAutoDropListGaDataClick,
  pRULeadsCloseLeadListGaDataClick,
  allGaDataClick,
  prospectDetailsGaDataClick,
  pRULeadsHomePageGaDataClick,
  prospectDetailsGaDataClick32,
  marketingCampaignDetailsGaDataClick33,
  agencyCampaignDetailsGaDataClick34,
  insightLeadDetailsGaDataClick35,
  leadDetailsGaDataClick,
  reassignLeadGaDataClick,
  reassignLeadGaDataClick38,
  reassignLeadGaDataClick39,
  agencyCampaignDetailGaDataClick,
  prospectDetailsGaDataClick42,
  salesViewProspectGaDataClick,
  salesViewProspectGaDataClick44,
  salesViewProspectGaDataClick45,
  prospectDetailsGaDataClick46,
  prospectDetailsGaDataClick47,
  searchProspectGaDataClick,
  prospectDetailsGaDataClick49,
  prospectListGaDataClick,
  prospectListGaDataClick51,
  prospectListGaDataClick52,
  prospectListGaDataClick53,
  marketingCampaignListGaDataClick,
  marketingCampaignListGaDataClick57,
  massCampaignListGaDataClick,
  massCampaignListGaDataClick59,
  massCampaignDetailsGaDataClick,
  agencyCampaignListGaDataClick,
  agencyCampaignListGaDataClick62,
  agencyCampaignListGaDataClick63,
  agencyCampaignDetailGaDataClick64,
  agencyCampaignDetailGaDataClick65,
  agencyCampaignDetailGaDataClick66,
  servicingCampaignListGaDataClick,
  servicingCampaignListGaDataClick68,
};
