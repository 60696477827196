import React, { FC, memo, useCallback, useMemo, useContext } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { ALL_OPTION, useSelectDownline } from './select-downline.hook';
import { CascadingSelector, Option } from '../cascading-selector/cascading-selector.component';
import { FilterState } from '../ProTable/ProFilter';
import { ListItemText } from '@mui/material';
import { GlobalHelper } from '../../helpers/GlobalHelpers';
import { AgentRole } from '../../types/common-types';
import { CampaignTypeEnum } from 'src/app/modules/Campaign/types/campaign-types';
import { OperationContext } from 'src/app/modules/Leads/components/lead-list-item/lead-operation-units/util/operation-context.util';

export type SelectDownlineProps = ParamsProps & {
  /* otherProp: string */
  campaignId: string;
  onChange?: (option: Array<Option>, codeArr: Array<string>) => void;
  isCampaignOwner?: boolean;
  currentOption?: Option[];
  campaignType: CampaignTypeEnum.normal | CampaignTypeEnum.agency;
};

export const SelectDownline: React.FC<SelectDownlineProps> = memo((props: SelectDownlineProps) => {
  const { onChange } = props;
  const { event } = useContext(OperationContext);
  const { loading, cascaderData, run, defaultSelected, role, agentCode, agentUnit } = useSelectDownline(props);
  const onSelectComplete = (selected: any) => {
    const [unitNode, agentNode] = selected;
    if (agentNode.value === ALL_OPTION.ALL_AGENT) {
      const allAgentCode = unitNode.child
        .filter((item: any) => item.value !== ALL_OPTION.ALL_AGENT)
        .map((item: any) => item.value);
      onChange && onChange(selected, allAgentCode);
    } else {
      onChange && onChange(selected, [agentNode.value]);
    }
    event && event.emit();
  };

  const renderNode = useCallback((node: Option) => {
    return (
      <div>
        <ListItemText primary={node.label} />
        {node.value !== ALL_OPTION.ALL_AGENT && <span>{node.value}</span>}
      </div>
    );
  }, []);

  const renderDisplayText = useCallback(
    (selected: Option[]) => {
      const [unitNode, agentNode] = selected;
      const unitLabel =
        agentCode === agentNode.value && unitNode.value === ALL_OPTION.ALL_UNIT ? agentUnit : unitNode.label;
      const agentLabel = agentNode.label;
      return `${unitLabel}  ${agentLabel}`;
    },
    [agentCode, agentUnit],
  );

  return role === AgentRole.MANAGER ? (
    <CascadingSelector
      renderNode={renderNode}
      data={cascaderData}
      onSelectComplete={onSelectComplete}
      defaultSelected={defaultSelected}
      renderDisplayText={renderDisplayText}
    />
  ) : (
    <></>
  );
});
