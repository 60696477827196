import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    display: 'flex',
  },
  updateTimeBox: {
    maxWidth: '138px',
  },
  normalText: {
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#666666',
  },
  spliter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '6px',
  },
  dot: {
    width: '12px',
    height: '12px',
    borderRadius: '6px',
    backgroundColor: '#E8192C',
    marginLeft: '16px',
    marginRight: '16px',
  },
  line: {
    width: '2px',
    height: '35px',
    backgroundColor: '#CCCCCC',
    marginTop: '12px',
    marginBottom: '5px',
  },
  activeText: {
    fontWeight: 500,
    color: '#333333',
  },
}));
