import { useState, useRef, useEffect, useCallback } from 'react';
import { get, forEach } from 'lodash';
import { getDayStart, getDayEnd } from './time-utils';

export function getDisplayName(agentInfo: any, lang: string): string {
  const langMapping = {
    en: 'enUs',
    'zh-Hant': 'zhHk',
  };
  const langKey = get(langMapping, lang, 'enUs');
  let displayName = get(agentInfo, `displayName.${langKey}`);
  if (!displayName || typeof displayName !== 'string') {
    displayName = get(agentInfo, `name.${langKey}.displayName`, get(agentInfo, `name.enUs.displayName`, '--'));
  }
  return displayName;
}
export const isValueExist = (field: any) => {
  return field !== undefined && field !== null;
};

export const numberSeparator = (num: string) => {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeSeparator = (str: string) => {
  return str.replace(/,/g, '');
};

export const preFillZero = (code: string): string => {
  const REACT_APP_AGENT_CODE_LENGTH = `${window.envConfig['REACT_APP_AGENT_CODE_LENGTH']}` || '8';
  const codeLengthInterger = parseInt(REACT_APP_AGENT_CODE_LENGTH);
  let userId: string = code.trim();
  // let allNumberRegx = /^[0-9]+$/;
  let allNumberRegx = new RegExp(/^[0-9]+$/);
  // Only Add '0' to front if that is agent code login ID
  while (userId.length < 5 && userId.match(allNumberRegx)) {
    userId = '0' + userId;
  }
  return userId;
};

export const preFillZeroList = (codeList: string[]): string[] => {
  return codeList.map((code) => {
    return preFillZero(code);
  });
};

// Input Byte, Output MB
export const fileSizeConvertorMb = (size: number) => {
  return `${(size / 1024 / 1024).toFixed(2)}MB`;
};

// export const NumberGreaterThanZeroPattern = /^([1-9]\d*(\.\d*[1-9])?)|(0\.\d*[1-9])$/;
export const NumberGreaterThanZeroPattern = new RegExp(/^([1-9]\d*(\.\d*[1-9])?)|(0\.\d*[1-9])$/);

export const disableInputInvalidValueForWeight = () => {
  return {
    step: 'any',
    onKeyPress: (event: any) => {
      const invalidChars = ['-', '+', 'e', 'E'];
      if (invalidChars.indexOf(event.key) !== -1) {
        event.preventDefault();
      }
    },
    onInput: (event: any) => {
      const invalidChars = ['-', '+', 'e', 'E'];
      if (invalidChars.indexOf(event.key) !== -1) {
        event.preventDefault();
      }
    },
    onPaste: (event: any) => {
      event.preventDefault();
    },
  };
};

export function useDebounce(fn: Function, delay: number, dep = []) {
  const initData: { fn: Function; timer: NodeJS.Timeout | null } = { fn, timer: null };
  const { current } = useRef(initData);
  useEffect(
    function () {
      current.fn = fn;
      // eslint-disable-next-line
    },
    [fn],
  );

  return useCallback(function f(...args: any[]) {
    if (current.timer) {
      clearTimeout(current.timer);
    }
    current.timer = setTimeout(() => {
      current.fn(...args);
    }, delay);
    // eslint-disable-next-line
  }, dep);
}

export function useThrottle(fn: Function, delay: number, dep = []) {
  const initData: { fn: Function; timer: NodeJS.Timeout | null } = { fn, timer: null };
  const { current } = useRef(initData);
  useEffect(
    function () {
      current.fn = fn;
      // eslint-disable-next-line
    },
    [fn],
  );

  return useCallback(function f(...args: any[]) {
    if (!current.timer) {
      current.timer = setTimeout(() => {
        current.timer = null;
      }, delay);
      current.fn(...args);
    }
    // eslint-disable-next-line
  }, dep);
}

export const csvHandler = (file: File): Promise<string[][]> => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = () => {
      let csvData = String(reader.result);
      let rows = csvData.split('\n');
      resolve(rows.map((row) => row.split(',')));
    };
    if (file) {
      reader.readAsBinaryString(file);
    }
  });
};

export const convertArrayToObject = (array: any[], key: string) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export enum FormMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
  DETAIL = 'detail',
}

export const pathExtractor = (currentPath: string, basePath: string) => {
  let path = currentPath.split(basePath).pop();
  if (path) {
    let pathArr = path.split('/');
    let formMode = pathArr[1] as FormMode;
    if (formMode === FormMode.CREATE) {
      return {
        formMode,
      };
    } else if (formMode === FormMode.EDIT || formMode === FormMode.VIEW || formMode === FormMode.DETAIL) {
      return {
        formMode,
      };
    }
  }
  return {
    formMode: FormMode.CREATE,
  };
};

/**
 * is a valid mail address
 * @param mailAddr target mail address
 * @returns true / false
 */
export function isEmail(mailAddr: string): boolean {
  if (!mailAddr) {
    return false;
  }
  // const regEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  const regEmail = new RegExp(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/);
  return regEmail.test(mailAddr);
}

export const useIsMobile = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  return width < 992 && navigator.userAgent.match(/iPad/i) === null;
};

export const roundPercentages = (values: number[]) => {
  const flooredValues = values.map((value) => Math.floor(value));
  const remainders = values.map((value) => value - Math.floor(value));
  const totalRemainder = 100 - flooredValues.reduce((a, b) => a + b);

  // Deep copy because order of remainders is important
  [...remainders]
    // Sort from highest to lowest remainder
    .sort((a, b) => b - a)
    // Get the n largest remainder values, where n = totalRemainder
    .slice(0, totalRemainder)
    // Add 1 to the floored percentages with the highest remainder (divide the total remainder)
    .forEach((r) => (flooredValues[remainders.indexOf(r)] += 1));

  return flooredValues;
};

export const calculateItemWidth = (columnGap: number, numPerRow: number) =>
  `calc((100% - ${columnGap * (numPerRow - 1)}px) / ${numPerRow})`;

export const getViewPort = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export const getBreakpoint = (mode: 'sm' | 'md' | 'lg' | 'xl') => {
  const breakpoints = {
    sm: 544, // Small screen / phone
    md: 768, // Medium screen / tablet
    lg: 992, // Large screen / desktop
    xl: 1200, // Extra large screen / wide desktop
  };
  return breakpoints[mode];
};

export enum APP_CONFIG {
  'GLOBAL_SYSTEM' = 'global-system',
  'GLOBAL_REGION' = 'global-region',
  'SALES_SDK' = 'sales-sdk',
  'SALES_COMMON' = 'sales-common',
}

export const getAppConfigs = (key: APP_CONFIG) => {
  const configs = window.localStorage.getItem('appConfigs');
  const appConfigs = configs ? JSON.parse(configs) : [];
  const config = appConfigs.find((config: any) => config.name === key);
  return get(config, 'content', {});
};

export const languageConvertor = (language: string) => {
  if (language === 'en') {
    return 'en-US';
  } else {
    return 'zh-HK';
  }
};
