import { getAutoDropLeadList } from 'src/app/modules/Leads/network/leadsCrud';

export const getDropList = async (
  params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
  sort?: { [key: string]: any },
  filter?: { [key: string]: any },
  dispatch?: React.Dispatch<any>,
  talkbot?: { isTalkbotLead?: boolean; isExportToTalkbot?: boolean },
) => {
  const { page, pageSize: limit } = params;
  const formatParams: any = { page: page + 1, limit, includeMassLead: true };

  if (sort) {
    const sortItem = Object.entries(sort)?.[0];
    if (sortItem) {
      formatParams.sortBy = sortItem[0];
      formatParams.sortOrder = sortItem[1] === 'asc' ? 1 : -1;
    }
  }

  if (!formatParams.sortBy) {
    formatParams.sortBy = 'dropDate';
    formatParams.sortOrder = 1;
  }

  if (talkbot) {
    formatParams.isTalkbotLead = talkbot.isTalkbotLead;
    formatParams.isExportToTalkbot = talkbot.isExportToTalkbot;
  }

  const res = await getAutoDropLeadList(formatParams, dispatch);
  if (res) {
    const { lead, totalDocs, ...rest } = res;

    return {
      success: true,
      data: (lead ?? []).map(({ prospect, prospectMaster, prospectDetail, ...rest }) => ({
        ...rest,
        prospectDetail,
        prospect: prospectDetail ?? prospect ?? prospectMaster,
      })),
      total: totalDocs,
      ...rest,
    };
  }

  return {
    success: false,
    data: [],
    total: 0,
    reminderAutoDropLeadDay: undefined,
  };
};

export const getDropListCount = async (
  dispatch?: React.Dispatch<any>,
  talkbot?: { isTalkbotLead?: boolean; isExportToTalkbot?: boolean },
) => {
  const res = await getAutoDropLeadList(
    {
      page: 1,
      limit: 1,
      includeMassLead: true,
      isTalkbotLead: talkbot?.isTalkbotLead,
      isExportToTalkbot: talkbot?.isExportToTalkbot,
    },
    dispatch,
  );
  if (res) {
    const { totalDocs, totalAutoDropLeadDay, reminderAutoDropLeadDay } = res;

    return {
      leadCount: totalDocs,
      totalAutoDropLeadDay,
      reminderAutoDropLeadDay,
    };
  }

  return {
    leadCount: 0,
  };
};
