import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    margin: theme.spacing(1),
    borderRadius: 5,
  },
  error: {
    backgroundColor: 'red',
  },
  stepIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#999999',
    borderRadius: '50%',
    color: '#FFFFFF',
    width: '24px',
    height: '24px',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '21.6px',
  },
  activeStepIcon: {
    backgroundColor: '#E8192C',
    ':after': {
      content: '""',
      position: 'absolute',
      width: '28px',
      height: '28px',
      borderRadius: '50%',
      border: '4px solid #FFE4E6',
    },
  },
  select: {
    width: '320px',
    borderRadius: '16px',
  },
  stepper: {
    margin: '24px',
  },
  textArea: {
    height: '240px',
    width: '100%',
    boxShadow: ' 0px 2px 12px 0px rgba(0, 0, 0, 0.08)',
    padding: '24px',
    borderRadius: '8px',
  },
  input: {
    width: '100%',
    height: '100%',
    resize: 'none',
  },
  commentTtitle: {
    color: '#666666',
    marginTop: '40px',
    marginBottom: '24px',
    fontSize: '24px',
    lineHeight: '32px',
  },
  backBt: {
    marginRight: '24px',
    border: '2px solid #CCCCCC',
  },
}));
