import React, { useMemo } from 'react';
import { ProspectTypes } from '../../../types/prospect-types';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

export type ProspectTypeTagProps = {
  status: string;
};

const tagConfig = {
  // currently only support these two types
  [ProspectTypes.prospect_type_non_candidate]: {
    color: '#E8192C',
    bgColor: '#FFE4E6',
    i18nKey: 'pruleads.prospect.list.prospectTypeNonCandidate',
  },
  [ProspectTypes.prospect_type_non_customer]: {
    color: '#4386E5',
    bgColor: '#EBF3FF',
    i18nKey: 'pruleads.prospect.list.prospectTypeNonCustomer',
  },
  // rest type no support, use default setting
  [ProspectTypes.prospect_type_agent]: {
    color: '#E8192C',
    bgColor: '#FFE4E6',
    i18nKey: 'pruleads.prospect.list.prospectTypeAgent',
  },
  [ProspectTypes.prospect_type_candidate]: {
    color: '#E8192C',
    bgColor: '#FFE4E6',
    i18nKey: 'pruleads.prospect.list.prospectTypeCandidate',
  },
  [ProspectTypes.prospect_type_customer]: {
    color: '#E8192C',
    bgColor: '#FFE4E6',
    i18nKey: 'pruleads.prospect.list.prospectTypeCustomer',
  },
};

export const useProspectTypeTag = ({ status }: ProspectTypeTagProps) => {
  const { t: Translation } = useTranslation();

  const configs = useMemo(() => {
    return Object.fromEntries(
      Object.entries(tagConfig).map(([key, value]) => [key, { ...value, text: Translation(value.i18nKey) }]),
    );
  }, [Translation]);

  return {
    status,
    configs,
  };
};
