import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    margin: theme.spacing(1),
    borderRadius: 5,
  },
  backBt: {
    borderColor: '#CCCCCC',
    borderWidth: 2,
    marginRight: 16,
  },
  sectionBox: {
    padding: '0px 24px',
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 8,
  },
  input: {
    width: '100%',
    padding: 0,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    padding: '24px 0px',
  },
  radio: {
    marginRight: '16px',
  },
  radionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleBox: {
    paddingBottom: '36px',
    marginTop: '24px',
  },
}));
