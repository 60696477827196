import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

/**
 * get agent info from auth
 * @returns agentInfo
 */
export const useAuth = () => {
  // redux
  // const dispatch = useDispatch();
  const agentInfo = useSelector<RootState>((state) => state.auth?.agentInfo);
  const { region, channel, sub, agentCode, role, email } = agentInfo || ({} as any);

  return {
    agentInfo,
    region,
    channel,
    sub,
    agentCode,
    role,
    email,
    divisionCode: get(agentInfo, 'division.code'),
  };
};
