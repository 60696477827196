import React, { FC, memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';

import { useStyles } from './reject-dialog.style';
import PruDialog from 'src/app/common/components/PruDialog';
import { CheckableSelectOption } from 'src/app/common/components/universal-selector-dialog/select-option/select-option.component';

export type DialogProps<T> = {
  open: boolean;
  onConfirm?: (val: string[]) => void;
  onClose?: () => void;
  description?: string;
  title?: string;
  value?: string[];
  options: Array<T>;
};

export const RejectDialog = memo(
  <T extends { label: string; value: string }>({
    title,
    open,
    onConfirm,
    onClose,
    description,
    options,
  }: DialogProps<T>) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    const [selected, setSelected] = useState<string[]>([]);

    return (
      <PruDialog
        open={open}
        dialogTitle={title}
        confirmBtnText={Translation('app.button.confirm')}
        canncelBtnText={Translation('app.button.cancel')}
        onOk={() => {
          onConfirm?.(selected);
        }}
        onCancel={() => {
          onClose?.();
        }}
        disabledConfirm={selected.length === 0}
      >
        <Box className={styles.container}>
          {description ? <Typography className={styles.text}>{description}</Typography> : null}
          <Box>
            {options.map((row, index) => {
              const { label } = row;
              const isSelected = selected.includes(row.value);
              const selectedIndex = isSelected ? index : -1;
              return (
                <CheckableSelectOption
                  key={index}
                  selected={selectedIndex}
                  label={label}
                  index={index}
                  className={index === 0 ? undefined : styles.mt_24}
                  onClick={(index) => {
                    const value = row.value;
                    if (isSelected) {
                      setSelected((e) => e.filter((v) => v !== value));
                    } else {
                      setSelected((e) => [...e, value]);
                    }
                  }}
                />
              );
            })}
          </Box>
        </Box>
      </PruDialog>
    );
  },
);
