export const CAMPAIGN_TITLE = 'campaign.tilte';
export const CAMPAIGN_DETAIL_TITLE = 'campaign.detail_title';
export const CAMPAIGN_NAMESPACE = 'CAMPAIGN';
export const CAMPAIGN_BASE_PATH = '/campaign'; // referred by 'src/app/modules/AgencyCampaign/constants.ts', need to manually change if updated

export const MARKETING_CAMPAIGN_TITLE = 'campaign.marketing.title';
export const MARKETING_CAMPAIGN_NAMESPACE = 'MARKETING_CAMPAIGN';
export const MARKETING_CAMPAIGN_LIST_PATH = '/marketing-campaign-list';
export const MARKETING_CAMPAIGN_DETAIL_PATH = '/marketing-campaign-detail';

export const MASS_CAMPAIGN_TITLE = 'campaign.mass.title';
export const MASS_CAMPAIGN_NAMESPACE = 'MASS_CAMPAIGN';
export const MASS_CAMPAIGN_LIST_PATH = '/mass-campaign-list';
export const MASS_CAMPAIGN_DETAIL_PATH = '/mass-campaign-detail';

export const SERVICING_CAMPAIGN_TITLE = 'campaign.servicing.title';
export const SERVICING_CAMPAIGN_NAMESPACE = 'SERVICING_CAMPAIGN';
export const SERVICING_CAMPAIGN_LIST_PATH = '/servicing-campaign-list';
export const SERVICING_CAMPAIGN_DETAIL_PATH = '/servicing-campaign-detail';

export const DefaultFeedbackStatus = 'pendingCall';
