import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { FormControlLabel, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { PruDateTimePicker } from '../../../PruDatePicker';
import dayjs, { Dayjs } from 'dayjs';

type ComponentProps = ParamsProps & {
  label?: string;
  valueObj: DateTimeValue;
  onChange?: (param: DateTimeValue) => void;
  maxDateTime?: string;
};

export type DateTimeValue = {
  startTime: Date | Dayjs | string | null;
  endTime: Date | Dayjs | string | null;
};

export const ListFilterDateTimeRangePickerComponent: React.FC<ComponentProps> = memo(
  ({ label, valueObj, onChange, maxDateTime }: ComponentProps) => {
    // i18n
    const { t } = useTranslation();

    const maxDateLimit = useMemo(() => {
      return dayjs(maxDateTime ?? undefined);
    }, [maxDateTime]);

    const startMaxTime = useMemo(() => {
      const endDate = valueObj?.endTime;
      if (!endDate) return maxDateLimit;
      return dayjs(endDate).isBefore(maxDateLimit) ? dayjs(endDate) : maxDateLimit;
    }, [maxDateLimit, valueObj?.endTime]);

    const endMinTime = useMemo(() => {
      const startDate = valueObj?.startTime;
      if (!startDate) return null;
      return dayjs(startDate);
    }, [valueObj?.startTime]);

    return (
      <Stack sx={{ mt: 3 }}>
        <FormLabel sx={{ fontSize: 16, fontWeight: 600, color: 'rgba(51, 51, 51, 1)' }}>{label || ''}</FormLabel>
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', mt: 2 }}>
          <PruDateTimePicker
            value={valueObj?.startTime}
            sx={{ flex: 1 }}
            ampm={false}
            maxDateTime={startMaxTime}
            onChange={(val) => {
              onChange?.({
                startTime: val || '',
                endTime: valueObj?.endTime || '',
              });
            }}
          />
          <Typography sx={{ mx: 2 }}>{t('to')}</Typography>
          <PruDateTimePicker
            value={valueObj?.endTime}
            sx={{ flex: 1 }}
            ampm={false}
            minDateTime={endMinTime}
            onChange={(val) => {
              onChange?.({
                startTime: valueObj?.startTime || '',
                endTime: val || '',
              });
            }}
          />
        </Stack>
      </Stack>
    );
  },
);
