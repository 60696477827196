import React, { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, IconButton, Dialog, DialogTitle, DialogContent, Input, FormLabel, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './list-filter.style';
import { FilterComponentsEnum } from './filter-components.enum';
import { useListFilter } from './list-filter.hook';
import {
  DateRadioGroupType,
  ListFilterDateRadioGroupComponent,
  ListFilterCheckboxGroupComponent,
  ListFilterDropdown,
  RadioGroupComponent,
  RadioGroupType,
} from './components';
import { isEmptyArray } from 'formik';
import { isEqual } from 'lodash';
import { DateRangePickerType } from '../simple-date-range-picker/simple-date-range-picker.component';
import { ListFilterDateRangePickerComponent } from './components/list-filter-date-range-picker/list-filter-date-range-picker.component';
import { ListFilterInputComponent } from './components/list-filter-input/list-filter-input.component';
import {
  DateTimeValue,
  ListFilterDateTimeRangePickerComponent,
} from './components/list-filter-date-time-range-picker/list-filter-date-time-range-picker.component';

export type ApiKey = string;
type ApiItemKey = string;

type CommonFilterProps = {
  key: ApiKey; // the identifier of filter field
  title: string;
  subtitle?: string;
  items: Array<{
    itemKey: ApiItemKey; // the identifier of the option in this field
    itemLabel: string; // value of the option
    extraCompo?: FilterComponentsEnum; // extra component type
  }>;
  visible?: (val: ListFilterData) => boolean;
};

export type CommonListFilterConfig = CommonFilterProps & {
  type: Exclude<FilterComponentsEnum, FilterComponentsEnum.DROPDOWN | FilterComponentsEnum.INPUT>; // type of this filter field
};

export type DropdownFilterConfig = CommonFilterProps & {
  type: FilterComponentsEnum.DROPDOWN;
  inputLabel: string;
};

export type InputFilterConfig = CommonFilterProps & {
  type: FilterComponentsEnum.INPUT;
  inputLabel?: string;
};

export type ListFilterConfig = CommonListFilterConfig | DropdownFilterConfig | InputFilterConfig;

export type ListFilterData = {
  [key: ApiKey]: Array<ApiItemKey> | DateRadioGroupType | DateRangePickerType | RadioGroupType | DateTimeValue | number;
};

export interface ListFilterProps {
  open: boolean;
  filterValues?: ListFilterData;
  defaultValues?: ListFilterData;
  filterConfigs: Array<ListFilterConfig>;
  onClose: () => void;
  onConfirm?: (value: ListFilterData) => void;
  onCancel?: () => void;
  onFilterSelect?: (filterState: ListFilterData) => void;
}

export const ListFilterComponent: React.FC<ListFilterProps> = memo(
  ({ open, filterConfigs, filterValues, defaultValues, onClose, onConfirm, onFilterSelect }) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });
    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    // custom-hook
    const { filter, setFilter, resetFilter } = useListFilter({ filterValues, defaultValues });

    const renderFilter = useCallback(
      (config: ListFilterConfig) => {
        switch (config.type) {
          case FilterComponentsEnum.RADIO_GROUP:
            return (
              <ListFilterDateRadioGroupComponent
                input={filter[config.key] as DateRadioGroupType}
                fieldTitle={config.title}
                onChange={(val: DateRadioGroupType) => {
                  setFilter((oldValue) => ({
                    ...oldValue,
                    [config.key]: val,
                  }));
                }}
              />
            );
          case FilterComponentsEnum.CHECK_BOX_GROUP:
            return (
              <ListFilterCheckboxGroupComponent
                filterConfig={config}
                value={filter[config.key] as Array<ApiItemKey>}
                onChange={(val) => {
                  setFilter((oldValue) => ({
                    ...oldValue,
                    [config.key]: val,
                  }));
                }}
              />
            );
          case FilterComponentsEnum.DATE_TIME_PICKER:
            return (
              <ListFilterDateRangePickerComponent
                input={filter[config.key] as DateRangePickerType}
                fieldTitle={config.title}
                onChange={(val: DateRangePickerType) => {
                  setFilter((oldVal) => ({
                    ...oldVal,
                    [config.key]: val,
                  }));
                }}
              />
            );

          case FilterComponentsEnum.PURE_RADIO_GROUP:
            return (
              <RadioGroupComponent
                filterConfig={config}
                value={filter[config.key] as RadioGroupType}
                onChange={(val) => {
                  onFilterSelect && onFilterSelect({ [config.key]: val });
                  setFilter((oldValue) => ({
                    ...oldValue,
                    [config.key]: val,
                  }));
                }}
              />
            );
          case FilterComponentsEnum.DROPDOWN:
            return (
              <ListFilterDropdown
                config={config}
                value={filter[config.key] as Array<ApiItemKey>}
                onChange={(value) => {
                  setFilter((oldValue) => ({
                    ...oldValue,
                    [config.key]: value,
                  }));
                }}
              />
            );
          case FilterComponentsEnum.INPUT:
            return (
              <ListFilterInputComponent
                filterConfig={config}
                value={filter[config.key]}
                onChange={(e: any) => {
                  setFilter((oldValue) => ({
                    ...oldValue,
                    [config.key]: e.target.value,
                  }));
                }}
              />
            );
          case FilterComponentsEnum.DATE_TIME_RANGE_PICKER:
            return (
              <ListFilterDateTimeRangePickerComponent
                valueObj={filter[config.key] as DateTimeValue}
                label={config.title}
                onChange={(val) => {
                  setFilter((oldVal) => ({
                    ...oldVal,
                    [config.key]: val,
                  }));
                }}
              />
            );
        }
      },
      [filter, setFilter],
    );

    const filterForm = useCallback(() => {
      if (filterValues) {
        return (
          <div className={styles.fullWidth}>
            {filterConfigs
              .filter((config) => !config.visible || config.visible(filter))
              .map((config) => {
                return <React.Fragment key={config.key}>{renderFilter(config)}</React.Fragment>;
              })}
          </div>
        );
      } else {
        return null;
      }
    }, [filterValues, styles.fullWidth, filterConfigs, renderFilter, filter]);

    return (
      <>
        <Dialog
          open={open}
          onClose={() => {
            resetFilter();
            onClose();
          }}
          classes={{ paper: styles.dialogContainer, root: styles.dialogRoot }}
        >
          <div className={styles.dialogHeader}>
            <DialogTitle>{Translation('agencyCampaign.dialog.filter')}</DialogTitle>
            <IconButton
              onClick={() => {
                resetFilter();
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent dividers classes={{ root: styles.dialogContentContainer }}>
            {filterForm()}
          </DialogContent>
          <div className={styles.dialogFooter}>
            <div className={styles.dialogButtonContainer}>
              <Button classes={{ root: styles.dialogButton }} color="info" variant="outlined" onClick={resetFilter}>
                {Translation('app.button.reset')}
              </Button>
              <Button
                classes={{ root: styles.dialogButton }}
                disabled={isEqual(filter, filterValues)}
                variant="contained"
                color="secondary"
                onClick={() => {
                  //remove empty item
                  const filteredList: ListFilterData = {};
                  Object.entries(filter).forEach((item) => {
                    if (!isEmptyArray(item[1])) {
                      filteredList[item[0]] = item[1];
                    }
                  });
                  onConfirm?.(filteredList);
                }}
              >
                {Translation('app.button.confirm')}
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  },
);
