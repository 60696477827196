import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    borderRadius: 5,
    margin: theme.spacing(1),
  },
  error: {
    backgroundColor: 'red',
  },
  operationBtn: {
    fontSize: 14,
    fontWeight: 700,
  },
  itemText: {
    fontWeight: 400,
    fonstSize: 14,
    lineHeight: '20px',
    color: '#666666',
  },
  ml_24: {
    marginLeft: 24,
  },
}));
