import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  filterContainer: {
    padding: '0px 24px',
  },
  selectorConatiner: {
    marginBottom: '24px',
  },
}));
