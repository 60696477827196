import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/network/authSlice';
import PruRoute from '../../../../common/components/PruRoute';
import { AGENT_BASE_PATH, FINANCIAL_AGENT_AGENT_PATH, AL_AGENT_AGENT_PATH, DOWNLINE_AGENT_PATH, GL_AGENT_AGENT_PATH, AL_AGENT_PATH_FOR_GL, FC_AGENT_PATH_FOR_GL } from '../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import FinancialAgentListingPage from './List/FinancialAgentListingPage';
import AlAgentListingPage from './List/AlAgentListingPage';
import GlAgentListingPage from './List/GlAgentListingPage';
import DownlineFcListingPage from './List/DownlineFcListingPage';
import DownlineAlListingPage from './List/DownlineAlListPage';
import DownlineFcforGlListingPage from './List/DownlineFcforGlListingPage';
const FinancialAgentListPath = `${AGENT_BASE_PATH}${FINANCIAL_AGENT_AGENT_PATH}`;
const DownlineFcPath = `${AGENT_BASE_PATH}${DOWNLINE_AGENT_PATH}`;
const AlAgentListPath = `${AGENT_BASE_PATH}${AL_AGENT_AGENT_PATH}`;
const GlAgentListPath = `${AGENT_BASE_PATH}${GL_AGENT_AGENT_PATH}`;
const AlAgentListPathForGlPath = `${AGENT_BASE_PATH}${AL_AGENT_PATH_FOR_GL}`
const FcAgentListPathForGlPath = `${AGENT_BASE_PATH}${FC_AGENT_PATH_FOR_GL}`
const FinancialAgentRoutes: FC<RouteComponentProps> = (props) => {
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const pathname = props.location.pathname;


  let actionRemarks = '';
  if (pathname.includes(`${FinancialAgentListPath}/detail/`)) {
    actionRemarks = 'Campaign ID: ' + pathname.split(`${FinancialAgentListPath}/detail/`)[1];
  }

  const analytics = getAnalytics();
  logEvent(analytics, 'function_access', {
    agent_code: user?.sub || '-',
    action_entry: 'Agency Campaign Entry',
    action_type: 'View',
    action_page: 'AgencyCampaign',
    action_remarks: actionRemarks,
    created_at: new Date().toString(),
  });

  return (
    <Switch>
      <PruRoute path={`${FinancialAgentListPath}`} component={FinancialAgentListingPage} />
      <PruRoute path={`${DownlineFcPath}`} component={DownlineFcListingPage} />
      <PruRoute path={`${AlAgentListPath}`} component={AlAgentListingPage} />
      <PruRoute path={`${GlAgentListPath}`} component={GlAgentListingPage} />
      <PruRoute path={`${AlAgentListPathForGlPath}`} component={DownlineAlListingPage} />
      <PruRoute path={`${FcAgentListPathForGlPath}`} component={DownlineFcforGlListingPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default FinancialAgentRoutes;
