import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './unread.style';

type ComponentProps = {
  /* otherProp: string */
};

export const Unread: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <Box className={styles.container}>
      <span className={styles.text}>{Translation('agencyCampaign.list.column.new')}</span>
    </Box>
  );
});
