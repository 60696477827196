import React, { FC, memo, ReactNode, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './section-mask.style';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

type ComponentProps = {
  children: ReactNode;
  showMask?: boolean;
  text?: string;
};

export const SectionMask: React.FC<ComponentProps> = memo(({ children, showMask, text }: ComponentProps) => {
  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  return (
    <Box className={styles.container}>
      {children}
      {showMask ? (
        <Box className={styles.mask}>
          <LockOutlinedIcon className={styles.icon} />
          {text ? <Typography className={styles.text}>{text}</Typography> : null}
        </Box>
      ) : null}
    </Box>
  );
});
