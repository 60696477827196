import React, { useMemo } from 'react';
import moment from 'moment';
import { formatSLA } from 'src/app/common/utils';
import { AgentStatusEnum, Lead } from '../../../types/types';

interface HookProps {
  slaDate?: string;
  currentTime?: number;
  item: Lead;
}

export const useSlaTimeCounter = ({ slaDate, currentTime: initTime, item }: HookProps) => {
  return useMemo(() => {
    const currentTime = initTime ?? Date.now();
    return {
      time: slaDate ? formatSLA(slaDate, currentTime) : undefined,
      show: !!slaDate,
      isExpired: !!slaDate && moment(slaDate).isBefore(currentTime),
      isAccepted: item.agentStatus === AgentStatusEnum.accepted, // if is no agentStatus, we assume that it is not accept(for opportunity)
      isAcceptExpired: !!slaDate && !!item.agentStatusUpdatedAt && moment(slaDate).isBefore(item.agentStatusUpdatedAt), // if it is not agentStatusUpdatedAt, we assume that it is not expired
    };
  }, [initTime, slaDate, item.agentStatus, item.agentStatusUpdatedAt]);
};
