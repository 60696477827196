import { CampaignElement, CampaignLeadItem } from '../types/campaign-types';

export const queryData = (keyword: string, campaigns: any[]) => {
  let res: any[] = [];
  campaigns.forEach((item) => {
    if (item.name.toLowerCase().includes(keyword.toLowerCase())) res.push(item);
  });
  return res;
};

export const queryPhone = (keyword: string, campaigns: any[]) => {
  let res: any[] = [];
  campaigns.forEach((item) => {
    if (item.phoneNumber === keyword) res.push(item);
  });
  return res;
};

export const generateCompareFunction = (sortParams: {
  [key: string]: any;
}): ((a: CampaignElement, b: CampaignElement) => number) => {
  return function (a: CampaignElement, b: CampaignElement): number {
    for (const field in sortParams) {
      const sortOrder = sortParams[field];
      if (a[field as keyof CampaignElement] === null || a[field as keyof CampaignElement] === undefined) {
        return b[field as keyof CampaignElement] === null || b[field as keyof CampaignElement] === undefined
          ? 0
          : sortOrder === 'asc'
            ? -1
            : 1;
      } else if (b[field as keyof CampaignElement] === null || b[field as keyof CampaignElement] === undefined) {
        return sortOrder === 'asc' ? 1 : -1;
      }

      if (a[field as keyof CampaignElement] < b[field as keyof CampaignElement]) {
        return sortOrder === 'asc' ? -1 : 1;
      } else if (a[field as keyof CampaignElement] > b[field as keyof CampaignElement]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
    }
    return 0;
  };
};

// export const generateLeadsCompareFunction = (sortParams: {
//   [key: string]: any;
// }): ((a: CampaignLeadItem, b: CampaignLeadItem) => number) => {
//   return function (a: CampaignLeadItem, b: CampaignLeadItem): number {
//     for (const field in sortParams) {
//       const sortOrder = sortParams[field];

//       if (a[field as keyof CampaignLeadItem] < b[field as keyof CampaignLeadItem]) {
//         return sortOrder === 'asc' ? -1 : 1;
//       } else if (a[field as keyof CampaignLeadItem] > b[field as keyof CampaignLeadItem]) {
//         return sortOrder === 'asc' ? 1 : -1;
//       }
//     }
//     return 0;
//   };
// };
export const generateLeadsCompareFunction = <T extends Record<string, any>>(sortParams: {
  [key: string]: any;
}): ((a: T, b: T) => number) => {
  return function (a: T, b: T): number {
    for (const field in sortParams) {
      const sortOrder = sortParams[field];

      if (a[field as keyof T] < b[field as keyof T]) {
        return sortOrder === 'asc' ? -1 : 1;
      } else if (a[field as keyof T] > b[field as keyof T]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
    }
    return 0;
  };
};
