import { Prospect } from '../../Prospect/types/prospect-types';
import { reconstuctNameByLastName } from 'src/app/common/utils';
import { LEADS_DETAIL_PATH } from '../constants';

export function getProspectName(propsect: Prospect): string {
  return propsect.displayName ?? (reconstuctNameByLastName(propsect.firstName, propsect.lastName) || 'Default');
}

export const openLeadDetail = (history: any, record: any) => {
  history.push(`${LEADS_DETAIL_PATH}/${record._id}`, {});
};

export const openLeadDetailInNewTab = (record: any) => {
  window.open(`${LEADS_DETAIL_PATH}/${record._id}`);
};
