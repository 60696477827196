import apiClient from '../axios';
import { Dispatch } from 'react';
import { apiErrorHandler } from '../apiErrorHandler';
import { DesignationItem } from '../../types/common-types';
import { AGENT_URL } from 'src/app/common/network';

export const ENABLE_DESIGNATION = window.envConfig['ENABLE_DESIGNATION'] === 'true';

export const getDesignations = async (dispatch?: Dispatch<any>): Promise<DesignationItem[]> => {
  return apiClient
    .get<DesignationItem[]>(`${AGENT_URL}/agents/designations`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
