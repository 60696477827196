import React, { useCallback, useMemo, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useStyles } from './leads-list.style';
import { ListPageProps, useLeadsList } from './leads-list.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import { FilterTableContainer } from 'src/app/common/components/filter-table-container/filter-table-container.component';
import { getClosed, getFollowUps, getOpportunities } from './util/api.util';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Lead, TabValues } from '../../../types/types';
import { LEADS_DETAIL_PATH } from '../../../constants';
import { AcceptLeads } from './components/accept-leads/accept-leads.component';
import { ActionItems } from './components/action-items/action-items.component';
import { displayCount } from 'src/app/common/utils/display.util';
import { LeadListReminderComponent } from './components/lead-list-reminder/lead-list-reminder.component';
import { OperationContext } from '../../../components/lead-list-item/lead-operation-units/util/operation-context.util';
import { ActionNodeType, ActionNodeTypeProps } from 'src/app/common/components/ProTable';
import { AutoCallDialogComponent } from 'src/app/modules/ai-talkbot/common/components/auto-call-dialog/auto-call-dialog.component';
import { getEligibleLeads } from 'src/app/modules/ai-talkbot/common/utils/talkbot.utils';

export const LeadsListPage: React.FC<ListPageProps> = (props: ListPageProps) => {
  const { t } = useTranslation();

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    activeFlowIds,
    exportData,
    selectedTab,
    setOppCount,
    setFollCount,
    setCloCount,
    opportunitiesColumns,
    followUpsColumns,
    closedColumns,
    onTabChange,
    oppCount,
    follCount,
    cloCount,
    opportunitiesCountAndAccept,
    opportunitiesFilterConfigs,
    followUpFilterConfigs,
    closedFilterConfigs,
    updatePageInfo,
    oppProviderValue,
    follProviderValue,
    onAccepted,
    setExportData,
    enableMassAcceptLeads,
  } = useLeadsList(props);

  const onClickRow = useCallback(
    (record: Lead) => {
      props.history.push(`${LEADS_DETAIL_PATH}/${record._id}`, {});
    },
    [props.history],
  );

  const ActionNode: ActionNodeType<Lead> = useMemo(() => {
    return (props: ActionNodeTypeProps<Lead>) => (
      <ActionItems
        {...props}
        onAccept={onAccepted}
        onAutoCall={() => setExportData(getEligibleLeads(props.selectedRows, activeFlowIds))}
      />
    );
  }, [onAccepted]);

  return (
    <>
      <Page>
        {/* PACS Customization: PACSPFAP-3086 | [Phase-1] - Removes the reminder alert for phase 1 */}
        {/* <LeadListReminderComponent history={props.history} /> */}
        <PageTitle title={t('leads')} />
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          sx={{ marginTop: '24px' }}
        >
          {/* PACS Customization: PACSPFAP-2658 | Updated Label salesOpTitle */}
          <Tab
            label={`${t('salesOpTitle')}${displayCount(oppCount)}`}
            tabIndex={0}
            value={TabValues.opportunities}
            className={styles.tabsText}
          />
          <Tab
            label={`${t('salesFuTitle')}${displayCount(follCount)}`}
            tabIndex={1}
            value={TabValues.followUp}
            className={styles.tabsText}
          />
          <Tab
            label={`${t('salesClTitle')}${displayCount(cloCount)}`}
            tabIndex={2}
            value={TabValues.closed}
            className={styles.tabsText}
          />
        </Tabs>{' '}
        <OperationContext.Provider value={oppProviderValue}>
          {selectedTab === TabValues.opportunities ? (
            <Box position={'relative'}>
              <FilterTableContainer
                cacheParams={{ current: props.cacheParams.current?.[TabValues.opportunities] }}
                rowKey={'_id'}
                showSelect={true} // PACS Customization: PACSPFAP-3393
                getData={getOpportunities}
                filterConfigs={opportunitiesFilterConfigs}
                getColumns={opportunitiesColumns}
                ActionNode={ActionNode}
                onClickRow={(record: any) => {
                  props.history.push(`${LEADS_DETAIL_PATH}/${record._id}`, {});
                }}
                onLoad={(_, pageInfo) => updatePageInfo(setOppCount, pageInfo)}
              />
              <Box position={'absolute'} bottom="6px" left="24px">
                <AcceptLeads isAcceptLeads={opportunitiesCountAndAccept?.isAcceptLeads} />
              </Box>
            </Box>
          ) : null}
          {selectedTab === TabValues.followUp ? (
            <OperationContext.Provider value={follProviderValue}>
              <FilterTableContainer
                cacheParams={{ current: props.cacheParams.current?.[TabValues.followUp] }}
                rowKey={'_id'}
                getData={getFollowUps}
                filterConfigs={followUpFilterConfigs}
                getColumns={followUpsColumns}
                ActionNode={ActionItems}
                showSelect={false}
                onLoad={(_, pageInfo) => updatePageInfo(setFollCount, pageInfo)}
                onClickRow={onClickRow}
              />
            </OperationContext.Provider>
          ) : null}
          {selectedTab === TabValues.closed ? (
            <FilterTableContainer
              cacheParams={{ current: props.cacheParams.current?.[TabValues.closed] }}
              rowKey={'_id'}
              getData={getClosed}
              filterConfigs={closedFilterConfigs}
              getColumns={closedColumns}
              showSelect={false}
              onClickRow={onClickRow}
              onLoad={(_, pageInfo) => updatePageInfo(setCloCount, pageInfo)}
            />
          ) : null}
        </OperationContext.Provider>
        {exportData && exportData?.length > 0 && (
          <AutoCallDialogComponent items={exportData} onCancel={() => setExportData(undefined)} />
        )}
      </Page>
    </>
  );
};
