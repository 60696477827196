import moment from 'moment';
import React, { useMemo } from 'react';

interface HookProps {
  campaign: any;
}

export const useCampaignBrief = ({ campaign }: HookProps) => {
  const disableRSVPEdit = useMemo(() => {
    return campaign.startDate && moment().isAfter(campaign.startDate);
  }, [campaign.startDate]);

  return { disableRSVPEdit };
};
