import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgentTypeItem, ChartListParam } from '../../../types/agent-types';
import { map } from 'lodash';
import { RootState } from 'src/redux/store';
import { TokenInfo } from '../../../../Auth/types/auth-types';
import { DashboardItem, FCDashboardName, DashboardType, FCDashboardFilter } from '../../../types/dashboard-types';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { useDataProvider, useJWT } from 'src/app/common/utils';
import { fetchChartList, fetchFinancialAgentTypeList } from '../../../network/agentCrud';
import { fetchDownlineDivision, fetchDownlineAgentsList } from '../../../network/agentCrud'
import { ApiErrorResponse } from 'src/app/common/network';
import FinancialAgentList from './FinancialAgentList';
import { RouteComponentProps } from 'react-router';
import { useIntl } from 'react-intl';
import { decodeJWT } from 'src/app/modules/Auth/network/authCrud';
import { setTokenInfo } from 'src/app/modules/Auth/network/authSlice';

type FinancialAgentListingPageProps = {} & ParamsProps & RouteComponentProps;

const initialState: ChartListParam = {
  periodRange: 'All',
  chartType: 'Table',
  filter: '',
  value: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): ChartListParam => {
  return initialParams
    ? {
        periodRange: 'All',
        chartType: 'Table',
        filter: '',
        value: '',
      }
    : initialState;
};

const FinancialAgentListPage: FC<FinancialAgentListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
  history,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [filterState, setFilterState] = useState<ChartListParam>(paramsInitiator(initialParams));
  const [, setDashboardList] = useState<DashboardItem[]>();
  const [agentTypeList, setAgentTypeList] = useState<AgentTypeItem[]>();
  const [downlineAgent, setDownlineAgent] = useState<any>('');
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  /* PACS Customization: Convert jwt-decode to jose jwt for pentest fix */
  const user = useJWT();
  const role = user?.role;
  /* PACS Customization: Convert jwt-decode to jose jwt for pentest fix */
  const [myFilter, setMyFilter] = useState({
    monthly_dates__c: filterState.value,
    type: filterState.value,
  });
  const { isLoading, refreshData } = useDataProvider<DashboardItem[]>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        let res = await fetchChartList(filterState, dispatch);
        return res
      } catch (err) {}
    },
    setDashboardList,
    false,
  );
  useDataProvider<AgentTypeItem[]>(
    async () => {
      try {
        return (await fetchFinancialAgentTypeList(dispatch)).sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });
      } catch (err) {}
    },
    setAgentTypeList,
    false,
  );

  /* PACS Customization: Convert jwt-decode to jose jwt for pentest fix */
  useEffect(() => {
    const token = window.localStorage.getItem('jwt');

    if (token) {
      decodeJWT(token).then((jwt) => dispatch(setTokenInfo(jwt)));
    }
  }, []);
  /* PACS Customization: Convert jwt-decode to jose jwt for pentest fix */

  useEffect(() => {
    //refreshData();
     refreshData()
  }, [filterState, sortKey]);

  const agent_code__c = user?.sub || '';

  const fetchDownline = (agent_code__c: string): Promise<any> => {
    if(agent_code__c){
      return fetchDownlineDivision(agent_code__c, dispatch)
    }
    return new Promise<any>((resolve,reject) => {

    })
  }

 useEffect(() => {
  if(role === 'MANAGER'){
   fetchDownline(agent_code__c).then((result) =>{
    if(result) {
      const {groupCode} = result[0];
      fetchDownlineAgentsList(agent_code__c, groupCode, dispatch)
        .then((result) => {
          setDownlineAgent(result)
        })
        .catch((err: ApiErrorResponse) => {
          console.log(err)
        });
    }
   });
  } else {
    setDownlineAgent('')
  }
},[])


  return (
    <>
      <PruFilter
        title={Translation('dashboard.common.controlPanel')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN_PERIOD,
            field: 'periodRange',
            initialValue: filterState.periodRange,
            defaultValue: initialState.periodRange,
            displayName: Translation('kpiDashboard.dashboard.period'),
            choices: [...map(FCDashboardName, (option: string) => ({ displayName: option, value: option }))],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'chartType',
            initialValue: filterState.chartType,
            defaultValue: initialState.chartType,
            displayName: Translation('dashboard.common.type'),
            choices: [...map(DashboardType, (option: string) => ({ displayName: option, value: option }))],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'filter',
            initialValue: filterState.filter,
            displayName: Translation('dashboard.common.filter'),
            choices: [
              { displayName: "", value: "" },
              ...map(FCDashboardFilter.get(filterState.chartType), (option: string) => ({ displayName: option, value: option }))
            ],
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'value',
            initialValue: filterState.value,
            displayName: Translation('dashboard.common.value'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            periodRange: data.periodRange,
            chartType: data.chartType,
            filter: data.filter,
            value: data.value,
          })
        }
        fetchData={refreshData}
      />
      <FinancialAgentList
        filter={filterState}
        myFilter={myFilter}
        onRefresh={true}
        refreshdata={refreshData}
        setMyFilter={setMyFilter}
      />
    </>
  );
};

export default FinancialAgentListPage;
