import React, { FC, memo, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './aging-lead-table.style';
import { useAgingLeadTable } from './aging-lead-table.hook';
import ProFilterTable from 'src/app/common/components/ProTable';
import { FilterProps } from 'src/app/common/components/ProTable/ProFilter';
import { UpdateLeadStatusDialog } from '../update-lead-status-dialog/update-lead-status-dialog.component';
import { useHistory } from 'react-router-dom';
import { LEADS_DETAIL_PATH } from 'src/app/modules/Leads/constants';

type AgingLeadTableProps = ParamsProps & {
  /* otherProp: string */
  onSelectedLead?: (selected: any) => void;
  onSelectUnit?: (groupCode: string) => void;
  onUpdatedLead?: (updatedLead: any[]) => void;
};

type UnitFilterProps = FilterProps<any> & {
  units: { groupCode: string; count: number }[];
  defaultUnit?: string;
  onChange: (unit: string) => void;
};

const UnitFilter = (props: UnitFilterProps) => {
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const { setFilterState, onSave, filterState, onExtraFilterChange, units, onChange, defaultUnit } = props;

  const [selectedUnit, setSelectedUnit] = React.useState<string>('');

  useEffect(() => {
    if (defaultUnit) {
      onExtraFilterChange?.({ groupCode: defaultUnit });
      setSelectedUnit(defaultUnit);
      onChange(defaultUnit);
    }
  }, [defaultUnit]);

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={selectedUnit}
      className={styles.select}
      onChange={(e) => {
        const groupCode = e.target.value as string;
        setSelectedUnit(groupCode);
        const updatedLeadState = { ...filterState };
        updatedLeadState['groupCode'] = e.target.value;
        setFilterState(updatedLeadState);
        onSave(updatedLeadState);
        onExtraFilterChange?.({ groupCode: groupCode });
        onChange(groupCode);
      }}
    >
      {units.map((unit) => (
        <MenuItem value={unit.groupCode}>{unit.groupCode}</MenuItem>
      ))}
    </Select>
  );
};

export const AgingLeadTable: React.FC<AgingLeadTableProps> = memo((props: AgingLeadTableProps) => {
  const { onSelectedLead, onSelectUnit, onUpdatedLead } = props;
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const history = useHistory();

  // custom-hook
  const {
    selectedUnit,
    units,
    tableColumns,
    defaultUnit,
    showDialog,
    targetLead,
    actionRef,
    resetStatus,
    setShowDialog,
    getData,
    setSelectedUnit,
    onConfirmEditStatus,
  } = useAgingLeadTable({ onUpdatedLead: onUpdatedLead });

  const _UnitFilter = useMemo(() => {
    return (props: FilterProps<any>) => (
      <UnitFilter
        {...props}
        units={units}
        onChange={(unit) => {
          setSelectedUnit(unit);
          onSelectUnit && onSelectUnit(unit);
          resetStatus();
        }}
        defaultUnit={defaultUnit}
      />
    );
  }, [units, defaultUnit]);

  const onRowSelection = (selectedRows: any[]) => {
    onSelectedLead && onSelectedLead(selectedRows);
  };

  const rowSelection = useMemo(() => {
    return {
      onChange: onRowSelection,
      getCheckboxProps: () => {
        return {};
      },
    };
  }, []);

  return (
    <>
      <ProFilterTable
        Filter={_UnitFilter}
        showFilterIcon={false}
        showFilter={true}
        rowKey="leadId"
        rowSelection={rowSelection}
        columns={tableColumns}
        request={getData}
        enableRefresh={false}
        actionRef={actionRef}
        onClickRow={(record: any) => {
          history.push(`${LEADS_DETAIL_PATH}/${record.leadId}`);
        }}
        // onFilterChange={onFilterChange}
      />
      <UpdateLeadStatusDialog
        onConfirm={onConfirmEditStatus}
        targetLead={targetLead}
        open={showDialog}
        onCancel={() => setShowDialog(false)}
      />
    </>
  );
});
