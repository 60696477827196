import moment from 'moment';
import { DateFormat } from 'src/app/common/utils';
import { configManager } from 'src/app/common/utils/app-configs.util';

export const getAutoDeleteProspectsConfig = () => {
  const config = configManager.getConfig('sales-common');
  const autoDeleteProspectsConfig = config?.Sales?.prospect?.autoDeleteProspects;
  return {
    notifyDays: autoDeleteProspectsConfig?.notifyDays ?? 7,
    purgeDays: autoDeleteProspectsConfig?.noUpdatedDays ?? 365,
  };
};

export const getQueryDate = (date = new Date()) =>
  moment(date)
    .subtract(getAutoDeleteProspectsConfig().purgeDays - getAutoDeleteProspectsConfig().notifyDays, 'd')
    .toISOString();

export const getDateKey = (date: Date) => {
  const d = moment(date).add(getAutoDeleteProspectsConfig().purgeDays, 'd').format(DateFormat.date);
  return d;
};
