import { IntlShape } from "react-intl";
import { ModuleHierachy } from "../../common/module/types";
import { LANDING_PAGE_TITLE, LANDING_PAGE_PATH } from "./constants";
import LandingPage from "./pages/LandingPage";

export const landingModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(LANDING_PAGE_TITLE),
    path: LANDING_PAGE_PATH,
    disableRoleControl: true,
    disableMenu: true,
    namespace: "landing",
    component: LandingPage,
    enableRead: true
  }
}