import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonBorder: {
    borderColor: '#CCCCCC',
  },
  confirmButton: {
    marginLeft: '16px',
    backgroundColor: '#E8192C',
    color: '#FFFFFF',
  },
  tabContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  status: {
    padding: 12,
    marginTop: 24,
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: 8,
    display: 'flex',
  },
}));
