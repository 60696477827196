import { get } from 'lodash';

/**
 * @description tab number
 */
export const displayCount = (count?: number | null) => {
  const str = typeof count === 'number' && count > 99 ? '99+' : count ?? '';
  return str ? ` ${str}` : '';
};

export const sortHelper = <T>(a: T, b: T, layer?: string, asc = true) => {
  const helper = (a: string, b: string) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1) * (asc ? 1 : -1);

  if (typeof a === 'string') {
    return helper(a, b as string);
  }
  if (!layer) {
    console.error('Sort Error: layer is must when T is Object');
    return 0;
  }
  return helper(get(a, layer), get(b, layer));
};
