import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { ActionNodeTypeProps } from 'src/app/common/components/ProTable';
import { Lead } from 'src/app/modules/Leads/types/types';
import { batchAccept } from '../../util/api.util';

export type ActionNodeProps = ActionNodeTypeProps<Lead> & {
  onAccept?: (leads: Lead[]) => void;
  onAutoCall?: (leads: Lead[]) => void;
};

export const useActionItems = ({ selectedRows, onAction, onAccept, onAutoCall }: ActionNodeProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [acceptLoading, setAcceptLoading] = useState(false);

  const accept = async () => {
    if (acceptLoading) return;
    setAcceptLoading(true);
    onAutoCall?.(selectedRows);
    const res = await batchAccept(
      selectedRows.map((i) => i._id),
      dispatch,
      t,
    );
    onAccept?.(selectedRows);
    res && onAction(['refresh', 'clearRow']);
    setAcceptLoading(false);
  };

  return {
    acceptLoading,
    accept,
    setAcceptLoading,
  };
};
