import React, { useState } from 'react';
import { Box, Button, Chip, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { useProSearchFilter } from './pro-search-filter.hook';
import { FilterProps } from '../../ProFilter';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ListFilterComponent } from '../../../list-filter/list-filter.component';
import { SearchIcon, FilterIcon, ClearIcon } from '../../../svg-icon/svg-icon.component';
import Remove from '@mui/icons-material/Clear';
import { formatDate, DateFormat } from 'src/app/common/utils';
import { valueToDisplay } from '../../../list-filter/util/list-filter.util';

const StyledChip = withStyles(Chip, (theme) => ({
  root: {
    color: '#E8192C',
    borderRadius: '8px',
    fontSize: '14px',
    backgroundColor: '#FFE4E6',
    '& .MuiChip-deleteIcon': {
      color: '#E8192C',
      fontSize: '20px',
      backgroundColor: '#FFE4E6',
      width: '18px',
      height: '18px',
    },
  },
}));

export const ProSearchFilter = <T extends { [key: string]: any } = {}>(props: FilterProps<T>) => {
  const {
    total,
    loading,
    showFilter,
    showInput = true,
    sx,
    extraFilter,
    onExtraFilterChange,
    filterConfigs,
    placeholder,
    onFocus,
  } = props;

  const {
    filterModalVisible,
    setFilterModalVisible,
    value,
    handleInputChange,
    onClear,
    styles,
    Translation,
    loadingRef,
    setValue,
  } = useProSearchFilter(props);

  return (
    <Box sx={sx}>
      <Box className={styles.searchContainer}>
        {showInput ? (
          <OutlinedInput
            id="outlined-adornment-search"
            type="text"
            value={value}
            autoComplete="off"
            onFocus={onFocus}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className={styles.searchIcon} />
              </InputAdornment>
            }
            endAdornment={
              value ? (
                <InputAdornment position="end">
                  <IconButton aria-label="clear input value" onClick={onClear} edge="end">
                    <HighlightOffIcon className={styles.clearIcon} />
                  </IconButton>
                </InputAdornment>
              ) : undefined
            }
            placeholder={placeholder ?? Translation('pruleads.prospect.list.search.placeholder')}
            inputProps={{
              className: styles.input,
            }}
            className={styles.inputContainer}
            onChange={(e) => {
              loadingRef.current = 0;
              setValue(e.target.value);
              handleInputChange(e);
            }}
            sx={{
              input: {
                '&::placeholder': {
                  fontSize: 16,
                  fontWeight: 400,
                  color: '#919EAB',
                },
              },
            }}
          />
        ) : (
          <div></div>
        )}
        {showFilter ? (
          <>
            <Box className={styles.filterIconButtonContainer}>
              <Button
                onClick={() => {
                  setFilterModalVisible(true);
                }}
                variant="text"
              >
                <FilterIcon className={styles.filterIcon} />
                <Typography className={styles.filterIconButtonText}>
                  {Translation('pruleads.prospect.list.filter')}
                </Typography>
              </Button>
            </Box>
            {filterModalVisible ? (
              <ListFilterComponent
                filterValues={extraFilter!}
                filterConfigs={filterConfigs!}
                open={filterModalVisible}
                onConfirm={(value) => {
                  onExtraFilterChange?.({ ...value });
                  setFilterModalVisible(false);
                }}
                onClose={() => {
                  setFilterModalVisible(false);
                }}
              />
            ) : null}
          </>
        ) : null}
      </Box>
      {value && !loading && loadingRef.current === 1 ? (
        <Box className={styles.mb24}>
          <Typography className={styles.filterResultText}>
            {Translation('pruleads.prospect.list.filterResult', {
              num: <span className={styles.highlight}>{total}</span>,
            })}
          </Typography>
        </Box>
      ) : null}
      {extraFilter && Object.keys(extraFilter).length > 0 ? (
        <Box className={styles.filters}>
          <Typography className={styles.filterText} component={'div'}>
            {Translation('pruleads.prospect.list.filter')}:
          </Typography>
          {filterConfigs
            ?.filter((i) => extraFilter.hasOwnProperty(i.key))
            .map((i) => {
              const { key, title, subtitle } = i;

              const displayValue = valueToDisplay(extraFilter, i);

              return (
                <StyledChip
                  className={styles.mr12}
                  key={key}
                  label={`${subtitle || title}: ${displayValue}`}
                  deleteIcon={<Remove />}
                  onDelete={() => {
                    const e = { ...extraFilter };
                    delete e[key];
                    onExtraFilterChange?.(e);
                  }}
                />
              );
            })}
          <Button
            className={styles.ml4}
            onClick={() => {
              onExtraFilterChange?.({});
            }}
            variant="text"
          >
            <ClearIcon className={styles.deleteIcon} />
            <Typography className={styles.deleteText}>{Translation('pruleads.prospect.list.clearFilter')}</Typography>
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
