import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
  },
  closeIcon: {
    width: '36px',
    height: '36px',
    color: '#333333',
  },
  iconButton: {
    marginLeft: 16,
    marginRight: 20,
  },
  headerStyle: {
    flex: 1,
  },
  title: {
    margin: 24,
    marginBottom: 0,
  },
  ml16: {
    marginLeft: 16,
  },
  childrenContainer: {
    overflow: 'scroll',
    height: '100%',
  },
}));
