import { FC, useEffect } from 'react';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { useHistory } from 'react-router-dom';
import { Button, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ROOT_PATH, Routes } from '../../common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { authenticateFailed } from 'src/redux/common/commonSlice';
import { logout } from '../Auth/network/authSlice';

const NotFoundErrorPage: FC = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { hasToken, isExpired } = useSelector<RootState, { hasToken: boolean; isExpired: boolean }>((state) => {
    const token = window.localStorage.getItem('jwt') ?? '';
    return {
      hasToken: !!token,
      isExpired: !!state.auth?.isExpired,
    };
  });

  useEffect(() => {
    if (!hasToken || isExpired) {
      dispatch(authenticateFailed());
      dispatch(logout());
      history.push(ROOT_PATH);
    }
  }, [hasToken, isExpired]);

  return (
    <div
      className={classes.wrapper}
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/error/bg3.jpg')})`,
      }}
    >
      <div className={classes.backBtnContainer}>
        <Button variant="contained" color="inherit" onClick={() => history.push(ROOT_PATH)}>
          Back to root
        </Button>
      </div>
      <div className={classes.content}>
        <h1 className={classes.title}>404</h1>
        <p className={classes.subtitle}>Sorry we can't seem to find the page you're looking for.</p>
        <p className={classes.desc}>
          There may be a misspelling in the URL entered,
          <br />
          or the page you are looking for may no longer exist.
        </p>
      </div>
    </div>
  );
};

export default NotFoundErrorPage;

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flex: 1,
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 32,
    paddingTop: 32,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      paddingLeft: 60,
      paddingTop: 0,
    },
  },
  backBtnContainer: {
    position: 'absolute',
    right: 40,
    top: 40,
    display: 'inline',
  },
  title: {
    fontSize: 113,
    color: 'transparent',
    WebkitTextStrokeWidth: '4.5px',
    WebkitTextStrokeColor: '#ffffff',
    [theme.breakpoints.up('md')]: {
      fontSize: 200,
    },
  },
  subtitle: {
    fontSize: 26,
    fontWeight: 700,
    color: '#3F4254',
  },
  desc: {
    fontSize: 18,
  },
}));
