import React, { FC, useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { TokenInfo } from '../../../../Auth/types/auth-types';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { fetchDashboardJWT } from 'src/app/modules/AgencyCampaign/network/dashboardCrud';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { AlChartLibraryDev, AlTopDashboardsDev } from 'src/app/modules/KpiDashboard/types/dashboard-chartID-dev';
import { AlChartLibraryUat, AlTopDashboardsUat } from 'src/app/modules/KpiDashboard/types/dashboard-chartID-uat';
import { AlChartLibraryProd, AlTopDashboardsProd } from 'src/app/modules/KpiDashboard/types/dashboard-chartID-prod';

import { NO_DATA_AVAILABLE } from '../../constants';
import { AlertType, appendAlertItem } from '../../../../../../redux/common/commonSlice';
const baseUrl = window.envConfig['REACT_APP_MONGODB_CHART_BASE_URL'];
const chartSubscriptionKey = window.envConfig['REACT_APP_MONGODB_CHART_SUBSCRIPTION_KEY'];

type topDashboardsProps = {
  dashboardLeft: string;
};

type libararyProps = {
  report: { chartID: string; chartType: string }[];
};

var libarary: libararyProps = AlChartLibraryDev;
var topDashboards: topDashboardsProps = AlTopDashboardsDev;

type ChartListProps = {
  filter: any;
  myFilter: any;
  onRefresh?: boolean;
  refreshdata: () => void;
  setMyFilter: (data: any) => void;
};

const AlAgentList: FC<ChartListProps> = ({ filter, myFilter, onRefresh, refreshdata, setMyFilter }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [availableRows, setAvailableRows] = useState<any>();
  const [noDataAvailable, setNoDataAvailable] = useState<String>('No data');
  const [isShowChart, setIsShowChart] = useState<Boolean>(false);
  const user = useSelector<RootState, TokenInfo | undefined>((state) => state.auth.user);

  const getEnv = useMemo(() => {
    const env = window.envConfig['REACT_APP_ENV'];
    if (env === 'uat') {
      libarary = AlChartLibraryUat;
      topDashboards = AlTopDashboardsUat;
    } else {
      if (env === 'prod') {
        libarary = AlChartLibraryProd;
        topDashboards = AlTopDashboardsProd;
      }
    }
  }, []);

  const agent_code__c = user?.sub || '';
  let defaultFilter: any = { agent_code__c };

  if (filter.periodRange === 'All' && filter.value.length > 1) {
    defaultFilter = {
      $and: [
        { agent_code__c },
        {
          reporting_quarter__c: {
            $gte: new Date(new Date(filter.value[0]).setHours(0, 0, 0, 0)),
            $lt: new Date(filter.value[1]),
          },
        },
      ],
    };
  } else if (filter.periodRange === 'Last Quarter') {
    defaultFilter = {
      $and: [
        { agent_code__c },
        {
          reporting_quarter__c: {
            $gte: new Date(moment(new Date()).subtract(1, 'quarter').startOf('quarter').format('MM/DD/YYYY')),
            $lt: new Date(moment(new Date()).subtract(1, 'quarter').endOf('quarter').format('MM/DD/YYYY')),
          },
        },
      ],
    };
  } else if (filter.periodRange === 'This Year') {
    defaultFilter = {
      $and: [
        { agent_code__c },
        {
          reporting_quarter__c: {
            $gte: new Date(moment().startOf('year').format('MM/DD/YYYY')),
            $lt: new Date(moment(new Date()).format('MM/DD/yyyy')),
          },
        },
      ],
    };
  } else if (filter.periodRange === 'Last Year') {
    defaultFilter = {
      $and: [
        { agent_code__c },
        {
          reporting_quarter__c: {
            $gte: new Date(moment(new Date()).subtract(1, 'year').startOf('year').format('MM/DD/YYYY')),
            $lt: new Date(moment(new Date()).subtract(1, 'year').endOf('year').format('MM/DD/YYYY')),
          },
        },
      ],
    };
  }

  const sdk = new ChartsEmbedSDK({
    baseUrl,
    getUserToken: async function () {
      return await fetchDashboardJWT(user?.sub, chartSubscriptionKey);
    },
  });
  var chartID = '';

  for (var i = 0; i < libarary.report.length; i++) {
    var object = libarary.report[i];

    if (object.chartType == filter.chartType) {
      chartID = object.chartID;
      // console.log(chartID);
      break;
    }
  }

  const chartDiv = useRef<HTMLDivElement>(null);
  const chartDivFirstRowLeft = useRef<HTMLDivElement>(null);
  const chartDivFirstRowRight = useRef<HTMLDivElement>(null);
  const chartDivSecondRowLeft = useRef<HTMLDivElement>(null);
  const chartDivSecondRowRight = useRef<HTMLDivElement>(null);
  const chartDivThirdRowLeft = useRef<HTMLDivElement>(null);
  const chartDivThirdRowRight = useRef<HTMLDivElement>(null);
  const chartDivFourthRowLeft = useRef<HTMLDivElement>(null);
  const chartDivFourthRowRight = useRef<HTMLDivElement>(null);
  const chartDivFifthRowLeft = useRef<HTMLDivElement>(null);
  const chartDivFifthRowRight = useRef<HTMLDivElement>(null);
  const [rendered, setRendered] = useState(false);

  const [chart, reRender] = useState(
    sdk.createChart({
      chartId: chartID,
      height: 800,
      width: 1800,
      theme: 'light',
      filter: defaultFilter,
    }),
  );

  const [moreIdList, setMoreIdList] = useState<Array<string>>([]);

  const [chartTable, setChartTable] = useState(
    sdk.createChart({
      chartId: '62bd4752-a69a-468b-84b7-2cdf1739fddc',
      height: 800,
      width: 1800,
      theme: 'light',
      filter: defaultFilter,
    }),
  );

  const [chartLeft, setChartLeft] = useState(
    sdk.createChart({
      chartId: '62bd4752-a69a-468b-84b7-2cdf1739fddc',
      height: 800,
      theme: 'light',
      filter: defaultFilter,
    }),
  );

  const [chartRight, setChartRight] = useState(
    sdk.createChart({
      chartId: '62bd4752-a69a-468b-84b7-2cdf1739fddc',
      height: 800,
      theme: 'light',
      filter: defaultFilter,
    }),
  );

  const [chartLeftMiddle, setChartLeftMiddle] = useState(
    sdk.createChart({
      chartId: '62bd4752-a69a-468b-84b7-2cdf1739fddc',
      height: 800,
      theme: 'light',
      filter: defaultFilter,
    }),
  );

  const [chartRightMiddle, setChartRightMiddle] = useState(
    sdk.createChart({
      chartId: '62bd4752-a69a-468b-84b7-2cdf1739fddc',
      height: 800,
      theme: 'light',
      filter: defaultFilter,
    }),
  );

  const [chartLeftBottom, setChartLeftBottom] = useState(
    sdk.createChart({
      chartId: '62bd4752-a69a-468b-84b7-2cdf1739fddc',
      height: 800,
      theme: 'light',
      filter: defaultFilter,
    }),
  );

  const [chartRightBottom, setChartRightBottom] = useState(
    sdk.createChart({
      chartId: '62bd4752-a69a-468b-84b7-2cdf1739fddc',
      height: 800,
      theme: 'light',
      filter: defaultFilter,
    }),
  );

  const [chartLeftLast, setChartLeftLast] = useState(
    sdk.createChart({
      chartId: '62bd4752-a69a-468b-84b7-2cdf1739fddc',
      height: 800,
      theme: 'light',
      filter: defaultFilter,
    }),
  );

  const [chartRightLast, setChartRightLast] = useState(
    sdk.createChart({
      chartId: '62bd4752-a69a-468b-84b7-2cdf1739fddc',
      height: 800,
      theme: 'light',
      filter: defaultFilter,
    }),
  );

  const [chartRightEnd, setChartRightEnd] = useState(
    sdk.createChart({
      chartId: '62bd4752-a69a-468b-84b7-2cdf1739fddc',
      height: 800,
      theme: 'light',
      filter: defaultFilter,
    }),
  );

  const getTopCharts = (topDashboards: any) => {
    if (chartDiv.current) {
      let left = sdk.createChart({
        chartId: topDashboards.dashboardLeft,
        height: 800,
        width: 1800,
        theme: 'light',
        filter: defaultFilter,
      });
      setChartTable(left);
      left.render(chartDiv.current).then(() => {
        setRendered(true);
        left.getData().then((res) => {
          const rowCount = JSON.parse(JSON.stringify(res)).documents.length;
          setAvailableRows(rowCount);
          if (rowCount < 1) {
            setNoDataAvailable(NO_DATA_AVAILABLE);
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: Translation('kpiDashboard.agent.data.notfound.title'),
                  content: Translation('kpiDashboard.agent.data.notfound.description'),
                },
              ]),
            );
          }
        });
      });
    }
  };

  const getMoreCharts = () => {
    let idList = [];
    for (var i = 0; i < libarary.report.length; i++) {
      if (libarary.report[i].chartType === 'Chart') {
        idList.push(libarary.report[i].chartID);
      }
    }

    setMoreIdList(idList);

    let width = (window.innerWidth - 60) / 2;

    if (chartDivFirstRowLeft.current) {
      let leftTop = sdk.createChart({
        chartId: idList[0],
        height: 400,
        width: width,
        theme: 'light',
        filter: defaultFilter,
      });
      setChartLeft(leftTop);
      leftTop
        .render(chartDivFirstRowLeft.current)
        .then(() => {
          setRendered(true);
          leftTop.getData().then((res) => {
            const rowCount = JSON.parse(JSON.stringify(res)).documents.length;
            setAvailableRows(rowCount);
            if (rowCount < 1) {
              setNoDataAvailable(NO_DATA_AVAILABLE);
              dispatch(
                appendAlertItem([
                  {
                    severity: AlertType.ERROR,
                    title: Translation('kpiDashboard.agent.data.notfound.title'),
                    content: Translation('kpiDashboard.agent.data.notfound.description'),
                  },
                ]),
              );
            }
          });
        })
        .catch((err) => console.log('Error during get data.', err));
    }

    if (chartDivFirstRowRight.current) {
      let rightTop = sdk.createChart({
        chartId: idList[1],
        height: 400,
        width: width,
        theme: 'light',
        filter: defaultFilter,
      });
      setChartRight(rightTop);
      rightTop
        .render(chartDivFirstRowRight.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }

    if (chartDivSecondRowLeft.current) {
      let leftMiddle = sdk.createChart({
        chartId: idList[2],
        height: 400,
        width: width,
        theme: 'light',
        filter: defaultFilter,
      });
      setChartLeftMiddle(leftMiddle);
      leftMiddle
        .render(chartDivSecondRowLeft.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivSecondRowRight.current) {
      let rightMiddle = sdk.createChart({
        chartId: idList[3],
        height: 400,
        width: width,
        theme: 'light',
        filter: defaultFilter,
      });
      setChartRightMiddle(rightMiddle);
      rightMiddle
        .render(chartDivSecondRowRight.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivThirdRowLeft.current && moreIdList[4]) {
      let leftBottom = sdk.createChart({
        chartId: moreIdList[4],
        height: 400,
        width: width,
        theme: 'light',
        filter: defaultFilter,
      });
      setChartLeftBottom(leftBottom);
      console.log('chartDivLeftBottom');
      leftBottom
        .render(chartDivThirdRowLeft.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivThirdRowRight.current && moreIdList[5]) {
      let rightBottom = sdk.createChart({
        chartId: moreIdList[5],
        height: 400,
        width: width,
        theme: 'light',
        filter: defaultFilter,
      });
      setChartRightBottom(rightBottom);
      rightBottom
        .render(chartDivThirdRowRight.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivFourthRowLeft.current && moreIdList[6]) {
      let leftLast = sdk.createChart({
        chartId: moreIdList[6],
        height: 400,
        width: width,
        theme: 'light',
        filter: defaultFilter,
      });
      setChartLeftLast(leftLast);
      console.log('chartDivLeftBottom');
      leftLast
        .render(chartDivFourthRowLeft.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivFourthRowRight.current && moreIdList[7]) {
      let rightLast = sdk.createChart({
        chartId: moreIdList[7],
        height: 400,
        width: width,
        theme: 'light',
        filter: defaultFilter,
      });
      setChartRightLast(rightLast);
      rightLast
        .render(chartDivFourthRowRight.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivFifthRowLeft.current && moreIdList[8]) {
      let rightLast = sdk.createChart({
        chartId: moreIdList[8],
        height: 400,
        width: width,
        theme: 'light',
        filter: defaultFilter,
      });
      setChartRightEnd(rightLast);
      rightLast
        .render(chartDivFifthRowLeft.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
    if (chartDivFifthRowRight.current && moreIdList[8]) {
      let rightLast = sdk.createChart({
        chartId: moreIdList[8],
        height: 400,
        width: width,
        theme: 'light',
        filter: defaultFilter,
      });
      setChartRightEnd(rightLast);
      rightLast
        .render(chartDivFifthRowRight.current)
        .then(() => setRendered(true))
        .catch((err) => console.log('Error during get data.', err));
    }
  };

  useEffect(() => {
    let width = (window.innerWidth - 60) / 2;
  }, [moreIdList]);

  useEffect(() => {
    switch (filter.filter) {
      case 'Assigned Date':
        setMyFilter({
          ...myFilter,
          monthly_dates__c: filter.value !== '' ? filter.value : undefined,
        });
        break;
      default:
        setMyFilter({
          monthly_dates__c: undefined,
        });
        break;
    }
  }, [chart, filter.filter, filter.value, rendered]);

  useEffect(() => {
    if (filter.chartType == 'Table') {
      setIsShowChart(false);
      setNoDataAvailable('');
      getTopCharts(topDashboards);
    } else {
      setIsShowChart(false);
    }
  }, [filter.periodRange, filter.chartType, filter.value, isShowChart, setMyFilter]);

  useEffect(() => {
    if (filter.chartType == 'Chart') {
      reRender(sdk.createChart({ chartId: chartID, height: 800, theme: 'light' }));
      setIsShowChart(true);
      setNoDataAvailable('');
      getMoreCharts();
    } else {
      reRender(sdk.createChart({ chartId: chartID, height: 800, theme: 'light' }));
      setIsShowChart(false);
    }
  }, [filter.periodRange, filter.chartType, isShowChart, setMyFilter]);

  useEffect(() => {
    let obj = JSON.parse(JSON.stringify(myFilter));
    if (obj?.monthly_dates__c) {
      obj.$and = [
        { monthly_dates__c: { $gte: new Date(myFilter.monthly_dates__c[0]) } },
        { monthly_dates__c: { $lte: new Date(myFilter.monthly_dates__c[1]) } },
      ];
      delete obj.monthly_dates__c;
    }
    chartTable.setFilter(defaultFilter).catch((err) => console.log('Error while filtering.', err));
    chartLeft.setFilter(defaultFilter).catch((err) => console.log('Error while filtering.', err));
    chartRight.setFilter(defaultFilter).catch((err) => console.log('Error while filtering.', err));
    chartLeftMiddle.setFilter(defaultFilter).catch((err) => console.log('Error while filtering.', err));
    chartRightMiddle.setFilter(defaultFilter).catch((err) => console.log('Error while filtering.', err));
    chartLeftBottom.setFilter(defaultFilter).catch((err) => console.log('Error while filtering.', err));
    chartRightBottom.setFilter(defaultFilter).catch((err) => console.log('Error while filtering.', err));
    chartLeftLast.setFilter(defaultFilter).catch((err) => console.log('Error while filtering.', err));
    chartRightLast.setFilter(defaultFilter).catch((err) => console.log('Error while filtering.', err));
  }, [
    chart,
    filter.value,
    rendered,
    myFilter,
    chartLeft,
    chartRight,
    chartLeftMiddle,
    chartRightMiddle,
    chartLeftBottom,
    chartRightBottom,
    chartLeftLast,
    chartRightLast,
  ]);

  return (
    <>
      {!isShowChart ? (
        <>
          <div className="top" style={{ marginBottom: 20 }}>
            {availableRows < 1 && <div style={{ fontSize: 14, marginBottom: 20 }}>{noDataAvailable}</div>}
            <div className="chart" ref={chartDiv} />
          </div>
        </>
      ) : (
        <>
          {availableRows < 1 && <div style={{ marginBottom: 20, fontSize: 14 }}>{noDataAvailable}</div>}
          <div className="top" style={{ marginBottom: 20, display: 'flex', flexDirection: 'row' }}>
            <div className="chart" ref={chartDivFirstRowLeft} />
            <div className="chart" ref={chartDivFirstRowRight} style={{ marginLeft: 20 }} />
          </div>
          <div className="middle" style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', height: 400 }}>
            <div className="chart" ref={chartDivSecondRowLeft} />
            <div className="chart" ref={chartDivSecondRowRight} style={{ marginLeft: 20 }} />
          </div>
          {moreIdList[4] && (
            <>
              <div className="bottom" style={{ marginBottom: 20, display: 'flex', flexDirection: 'row' }}>
                <div className="chart" ref={chartDivThirdRowLeft} />
                {moreIdList[5] && <div className="chart" ref={chartDivThirdRowRight} style={{ marginLeft: 20 }} />}
              </div>
              {moreIdList[6] && (
                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row' }} className="bottom">
                  <div className="chart" ref={chartDivFourthRowLeft} />
                  <div className="chart" style={{ marginLeft: 20 }} />
                </div>
              )}
              {moreIdList[7] && (
                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row' }} className="bottom">
                  <div className="chart" ref={chartDivFourthRowRight} />
                  {moreIdList[8] && <div className="chart" ref={chartDivFifthRowRight} style={{ marginLeft: 20 }} />}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AlAgentList;
