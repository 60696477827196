import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';

import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { useStyles } from './lead-prospect-name.style';
import { reconstuctNameByLastName } from '../../../../../common/utils';
import { HighlightMatchedText } from '../../../../../common/components/highlight-matched-text/highlight-matched-text.component';
import { Lead } from '../../../types/types';

type ComponentProps = ParamsProps & {
  displayName?: string;
  item: Lead;
  keyword?: string;
};

export const LeadProspectNameComponent: React.FC<ComponentProps> = memo(
  ({ displayName, item, keyword }: ComponentProps) => {
    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    return (
      <div className={styles.content}>
        <HighlightMatchedText highLightText={keyword ?? ''}>
          {displayName || reconstuctNameByLastName(item.prospect?.firstName, item.prospect?.lastName)}
        </HighlightMatchedText>
      </div>
    );
  },
);
