import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'relative',
  },
  mask: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 999,
  },
  icon: {
    width: 80,
    height: 80,
    color: '#333333',
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: 20,
  },
}));
