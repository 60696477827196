import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useList } from './list.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import ProFilterTable from 'src/app/common/components/ProTable';
import { getColumns } from './utils/list.util';
import { ProTableOperationBtnComponent } from 'src/app/common/components/ProTable/components';
import { AI_TALKBOT_BASE_PATH, AI_TALKBOT_EXPORT_HISTORY_PATH, DETAIL_PATH } from '../../../constants';
import { ExportListItem } from '../../../types/talkbot-export-history-types';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const ListPage: React.FC<PageProps> = (props: PageProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  // custom-hook
  const { getData } = useList();

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle title={t('title.talkbot.export_history')} className="tw-mb-6" />
      <ProFilterTable
        showFilter={false}
        showSelect={false}
        enableRefresh={false}
        rowKey="date"
        columns={getColumns({ t, history })}
        request={getData}
      />
    </Page>
  );
};

export const renderOperation = ({
  item,
  t,
  history,
}: {
  item: ExportListItem;
  t: (key: string, values?: Record<string, any>) => string;
  history: any;
}) => {
  return (
    <ProTableOperationBtnComponent
      label={t('app.button.view')}
      onClick={() => {
        history.push(
          `${AI_TALKBOT_BASE_PATH}${AI_TALKBOT_EXPORT_HISTORY_PATH}${DETAIL_PATH}/${encodeURIComponent(item.date)}`,
        );
      }}
    />
  );
};
