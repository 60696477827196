import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { getAgentCodeListByGroup } from 'src/app/modules/Leads/network/leadsCrud';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { getAgentReassignToTableColumns, queryData } from '../../util/reassign-lead.util';
import { localeMapToBackend } from 'src/app/modules/AgentProfile/common/utils/locale-utils';

interface HookProps {
  groupCode: string;
  // other params
}

const defaultParam = {
  page: 1,
  pageSize: 20,
  limit: 5,
};

export const useAgentReassignToTable = ({ groupCode }: HookProps) => {
  const dispatch = useDispatch();

  const locale = useLang();
  const mappedLocale = localeMapToBackend[locale] || locale;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const getData = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<any> => {
      const { page, pageSize, keyword: searchkey } = params;
      const queryParam = {
        agentCode: globalStore.getAgentCode(),
        groupCode: groupCode,
      };

      const res = await getAgentCodeListByGroup(queryParam, dispatch);

      let data = res;
      if (searchkey) {
        data = queryData(searchkey, data, mappedLocale);
      }
      data = data.slice(page * pageSize, (page + 1) * pageSize);
      if (res) {
        return {
          success: true,
          data: data,
          total: searchkey ? data.length : res.length,
        };
      } else {
        return {
          success: false,
          data: [],
          total: 0,
        };
      }
    },
    [dispatch, groupCode],
  );

  const tableColumns = useMemo(
    () => getAgentReassignToTableColumns({ t: Translation, keyword: '', styles: {}, lang: mappedLocale }),
    [],
  );

  return { getData, tableColumns };
};
