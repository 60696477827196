import { get } from 'lodash';
import { useForm } from 'rc-field-form';
import { useContext, useRef, useMemo, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { LeadDetailContext } from '../../lead-detail.hook';
import { LeadDetailForm } from 'src/app/modules/Leads/types/lead-detail-types';
import moment from 'moment';
import { constants } from 'src/app/common/constants';
import { patchLeadById } from 'src/app/modules/Leads/network/leadsCrud';
import { configManager } from 'src/app/common/utils/app-configs.util';
import { ReferredTypeEnum } from 'src/app/modules/Leads/types/types';
import { numberTypeFields } from 'src/app/modules/Prospect/pages/prospect-detail/component/prospect-information/config/form.config';

interface HookProps {
  leadDetail: any;
  // other params
}

export const useLeadInformation = ({ leadDetail }: HookProps) => {
  const { leadInfomationTabState, updateLeadInfoTabState, updateLeadDetail } = useContext(LeadDetailContext);
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (key: string, values?: Record<string, any>) => intl.formatMessage({ id: key }, values);
  const [form] = useForm();
  const leadDetailRef = useRef<LeadDetailForm>();
  const referredSource = get(configManager.getConfig('sales-sdk'), 'Sales.lead.referredSource', '');
  const [showReferrby, setShowReferrby] = useState(false);
  const [selectedReferrBy, setSelectedReferrBy] = useState<any>();

  const getInitValue = (routeItem: LeadDetailForm & { engagement?: any[] }) => {
    delete routeItem.engagement; // remove
    const obj: any = {
      ...routeItem,
    };
    numberTypeFields.forEach((field: string) => {
      const fieldValue: any = obj[field];
      if (fieldValue === 0) {
        obj[field] = '0'; // convert to string
      }
    });
    return obj;
  };

  const formModel: LeadDetailForm = useMemo(() => {
    const initedValue = getInitValue(leadDetail);
    if (referredSource === ReferredTypeEnum.prospect) {
      setSelectedReferrBy({
        displayName: initedValue.referredProspectName ?? '',
        id: initedValue.referredProspectId ?? '',
      });
    } else {
      setSelectedReferrBy({
        displayName: initedValue.referredClientName ?? '',
        id: initedValue.referredClientId ?? '',
      });
    }

    initedValue.leadDate = moment(initedValue?.leadDate).format(constants.Default_Date_Format);
    initedValue.poeLastSyncDate = initedValue?.poeLastSyncDate ? moment(initedValue?.poeLastSyncDate).format(constants.Default_Date_Format) : ''; // PACS Customization: PACSPFAP-2774
    initedValue.euResident = initedValue?.euResident ? Translation('app.select.yes') : Translation('app.select.no'); // PACS Customization: PACSPFAP-2774
    const marketingConsent = initedValue?.prospect ? initedValue?.prospect?.marketingConsent : initedValue?.prospectMaster?.marketingConsent; // PACS Customization: PACSPFAP-2774
    initedValue.marketingConsent = marketingConsent ? Translation('lead.info.subtitle.marketingConsent.agreed') : Translation('lead.info.subtitle.marketingConsent.notAgreed'); // PACS Customization: PACSPFAP-2774
    leadDetailRef.current = initedValue;
    return initedValue;
  }, [leadDetail]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const onSelectReferrBy = useCallback((value: any) => {
    if (referredSource !== ReferredTypeEnum.prospect) {
      setSelectedReferrBy({ ...value[0], displayName: value[0].clientName, id: value[0].clientCode });
    } else {
      setSelectedReferrBy({ ...value[0], id: value[0]._id });
    }
    setShowReferrby(false);
  }, []);
  const onFiledChange = useCallback((changedValues: any, allValues: any) => {
    const targetFiled = changedValues[0];
    const fieldName = targetFiled.name[0];
    if (fieldName === 'state') {
      const value = targetFiled.value;
    }
  }, []);
  const onDeleteDialogClose = useCallback(() => {
    setShowDeleteDialog(false);
  }, []);
  const deleteLead = useCallback(() => {
    setShowDeleteDialog(true);
  }, []);
  const submit = useCallback(() => {
    const formData: LeadDetailForm = form.getFieldsValue();
    let patchData = { ...formData, id: leadDetail.id };
    if (referredSource === ReferredTypeEnum.prospect) {
      patchData = {
        ...patchData,
        referredProspectId: selectedReferrBy?.id,
        referredProspectName: selectedReferrBy?.displayName,
      };
    } else {
      patchData = {
        ...patchData,
        referredClientId: selectedReferrBy?.id,
        referredClientName: selectedReferrBy?.displayName,
      };
    }

    updateLeadInfoTabState && updateLeadInfoTabState({ ...leadInfomationTabState, saving: true });
    leadDetailRef.current = { ...leadDetailRef.current, ...patchData };
    patchLeadById(patchData, dispatch).finally(() => {
      updateLeadDetail({ ...leadDetailRef.current, ...patchData });
      updateLeadInfoTabState &&
        updateLeadInfoTabState({ ...leadInfomationTabState, saving: false, leadInfoReadOnly: true });
    });
  }, [leadInfomationTabState, updateLeadInfoTabState, leadDetail, updateLeadDetail, selectedReferrBy]);

  return {
    form,
    formModel,
    showReferrby,
    selectedReferrBy,
    showDeleteDialog,
    onSelectReferrBy,
    setShowReferrby,
    onFiledChange,
    onDeleteDialogClose,
    deleteLead,
    submit,
  };
};
