import { ButtonProps, CircularProgress, Button as MuiButton } from '@mui/material';
import { FC, memo } from 'react';
import { withStyles } from 'tss-react/mui';

export const Button = withStyles(MuiButton, (theme) => ({
  root: {
    boxShadow: 'none',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 500,
  },
  containedPrimary: {
    color: '#FFFFFF',
    backgroundColor: '#E8192C',
    '&:hover': {
      backgroundColor: 'rgb(162, 17, 30)',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
  },
  outlinedPrimary: {
    color: '#333333',
    border: '2px solid #CCC',
    '&:hover': {
      backgroundColor: 'rgba(232, 25, 44, 0.04)',
      boxShadow: 'none',
      border: '2px solid #CCC',
    },
  },
}));

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

export const LoadingButton: FC<LoadingButtonProps> = memo(({ loading, children, ...rest }: LoadingButtonProps) => {
  return (
    <Button {...rest}>
      {children}
      {loading && <CircularProgress sx={{ marginLeft: '8px', color: '#FCF3F3' }} size={14} />}
    </Button>
  );
});
