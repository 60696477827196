import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { RequestData } from 'src/app/common/components/ProTable';
import { getLeadList } from 'src/app/modules/AgencyCampaign/network/campaignCurd';
import { getActionColumns, getNoActionColumns, i18nKeyMapping } from './util/campaign-lead-filter-table.util';
import { useStyles } from './campaign-lead-filter-table.style';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';
import { Lead } from 'src/app/modules/Leads/types/types';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import {
  agencyCampaignDetailsGaDataClick,
  agencyCampaignDetailsGaDataClick1,
} from 'src/app/common/ga/types/ga-click-lead';

interface HookProps {
  campaignId: string;
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  agentCodeLocal: string;
}

export const useCampaignLeadFilterTable = ({ campaignId, cacheParams, agentCodeLocal }: HookProps) => {
  // i18n
  const intl = useIntl();
  const Translation = useCallback((id: string) => intl.formatMessage({ id }), [intl]);

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const [isSelf, setIsSelf] = useState(true);
  const [selectedProspectId, setSelectedProspectId] = useState('');
  const [showApptDialog, setShowApptDialog] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  // redux
  const dispatch = useDispatch();
  const getData = useCallback(
    async (
      params: {
        page: number;
        pageSize: number;
        leadState?: string | undefined;
        agentCodes?: string[] | undefined;
      },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<RequestData<Lead>> => {
      const { page, pageSize, leadState, agentCodes } = params;
      let sortBy = 'updatedAt';
      let sortOrder = -1;
      const searchAgentCode = agentCodes ? agentCodes : [agentCodeLocal];
      const leadQuery = i18nKeyMapping[leadState as keyof typeof i18nKeyMapping]?.query;
      if (sort && Object.keys(sort).length > 0) {
        const [key, val] = Object.entries(sort)[0];
        sortBy = key;
        if (val === 'desc') {
          sortOrder = -1;
        } else {
          sortOrder = 1;
        }
      }
      if (campaignId) {
        const res = await getLeadList(
          {
            campaignId,
            agentCode: searchAgentCode,
            page: page + 1,
            pageSize,
            leadQuery,
            sortBy,
            sortOrder,
          },
          dispatch,
        );

        if (res) {
          const { docs, totalDocs, ...rest } = res;
          return {
            success: true,
            data: docs ?? [],
            total: totalDocs,
            ...rest,
          };
        }
      }

      return { success: false, data: [], total: 0 };
    },
    [agentCodeLocal, campaignId, dispatch],
  );
  const columns = useMemo(() => {
    // PACS Customization: PACSPFAP-3072 | Phase-1 | Start
    // if (isSelf) {
    //   return getActionColumns({
    //     t: Translation,
    //     styles,
    //     onAppt: (item: Lead) => {
    //       takeUIClickEvent(agencyCampaignDetailsGaDataClick1);
    //       // setSelectedProspectId(item._id);
    //       // setShowApptDialog(true);
    //     },
    //     onUpdate: (item: Lead) => {
    //       takeUIClickEvent(agencyCampaignDetailsGaDataClick);
    //       // setSelectedProspectId(item._id);
    //       // setShowUpdateDialog(true);
    //     },
    //   });
    // } else {
      return getNoActionColumns({ t: Translation, styles });
    // }
    // PACS Customization: PACSPFAP-3072 | Phase-1 | End
  }, [Translation, isSelf, styles]);

  const onFilterChange = useCallback(
    (filterState: FilterState) => {
      cacheParams && (cacheParams.current = filterState);
      if (
        !cacheParams?.current?.filterState.downline ||
        cacheParams?.current?.filterState.downline[1].value === agentCodeLocal
      ) {
        setIsSelf(true);
      } else {
        setIsSelf(false);
      }
    },
    [agentCodeLocal, cacheParams],
  );

  return {
    columns,
    getData,
    onFilterChange,
  };
};
