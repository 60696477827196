import React, { FC, memo, useMemo, useState } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './header-pc.style';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { NavLink } from 'react-router-dom';
import LanguageSwitch from '../../container/components/header/language-switch';
import QuickUserToggler from '../../container/components/header/quick-user-toggler';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  showRightContent?: boolean;
  className?: string;
};

export const HeaderPc: React.FC<ComponentProps> = memo(({ showRightContent = true, className }: ComponentProps) => {
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <div className={`${styles.wrap} ${className ?? styles.wrapMenu}`}>
      <div className={styles.logoContaner}>
        <NavLink color="inherit" to="/">
          <img
            alt="logo"
            className={styles.prudentialLogo}
            src={toAbsoluteUrl('/media/pruforce-logo/prudential_logo_header.png')}
          />
        </NavLink>
        <div className={styles.splintLine} />
        <img
          alt="logo"
          className={styles.pruforceLogo}
          src={toAbsoluteUrl('/media/pruforce-logo/pruforce_logo_header.png')}
        />
      </div>
      {showRightContent ? (
        <div className={styles.rightContainer}>
          <LanguageSwitch />
          <QuickUserToggler />
        </div>
      ) : null}
    </div>
  );
});
