import React, { useMemo, useState } from 'react';
import { get as lodashGet } from 'lodash';
import { campaignSectionFieldItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { convertArrayToObject } from 'src/app/common/utils/common-utils';

interface HookProps {
  formState: any;
  fieldItem: campaignSectionFieldItem;
  sectionKey: string;
  isCampaignFilesUpdated?: { [section: string]: boolean };
}

export const useAttachmentUploader = ({ formState, fieldItem, sectionKey, isCampaignFilesUpdated }: HookProps) => {
  const [uploading, setUploading] = useState(false);
  let ruleObject = convertArrayToObject(fieldItem.rules, 'title');
  const source: any[] = lodashGet(formState, `${sectionKey}.${fieldItem.key}.resources`, []);

  const hasChange = useMemo(() => {
    if (isCampaignFilesUpdated) {
      return isCampaignFilesUpdated[fieldItem.key];
    } else {
      return false;
    }
  }, [fieldItem.key, isCampaignFilesUpdated]);

  return { uploading, setUploading, ruleObject, source, hasChange };
};
