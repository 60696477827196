import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { Collapse, List, ListItemButton, ListItemText, Theme, Badge } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ExpandLess, ExpandMore, ChevronRight } from '@mui/icons-material';
import { ModuleHierachy } from 'src/app/common/module/types';
import { useProjectSelector } from 'src/redux/store';
import { CampaignTypeEnum } from 'src/app/modules/Campaign/types/campaign-types';

const getMuenClassName = (classes: any, menuLevel: number) => {
  if (menuLevel > 1) {
    switch (menuLevel) {
      case 2:
        return classes.active_menuLevel2;
      case 3:
        return classes.active_menuLevel3;
      default:
        return '';
    }
  }
  return '';
};

export const UnreadCampaignMap: any = {
  CAMPAIGN: 'total',
  MARKETING_CAMPAIGN: CampaignTypeEnum.normal,
  MASS_CAMPAIGN: CampaignTypeEnum.mass,
  SERVICING_CAMPAIGN: CampaignTypeEnum.servicing,
  AGENCY_CAMPAIGN: CampaignTypeEnum.agency,
  AGENCY_CAMPAIGN_LIST: CampaignTypeEnum.agency,
};

const NestedMenu = ({
  menuList,
  isExpand,
  menuLevel = 1,
  badge = {},
  onClickMenu,
}: {
  menuList: ModuleHierachy[];
  isExpand: boolean;
  menuLevel?: number;
  badge?: { [k: string]: number };
  onClickMenu?: (menu: ModuleHierachy) => void;
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [openMenu, setOpenMenu] = useState<{ [k: string]: boolean }>({});
  const { classes, cx } = useStyles();
  const { pathname } = useLocation();
  const isAsideMenuCollapse = useProjectSelector((state) => state.layout.isAsideMenuCollapse);

  useEffect(() => {
    const activeMenu = menuList?.filter((item) => pathname?.startsWith(item.path))[0];
    if (activeMenu?.child && activeMenu?.child?.length > 0) {
      const { path, title } = activeMenu;
      setOpenMenu({ [`${title}-${path}`]: true });
    }
  }, [menuList]);

  useEffect(() => {
    if (isAsideMenuCollapse && !isExpand) {
      setOpenMenu({});
    }
  }, [isAsideMenuCollapse, isExpand]);

  const handleClickMenuItem = useCallback(
    (menu: ModuleHierachy) => {
      const { externalUrl, child, title, path, namespace } = menu ?? {};

      const key = `${title}-${path}`;
      if (child && child.length > 0 && child.find((item) => !item.disableMenu)) {
        setOpenMenu((prevState) => ({
          [key]: !prevState[key],
        }));
      } else {
        if (typeof externalUrl === 'string') {
          window.open(externalUrl as string, '_blank');
        } else if (typeof externalUrl === 'function') {
          externalUrl();
        }
      }
      onClickMenu?.(menu);
    },
    [onClickMenu],
  );

  return (
    <List disablePadding>
      {menuList?.map((menu, index) => {
        const { menuHeader, title, path, enableRead, namespace } = menu;
        const isSubMenuOpen = !!openMenu[`${title}-${path}`];
        const hasSubMenu = menu?.child && menu?.child?.length > 0 && !menu.child.some((item) => item.disableMenu);
        const isDivType = hasSubMenu || menu.externalUrl;
        const childMenuLevel = hasSubMenu ? menuLevel + 1 : menuLevel;
        const activeClass =
          !!path && pathname?.startsWith(path)
            ? `${classes.menu} ${classes.active} ${getMuenClassName(classes, menuLevel)}`
            : classes.menu;
        const unreadMessage = namespace ? badge[UnreadCampaignMap[namespace]] ?? 0 : 0;
        return enableRead ? (
          <Fragment key={`${title}-${path}`}>
            {menuHeader && (
              <div className={cx(classes.menuHeader, index === 0 ? classes.topMenuHeader : undefined)}>
                {menuHeader.toUpperCase()}
              </div>
            )}
            <li>
              <ListItemButton
                onClick={() => handleClickMenuItem(menu)}
                component={isDivType ? 'div' : NavLink}
                to={isDivType ? undefined : menu?.path}
                className={activeClass}
              >
                {/* {menu?.icon ? (
                  <span className={`${classes.icon} icon`}>
                    <SVG src={toAbsoluteUrl(menu.icon)} />
                  </span>
                ) : (
                  <i className={`${classes.dot} dot`} />
                )} */}
                {menuLevel > 1 && <i className={`${classes.dot} dot`} />}
                <span
                  className={cx(classes.menuContent, { [classes.hideMenuContent]: isAsideMenuCollapse && !isExpand })}
                >
                  <ListItemText primary={title} className={classes.menuText} />
                  {hasSubMenu || Boolean(unreadMessage) ? (
                    isSubMenuOpen ? (
                      <ExpandMore />
                    ) : unreadMessage ? (
                      <Badge
                        badgeContent={unreadMessage}
                        color="secondary"
                        slotProps={{ badge: { className: classes.mybadge } }}
                      />
                    ) : (
                      <ChevronRight />
                    )
                  ) : null}
                </span>
              </ListItemButton>
              {hasSubMenu && (
                <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit className={classes.collapseMenu}>
                  <NestedMenu
                    menuLevel={childMenuLevel}
                    menuList={menu.child!}
                    isExpand={isExpand}
                    badge={badge}
                    onClickMenu={onClickMenu}
                  />
                </Collapse>
              )}
            </li>
          </Fragment>
        ) : null;
      })}
    </List>
  );
};

export default memo(NestedMenu);

const useStyles = makeStyles()((theme: Theme) => ({
  menu: {
    '--normal-color': '#637381 !important',
    '--active-color': '#EB3A34 !important',
    '--active-icon-color': '#3699FF',
    '---active-dot-color': '#EB3A34',
    color: 'var(--normal-color)',
    '&:hover': {
      color: 'var(--active-color)',
      background: '#EB3A3414',
    },
    '& .dot': {
      backgroundColor: 'var(--normal-color)',
    },
    '&:hover .dot': {
      backgroundColor: 'var(---active-dot-color)',
    },
    '&:hover .icon svg g [fill]': {
      fill: 'var(--active-icon-color)',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '9px 16px',
    borderRadius: '8px',
    marginLeft: '16px',
    marginRight: '16px',
  },
  active: {
    color: 'var(--active-color)',
    background: '#EB3A3414',
    '& .dot': {
      backgroundColor: 'var(---active-dot-color)',
    },
    '& .icon svg g [fill]': {
      fill: 'var(--active-icon-color)',
    },
  },
  menuContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hideMenuContent: {
    display: 'none',
  },
  menuText: {
    flex: 1,
  },
  collapseMenu: {
    paddingLeft: theme.spacing(2),
  },
  icon: {
    height: 23,
    width: 23,
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg g [fill]': {
      fill: '#494b74',
    },
  },
  dot: {
    height: 4,
    width: 4,
    borderRadius: '100%',
    marginRight: 12,
    flexShrink: 0,
  },
  active_menuLevel2: {
    background: 'transparent',
    ' & span': {
      fontWeight: 600,
      color: '#212B36',
    },
    '& .dot': {
      width: 8,
      height: 8,
    },
  },
  active_menuLevel3: {
    background: 'transparent',
  },
  mybadge: {
    minWidth: 16,
    height: 16,
    fontSize: '9px',
    lineHeight: '10px',
    borderRadius: 8,
    marginRight: 10,
    fontWeight: 700,
    color: '#fff !important',
  },
  menuHeader: {
    fontSize: 12,
    fontWeight: 700,
    margin: '0 16px',
    padding: '24px 16px 13px',
  },
  topMenuHeader: {
    paddingTop: 34,
  },
}));
