import { FC, PropsWithChildren } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ['Poppins'].join(','),
      button: {
        textTransform: 'none',
      },
    },
    shape: {
      borderRadius: 8, // defaults to 4
    },
    // Overridable: error | inherit | primary | secondary | success | info | warning
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#17c191',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: '#E8192C',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: '#FF4842',
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      info: {
        main: '#444',
      },
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    components: {
      MuiCheckbox: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: false,
        },
      },
      MuiButton: {
        defaultProps: {
          color: 'inherit',
        },
        styleOverrides: {
          sizeLarge: {
            font: 'normal normal normal 1.2375rem/1.8675rem SF Pro Text',
          },
        },
      },
      MuiPopover: {
        defaultProps: {
          elevation: 1,
        },
      },
      MuiRadio: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiSelect: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          },
        },
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#F0F0F0',
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
          margin: 'dense',
          size: 'small',
        },
        styleOverrides: {
          root: {
            '& fieldset': {
              borderColor: '#F0F0F0',
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          fontFamily: 'inherit',
        },
      },
    },
  },
);

export const MaterialThemeProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MaterialThemeProvider;
