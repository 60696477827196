import React, { ReactNode, memo, useMemo } from 'react';
import { Box, Button, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { useStyles } from './action-items.style';
import { MergeDialog } from '../merge-dialog/merge-dialog.component';
import { Dialog } from 'src/app/common/components/Dialog/dialog/dialog.component';
import { SelectCategoryDialog } from '../select-category-dialog/select-category-dialog.component';
import { ClearIcon, EditIcon, MergeIcon } from 'src/app/common/components/svg-icon/svg-icon.component';
import { ActionNodeProps, DialogType, useActionItems } from './action-items.hook';
import { PruToast } from '../../../../../../common/components/pru-toast';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import {
  prospectListGaDataClick51,
  prospectListGaDataClick52,
  prospectListGaDataClick53,
} from 'src/app/common/ga/types/ga-click';

const IconTextButton = ({
  onClick,
  sx,
  text,
  icon,
  disabled,
  tooltipsTitle,
}: {
  icon: ReactNode;
  text: string;
  onClick: () => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  tooltipsTitle?: string;
}) => {
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const buttonNode = (
    <Button
      sx={sx}
      onClick={disabled ? undefined : onClick}
      variant="text"
      className={disabled ? styles.disabled : styles.enable}
    >
      {icon}
      <Typography className={styles.iconButtonText}>{text}</Typography>
    </Button>
  );

  return tooltipsTitle ? (
    <Tooltip title={tooltipsTitle} disableHoverListener={!disabled} disableTouchListener={!disabled}>
      {buttonNode}
    </Tooltip>
  ) : (
    buttonNode
  );
};

export const ActionItems: React.FC<ActionNodeProps> = memo((props: ActionNodeProps) => {
  const { prospectCategories, selectedRows } = props;
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const {
    open,
    setOpen,
    Translation,
    disableDelete,
    batchCategory,
    deleteProspects,
    merge,
    retainIndex,
    categoryLoading,
    mergeLoading,
    deleteLoading,
  } = useActionItems(props);

  return (
    <Box paddingRight={'16px'}>
      <IconTextButton
        disabled={!(prospectCategories && prospectCategories.length > 0)}
        icon={<EditIcon className={styles.icon} />}
        text={Translation('pruleads.prospect.list.editCategory')}
        onClick={() => {
          setOpen(DialogType.category);
          takeUIClickEvent(prospectListGaDataClick52 as any);
        }}
      />
      <IconTextButton
        icon={<MergeIcon className={styles.mergeIcon} />}
        text={Translation('pruleads.prospect.list.Merge')}
        onClick={() => {
          takeUIClickEvent(prospectListGaDataClick51 as any);
          if (selectedRows.length === 1) {
            PruToast({
              message: Translation('prospect_merge_please_select_at_least_two_prospects'),
            });
            return;
          }
          setOpen(DialogType.merge);
        }}
      />
      <IconTextButton
        disabled={disableDelete}
        icon={<ClearIcon className={styles.icon} color={disableDelete ? '#CCCCCC' : undefined} />}
        text={Translation('pruleads.prospect.list.Delete')}
        onClick={() => {
          setOpen(DialogType.delete);
          takeUIClickEvent(prospectListGaDataClick53 as any);
        }}
        tooltipsTitle={Translation('pruleads.prospect.list.action.delete.tooltips')}
      />
      {open === DialogType.merge && (
        <MergeDialog
          open={open === DialogType.merge}
          rows={selectedRows}
          onConfirm={(rows, index) => {
            retainIndex.current = index;
            setOpen(DialogType.mergeConfirm);
          }}
          onClose={() => setOpen(DialogType.close)}
        />
      )}
      {open === DialogType.mergeConfirm && (
        <Dialog
          title={Translation('pruleads.prospect.list.Merge')}
          content={Translation('pruleads.prospect.list.action.merge.dialog.doubleConfirm')}
          open={open === DialogType.mergeConfirm}
          loading={mergeLoading}
          onConfirm={() => {
            merge();
          }}
          onClose={() => setOpen(DialogType.close)}
        />
      )}
      {open === DialogType.delete && (
        <Dialog
          title={Translation('pruleads.prospect.list.Delete')}
          content={Translation('pruleads.prospect.list.action.delete.dialog.text', { num: selectedRows.length })}
          open={open === DialogType.delete}
          loading={deleteLoading}
          onConfirm={() => {
            deleteProspects();
          }}
          onClose={() => {
            setOpen(DialogType.close);
          }}
        />
      )}
      {open === DialogType.category && (
        <SelectCategoryDialog
          prospectCategories={prospectCategories}
          loading={categoryLoading}
          open={open === DialogType.category}
          onConfirm={(categories, index) => {
            batchCategory(categories[index]._id);
          }}
          onClose={() => {
            setOpen(DialogType.close);
          }}
        />
      )}
    </Box>
  );
});
