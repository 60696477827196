import { Analytics, getAnalytics, setUserProperties } from 'firebase/analytics';
import { GAUserProperties } from './types/ga-general-type';
import packageJson from '../../../../package.json';

const ROLLOUT_VERSION = packageJson['rollout'];

export const updateUserProperties = (userProperties: GAUserProperties) => {
  let analytics: Analytics;
  try {
    analytics = getAnalytics();
  } catch {
    return;
  }
  setUserProperties(analytics, {
    ...userProperties,
    pf_user_id: userProperties.user_id || undefined,
    Agent: userProperties.user_id || undefined,
    rollout: ROLLOUT_VERSION,
  });
};
