import React, { FC, memo } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { RejectOpportunityButtonHookProps, useRejectOpportunityButton } from './reject-opportunity-button.hook';
import { ProTableOperationBtnComponent } from 'src/app/common/components/ProTable/components/pro-table-operation-btn/pro-table-operation-btn.component';
import { Reject } from '../../../lead-operation/components/reject/reject.component';

type ComponentProps = RejectOpportunityButtonHookProps;

export const RejectOpportunityButton: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { tab } = props;
  const { t } = useTranslation();
  // custom-hook
  const { toReject, setToReject, rejectLoading, doReject, doRejectGA } = useRejectOpportunityButton(props);
  return (
    <>
      <ProTableOperationBtnComponent
        loading={rejectLoading}
        onClick={() => {
          setToReject(true);
          doRejectGA([]);
        }}
        label={t('app.button.reject')}
      />
      {toReject ? <Reject tab={tab} onReject={doReject} onClose={() => setToReject(false)} /> : null}
    </>
  );
});
