import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Box, Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDetail } from './detail.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import ProFilterTable from 'src/app/common/components/ProTable';
import { getColumns, getFilterConfig, tagConfig } from './utils/detail.util';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components';
import { Tag } from 'src/app/common/components/tag';
import { LEADS_DETAIL_PATH } from 'src/app/modules/Leads/constants';
import { TalkBotCallInterestTags, TalkBotCallStatus } from '../../../types/talkbot-export-history-types';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const DetailPage: React.FC<PageProps> = (props: PageProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { date } = useParams<{ date: string }>();

  // custom-hook
  const { getData, toDetail } = useDetail({ date: decodeURIComponent(date) });

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={t('title.talkbot.export_details')}
        rightNode={
          <Button variant="outlined" color="info" onClick={history.goBack}>
            {t('app.button.back')}
          </Button>
        }
      />
      <ProFilterTable
        Filter={ProSearchFilter}
        showSearchInput={false}
        filterConfigs={getFilterConfig(t)}
        showSelect={false}
        rowKey="_id"
        columns={getColumns({ t })}
        request={getData}
        enableRefresh={false}
        onClickRow={(row) => toDetail(row.contactId)}
      />
    </Page>
  );
};

export const renderTag = (value: string, t: (val: string) => string, type?: 'status' | 'tag') => {
  const isGreenKey = value === TalkBotCallInterestTags.highInterest || value === TalkBotCallStatus.connected;
  const configs: any = Object.fromEntries(
    Object.entries(tagConfig).map(([key, val]) => [
      key,
      {
        ...val,
        text: t(
          type === 'tag'
            ? `talkbot.export_history.contact_tag.${value?.toLocaleLowerCase()}`
            : `talkbot.export_history.call_status.${value?.toLocaleLowerCase()}`,
        ),
      },
    ]),
  );

  return <Tag value={isGreenKey ? 'green' : 'blue'} configs={configs} />;
};

export const renderValue = (value?: string) => {
  return <Typography sx={{ color: '#666' }}>{value || 'N/A'}</Typography>;
};
