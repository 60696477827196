import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  selectContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  selectItem: {
    '--normal-color': '#637381 !important',
    '--active-color': '#EB3A34 !important',
    '--active-icon-color': '#3699FF',
    '---active-dot-color': '#EB3A34',
    color: '#333333',
    '&:hover': {
      color: 'var(--active-color)',
      background: '#EB3A3414',
    },
    '& .dot': {
      backgroundColor: 'var(--normal-color)',
    },
    '&:hover .dot': {
      backgroundColor: 'var(---active-dot-color)',
    },
    '&:hover .icon svg g [fill]': {
      fill: 'var(--active-icon-color)',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    borderRadius: '3px',
    marginLeft: '6px',
    marginRight: '6px',
    width: '238px',
  },
  active: {
    color: 'var(--active-color)',
    background: '#EB3A3414',
    '& .icon svg g [fill]': {
      fill: 'var(--active-icon-color)',
    },
  },
  popover: {
    marginTop: '8px',
    '& .MuiBackdrop-root': {
      background: 'rgba(0, 0, 0, 0) !important',
    },
  },
  list: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  parentGrid: {
    borderRightWidth: 0.5,
    borderRightColor: '#DCDCDC',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 16,
    height: 32,
    jsutifyContent: 'space-between',
    width: 350,
    paddingLeft: 16,
    paddingRight: 16,
    border: '0.5px solid #DCDCDC',
  },
  displayText: {
    whiteSpace: 'nowrap',
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 24,
    fontWeight: 500,
  },
  input: {
    width: '100%',
    margin: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
    },
  },
  hideInput: {
    width: 0,
  },
}));
