import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  list: {
    '--active-color': '#EB3A34 !important',
    padding: '0px',
  },
  listItem: {
    width: '392px',
    height: '56px',
    // borderWidth: '1px',
    // borderColor: '#F0F0F0',
    border: '1px solid #F0F0F0',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    marginTop: '24px',
  },
  error: {
    backgroundColor: 'red',
  },
  icon: {
    width: '24px',
    height: '24px',
    color: '#666666',
  },
  listText: {
    fontSize: '16px',
    color: '#333333',
    marginLeft: '8px',
  },
  error_description: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  highLightList: {
    border: '2px solid #EB3A34',
  },
  hightLightIcon: {
    color: 'var(--active-color)',
  },
  hightLightText: {
    color: 'var(--active-color)',
  },
}));
