import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import {
  Button,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  List,
  ListItem,
  Typography,
  ListItemButton,
} from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './error-status-dialog.style';
import { useErrorStatusDialog } from './error-status-dialog.hook';
import PruDialog from 'src/app/common/components/PruDialog';
import { PersonOutline } from '@mui/icons-material';
import { Customer } from '../../util/existing-customers.util';
import { ExistingProspect } from 'src/app/modules/Prospect/types/prospect-types';
import { ErrorType } from '../../import-existing-customers.hook';

type ComponentProps = {
  errorType: ErrorType.DUPLICATED | ErrorType.NOT_ALL_SUCCESS | undefined;
  open: boolean;
  onCancel: () => void;
  onConfirm: (selectedCustomer?: Array<ExistingProspect>) => void;
  customer: ExistingProspect[];
};

// const ErrorStatusConfig = {
//   [ErrorType.DUPLICATED]: {
//     title: 'Phone number check',
//     description: ()=>'Existed phone number, please remove to proceed.',
//   },
//   [ErrorType.NOT_ALL_SUCCESS]: {
//     title: 'Not all succeeded',
//     description: (failedCount:number)=>`${failedCount} customers failed:`,
//   },
// }

const getErrorStatusConfig = (errorType: ErrorType | undefined, intl: IntlShape) => {
  switch (errorType) {
    case ErrorType.DUPLICATED:
      return {
        title: intl.formatMessage({ id: 'prospect.customer.import.check.phoneNumber' }),
        description: intl.formatMessage({ id: 'prospect.existed_phone_number' }),
      };
    case ErrorType.NOT_ALL_SUCCESS:
      return {
        title: intl.formatMessage({ id: 'prospect.customer.import.no_all_succeed' }),
        description: intl.formatMessage({ id: 'prospect.not_all_succeeded' }),
      };
    default:
      return {
        title: intl.formatMessage({ id: 'common.serverError' }),
        description: intl.formatMessage({ id: 'common.serverError' }),
      };
  }
};

export const ErrorStatusDialog: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { open, errorType, customer, onCancel, onConfirm } = props;

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes, cx } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const { title, description } = getErrorStatusConfig(errorType, intl);

  const [selectedCustomer, setSelectedCustomer] = useState<ExistingProspect[]>([]);

  const handleSelectCustomer = (customer: ExistingProspect) => {
    // if (errorType !== ErrorType.NOT_ALL_SUCCESS) return;
    const index = selectedCustomer?.findIndex((item) => item.clientCode === customer.clientCode);
    if (index === -1) {
      setSelectedCustomer([...selectedCustomer, customer]);
    } else {
      setSelectedCustomer(selectedCustomer?.filter((item) => item.clientCode !== customer.clientCode));
    }
  };

  const clickCancel = useCallback(() => {
    setSelectedCustomer([]);
    onCancel();
  }, [onCancel]);

  const clickConfirm = useCallback(() => {
    onConfirm(selectedCustomer);
  }, [selectedCustomer, onConfirm]);

  useEffect(() => {
    setSelectedCustomer(customer);
  }, [customer]);

  return (
    <PruDialog
      open={open}
      onOk={clickConfirm}
      onCancel={clickCancel}
      dialogTitle={title}
      canncelBtnText={Translation('app.button.cancel').toUpperCase()}
      confirmBtnText={Translation('app.button.confirm').toUpperCase()}
    >
      <div>
        <span className={styles.error_description}>{description}</span>
        <List className={styles.list}>
          {customer &&
            customer.map((item) => {
              const showHighlightStyle =
                errorType === ErrorType.NOT_ALL_SUCCESS &&
                selectedCustomer?.findIndex((selected) => selected.clientCode === item.clientCode) !== -1;
              return (
                <ListItemButton
                  key={item.clientCode}
                  component="div"
                  onClick={() => {
                    handleSelectCustomer(item);
                  }}
                  // style={{ backgroundColor: 'red', border: '1px solid #000' }}
                  className={cx({
                    [styles.listItem]: true,
                    [styles.highLightList]: showHighlightStyle,
                  })}
                >
                  <PersonOutline
                    className={cx({
                      [styles.icon]: true,
                      [styles.hightLightIcon]: showHighlightStyle,
                    })}
                  />
                  <span
                    className={cx({
                      [styles.listText]: true,
                      [styles.hightLightText]: showHighlightStyle,
                    })}
                  >
                    {item.firstName}
                  </span>
                </ListItemButton>
              );
            })}
        </List>
      </div>
    </PruDialog>
  );
});
