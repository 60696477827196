import { IntlShape } from 'react-intl';
import moment from 'moment';
import { APP_CONFIG, getAppConfigs } from 'src/app/common/utils';

interface ProspectCategoryResponseModel {
  _id: string;
  type: string;
  name: any;
  description: any;
  isDefault: boolean;
  targetNumber: number;
  targetFrequency: number;
  colorLabel: string | null;
  backgroundColorLabel: string | null;
  weight: number;
}

export const formateProsectCategory = (categorys: any[], currentLang: string, intl: IntlShape) => {
  const convertCategoryProspect = (raw: ProspectCategoryResponseModel) => {
    return {
      id: raw._id,
      name: (raw.name && raw.name[currentLang]) || '',
      rawType: raw.type,
      type: raw.type === 'None' ? intl.formatMessage({ id: 'none_acm_type' }) : raw.type,
      description: (raw.description && raw.description[currentLang]) || '',
      isDefault: raw.isDefault,
      targetNumber: raw.targetNumber,
      targetFrequency: raw.targetFrequency,
      color: raw.colorLabel || '#858585',
      backgroundColor: raw.backgroundColorLabel || '#ebebeb',
    };
  };

  // transform the data into the format we are expecting
  try {
    const rawData = categorys;
    const resultCategoryProspects = rawData.map(convertCategoryProspect);
    return resultCategoryProspects;
  } catch (error) {
    console.error('Error in formateProsectCategory', error);
    return [];
  }
};

export const DateToTs = (date: number | string): number => {
  if (typeof date === 'number') {
    return date;
  }
  const Standard_DateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  const stillUtc = moment.utc(date).toDate();
  const localDateString = moment(stillUtc).local().format(Standard_DateFormat);
  return moment(localDateString, Standard_DateFormat).valueOf();
};

export function getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : '';
}

export const checkDateEquals = (
  date1: number | string | Date,
  date2: number | string | Date,
  format: string = 'MM/DD',
): boolean => {
  const d1 = moment(new Date(date1)).format(format);
  const d2 = moment(new Date(date2)).format(format);
  return d1 === d2;
};

export const getRegionIdByCode = (code: string) => {
  if (!code) {
    return '';
  }
  const reigonList = getAppConfigs(APP_CONFIG.GLOBAL_REGION).fullRegionList ?? [];
  let pickedRegion = 'hk';
  for (const key in reigonList) {
    if (Object.prototype.hasOwnProperty.call(reigonList, key)) {
      const region = reigonList[key];
      if (region.code === code) {
        pickedRegion = key;
      }
    }
  }
  return pickedRegion;
};
