import React, { FC, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { TokenInfo } from '../../../../Auth/types/auth-types';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { fetchDashboardJWT } from 'src/app/modules/AgencyCampaign/network/dashboardCrud';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useIntl } from "react-intl";
import { DashboardLibraryDev, TopDashboardsDev } from 'src/app/modules/KpiDashboard/types/dashboard-chartID-dev';
import { DashboardLibraryUat, TopDashboardsUat } from 'src/app/modules/KpiDashboard/types/dashboard-chartID-uat';
import { DashboardLibraryProd, TopDashboardsProd } from 'src/app/modules/KpiDashboard/types/dashboard-chartID-prod';
import { NO_DATA_AVAILABLE } from '../../constants'
import { AlertType, appendAlertItem } from '../../../../../../redux/common/commonSlice';

const baseUrl = window.envConfig['REACT_APP_MONGODB_CHART_BASE_URL'];
const chartSubscriptionKey = window.envConfig['REACT_APP_MONGODB_CHART_SUBSCRIPTION_KEY'];

type topDashboardsProps ={
  dashboardLeft: string;
}

type libararyProps = {
  report: { chartID: string, chartType: string }[]
}

var libarary: libararyProps = DashboardLibraryDev;
var topDashboards: topDashboardsProps = TopDashboardsDev;


type ChartListProps = {
  filter: any;
  myFilter: any;
  onRefresh?: boolean;
  refreshdata: () => void;
  setMyFilter: (data: any) => void;
  agentList: any;
  agentRole: string
};

const DownlineFcforGlList : FC<ChartListProps> = ({ filter, myFilter, onRefresh, refreshdata, setMyFilter, agentList, agentRole }) => {
  const [isShowChart, setIsShowChart] = useState<Boolean>(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [availableRows, setAvailableRows] = useState<any>()
  const [noDataAvailable, setNoDataAvailable] = useState<String>('No data')
  const user = useSelector<RootState, TokenInfo | undefined>(state => state.auth.user);

    const env = window.envConfig["REACT_APP_ENV"];
    if (env === "uat") {
      libarary = DashboardLibraryUat;
       topDashboards = TopDashboardsUat;
    }
    else {
      if (env === "prod") {
       libarary = DashboardLibraryProd;
        topDashboards = TopDashboardsProd;
      }
    }

const agent_code__c = user?.sub || '';
const filteredAgentList = agentList

let defaultFilter = {};
if(filteredAgentList) {
  defaultFilter = { agent_code__c: {$in:[...filteredAgentList]} };
}

  if (filter.periodRange === 'All' && filter.value.length > 1){
      defaultFilter = { $and: [{ agent_code__c: { $in: [...filteredAgentList] } }, { monthly_dates__c: { $gte: new Date(new Date(filter.value[0]).setHours(0, 0, 0, 0)) ,$lt: new Date(filter.value[1])}}]}
      }
      else if(filter.periodRange === 'Last Month') {
        defaultFilter = {$and : [{agent_code__c:{$in: [...filteredAgentList]}},
          {monthly_dates__c:{$gte: new Date(moment(new Date()).subtract(1, 'months').startOf('months').format("MM/DD/yyyy")) ,
          $lt: new Date(moment(new Date()).subtract(1, 'months').endOf('months').format("MM/DD/yyyy"))}}]
      }  }
      else if(filter.periodRange === 'This Quarter') {
        defaultFilter = {$and : [{agent_code__c:{$in:[...filteredAgentList]}}, {monthly_dates__c:{$gte: new Date(moment().startOf('quarter').format('MM/DD/YYYY')) ,
        $lt: new Date(moment().endOf('quarter').format('MM/DD/YYYY'))}}]
      } }
      else if(filter.periodRange === 'Last Quarter') {
        defaultFilter = {$and : [{agent_code__c:{$in:[...filteredAgentList]}}, {monthly_dates__c:{$gte: new Date(moment(new Date()).subtract(1, 'quarter').startOf('quarter').format('MM/DD/YYYY')) ,
        $lt: new Date(moment(new Date()).subtract(1, 'quarter').endOf('quarter').format('MM/DD/YYYY'))}}]
      } }
      else if(filter.periodRange === 'This Year') {
        defaultFilter = {$and : [{agent_code__c:{$in:[...filteredAgentList]}}, {monthly_dates__c:{$gte: new Date(moment().startOf('year').format('MM/DD/YYYY')) ,
        $lt: new Date(moment(new Date()).format("MM/DD/yyyy"))}}]
      }}
      else if(filter.periodRange === 'Last Year') {
        defaultFilter = {$and : [{agent_code__c:{$in:[...filteredAgentList]}}, {monthly_dates__c:{$gte: new Date(moment(new Date()).subtract(1,'year').startOf('year').format('MM/DD/YYYY')) ,
        $lt:  new Date(moment(new Date()).subtract(1,'year').endOf('year').format('MM/DD/YYYY'))}}]
      }
      }

  const sdk = new ChartsEmbedSDK({
    baseUrl,
    getUserToken: async function () {
      return await fetchDashboardJWT(user?.sub, chartSubscriptionKey)
    }
  });
  var chartID = '';

  for (var i = 0; i < libarary.report.length; i++) {
    var object = libarary.report[i];
   // console.log(object);
      if (object.chartType == filter.chartType) {
        chartID = object.chartID;
        // console.log(chartID);
        break;
      }
  }

  const chartDiv = useRef<HTMLDivElement>(null);
  const [rendered, setRendered] = useState(false);

  const [chart, reRender] = useState(
    sdk.createChart({
      chartId: chartID,
      height: 800,
      width:  1800,
      theme: 'light',
      filter: defaultFilter,
    }),
  );

  const [moreIdList, setMoreIdList] = useState<Array<string>>([]);

  const [chartTable, setChartTable] = useState(
    sdk.createChart({
      chartId: '62bd4752-a69a-468b-84b7-2cdf1739fddc',
      height: 800,
      width:  1800,
      theme: 'light',
      filter: defaultFilter,
    })
  );

  const getTopCharts = (topDashboards: any) => {
    if (chartDiv.current) {
        let left = sdk.createChart({
          // chartId: '62da4cf1-f1eb-49bc-8648-5681185edbde',
           chartId: topDashboards.dashboardLeft,
           height:  800,
           width:  1800,
           theme: 'light',
           filter: defaultFilter
         });

         setChartTable(left);
         left
           .render(chartDiv.current)
           .then(() => {
            setRendered(true)
            left.getData().then((res) => {
              const rowCount = JSON.parse(JSON.stringify(res)).documents.length
              setAvailableRows(rowCount)
              if(rowCount < 1){
                setNoDataAvailable(NO_DATA_AVAILABLE)
                dispatch(
                  appendAlertItem([
                    {
                      severity: AlertType.ERROR,
                      title: Translation('kpiDashboard.agent.data.notfound.title'),
                      content: Translation('kpiDashboard.agent.data.notfound.description'),
                    },
                  ]),
                );
              }
            });
          })
           .catch((err) => console.log('Error during get data.', err));
      }
  };

  const getMoreCharts = () => {
    let idList = [];
    for (var i = 0; i < libarary.report.length; i++) {
      if (libarary.report[i].chartType === 'Chart') {
        idList.push(libarary.report[i].chartID);
      }
    }
    setMoreIdList(idList);
    let width = (window.innerWidth - 40) / 3;
  };

  useEffect(() => {
    let width = (window.innerWidth - 40) / 3;
  }, [moreIdList]);

  useEffect(() => {
    switch (filter.filter) {
      case 'Assigned Date':
        setMyFilter({
          ...myFilter,
          monthly_dates__c: filter.value !== '' ? filter.value : undefined,
        });
        break;
      default:
        setMyFilter({
          monthly_dates__c: undefined,
        });
        break;
    }

  }, [chart, filter.filter, filter.value, rendered]);


  useEffect(() => {

    if ( filter.chartType == 'Table' ) {
      setIsShowChart(false);
      getTopCharts(topDashboards);
    } else {
      setIsShowChart(false);
    }
  }, [filter.periodRange, filter.chartType, filter.value, isShowChart, setMyFilter]);


  useEffect(() => {
    if (filter.chartType == 'Chart') {
       reRender(sdk.createChart({ chartId: chartID, height: 800, theme: 'light' }));
      setIsShowChart(true);
      getMoreCharts();
    } else {
      reRender(sdk.createChart({ chartId: chartID, height: 800, theme: 'light' }));
      setIsShowChart(false);
    }
  }, [filter.periodRange, filter.chartType, isShowChart, setMyFilter]);



  useEffect(() => {
    let obj = JSON.parse(JSON.stringify(myFilter));
    if (obj?.monthly_dates__c) {
      obj.$and = [
        { monthly_dates__c: { $gte: new Date(myFilter.monthly_dates__c[0]) } },
        { monthly_dates__c: { $lte: new Date(myFilter.monthly_dates__c[1]) } },
      ];
      delete obj.monthly_dates__c;
    }
    if(filteredAgentList) {
      chartTable.setFilter(defaultFilter).catch((err) => console.log('Error while filtering.', err));
    }

  }, [chart, filter.value, rendered, myFilter]);

  return (
         <>
         <div
           className="top"
           style={{ marginBottom: 20 }
           }>
            {availableRows < 1 && (<div style={{ fontSize: 14, marginBottom: 20 }}>{noDataAvailable}</div> )}
           <div className="chart" ref={chartDiv} />
         </div>
       </>
  );
};

export default DownlineFcforGlList;
