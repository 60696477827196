import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    borderRadius: 5,
    margin: theme.spacing(1),
  },
  error: {
    backgroundColor: 'red',
  },
  addBtnContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  mt_24: {
    marginTop: 24,
  },
  warningContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    fontSize: 12,
    color: '#E8192C',
    lineHeight: '20px',
    height: 400,
  },
  warningTimeContainer: {
    display: 'flex',
  },
  warningTimeContent: {
    fontSize: 14,
    marginLeft: 12,
  },
  warningNeedApproval: {
    lineHeight: '16px',
  },
  warningImg: {
    width: '180px',
    marginTop: 44,
  },
}));
