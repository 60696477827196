import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  LEADS_TITLE,
  LEADS_BASE_PATH,
  LEADS_NAMESPACE,
  LEADS_LIST_PATH,
  LEADS_LIST_NAMESPACE,
  LEADS_DETAIL_PATH,
} from './constants';

import LeadsListRoutes from './pages/leads/LeadsListRoutes';

export const leadsModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    menuHeader: Translation('title.pruleads'),
    title: Translation(LEADS_TITLE),
    path: LEADS_LIST_PATH,
    namespace: LEADS_LIST_NAMESPACE,
    component: LeadsListRoutes,
    disableMenu: false,
    disableRoleControl: true,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
    // child: [
    //   {
    //     title: Translation('lead.leadsList'),
    //     path: LEADS_LIST_PATH,
    //     namespace: LEADS_LIST_NAMESPACE,
    //     component: LeadsListRoutes,
    //     enableRead: true,
    //     enableCreate: true,
    //     enableUpdate: true,
    //     // page: [
    //     //   {
    //     //     title: Translation(CAMPAIGN_DETAIL_TITLE),
    //     //     path: `${CAMPAIGN_BASE_PATH}${MARKETING_CAMPAIGN_LIST_PATH}${MARKETING_CAMPAIGN_DETAIL_PATH}`,
    //     //   },
    //     // ],
    //   },
    // ],
    page: [
      {
        title: Translation('salesLdTitle'),
        path: `${LEADS_DETAIL_PATH}`,
      },
    ],
  };
};
