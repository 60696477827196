import { Button } from '@mui/material';
import { ProColumns } from 'src/app/common/components/ProTable';
import { Columns, LeadStatusEnum, ReassignLead, UpdateActivityStatus } from 'src/app/modules/Leads/types/types';
import { DateFormat, formatDate } from 'src/app/common/utils';
import { renderLeadCampaignName, renderLeadTypeOfLead } from 'src/app/modules/Leads/util/table-columns-render.util';
import { COLUMN_ITEMS } from 'src/app/modules/Leads/util/column-items.util';

export const getReassignLeadTableColumns = ({
  t,
  keyword,
  styles,
  onEditClick,
  statusUpdatedLead,
}: {
  t: (val: string) => string;
  keyword?: string;
  styles: Record<string, any>;
  onEditClick: (item: any) => void;
  statusUpdatedLead: any[];
}): ProColumns<any>[] => {
  return [
    {
      title: t('pruleads.prospect.list.column.name'),
      dataIndex: 'displayName',
      width: '290px',
      sorter: true,
      render: (currValue: string, item) => {
        return currValue;
      },
    },
    {
      title: t('lead.reassignLead.leadStatus'),
      dataIndex: 'leadStatus',
      width: '290px',
      render: (currValue: string, item) => {
        const status = statusUpdatedLead.find((lead) => lead.leadId === item.leadId)?.leadStatus ?? currValue;
        return leadStatusToString(status as LeadStatusEnum, t);
      },
    },
    {
      title: t('campaign.list.leadLabel'),
      dataIndex: 'labels',
      width: '290px',
      render: (currValue: string, item) => {
        return renderLeadTypeOfLead(currValue, item);
      },
    },
    // {
    //   title: t('campaign.list.campaignName'),
    //   dataIndex: 'campaignName',
    //   width: '290px',
    //   sorter: true,
    //   render: (currValue: string, item) => {
    //     return renderLeadCampaignName(currValue, item);
    //   },
    // },
    {
      ...COLUMN_ITEMS[Columns.source](),
      title: t(COLUMN_ITEMS[Columns.source]().title as string),
    },
    {
      ...COLUMN_ITEMS[Columns.leadSource](),
      title: t(COLUMN_ITEMS[Columns.leadSource]().title as string),
    },
    {
      title: t('campaign.list.lastUpdated'),
      dataIndex: 'updatedAt',
      width: '176px',
      sorter: true,
      render: (currValue: string) => {
        return formatDate(currValue, DateFormat.datetime);
      },
    },
    {
      title: t('prospect.appoinment.title.operation'),
      width: '290px',
      dataIndex: 'op',
      sticky: true,
      render: (currValue: string, item) => {
        return (
          <span>
            <Button
              variant="text"
              sx={{
                color: 'rgba(67, 134, 229, 1)',
              }}
              onClick={() => {
                onEditClick(item);
              }}
            >
              {t('lead.reassignLead.editLeadStatus')}
            </Button>
          </span>
        );
      },
    },
  ];
};

export const getAgentReassignToTableColumns = ({
  t,
  keyword,
  styles,
  lang,
}: {
  t: (val: string) => string;
  keyword?: string;
  styles: Record<string, any>;
  lang: string;
}): ProColumns<any>[] => {
  return [
    {
      title: t('pruleads.prospect.list.column.name'),

      dataIndex: 'name',
      width: '290px',
      render: (currValue: any, item) => {
        return currValue[lang];
      },
    },
    {
      title: t('lead.reassignLead.agentCode'),
      dataIndex: 'agentCode',
      width: '290px',
      render: (currValue: string, item) => {
        return currValue;
      },
    },
  ];
};

export const queryData = (keyword: string, agents: Array<any>, lang: string) => {
  let res: any[] = [];
  agents.forEach((item) => {
    const name = item.name[lang] ?? '';
    if (name && name.toLowerCase().includes(keyword.toLowerCase())) {
      res.push(item);
    }
  });
  return res;
};

export function leadStatusToString(status: LeadStatusEnum, t: (val: string) => string): string {
  switch (status) {
    case LeadStatusEnum.MET:
      return t('follow_up_state_met');
    case LeadStatusEnum.QUOTE:
      return t('follow_up_state_quote');
    case LeadStatusEnum.PROPOSAL:
      return t('follow_up_state_proposal');
    case LeadStatusEnum.POLICY:
      return t('policy_issued');
    case LeadStatusEnum.CONTACT_SUCCESS:
      return t('follow_up_state_contactSuccess');
    case LeadStatusEnum.PENDING_FOR_CONTACT:
      return t('follow_up_state_pending');
    case LeadStatusEnum.DEAD:
      return t('dead_lead');
    case LeadStatusEnum.REJECTED:
      return t('rejected');
    case LeadStatusEnum.PENDING_FOR_SERVICE:
      return t('marketing_lead_accepted');
    case LeadStatusEnum.ACCEPT_EXPIRED:
    case LeadStatusEnum.CONTACT_EXPIRED:
      return t('marketing_lead_expired');
    case LeadStatusEnum.WAITING_FOR_ACCEPT:
      return t('reassignLeadPending');
  }
}

export const toReassignLead = (leads: any[]): ReassignLead[] => {
  return leads.map((lead) => {
    return {
      leadId: lead.leadId,
      updateActivityStatus:
        lead.lastStatus && lead.leadStatus !== lead.lastStatus
          ? UpdateActivityStatus.UPDATE_TO_PENDING
          : UpdateActivityStatus.KEEP_SAME,
    };
  });
};
