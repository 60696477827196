import React, { memo, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './merge-dialog.style';
import PruDialog from 'src/app/common/components/PruDialog';
import { MergeSelectOption } from '../../../../../../common/components/universal-selector-dialog/select-option/select-option.component';
import { Prospect } from 'src/app/modules/Prospect/types/prospect-types';
import { reconstuctNameByLastName } from 'src/app/common/utils';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

type ComponentProps = {
  open: boolean;
  rows: Prospect[];
  onConfirm?: (rows: Prospect[], index: number) => void;
  onClose?: () => void;
  loading?: boolean;
};

export const MergeDialog: React.FC<ComponentProps> = memo(
  ({ open, rows, onConfirm, onClose, loading }: ComponentProps) => {
    const { t: Translation } = useTranslation();
    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    const [selected, setSelected] = useState(0);

    return (
      <PruDialog
        open={open}
        dialogTitle={Translation('pruleads.prospect.list.Merge')}
        confirmBtnText={Translation('app.button.confirm')}
        canncelBtnText={Translation('app.button.cancel')}
        onOk={() => {
          onConfirm?.(rows, selected);
        }}
        onCancel={() => {
          onClose?.();
        }}
        loading={loading}
      >
        <Box className={styles.container}>
          <Typography className={styles.text}>
            {Translation('pruleads.prospect.list.action.merge.dialog.text')}
          </Typography>
          <Box>
            {rows.map((row, index) => {
              const { displayName } = row;
              const label = displayName || reconstuctNameByLastName(row.firstName, row.lastName);
              return (
                <MergeSelectOption
                  key={index}
                  label={label}
                  selected={selected}
                  index={index}
                  className={styles.mt24}
                  onClick={(index) => {
                    if (selected !== index) {
                      setSelected(index);
                    }
                  }}
                />
              );
            })}
          </Box>
        </Box>
      </PruDialog>
    );
  },
);
