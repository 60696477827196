import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  MANAGER_DASHBOARD_TITLE,
  MANAGER_DASHBOARD_PRULEADS_TITLE,
  MANAGER_DASHBOARD_BASE_PATH,
  MANAGER_DASHBOARD_PRULEADS_PATH,
  MANAGER_DASHBOARD_PRULEADS_NAMESPACE,
} from './constants';
import PruLeadsRoutes from './pages/PruLeads/PruLeadsRoutes';
import { PruLeadsIcon } from 'src/app/common/components/ManagerDashboard';

export const managerDashboardModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  // const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: MANAGER_DASHBOARD_TITLE,
    path: MANAGER_DASHBOARD_BASE_PATH,
    disableMenu: true,
    disableRoleControl: true,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
    child: [
      {
        title: MANAGER_DASHBOARD_PRULEADS_TITLE,
        dashboardIcon: PruLeadsIcon,
        path: `${MANAGER_DASHBOARD_BASE_PATH}${MANAGER_DASHBOARD_PRULEADS_PATH}`,
        namespace: MANAGER_DASHBOARD_PRULEADS_NAMESPACE,
        component: PruLeadsRoutes,
        enableRead: true,
        enableCreate: true,
        enableUpdate: true,
      },
    ],
  };
};
