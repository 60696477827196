import React, { ReactNode, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, Dialog, DialogProps, IconButton, Slide, Container } from '@mui/material';
import { useStyles } from './full-screen-dialog.style';
import CloseIcon from '@mui/icons-material/Close';
import { HeaderPc } from '../../layout/components/header/header-pc/header-pc.component';
import { PageTitle } from '../../page/page.component';
import { Button, LoadingButton } from 'src/app/common/components/button/button.component';

export interface FullScreenDialogProps extends Omit<DialogProps, 'onClose'> {
  disableConfirm?: boolean;
  noConfirm?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  title: string;
  children?: ReactNode;
  backBtnText?: string;
  confirmBtnText?: string;
  loading?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FullScreenDialog: React.FC<FullScreenDialogProps> = memo(
  ({
    open,
    onConfirm,
    onClose,
    children,
    title,
    disableConfirm,
    noConfirm,
    confirmBtnText,
    backBtnText,
    loading,
    ...rest
  }: FullScreenDialogProps) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    return (
      <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition} {...rest}>
        <Box className={styles.header}>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" className={styles.iconButton}>
            <CloseIcon className={styles.closeIcon} />
          </IconButton>
          <HeaderPc showRightContent={false} className={styles.headerStyle} />
        </Box>
        {/* same with page */}
        <Container sx={{ maxWidth: '1700px !important' }}>
          <Box className={styles.childrenContainer}>
            <PageTitle
              className={styles.title}
              title={title}
              rightNode={
                <>
                  <Button variant="outlined" onClick={onClose}>
                    {backBtnText ?? Translation('app.button.back')}
                  </Button>
                  {noConfirm ? null : (
                    <LoadingButton
                      className={styles.ml16}
                      variant="contained"
                      color="primary"
                      onClick={onConfirm}
                      disabled={disableConfirm}
                      loading={loading}
                    >
                      {confirmBtnText ?? Translation('app.button.confirm')}
                    </LoadingButton>
                  )}
                </>
              }
            />
            {children}
          </Box>
        </Container>
      </Dialog>
    );
  },
);
