import React, { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { ActionNodeTypeProps } from 'src/app/common/components/ProTable';
import { Prospect, ProspectCategory } from 'src/app/modules/Prospect/types/prospect-types';
import {
  batchEditProspectsCategory,
  deleteProspect,
  mergeProspect,
} from 'src/app/modules/Prospect/network/prospectCurd';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';

export type ActionNodeProps = ActionNodeTypeProps<Prospect> & {
  prospectCategories?: ProspectCategory[];
};

export enum DialogType {
  close,
  category,
  merge,
  mergeConfirm,
  delete,
}

export const useActionItems = ({ selectedRows, onAction }: ActionNodeProps) => {
  const { t: Translation } = useTranslation();

  const [open, setOpen] = useState(DialogType.close);
  const retainIndex = useRef<any>(0);
  const dispatch = useDispatch();
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [mergeLoading, setMergeLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const batchCategory = async (prospectCategoryId: string) => {
    try {
      setCategoryLoading(true);
      await batchEditProspectsCategory({
        target: selectedRows.map(({ _id }) => _id),
        prospectCategoryId,
      });
      onAction(['clearRow', 'refresh']);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('common.updateSuccessfully'),
            content: '',
          },
        ]),
      );
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: Translation('prospect.edit.category.failTitle'),
            content: Translation('common.pleaseTryAgain'),
          },
        ]),
      );
    } finally {
      setCategoryLoading(false);
      setOpen(DialogType.close);
    }
  };

  const merge = async () => {
    try {
      const index = retainIndex.current;
      setMergeLoading(true);
      await mergeProspect([
        {
          target: selectedRows[index]._id,
          list: selectedRows.map(({ _id }) => _id),
        },
      ]);
      onAction(['clearRow', 'refresh']);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('prospect.mergeSuccessfully'),
            content: '',
          },
        ]),
      );
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: Translation('prospect.mergeFailed'),
            content: Translation('common.pleaseTryAgain'),
          },
        ]),
      );
    } finally {
      setMergeLoading(false);
      setOpen(DialogType.close);
    }
  };

  const deleteProspects = async () => {
    try {
      setDeleteLoading(true);
      await deleteProspect(selectedRows.map(({ _id }) => _id));
      onAction(['clearRow', 'refresh']);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('prospect.deleteSuccessfully'),
            content: '',
          },
        ]),
      );
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: Translation('prospect.deleteFailed'),
            content: Translation('common.pleaseTryAgain'),
          },
        ]),
      );
    } finally {
      setDeleteLoading(false);
      setOpen(DialogType.close);
    }
  };

  const disableDelete = useMemo(() => selectedRows.some(({ activeLeads }) => !!activeLeads), [selectedRows]);

  return {
    open,
    setOpen,
    Translation,
    disableDelete,
    batchCategory,
    deleteProspects,
    merge,
    retainIndex,
    categoryLoading,
    mergeLoading,
    deleteLoading,
  };
};
