import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { ApiErrorResponse } from '../../../../common/network/apiErrorHandler';
import { useDispatch } from 'react-redux';
import { LayoutSplashScreen } from 'src/app/layout';
import { fetchSurveyItem } from '../../network/surveyCrud';
import { SurveyResult } from '../../types/survey-types';
import SurveySubmitForm from './components/SurveySubmitForm';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';

const SurveySubmitPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { surveyId } = useParams<{surveyId: string}>();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [surveyResult, setSurveyResult] = useState<SurveyResult>();

  const reloadData = () => {
    if (surveyId) {
      setIsLoading(true);
      fetchSurveyItem(surveyId, dispatch)
        .then(result => {
          setSurveyResult(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [surveyId]);

  return (
    <>
      {isLoading || !surveyResult ? (
        <LayoutSplashScreen />
      ) : (
        <SurveySubmitForm
          surveyId={surveyResult.surveyId}
          surveyBody={surveyResult.surveyBody}
          surveyResult={surveyResult.surveyResults.length > 0 ? surveyResult.surveyResults[0] : undefined}
        />
      )}
    </>
  )
};

export default SurveySubmitPage;
