import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { forEach, get, set } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import { getDownline, getLeadsByGroupCode, reasignLead } from '../../../network/leadsCrud';
import { GetLeadsByGroupReqDto, ReassignLeadsListTypeEnum, ReassignLeadsRequest } from '../../../types/types';
import { getReassignLeadTableColumns, toReassignLead } from './util/reassign-lead.util';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { useHistory } from 'react-router-dom';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import {
  reassignLeadGaDataClick1,
  reassignLeadGaDataClick2,
  reassignLeadGaDataClick3,
} from 'src/app/common/ga/types/ga-click-lead';

interface HookProps {
  id: number;
  // other params
}

const defaultParam = {
  page: 1,
  pageSize: 20,
  limit: 5,
};

export const useReassignLead = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const history = useHistory();

  const [steps, setSteps] = useState(['Select Reassign Leads', 'Select Agent Reassign to']);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedUnit, setSelectedUnit] = useState<string>();
  const [selectedLeads, setSelectedLeads] = useState<any[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<any>();
  const [updatedLeads, setUpdatedLeads] = useState<any[]>([]);

  const commentRef = useRef<string>('');

  const onSelectedLeads = useCallback((selectedLeads: any[]) => {
    setSelectedLeads(selectedLeads);
  }, []);

  const onSelectedUnit = useCallback((groupCode: string) => {
    takeUIClickEvent(reassignLeadGaDataClick1);
    setSelectedUnit(groupCode);
  }, []);

  const onSelectedAgent = useCallback((agent: any) => {
    setSelectedAgent(agent);
  }, []);

  const onUpdatedLead = useCallback((updatedLeads: any[]) => {
    setUpdatedLeads(updatedLeads);
    takeUIClickEvent(reassignLeadGaDataClick2);
  }, []);

  const onCommentInput = useCallback((comment: string) => {
    commentRef.current = comment;
  }, []);

  const hasSelectedLeads = useMemo(() => selectedLeads.length > 0, [selectedLeads]);

  const hasSelectedAgent = useMemo(() => !!selectedAgent, [selectedAgent]);

  const disableNext = useMemo(() => {
    return currentStep === 1 ? !hasSelectedLeads : !hasSelectedAgent;
  }, [hasSelectedAgent, hasSelectedLeads, currentStep]);

  const submit = useCallback(() => {
    if (updatedLeads.length > 0) {
      selectedLeads.forEach((lead) => {
        const updatedLead = updatedLeads.find((updatedLead) => updatedLead.leadId === lead.leadId);
        lead.lastStatus = lead.leadStatus;
        if (updatedLead) {
          lead.leadStatus = updatedLead.leadStatus;
        }
      });
    }

    const req: ReassignLeadsRequest = {
      groupCode: selectedUnit,
      leads: toReassignLead(selectedLeads),
      toAgent: selectedAgent.agentCode,
      comment: commentRef.current,
      type: ReassignLeadsListTypeEnum.PARTIAL,
    };

    reasignLead(req, dispatch).then((res) => {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            content: Translation('lead.reassignLead.reassignedSuccessful'),
          },
        ]),
      );
      history.goBack();
    });
  }, [selectedAgent, selectedLeads, updatedLeads, selectedUnit]);

  const handleNext = useCallback(() => {
    if (currentStep === 1) {
      setCurrentStep(2);
      return;
    }
    takeUIClickEvent(reassignLeadGaDataClick3);
    submit();
  }, [currentStep, submit]);

  return {
    steps,
    currentStep,
    selectedUnit,
    disableNext,
    handleNext,
    setCurrentStep,
    onSelectedLeads,
    onSelectedUnit,
    onSelectedAgent,
    onUpdatedLead,
    onCommentInput,
  };
};
