import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  majorRed: {
    color: '#E8192C',
  },
  content: {
    color: '#999999',
    fontSize: '14px',
    fontWeight: 500,
    paddingTop: '24px',
    paddingBottom: '24px',
  },
}));
