import { forEach } from 'lodash';
import { Dispatch } from 'react';
import { apiClient ,apiErrorHandler} from 'src/app/common/network';
import { convertServerLocale,RegionLocale } from 'src/app/i18n';
import {
  AgentItem,
  AgentListParam,
  AgentPaginateList,
  AgentDetailItem,
  AgentStructureItem,
  AgentTypeItem,
  DownlineVerifyList,
  ImportVerify,
  LeadUploadItem,
  NonDownlineVerifyList,
} from '../types/agent-types';

const AGENT_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_AGENT']}`;
// const AGENT_URL = `http://localhost:3333`
// const MANAGER_URL  = `http://localhost:5555`
const MANAGER_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_MANAGER']}`;
export const fetchCurrentAgentInfo = async (lang: string, dispatch?: Dispatch<any>): Promise<any> => {
  let queryPath = `${AGENT_URL}/agents/me?lang=${convertServerLocale(lang as RegionLocale)}`;

  return apiClient
    .get<any>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchFinancialAgentList = async (
  params: AgentListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<AgentPaginateList> => {
  let queryPath = `${AGENT_URL}/agents/me/agency-campaign?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${param}&` : '';
  });

  //Query for sorting
  let sortingCount = 0;
  queryPath += `sort=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${sortParam.key}:${sortParam.value}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:desc`;
  }

  return apiClient
    .get<AgentPaginateList>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export type ChartListParam = {
  periodRange: string;
  chartType: string;
  filter: string;
  value: string;
};

export const fetchChartList = async (params: ChartListParam, dispatch?: Dispatch<any>): Promise<any> => {};

export const fetchAlAgentList = async (dispatch?: Dispatch<any>): Promise<AgentTypeItem[]> => {
  return (
    apiClient
      //{{url}}/manager-experience/{{version}}/managers/me/productions
      // .get<AgentTypeItem[]>(`${MANAGER_URL}/managers/me/productions`)
      .get<AgentTypeItem[]>(`${MANAGER_URL}/managers/me/downlines/divisions`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw apiErrorHandler(err, dispatch);
      })
  );
};

export const fetchFinancialAgentTypeList = async (dispatch?: Dispatch<any>): Promise<AgentTypeItem[]> => {
  return apiClient
    .get<AgentTypeItem[]>(`${AGENT_URL}/agents/me/campaign-type/list`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDownlineDivision = async (agentTypeId: string, dispatch?: Dispatch<any>): Promise<any> => {
  //  debugger
  return apiClient
    .get<string>(`${MANAGER_URL}/managers/${agentTypeId}/downlines/divisions`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDownlineAgentsList = async (
  agentTypeId: string,
  groupCode: string,
  dispatch?: Dispatch<any>,
): Promise<string> => {
  return apiClient
    .get<string>(`${MANAGER_URL}/managers/${agentTypeId}/downlines/agents/${groupCode}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchGlAgentsList = async (agentTypeId: string, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${AGENT_URL}/agents/${agentTypeId}/agent-360-dtl/all`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchFilterGlAgentList = async (
  agentTypeId: string,
  startDate: string,
  endDate: string,
  dispatch?: Dispatch<any>,
): Promise<string> => {
  return apiClient
    .get<string>(`${AGENT_URL}/agents/${agentTypeId}/agent-360-dtl/all?startDate=${startDate}&endDate=${endDate}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignTypeStructure = async (
  agentTypeId: string,
  dispatch?: Dispatch<any>,
): Promise<AgentStructureItem> => {
  return apiClient
    .get<AgentStructureItem>(`${AGENT_URL}/agents/me/campaign-type/${agentTypeId}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadParticipantUploadCsv = async (dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${AGENT_URL}/agents/me/agency-campaign/participant/template/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDownlineList = async (
  params: AgentListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<AgentPaginateList> => {
  let queryPath = `${AGENT_URL}/agents/me/agency-campaign/downline/list?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${param}&` : '';
  });
  //Query for sorting
  let sortingCount = 0;
  queryPath += `sort=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${sortParam.key}:${sortParam.value}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:desc`;
  }
  return apiClient
    .get<AgentPaginateList>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

/**
 * select from list
 * @returns
 */
export const verifyDownlineList = async (
  param: ImportVerify,
  dispatch?: Dispatch<any>,
): Promise<NonDownlineVerifyList> => {
  let path = `${AGENT_URL}/agents/me/agency-campaign/downline/verification`;
  return apiClient
    .post<NonDownlineVerifyList>(path, param, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
/**
 * eligibility-check for import participant from file
 * @param dispatch
 * @returns
 */
export const verifyNonDownlineList = async (
  param: ImportVerify,
  dispatch?: Dispatch<any>,
): Promise<NonDownlineVerifyList> => {
  let path = `${AGENT_URL}/agents/me/agency-campaign/non-downline/verification`;
  return apiClient
    .post<NonDownlineVerifyList>(path, param, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const verifyEligibilityList = async (
  agentList: string[],
  rules: string[],
  dispatch?: Dispatch<any>,
): Promise<DownlineVerifyList> => {
  let path = `${AGENT_URL}/agents/me/agency-campaign/eligibility-check`;
  return apiClient
    .post<DownlineVerifyList>(
      path,
      {
        agents: agentList,
        rules: rules,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewCampaign = async (data: AgentDetailItem, dispatch?: Dispatch<any>): Promise<AgentDetailItem> => {
  return apiClient
    .post<AgentDetailItem>(`${AGENT_URL}/agents/me/agency-campaign`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateCampaign = async (
  data: AgentDetailItem,
  campaignId: string,
  dispatch?: Dispatch<any>,
): Promise<AgentDetailItem> => {
  return apiClient
    .patch<AgentDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/${campaignId}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const submitCampaign = async (
  data: AgentDetailItem,
  agentId: string,
  dispatch?: Dispatch<any>,
): Promise<AgentDetailItem> => {
  return apiClient
    .patch<AgentDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/submit/${agentId}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const cancelCampaignSubmission = async (
  data: AgentDetailItem,
  dispatch?: Dispatch<any>,
): Promise<AgentDetailItem> => {
  return apiClient
    .post<AgentDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/discard-status`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignItem = async (id: string, dispatch?: Dispatch<any>): Promise<AgentDetailItem> => {
  return apiClient
    .get<AgentDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadLeadsTemplateCsv = async (dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${AGENT_URL}/agents/me/agency-campaign/lead/template/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const uploadLead = async (data: LeadUploadItem, dispatch?: Dispatch<any>): Promise<LeadUploadItem> => {
  return apiClient
    .post<AgentDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/lead/create`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchParticipantList = async (
  params: AgentListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<AgentPaginateList> => {
  let queryPath = `${AGENT_URL}/agents/me/agency-campaign/participant/list?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${param}&` : '';
  });
  //Query for sorting
  let sortingCount = 0;
  queryPath += `sortBy=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${sortParam.key}:${sortParam.value}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:desc`;
  }
  return apiClient
    .get<AgentPaginateList>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const addParticipant = async (data: any[], dispatch?: Dispatch<any>): Promise<LeadUploadItem[]> => {
  return apiClient
    .post<AgentDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/participant`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const removeParticipant = async (data: any, dispatch?: Dispatch<any>): Promise<LeadUploadItem> => {
  return apiClient
    .post<AgentDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/participant/remove`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const sendInvitation = async (data: any, dispatch?: Dispatch<any>): Promise<LeadUploadItem> => {
  return apiClient
    .post<AgentDetailItem>(`${AGENT_URL}/agents/me/agency-campaign/invitation`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const checkAllRsvpResponded = async (campaignObjId: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<any>(`${AGENT_URL}/agents/me/agency-campaign/participant/all-responded?id=${campaignObjId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
