import { combineReducers } from 'redux';

import { authSlice, authPersistConfig } from '../app/modules/Auth/network/authSlice';
import { commonSlice } from './common/commonSlice';
import { persistReducer } from 'redux-persist';
import { gaSlice } from '../app/common/ga/redux/gaSlice';
import { layoutSlice } from './layout/layout-reducer';
import { insightSlice } from '../app/modules/Insight/redux/insight.slice';
import { talkbotSlice } from 'src/app/modules/ai-talkbot/redux/talkbot-redux';

export const rootReducer = combineReducers({
  common: commonSlice.reducer,
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  ga: gaSlice.reducer,
  layout: layoutSlice.reducer,
  insight: insightSlice.reducer,
  talkbot: talkbotSlice.reducer,
});
