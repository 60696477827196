import React, { FC } from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import {ReportsPage} from './reports.screen';
import {
    REPORTS_BASE_PATH
} from '../../constants';



const ReportRoutes: FC<RouteComponentProps> = ({ history, location, match }) => {
  return (
    <Switch>
      <PruRoute
        exact
        path={REPORTS_BASE_PATH}
        render={(props) => <ReportsPage />}
      />
      <Redirect to="/error" />
    </Switch>
  );
};

export default ReportRoutes;
