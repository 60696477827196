import { get, set } from 'lodash';
import {
  OperationType,
  renderDateTime,
  renderFollowUpLeadOperation,
  renderLeadCampaignName,
  renderLeadOperation,
  renderLeadProspectName,
  renderLeadRemarkNoLabel,
  renderLeadRemark,
  renderLeadStatus,
  renderLeadText,
  renderLeadTypeOfLead,
  renderOpportunityLeadOperation,
  renderProspectLeadOperation,
  renderSLATime,
  renderLeadDropDate,
  renderLeadSource,
  TalkbotOperationType,
} from 'src/app/modules/Leads/util/table-columns-render.util';
import { ProColumns } from 'src/app/common/components/ProTable';
import { Columns, FollowUpStatusEnum, Lead } from '../types/types';

type ColumnValues = (typeof Columns)[keyof typeof Columns];

type ColumnItems = {
  [K in ColumnValues]: (arg1?: any, arg2?: any) => ProColumns<Lead>;
};

export const COLUMN_ITEMS: ColumnItems = {
  [Columns.displayName]: (keyword?: string) => ({
    title: 'name',
    dataIndex: Columns.displayName,
    sorter: true,
    width: '290px',
    render: (currValue: string, item: Lead) => renderLeadProspectName(currValue, item, keyword),
  }),
  [Columns.typeOfLead]: () => ({
    title: 'salesFuTypeOfLeadTitle',
    dataIndex: Columns.typeOfLead,
    width: '176px',
    render: renderLeadTypeOfLead,
  }),
  [Columns.sla]: () => ({
    title: 'lead_status_nav_tilte_sla',
    dataIndex: Columns.sla,
    sorter: true,
    width: '176px',
    render: renderSLATime,
  }),
  [Columns.campaignName]: () => ({
    title: 'salesAddLeadCampaignName',
    dataIndex: Columns.campaignName,
    sorter: true,
    width: '466px',
    render: renderLeadCampaignName,
  }),
  [Columns.source]: () => ({
    title: 'salesLdSourceTitle',
    dataIndex: Columns.source,
    sorter: false,
    width: '466px',
    render: renderLeadSource,
  }),
  [Columns.leadStatus]: () => ({
    title: 'lead_status',
    dataIndex: Columns.leadStatus,
    width: '466px',
    render: renderLeadStatus,
  }),
  [Columns.remark]: () => ({
    title: 'remark',
    dataIndex: Columns.remark,
    width: '290px',
    render: renderLeadRemark,
  }),
  // PACS Customization: PACSPFAP-3089 | Start
  [Columns.noLabelRemark]: () => ({
    title: 'remark',
    dataIndex: Columns.remark,
    width: '290px',
    render: renderLeadRemarkNoLabel,
  }),
  // PACS Customization: PACSPFAP-3089 | End
  [Columns.leadSource]: () => ({
    title: 'salesAddLeadLeadSubSource',
    dataIndex: Columns.leadSource,
    // sorter: true,
    width: '176px',
    render: (currValue: string, item: Lead) => renderLeadText(item?.leadSubSource?.sourceName ?? ''),
  }),
  [Columns.lastViewedAt]: () => ({
    title: 'recently_viewed',
    sorter: true,
    dataIndex: Columns.lastViewedAt,
    width: '176px',
    render: renderDateTime,
  }),
  [Columns.updatedAt]: () => ({
    title: 'last_update_time',
    sorter: true,
    dataIndex: Columns.updatedAt,
    width: '176px',
    render: renderDateTime,
  }),
  [Columns.operation]: (operations: OperationType, status?: FollowUpStatusEnum) => ({
    title: 'section.common.operation',
    sticky: true,
    dataIndex: Columns.operation,
    width: '176px',
    render: (currValue: string, item: Lead, action) => renderLeadOperation(item, operations, action, status),
  }),
  [Columns.dropDate]: () => ({
    title: 'lead.list.header.dropDate',
    dataIndex: Columns.dropDate,
    width: '176px',
    render: renderLeadDropDate,
  }),
  [Columns.opportunityOperation]: (operations: TalkbotOperationType) => ({
    title: 'section.common.operation',
    sticky: true,
    dataIndex: Columns.opportunityOperation,
    width: '176px',
    render: (currValue: string, item: Lead, action) => renderOpportunityLeadOperation(item, action, operations),
  }),
  [Columns.followUpOperation]: () => ({
    title: 'section.common.operation',
    sticky: true,
    dataIndex: Columns.followUpOperation,
    width: '176px',
    render: (currValue: string, item: Lead, action) => renderFollowUpLeadOperation(item, action),
  }),
  [Columns.prospectLeadOperation]: () => ({
    title: 'section.common.operation',
    sticky: true,
    dataIndex: Columns.prospectLeadOperation,
    width: '176px',
    render: (currValue: string, item: Lead, action) => renderProspectLeadOperation(item, action),
  }),
};
