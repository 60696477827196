import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  birthdayIconContainer: { backgroundColor: '#FFEFE3', display: 'inline-block' },
  birthdayIcon: { color: '#FF872E', width: '24px', height: '24px' },
  pageContainer: {
    paddingTop: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  m24: {
    margin: 24,
  },
}));
