import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Prospect } from '../../../types/prospect-types';
import { FullScreenDialogProps } from 'src/app/common/components/Dialog/full-screen-dialog/full-screen-dialog.component';

export interface ReferredByProps {
  defaultSelectedRows?: Prospect[];
}

export const useReferredBy = ({ defaultSelectedRows }: ReferredByProps) => {
  const { t: Translation } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<Prospect[] | undefined>(defaultSelectedRows);

  const rowSelection = useMemo(
    () => ({
      onChange: (rows: Prospect[]) => {
        setSelectedRow(rows);
      },
      getCheckboxProps: () => ({
        disabled: false,
      }),
    }),
    [],
  );

  return {
    Translation,
    selectedRow,
    rowSelection,
  };
};
