import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TalkbotInitialState {
  activeFlowIds?: number[];
}

const initialState: TalkbotInitialState = {};

export const talkbotSlice = createSlice({
  name: 'talkbot',
  initialState,
  reducers: {
    setActiveFlowIds(state, action: PayloadAction<number[]>) {
      state.activeFlowIds = action.payload;
    },
  },
});

export const { setActiveFlowIds } = talkbotSlice.actions;
