export interface Prospect {
  _id: string;
  firstName: string;
  lastName: string;
  types: string[];
  countryCode: string;
  phoneNumber: string;
  email: string;
  prospectCategory: string;
  sourceType: string;
  nextEngagement: string[];
  nameWeight: number;
  displayName: string;
  fieldUpdatedAt: string;
  createdAt: string;
  updatedAt: string;
  birthDate: string;
  gender: string;
  activeLeads: number;
}

export interface ProspectsResponse {
  prospects: Prospect[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number | null;
  nextPage: number | null;
}

export interface ProspectCategory {
  _id: string;
  type: string;
  isDefault: boolean;
  isActive: boolean;
  createdBy: string;
  updatedBy: string;
  name: {
    en: string;
    'zh-Hant': string;
  };
  description: {
    en: string;
    'zh-Hant': string;
  };
  createdAt: string;
  updatedAt: string;
  backgroundColorLabel: string;
  colorLabel: string;
}

export interface ProspectCategoriesResponse {
  prospectCategories: ProspectCategory[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number | null;
  nextPage: number | null;
}

export enum ProspectTypes {
  prospect_type_customer = 'prospect_type_customer',
  prospect_type_non_customer = 'prospect_type_non_customer',
  prospect_type_candidate = 'prospect_type_candidate',
  prospect_type_non_candidate = 'prospect_type_non_candidate',
  prospect_type_agent = 'prospect_type_agent',
}

export enum ProspectSourceTypes {
  prospect_source_marketing = 'prospect_source_marketing',
  prospect_source_agent = 'prospect_source_agent',
}

export interface RequestProspect {
  firstName: string;
  lastName: string;
  regionCode?: string;
  phoneNumber: string;
  email: string;
  prospectCategory: string;
  types?: string[];
  referredBy: string;
}

export interface PoliciesResponseModel {
  policyNo: string;
  productName: string;
  status: string;
  AFYP: string;
  polOwnerCode: string;
  polOwnerName: string;
  laClientCode: string;
  laClientName: string;
  statusCode: number;
}

export interface ProspectSpecifedColumn {
  countryCode?: string;
  phoneNumber?: string;
}

export interface ProspectEngagement {
  id: string;
  prospectId: string;
  prospectCategory?: string;
  subject: string;
  description?: string;
  engagementTypeId: string;
  methodId: string;
  sendDate: string;
  effectivenessId?: string;
  createdAt?: number;
  updatedAt?: number;
}

export enum ProspectSourceTypeEnum {
  salesMarketing = 'prospect_source_marketing',
  salesAgent = 'prospect_source_agent',
}

export enum ProspectTypeEnum {
  salesCustomer = 'prospect_type_customer',
  salesNonCustomer = 'prospect_type_non_customer',
  candidate = 'prospect_type_candidate',
  potential_candidate = 'prospect_type_non_candidate',
  prospect_type_agent = 'prospect_type_agent',
}

export enum ProspectContactStatusEnum {
  salesYes = 'Y',
  salesNo = 'N',
}

export enum ProspectMaritalStatusEnum {
  salesSingle = 'single',
  salesMarried = 'married',
  salesDivorced = 'divorced',
  salesWidowed = 'widowed',
  salesUnknown = 'unknown',
}

export interface ProspectPolicy {
  policyNumber: string;
  clientCode: string;
  effectiveDate: string;
  expiredDate: string;
  eligibleBenefit: string;
  paymentMethod: string;
  paidUpDate: string;
  productDescription: string;
  insuredName: string;
  insuredClientCode: string;
  insuredGender: string;
  insuredPhoneNumber: string;
  insuredNextAge: number;
  otherCustomerInformation?: string;
}

export interface ProspectDetail {
  id: string;
  engagement: ProspectEngagement[];
  source: ProspectSourceTypeEnum;
  types: ProspectTypeEnum[];
  regionCode?: string;
  nextEngagement: string[];
  prospectCategory: string;
  createdAt: number;
  updatedAt: number;
  firstName: string; // First Name
  lastName?: string; // Last Name
  nameWeight?: number;
  displayName?: string;
  gender?: string; // Gender
  birthDate?: string; // Birth of Date
  // @ts-ignore
  maritalStatus?: ProspectMaritalStatusEnum; // Marital Status
  occupation?: string; // Occupation
  company?: string; // Company of Employment
  workPosition?: string; // Work Position
  income?: string; // Income
  // @ts-ignore
  language?: ProspectLanguageEnum; // Preferred Language
  race?: string; // Race
  nationality?: string; // Nationality
  permanentResident?: boolean; // Permanent Resident
  education?: string; // Education
  smokeHabit?: string; // Smoker Indicator
  salutation?: string; // Salutation
  countryNationalId?: string; // Country National ID
  chinaId?: string; // Country National ID
  passportNumber?: string; // Passport Number
  notes?: string; // Notes
  referredBy?: string; // Referred By
  nric?: string; // NRIC
  nricSuffix?: string; // NRIC Suffix
  // Contact Information
  phoneNumber: string; // Phone Number-number
  secondPhoneNumber?: string; // Second Phone Number
  email?: string; // Email Address
  secondEmail?: string; // Second Email Address
  fbProfile?: string; // Facebook Profile
  whatsApp?: string; // whatsApp
  wechatId?: string; // WeChat ID
  address?: string; // Address
  state?: string; // State
  city?: string; // City

  zipCode?: string; // Zip Code
  marketingConsent?: boolean; // PACS Customization | PACSPFAP-3083
  optedOutContactStatus: ProspectContactStatusEnum; // Opt Out(Y/N)
  expectedIncomeCoverMonths?: number; // Expected months of income need cover
  existingCISumAssured?: number; // Sum assured of existing CI policy
  preferredCISumAssured?: string; // Preferred sum assured of CI protection
  // Family Information
  totalChild?: number; // Number of Children
  childrenDetails?: string; // Children Details
  spouseDetails?: string; // Spouse Details
  parentDetails?: string; // Parent Details
  otherFamilyDetails?: string; // Other Family Details
  categoryUpdatedLog?: [];
  // customerized filed
  policies?: ProspectPolicy[];

  activeLeads?: number;

  prospectClientCode?: string;
}

export enum ProspectGenderEnum {
  salesFemale = 'F',
  salesMale = 'M',
}

export interface ProspectDetailForm {
  prospectCategory?: string;
  firstName?: string; // First Name
  lastName?: string; // Last Name
  gender?: string; // Gender
  birthDate?: string; // Birth of Date
  // @ts-ignore
  maritalStatus?: ProspectMaritalStatusEnum; // Marital Status
  occupation?: string; // Occupation
  company?: string; // Company of Employment
  workPosition?: string; // Work Position
  income?: string; // Income
  // @ts-ignore
  language?: ProspectLanguageEnum; // Preferred Language
  race?: string; // Race
  nationality?: string; // Nationality
  permanentResident?: boolean; // Permanent Resident
  education?: string; // Education
  smokeHabit?: string; // Smoker Indicator
  salutation?: string; // Salutation
  countryNationalId?: string; // Country National ID
  chinaId?: string;
  passportNumber?: string; // Passport Number
  notes?: string; // Notes
  referredBy?: string; // Referred By
  // Contact Information
  regionCode?: string;
  phoneNumber?: string; // Phone Number-number
  secondPhoneNumber?: string; // Second Phone Number
  email?: string; // Email Address
  secondEmail?: string; // Second Email Address
  fbProfile?: string; // Facebook Profile
  whatsApp?: string; // Whatsapp
  wechatId?: string; // WeChat ID
  address?: string; // Address
  state?: string; // State
  city?: string; // City

  zipCode?: string; // Zip Code
  optedOutContactStatus?: ProspectContactStatusEnum; // Opt Out(Y/N)
  // Family Information
  totalChild?: number; // Number of Children
  childrenDetails?: string; // Children Details
  spouseDetails?: string; // Spouse Details
  parentDetails?: string; // Parent Details
  otherFamilyDetails?: string; // Other Family Details
  expectedIncomeCoverMonths?: number; // Expected months of income need cover
  existingCISumAssured?: number; // Sum assured of existing CI policy
  preferredCISumAssured?: string; // Preferred sum assured of CI protection
  error?: any;
  id: string;
  nric?: string; // PACS Customization | PACSPFAP-3083
  nricSuffix?: string; // PACS Customization | PACSPFAP-3083
  marketingConsent?: boolean; // PACS Customization | PACSPFAP-3083
}

export enum ProspectLanguageEnum {
  salesCantonese = 'cantonese',
  salesEnglish = 'english',
  salesMandarin = 'mandarin',
}

export enum YesOrNoEnum {
  salesYes = 'Y',
  salesNo = 'N',
}

export enum SalutationEnum {
  Mr = 'Mr.',
  Mrs = 'Mrs.',
  Ms = 'Ms.',
}

export enum ProspectRaceEnum {
  salesChinese = 'Chinese',
  salesEura = 'Eura',
  salesIndian = 'Indian',
  salesMalay = 'Malay',
  salesOther = 'Other',
}

export enum ProspectStateEnum {
  salesChinaMainland = 'China Mainland',
  salesHongKong = 'Hong Kong',
}

export enum ProspectCityEnum {
  salesHongKong_Hongkong = 'hong kong',
  salesHongKong_Kowloon = 'kowloon',
  salesHongKong_NewTerritories = 'new territories',

  salesChinaMainland_Anhui = 'anhui',
  salesChinaMainland_Beijing = 'beijing',
  salesChinaMainland_Chongqing = 'chongqing',
  salesChinaMainland_Fujian = 'fujian',
  salesChinaMainland_Gansu = 'gansu',
  salesChinaMainland_Guangdong = 'guangdong',
  salesChinaMainland_Guangxi = 'guangxi',
  salesChinaMainland_Guizhou = 'guizhou',
  salesChinaMainland_Hainan = 'hainan',
  salesChinaMainland_Hebei = 'hebei',
  salesChinaMainland_Heilongjiang = 'heilongjiang',
  salesChinaMainland_Henan = 'henan',
  salesChinaMainland_Hubei = 'hubei',
  salesChinaMainland_Hunan = 'hunan',
  salesChinaMainland_InnerMongolia = 'inner mongolia',
  salesChinaMainland_Jiangsu = 'jiangsu',
  salesChinaMainland_Jiangxi = 'jiangxi',
  salesChinaMainland_Jilin = 'jilin',
  salesChinaMainland_Liaoning = 'liaoning',
  salesChinaMainland_Ningxia = 'ningxia',
  salesChinaMainland_Qinghai = 'qinghai',
  salesChinaMainland_Shaanxi = 'shaanxi',
  salesChinaMainland_Shandong = 'shandong',
  salesChinaMainland_Shanghai = 'shanghai',
  salesChinaMainland_Shanxi = 'shanxi',
  salesChinaMainland_Sichuan = 'sichuan',
  salesChinaMainland_Tianjin = 'tianjin',
  salesChinaMainland_Tibet = 'tibet',
  salesChinaMainland_Xinjiang = 'xinjiang',
  salesChinaMainland_Yunnan = 'yunnan',
  salesChinaMainland_Zhejiang = 'zhejiang',
}

export type ExistingProspect = Omit<ProspectDetail, 'id'> & {
  clientCode: string;
};

// PACS Customization | [PACSPFAP-3059] | Start
export enum ProspectSegmentEnum {
  MM = 'MM',
  EA = 'EA',
  AF = 'AF',
  HN = 'HN',
  UH = 'UH',
}

export enum ProspectupgraderShortfallEnum {
  below_3k = '3000',
  below_5k = '5000',
  below_10k = '10000',
  below_20k = '20000',
  below_30k = '30000',
}

export enum ProspectIsCustomerOpusEnum {
  Yes = 'Y',
  No = 'N',
}
// PACS Customization | [PACSPFAP-3059] | End