import { ProColumns } from 'src/app/common/components/ProTable';
import { Columns, Lead } from 'src/app/modules/Leads/types/types';
import { COLUMN_ITEMS } from 'src/app/modules/Leads/util/column-items.util';

export const getProspectLeadsColumns = (): ProColumns<Lead>[] => [
  // COLUMN_ITEMS[Columns.campaignName](),
  COLUMN_ITEMS[Columns.source](),
  COLUMN_ITEMS[Columns.leadStatus](),
  // COLUMN_ITEMS[Columns.sla](),  // PACS Customization | [PACSPFAP-3083]
  COLUMN_ITEMS[Columns.leadSource](),
  COLUMN_ITEMS[Columns.lastViewedAt](),
  COLUMN_ITEMS[Columns.updatedAt](),
  COLUMN_ITEMS[Columns.remark](),
  COLUMN_ITEMS[Columns.prospectLeadOperation](), // PACS Customization | [PACSPFAP-3691] | Phase-2
];
