import React, { FC, memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './update-servicing-campaign-dialog.style';
import { useUpdateServicingCampaignDialog } from './update-servicing-campaign-dialog.hook';
import { CampaignLeadItem } from 'src/app/modules/Campaign/types/campaign-types';
import PruDialog from 'src/app/common/components/PruDialog';
import { DefaultFeedbackStatus } from 'src/app/modules/Campaign/constants';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  targetCampaignLead?: CampaignLeadItem;
  open: boolean;
  campaignId: string;
  onConfirm: (status: string) => void;
  onCancel: () => void;
};

export const UpdateServicingCampaignDialog: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { open, campaignId, onConfirm, onCancel } = props;

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const { selectedStatus, activityStatusFeedback, onSelectStatus } = useUpdateServicingCampaignDialog({
    campaignId: campaignId,
  });

  const onOk = useCallback(() => {
    onConfirm(selectedStatus ?? '');
  }, [selectedStatus, onConfirm]);

  return (
    <PruDialog open={open} onOk={onOk} onCancel={onCancel}>
      <div>
        {activityStatusFeedback &&
          activityStatusFeedback.map((status) => {
            return (
              <div className={styles.radioContainer}>
                <Radio
                  disabled={status?.key === DefaultFeedbackStatus}
                  onChange={(event) => onSelectStatus(event.target.value)}
                  value={status?.key}
                  checked={selectedStatus === status?.key}
                />
                <span>{status?.value}</span>
              </div>
            );
          })}
      </div>
    </PruDialog>
  );
});
