import React, { FC, memo, useMemo, useState } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { Dialog } from 'src/app/common/components/Dialog/dialog/dialog.component';
import { RejectDialog } from '../reject-dialog/reject-dialog.component';
import { TabValues } from 'src/app/modules/Leads/types/types';
import { reasonsToOptions } from 'src/app/modules/Leads/pages/leads/leads-list/util/helper.util';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  onReject: (val: string[]) => Promise<void>;
  onClose: () => void;
  tab?: TabValues;
  isNew?: boolean; // todo: PM
};

export const Reject: React.FC<ComponentProps> = memo(
  ({ onReject, onClose, tab = TabValues.opportunities, isNew = true }: ComponentProps) => {
    const { t } = useTranslation();

    const [showImportNotice, setShowImportNotice] = useState(true);
    const [showReason, setShowReason] = useState(false);

    const rejectReasonOptions = useMemo(() => {
      return reasonsToOptions(tab === TabValues.opportunities, t);
    }, [t, tab]);

    return (
      <>
        <Dialog
          title={t('campaign.marketing.detail.leads.rejectDialog.title')}
          content={t(isNew ? 'salesNncMessagePassed' : 'salesNncMessageStillFind')}
          confirmBtnText={t('global.text.yes')}
          cancelBtnText={t('global.text.no')}
          open={showImportNotice}
          onConfirm={() => {
            setShowReason(true);
            setShowImportNotice(false);
          }}
          onClose={() => {
            setShowImportNotice(false);
            onClose();
          }}
        />
        <RejectDialog
          title={t('lead.rejectReason')}
          open={showReason}
          options={rejectReasonOptions}
          onConfirm={(val) => {
            onReject(val);
            onClose();
          }}
          onClose={onClose}
        />
      </>
    );
  },
);
