import React, { memo, useMemo } from 'react';

import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { useStyles } from './lead-campaign-name.style';
import { Box, Stack, createSvgIcon } from '@mui/material';
import { Lead } from '../../../types/types';

type ComponentProps = ParamsProps & {
  item: Lead;
};

const ImgPlaceholder = createSvgIcon(
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" fill="#F5F5F5" />
  </svg>,
  'placeholder',
);

export interface ImageNameProps {
  showImage?: boolean;
  imageUrl?: string;
  name?: string;
}

export const ImageName: React.FC<ImageNameProps> = memo(({ showImage = true, imageUrl, name }: ImageNameProps) => {
  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  return (
    <>
      {(showImage && imageUrl) || name ? (
        <Stack spacing={3} direction={'row'} alignItems={'center'}>
          {showImage ? (
            imageUrl ? (
              <Box borderRadius={'6px'} overflow="hidden">
                <img src={imageUrl} height={40} width={40} alt="" />
              </Box>
            ) : (
              <ImgPlaceholder sx={{ width: 40, height: 40, borderRadius: '6px' }} />
            )
          ) : null}
          <div className={styles.content}>{name}</div>
        </Stack>
      ) : null}
    </>
  );
});

export const LeadCampaignNameComponent: React.FC<ComponentProps> = memo(({ item }: ComponentProps) => {
  return <>{item?.campaign ? <ImageName imageUrl={item?.campaign?.image?.url} name={item.campaign?.name} /> : null}</>;
});

export const LeadSource: React.FC<ComponentProps> = memo(({ item }: ComponentProps) => {
  return <ImageName imageUrl={item?.sourceImage?.url} name={item.sourceName} />;
});
