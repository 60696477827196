import { makeStyles } from 'tss-react/mui';

export const HEADER_PC_HEIGHT = 88;

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    height: HEADER_PC_HEIGHT,
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 32,
    boxShadow: '0px 35px 8px -30px rgba(244, 246, 248, 1)',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapMenu: {
    paddingLeft: 32,
    position: 'fixed',
    zIndex: 1000,
    top: 0,
    right: 0,
    left: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logoContaner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  prudentialLogo: {
    width: '181px',
  },
  splintLine: {
    width: '1.35px',
    height: '38px',
    backgroundColor: '#D1D7E3',
    marginLeft: '28.5px',
    marginRight: '33px',
  },
  pruforceLogo: {
    height: '96%',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
