import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import { ActionItems } from './components/action-items/action-items.component';
import { ProspectFilterTable } from '../components/prospect-filter-table/prospect-filter-table.component';
import { useIntl } from 'react-intl';
import { AddProspectDialog } from '../add-prospect/components/add-prospect-dialog/add-prospect-dialog.component';
// import { PurgeProspectReminder } from '../components/purge-prospect-reminder/purge-prospect-reminder.component';
import { useStyles } from './list.style';
import { Button } from 'src/app/common/components/button/button.component';
import { PROSPECT_BASE_PATH, PROSPECT_DETAIL_PATH } from '../../constants';
import { Prospect } from '../../types/prospect-types';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { RouteComponentProps } from 'react-router-dom';

type ListPageProps = ParamsProps &
  RouteComponentProps & {
    cacheParams: React.MutableRefObject<Record<string, any> | undefined>;
  };

export const ListPage: React.FC<ListPageProps> = (props: ListPageProps) => {
  const intl = useIntl();
  const Translation = useCallback((id: string) => (id ? intl.formatMessage({ id }) : ''), [intl]);

  const [open, setOpen] = React.useState(false);

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <Page className={styles.pageContainer}>
      {/* PACS Customization PACSPFAP-3647 */}
      {/* <PurgeProspectReminder /> */}
      <Box className={styles.m24}>
        <PageTitle
          // PACS Customization: PACSPFAP-3083 | Updated Label
          title={Translation('title.prospect')}
          // PACS Customization: PACSPFAP-3083 | Phase-1 | Start
          // rightNode={
          //   <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          //     {Translation('pruleads.prospect.list.addNew')}
          //   </Button>
          // }
          // PACS Customization: PACSPFAP-3083 | Phase-1 | End
        />
        <ProspectFilterTable
          cacheParams={props.cacheParams}
          ActionNode={ActionItems}
          onClickRow={(record: Prospect) => {
            props.history.push(`${PROSPECT_BASE_PATH}${PROSPECT_DETAIL_PATH}/${record._id}`, {
              prospectBasicInfo: record,
            });
          }}
        />
        <AddProspectDialog
          open={open}
          onCancel={() => setOpen(false)}
          onConfirm={() => {
            setOpen(false);
          }}
        />
      </Box>
    </Page>
  );
};
