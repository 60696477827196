import React, { ReactNode, memo, useMemo } from 'react';
import { Box, Button, Popover, Typography } from '@mui/material';
import { useStyles } from './popover-selector.style';

interface PopoverSelectorProps {
  value?: string;
  options: { label: string; value: string | number }[];
  onChange?: (val: string | number) => void;
  children: ReactNode;
}

export const PopoverSelector: React.FC<PopoverSelectorProps> = memo(
  ({ children, options, onChange }: PopoverSelectorProps) => {
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'Popover-Selector' : undefined;

    return (
      <div className={styles.container}>
        <Button aria-describedby={id} variant="text" onClick={handleClick}>
          {children}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box className={styles.optionContainer}>
            {options.map(({ label, value }) => (
              <Box
                className={styles.option}
                key={value}
                onClick={() => {
                  onChange?.(value);
                  handleClose();
                }}
              >
                <Typography className={styles.text}>{label}</Typography>
              </Box>
            ))}
          </Box>
        </Popover>
      </div>
    );
  },
);
