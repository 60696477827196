import React, { FC, memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useStyles } from './new-campaign-dialog.style';
import PruDialog from 'src/app/common/components/PruDialog';
import { CampaignTypeItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';

type ComponentProps = {
  open: boolean;
  options: CampaignTypeItem[];
  onConfirm?: (val?: string) => void;
  onClose?: () => void;
};

export const NewCampaignDialog: React.FC<ComponentProps> = memo(
  ({ open, options, onConfirm, onClose }: ComponentProps) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    // custom-hook
    const [selected, setSelected] = useState<string>();

    return (
      <PruDialog
        open={open}
        dialogTitle={Translation('agencyCampaign.common.newCampaign')}
        confirmBtnText={Translation('app.button.next')}
        canncelBtnText={Translation('app.button.cancel')}
        onOk={() => {
          onConfirm?.(selected);
        }}
        onCancel={() => {
          onClose?.();
        }}
      >
        <Box className={styles.container}>
          <Box className={styles.optionsContainer}>
            <RadioGroup style={{ width: '100%' }}>
              {options &&
                options.map((campaignTypeItem) => {
                  if (campaignTypeItem.status === 'active') {
                    return (
                      <div className={classes.radioItem} key={campaignTypeItem._id}>
                        <FormControlLabel
                          key={campaignTypeItem._id}
                          value={campaignTypeItem._id}
                          control={
                            <Radio
                              onChange={() => {
                                setSelected(campaignTypeItem._id);
                              }}
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                            />
                          }
                          label={<Typography className={classes.formControlLabel}>{campaignTypeItem.name}</Typography>}
                          style={{ margin: 0 }}
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
            </RadioGroup>
          </Box>
        </Box>
      </PruDialog>
    );
  },
);
