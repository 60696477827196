import { LOCAL_STORAGE_KEYS } from '../constants';
import { Agent } from '../types/common-types';

export enum StoreKeys {
  AGENT_INFO_KEY = 'AGENT_INFO_KEY',
}

export type GlobalStoreType = {
  [StoreKeys.AGENT_INFO_KEY]?: Agent;
};

export class GlobalStore {
  private AGENT_INFO_KEY = StoreKeys.AGENT_INFO_KEY;
  private STORE: GlobalStoreType = {
    [this.AGENT_INFO_KEY]: undefined,
  };

  private setAgentAndSave(agent: Agent, save: boolean): void {
    this.STORE[this.AGENT_INFO_KEY] = agent;
    if (save) {
      try {
        // assume agent cannot be undefined
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.agentInfo, JSON.stringify(agent ?? null));
      } catch (err) {
        console.warn('save global error: ', err);
      }
    }
  }

  public setAgent(agent: Agent): void {
    this.setAgentAndSave(agent, true);
  }

  public clearAgent() {
    this.STORE[this.AGENT_INFO_KEY] = undefined;
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.agentInfo);
  }

  public getAgent(): Agent | undefined {
    return this.STORE[this.AGENT_INFO_KEY];
  }

  public getAgentCode(): string {
    return this.getAgent()?.agentCode ?? '';
  }

  private init(): void {
    const agent = this.getAgent();
    if (agent) return;
    try {
      const agentStr = window.localStorage.getItem(LOCAL_STORAGE_KEYS.agentInfo) ?? undefined;
      const agent = agentStr ? JSON.parse(agentStr) : agentStr;
      this.setAgentAndSave(agent, false);
    } catch (err) {
      console.warn('init global error: ', err);
    }
  }

  constructor() {
    this.init();
  }
}

export const globalStore = new GlobalStore();
