import { AppConfigData } from '../network/crud/configsCurd';

export const getShortForm = (str?: string): string => {
  if (!str) return '';
  let output = str.replace(/\s/g, '_');
  if (output.length > 4) {
    output = `${output.substring(0, 2)}${output.substring(output.length - 2, output.length)}`;
  }
  return `_${output.toUpperCase()}`;
};

export const getScreenNameShortForm = (str?: string): string => {
  if (!str) return '';
  return `_${str.replace(/\s/g, '_').toUpperCase()}`;
};

export const getUserLBU = (appConfig: AppConfigData[] | null) => {
  if (!appConfig) return undefined;
  const globalConfig = appConfig.find((val) => val.name === 'global-system');
  return globalConfig?.content?.region?.lbuCode;
};

/*
  / -> /auth/login or /home

  /callback
  /:redirect/callback

  /error -> /error/not-found
  /error/not-found
  /error/forbidden

  /application -> /application/download
  /application/download
  /pruforce -> /application

  /auth -> /auth/login
  /auth/login
  /auth/login/callback
  /logout

  //---modules---
  /home

  /agencyCampaign -> /agencyCampaign/campaign
  /agencyCampaign/campaign
  /agencyCampaign/campaign/create/:typeId
  /agencyCampaign/campaign/detail/:typeId
  /agencyCampaign/dashboard

  /agent-profile -> X
  /agent-profile/create
  /agent-profile/edit

  /agent-profile/aboutMe
  /agent-profile/getInTouchWithMe
  /agent-profile/award
  /agent-profile/award-view-more
  /agent-profile/promotion
  /agent-profile/submit-success
  /agent-profile/create-success

  /insurance-summaries -> X
  /insurance-summaries/default/preview/:language
  /insurance-summaries/results/:resultId/:language

  /summary -> X
  /summary/:resultId

  /survey -> X
  /survey/:surveyId
  /survey/customer/:surveyId
  /survey/customer/:surveyId/result/:surveyResultId
*/
