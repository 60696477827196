import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getColumns } from './util/participant-list.util';
import { RequestData } from 'src/app/common/components/ProTable';
import { AgentItem, AgentListParam } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { fetchParticipantList } from 'src/app/modules/AgencyCampaign/network/campaignCurd';

interface HookProps {
  startDate?: string;
  campaignOwner: string;
  disabledEdit?: boolean;
  disabled?: boolean;
  setRemoveOption: (val: any) => void;
  campaignObjId?: string;
  onSelectedRowChange: (rows: AgentItem[]) => void;
  isRequiredRsvp: boolean;
}

export const useParticipantList = ({
  startDate,
  campaignOwner,
  disabledEdit,
  disabled,
  setRemoveOption,
  campaignObjId,
  onSelectedRowChange,
  isRequiredRsvp,
}: HookProps) => {
  const intl = useIntl();
  const Translation = useCallback((id: string) => (id ? intl.formatMessage({ id }) : ''), [intl]);

  const dispatch = useDispatch();

  const columns = useMemo(() => {
    const columnsSetting = getColumns({
      t: Translation,
      startDate,
      campaignOwner,
      disabledEdit,
      disabled,
      setRemoveOption,
    });
    return isRequiredRsvp ? columnsSetting : columnsSetting.filter((item) => item.dataIndex !== 'rsvp');
  }, [Translation, campaignOwner, disabled, disabledEdit, isRequiredRsvp, setRemoveOption, startDate]);

  const getData = useCallback(
    async (
      params: { page: number; pageSize: number },
      sort?: { [key: string]: any },
      filter?: { [key: string]: string },
    ): Promise<RequestData<AgentItem>> => {
      // todo: check sort
      const { page, pageSize } = params;

      const res = await fetchParticipantList(
        { page: page + 1, limit: pageSize, id: campaignObjId } as AgentListParam,
        Object.entries(sort ?? {}).map(([key, value]) => ({ key, value })),
        dispatch,
      );

      if (res) {
        const { docs: data, totalDocs: total } = res;

        return {
          success: true,
          data: data ?? [],
          total,
        };
      }

      return {
        success: false,
        data: [],
        total: 0,
      };
    },
    [campaignObjId, dispatch],
  );

  const rowSelection = useMemo(
    () => ({
      onChange: onSelectedRowChange,
      getCheckboxProps: (row: any) => {
        return {
          disabled: row.agentCode === campaignOwner,
        };
      },
    }),
    [onSelectedRowChange, campaignOwner],
  );

  return {
    columns,
    getData,
    rowSelection,
  };
};
