export enum TopDashboardsUat {
  dashboardLeft = "62da4cf1-f1eb-49bc-8648-5681185edbde",
}

export const DashboardLibraryUat = {
  report: [
    {
      chartID: '62da4cf1-f1eb-49bc-8648-5681185edbde',
      chartType: 'Table',
    },
    {
      chartID: 'b881d5f4-576e-417a-90aa-546510d2e13d',
      chartType: 'Chart',
    },
    {
      chartID: '26ca0391-c648-468d-8018-b0dd0713fac9',
      chartType: 'Chart',
    },
    {
      chartID: '445ec441-3f1e-4ca6-bf80-d5f53973fc17',
      chartType: 'Chart',
    },
    {
      chartID: '62bd4752-a69a-468b-84b7-2cdf1739fddc',
      chartType: 'Chart',
    },
    {
      chartID: '421443a6-27ec-4830-98ef-cd085e0ecb77',
      chartType: 'Chart',
    },
    {
      chartID: '62c2acdf-f6cc-458d-898e-6bde7271910b',
      chartType: 'Chart',
    },
    {
      chartID: '78bb63b1-67de-44f6-8b74-20ac0f3f95a0',
      chartType: 'Chart',
    },
    {
      chartID: '2806e527-4157-4973-99a6-f5936f60551e',
      chartType: 'Chart',
    },
  ],
};

export enum AlTopDashboardsUat {
  dashboardLeft = "6323fd62-6ea1-48eb-88d5-cfa6c48f8a2f",
}

export const AlChartLibraryUat = {
  report: [
    {
      // al table
      chartID: '6323fd62-6ea1-48eb-88d5-cfa6c48f8a2f',
      chartType: 'Table',
    },
    {
      //Overall risk
      chartID: '63283e50-adfc-4b08-80f1-600961c55af3',
      chartType: 'Chart',
    },
    {
      // BSC Grade
      chartID: 'fe9fb764-39c4-444e-9c05-bedfe187bd0c',
      chartType: 'Chart',
    },
    {
      // average pre paying rate
      chartID: '63284500-ebcf-42f4-8a15-864e79c46861',
      chartType: 'Chart',
    },
    {
      //single premium presistency
      chartID: '433b68ac-5a32-4c1b-b8ca-579f00b58c4f',
      chartType: 'Chart',
    },
    {
      // Net ROP unsuccessful appeals(Comulative YTD)
      chartID: '78c3289d-cd32-48bf-b86a-57e4e6ee800d',
      chartType: 'Chart',
    },
    {
      // CFI own life
      chartID: '848d6c10-0e73-4d1f-9eab-7a3f77ff2106',
      chartType: 'Chart',
    },
    {
      // Forfeiture
      chartID: 'afbe85cd-42a4-42fc-959f-65c6d815aef3',
      chartType: 'Chart',
    },
    {
      //Market conduct (Comulative YTD)
      chartID: '6681b42d-e05c-4391-ad2c-28f7ec78eb97',
      chartType: 'Chart',
    },
    {
      //Market conduct (Comulative YTD)
      chartID: '3943604a-dc36-4265-a55c-ab0f98206e46',
      chartType: 'Chart',
    },
  ],
};