import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  backButtonContainer: {
    backgroundColor: 'white',
    height: 52,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 600,
  },
  leftBarContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  level1: {
    borderBottom: '1px solid #F0F0F0',
  },
  level1Title: {
    paddingTop: 15,
    paddingBottom: 15,
    fontWeight: 'bold',
    flex: 1,
  },
  level2: {
    // borderTop: '1px solid #F0F0F0',
    marginTop: 15,
    marginLeft: 15,
    height: 70,
    display: 'flex',
    flexDirection: 'column',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
  },
  level2Title: {
    padding: 15,
    fontWeight: 'bold',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  level2Container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  footContainer: {
    display: 'flex',
  },
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    marginBottom: 25,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  basicInfoContainer: {
    padding: 16,
    backgroundColor: theme.palette.common.white,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  bottomSpacing: {
    marginBottom: 5,
  },
  largerBottomSpacing: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  sectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  fieldContainer: {
    width: '50%',
    boxSizing: 'border-box',
    marginBottom: 16,
  },
  participantRowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  participantCellContainer: {
    paddingLeft: 0,
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  olderRecord: {
    color: '#C1C1C1',
  },
  iconButton: {
    padding: 8,
    marginTop: -8,
  },
  connector: {
    color: '#F0F0F0',
  },
  time: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
  },
  context: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
  },
  comment: {
    fontWeight: 400,
  },
  approvalCard: {
    marginTop: 24,
    paddingTop: 47,
    paddingBottom: 51,
  },
  remarkCard: {
    marginTop: 29,
    padding: 24,
  },
  remarkCardContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  remarkCardContentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    background: '#FFFFFF',
  },
  remarkCardFilesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  flex_1: {
    flex: 1,
  },
  dot: {
    boxShadow: 'none',
  },
}));
