import React, { useState, Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { PruTableRow } from '../Table/PruTableRow';
import { Checkbox, IconButton, Radio } from '@mui/material';
import { CheckType, ProColumns, ProOperationDef, RowSelection } from '.';
import { TableCell } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import StickyTableCell from './StickyTableCell';

interface ProTableRowItemProps<T> {
  columns: ProColumns<T>[];
  operationDef?: ProOperationDef<T>[];
  operationSticky?: boolean;
  rowSelection?: RowSelection<T>;
  row: T;
  rowIndex: number;
  selectedRow: T[];
  handleSelectSingleRow: (event: any, row: T) => void;
  open: boolean;
  setOpen: (params: boolean) => void;
  level?: number;
  rowKey: string;
  className?: string;
  checkType?: CheckType;
  rowClickable?: boolean;
  actions: (actions: ('clearRow' | 'refresh')[]) => void;
}

interface ProTableRowProps<T> {
  columns: ProColumns<T>[];
  operationDef?: ProOperationDef<T>[];
  operationSticky?: boolean;
  rowSelection?: RowSelection<T>;
  row: T;
  rowIndex: number;
  selectedRow: T[];
  handleSelectSingleRow: (event: any, row: T) => void;
  level?: number;
  rowKey: string;
  className?: string;
  checkType?: CheckType;
  rowClickable?: boolean;
  actions: (actions: ('clearRow' | 'refresh')[]) => void;
}

export const CHECK_CELL_WIDTH = 64;

const useProTableRowItemStyles = makeStyles()(() => ({
  operationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  operationBtn: {
    color: '#4386E5',
    cursor: 'pointer',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  disabled: {
    color: '#CCCCCC',
    cursor: 'default',
  },
}));

const ProTableRowItem = <T extends { [key: string]: any } = { [key: string]: any }>({
  columns,
  operationDef,
  operationSticky,
  rowSelection,
  row,
  selectedRow,
  rowIndex,
  handleSelectSingleRow,
  open,
  setOpen,
  level = 0,
  rowKey,
  className,
  checkType,
  rowClickable,
  actions,
}: ProTableRowItemProps<T>) => {
  const { classes, cx } = useProTableRowItemStyles();
  const { getCheckboxProps } = rowSelection || { getCheckboxProps: () => ({ disabled: false }) };

  const labelId = `enhanced-table-checkbox-${rowIndex}`;

  const renderTableCellItem = <T extends { [key: string]: any } = { [key: string]: any }>(
    row: T,
    col: ProColumns<T>,
    rowIndex: number,
    colIndex: number,
    level: number,
  ) => {
    const { render, dataIndex, valueType } = col;
    let result: React.ReactNode;
    if (valueType === 'index') {
      result = rowIndex + 1;
    } else if (render) {
      result = render(dataIndex && row[dataIndex], row, actions);
    } else {
      result = dataIndex ? row[dataIndex] : '';
    }
    if (colIndex === 0 && row.children) {
      result = (
        <span style={{ marginLeft: 18 * (level - 1) }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {!open ? <AddBoxOutlinedIcon /> : <IndeterminateCheckBoxOutlinedIcon />}
          </IconButton>
          {result}
        </span>
      );
    } else if (colIndex === 0) {
      result = <span style={{ marginLeft: 18 * level }}>{result}</span>;
    }
    return result;
  };

  const renderOperationRow = (row: any, rowIndex: number) => {
    return (
      <div className={classes.operationContainer}>
        {operationDef?.map((operation, index) => (
          <Fragment key={`operation-${index}`}>
            {(!operation.hidden || !operation.hidden(row)) && (
              <div
                style={{ marginRight: operationDef[index + 1] ? 10 : 0 }}
                className={cx(
                  classes.operationBtn,
                  operation.disabled && operation.disabled(row) ? classes.disabled : undefined,
                )}
                onClick={() => {
                  if (!operation.disabled || !operation.disabled(row)) {
                    operation.onClick(row, rowIndex);
                  }
                }}
              >
                {operation.title}
              </div>
            )}
          </Fragment>
        ))}
      </div>
    );
  };

  return (
    <PruTableRow
      className={className}
      onClick={(event) => handleSelectSingleRow(event, row)}
      selected={selectedRow.findIndex((r) => r[rowKey] === row[rowKey]) > -1}
      style={rowClickable ? { cursor: 'pointer' } : undefined}
    >
      {rowSelection ? (
        <TableCell align="right" width={`${CHECK_CELL_WIDTH}px`}>
          {CheckType.radio === checkType ? (
            <Radio
              disabled={getCheckboxProps(row, selectedRow).disabled}
              checked={selectedRow.findIndex((r) => r[rowKey] === row[rowKey]) > -1}
              inputProps={{ 'aria-labelledby': labelId }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 24 }, padding: 0 }}
            />
          ) : (
            <Checkbox
              disabled={getCheckboxProps(row, selectedRow).disabled}
              checked={selectedRow.findIndex((r) => r[rowKey] === row[rowKey]) > -1}
              inputProps={{ 'aria-labelledby': labelId }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 24 }, padding: 0 }}
            />
          )}
        </TableCell>
      ) : null}
      {columns.map((col, colIndex) => {
        const width = colIndex === columns.length - 1 ? undefined : col.width;
        return col.sticky ? (
          <StickyTableCell
            component="td"
            scope="row"
            key={`cell-level${level}-${col.dataIndex}-${rowIndex}-${colIndex}`}
            align={col.align}
            // width={col.width ?? '300px'}
            className={`sticky-cell`}
            sx={{
              minWidth: col.width,
              width,
              maxWidth: width,
              overflowWrap: 'break-word',
            }}
            onClick={(e) => e.stopPropagation()} // so that sticky column cannot trigger onRowClick
          >
            {renderTableCellItem(row, col, rowIndex, colIndex, level)}
          </StickyTableCell>
        ) : (
          <TableCell
            component="td"
            scope="row"
            key={`cell-level${level}-${col.dataIndex}-${rowIndex}-${colIndex}`}
            align={col.align}
            sx={{
              minWidth: col.width,
              width,
              maxWidth: width,
              overflowWrap: 'break-word',
            }}
          >
            {renderTableCellItem(row, col, rowIndex, colIndex, level)}
          </TableCell>
        );
      })}
      {operationDef && operationDef.length > 0 && (
        <>
          {operationSticky ? (
            <StickyTableCell
              key={`cell-level${level}-operation-${rowIndex}`}
              component="td"
              scope="row"
              align="left"
              className={`sticky-cell`}
              sx={{ minWidth: 176, cursor: 'default' }}
              onClick={(e) => e.stopPropagation()} // so that sticky column cannot trigger onRowClick
            >
              {renderOperationRow(row, rowIndex)}
            </StickyTableCell>
          ) : (
            <TableCell
              key={`cell-level${level}-operation-${rowIndex}`}
              component="td"
              scope="row"
              align="left"
              sx={{ minWidth: 176, cursor: 'default' }}
            >
              {renderOperationRow(row, rowIndex)}
            </TableCell>
          )}
        </>
      )}
    </PruTableRow>
  );
};

const ProTableRow = <T extends { [key: string]: any } = { [key: string]: any }>({
  columns,
  operationDef,
  operationSticky,
  rowSelection,
  row,
  selectedRow,
  rowIndex,
  handleSelectSingleRow,
  level = 0,
  rowKey,
  className,
  checkType,
  rowClickable,
  actions,
}: ProTableRowProps<T>) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <ProTableRowItem
        rowKey={rowKey}
        level={level}
        columns={columns}
        operationDef={operationDef}
        operationSticky={operationSticky}
        rowSelection={rowSelection}
        row={row}
        selectedRow={selectedRow}
        rowIndex={rowIndex}
        handleSelectSingleRow={handleSelectSingleRow}
        open={open}
        setOpen={setOpen}
        className={className}
        checkType={checkType}
        rowClickable={rowClickable}
        actions={actions}
      />
      {open
        ? ((row && row.children) || []).map((rowChild: T, i: number) => (
            <ProTableRow
              rowKey={rowKey}
              level={level + 1}
              key={`childrow-level${level}-${i}`}
              columns={columns}
              rowSelection={rowSelection}
              row={rowChild}
              selectedRow={selectedRow}
              rowIndex={rowIndex}
              handleSelectSingleRow={handleSelectSingleRow}
              className={className}
              checkType={checkType}
              rowClickable={rowClickable}
              actions={actions}
            />
          ))
        : null}
    </>
  );
};

export default ProTableRow;
