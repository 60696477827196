import React, { FC, memo, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './delete-lead-dialog.style';
import PruDialog from 'src/app/common/components/PruDialog';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LeadDetail, LeadDetailForm } from 'src/app/modules/Leads/types/lead-detail-types';
import { deleteAgentCreateLead } from 'src/app/modules/Leads/network/leadsCrud';
import { LEADS_LIST_PATH } from 'src/app/modules/Leads/constants';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  open: boolean;
  onClose: () => void;
  leadDetail: LeadDetailForm;
};

export const DeleteLeadDialog: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { open, leadDetail, onClose } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const history = useHistory();

  const [shouDialog, setShowDialog] = React.useState(open);
  const [deleting, setDeleting] = React.useState(false);

  useEffect(() => {
    setShowDialog(open);
  }, [open]);

  const onCancel = () => {
    setShowDialog(false);
    onClose();
  };

  const onOk = () => {
    if (deleting) return;

    //delete
    setDeleting(true);
    deleteAgentCreateLead(leadDetail.id, dispatch)
      .then((res) => {
        onCancel();
        history.push(LEADS_LIST_PATH);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  return (
    <PruDialog
      open={shouDialog}
      onOk={onOk}
      onCancel={onCancel}
      dialogTitle={Translation('app.button.delete')}
      canncelBtnText={Translation('app.button.cancel').toUpperCase()}
      // hideCancelBtn={Boolean(leadDetail.activeLeads)}
      confirmBtnText={
        deleting ? <CircularProgress size={18} color="inherit" /> : Translation('app.button.confirm').toUpperCase()
      }
    >
      <span>{Translation('lead.info.deleteDesc')}</span>
    </PruDialog>
  );
});
