import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import { useStyles } from './auto-purge-list.style';
import { getColumns } from './util/auto-purge-list.util';
import { getProspectCategories, getProspects } from './util/api.util';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

export const useAutoPurgeList = () => {
  const { t: Translation } = useTranslation();

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const locale = useLang();
  const dispatch = useDispatch();

  const { data: prospectCategories } = useRequest(
    async () => {
      const res = await getProspectCategories(locale, dispatch);
      return res;
    },
    { manual: false },
  );

  const columns = useMemo(
    () => getColumns({ t: Translation, prospectCategories, styles }),
    [Translation, prospectCategories, styles],
  );

  const getData = useCallback(
    async (
      params: { page: number; pageSize: number },
      sort?: { [key: string]: any },
      filter?: { [key: string]: string },
    ) => {
      return await getProspects(dispatch, params, sort, filter);
    },
    [dispatch],
  );

  return {
    prospectCategories,
    columns,
    getData,
  };
};
