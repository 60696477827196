import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftContainer: { flexGrow: 1 },
  titleText: {
    fontSize: '34px',
    fontWeight: 700,
  },
  pageContainer: {
    marginLeft: '24px',
    marginRight: '24px',
  },
}));
