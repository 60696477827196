import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './simple-date-range-picker.style';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export type DateRangePickerType = {
  startDate: string;
  endDate: string;
};

type ComponentProps = ParamsProps & {
  value?: DateRangePickerType;
  onChange?: (value: DateRangePickerType) => void;
  maxDate?: string;
};

export const SimpleDateRangePickerComponent: React.FC<ComponentProps> = memo(
  ({ value, onChange, maxDate }: ComponentProps) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    const maxDateLimit = useMemo(() => {
      return dayjs(maxDate ?? undefined);
    }, [maxDate]);

    const startMaxDate = useMemo(() => {
      const endDate = value?.endDate;
      if (!endDate) return maxDateLimit;
      return dayjs(endDate).isBefore(maxDateLimit) ? dayjs(endDate) : maxDateLimit;
    }, [maxDateLimit, value?.endDate]);

    const endMinDate = useMemo(() => {
      const startDate = value?.startDate;
      if (!startDate) return null;
      return dayjs(startDate);
    }, [value?.startDate]);

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={styles.container}>
          <DatePicker
            label={Translation('agencyCampaign.common.startDate')}
            value={value?.startDate !== '' ? dayjs(value?.startDate) : null}
            format="DD/MM/YYYY"
            maxDate={startMaxDate}
            onChange={(val) => {
              onChange?.({
                startDate: val ? dayjs(val).toISOString() : '',
                endDate: value?.endDate || '',
              });
            }}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  onChange?.({
                    startDate: '',
                    endDate: value?.endDate || '',
                  });
                },
              },
              textField: { variant: 'outlined', color: 'secondary', size: 'medium' },
            }}
          />
          <div className={styles.toBox}>
            <span className={styles.toFont}>{Translation('to')}</span>
          </div>
          <DatePicker
            label={Translation('agencyCampaign.common.endDate')}
            value={value?.endDate !== '' ? dayjs(value?.endDate) : null}
            minDate={endMinDate}
            maxDate={maxDateLimit}
            onChange={(val) => {
              onChange?.({
                startDate: value?.startDate || '',
                endDate: val ? dayjs(val).endOf('day').toISOString() : '',
              });
            }}
            format="DD/MM/YYYY"
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  onChange?.({
                    startDate: '',
                    endDate: value?.endDate || '',
                  });
                },
              },
              textField: { variant: 'outlined', color: 'secondary', size: 'medium' },
            }}
          />
        </div>
      </LocalizationProvider>
    );
  },
);
