import { useDispatch } from 'react-redux';
import { useRequest } from 'ahooks';
import { fetchCampaignDetail } from '../../../network/campaignCurd';
import { CampaignDetail } from '../../../types/campaign-types';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { massCampaignDetailsGaDataClick, massCampaignDetailsGaDataClick1 } from 'src/app/common/ga/types/ga-click-lead';

interface HookProps {
  id: string;
  // other params
}

export const useDetailPage = ({ id }: HookProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, loading } = useRequest(() => fetchCampaignDetail(id, dispatch), {
    manual: false,
  });

  const convertCampaignInfo = (data: any): CampaignDetail => {
    const newCampaignData: CampaignDetail = { ...data } as CampaignDetail;
    return newCampaignData;
  };

  const navToList = () => {
    history.goBack();
  };
  const oppProviderValue = useMemo(
    () => ({
      gaData: {
        accept: massCampaignDetailsGaDataClick,
        reject: massCampaignDetailsGaDataClick1,
      },
    }),
    [],
  );
  return {
    loading,
    data: convertCampaignInfo(data),
    navToList,
    oppProviderValue,
  };
};
