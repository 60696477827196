import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    borderRadius: 5,
    margin: theme.spacing(1),
  },
  error: {
    backgroundColor: 'red',
  },
  actionButton: {
    minWidth: '0px',
    padding: 0,
    color: '#4386E5',
    fontWeight: '700',
  },
  buttonMargin: {
    marginLeft: '48px',
  },
  content: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
}));
