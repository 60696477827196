import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Stack, ToggleButton } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './category-selector.style';
import { useCategorySelector } from './category-selector.hook';

type ComponentProps = ParamsProps & {
  campaignId: string;
  agentCodes: string[];
  onChange: (selectKey: string) => void;
  value: string;
};

export const CategorySelectorComponent: React.FC<ComponentProps> = memo(
  ({ campaignId, agentCodes, value, onChange }: ComponentProps) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    const stageTitle: { [key: string]: string } = {
      total: Translation('campaign.lead.followUp.total'),
      pendingAccept: Translation('campaign.lead.followUp.pending'),
      pendingContact: Translation('campaign.lead.followUp.pendingToContact'),
      contactSuccess: Translation('campaign.lead.followUp.contactSuccess'), // PACS Customization: PACSPFAP-2662
      met: Translation('campaign.lead.followUp.met'), // PACS Customization: PACSPFAP-2662 | PACSPFAP-3081 | Updated Label
      proposal: Translation('campaign.lead.followUp.proposal'), // PACS Customization: PACSPFAP-2662 | PACSPFAP-3081 | Updated Label
      quote: Translation('campaign.lead.followUp.quote'),
      policy: Translation('campaign.lead.followUp.policy'),
      // PACS Customization: PACSPFAP-3081 | Updated Label
      dead: Translation('campaign.lead.followUp.dead'),
    };

    // custom-hook
    const { list, overviewConfig } = useCategorySelector({ campaignId, agentCodes });
    return (
      <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
        {overviewConfig.map((item) => {
          return (
            <ToggleButton
              key={item}
              value={item}
              selected={value === item}
              color="secondary"
              classes={{ root: styles.buttonContainer }}
              onClick={(e, val) => onChange(val)}
            >
              <div className={styles.buttonLayout}>
                <span className={styles.categoryTitle}>{stageTitle[item]}</span>
                <span className={styles.categoryCount}>{list ? list[`${item}`] ?? 0 : '-'}</span>
              </div>
            </ToggleButton>
          );
        })}
      </Stack>
    );
  },
);
