import { RequestData } from 'src/app/common/components/ProTable';
import { fetchProspectCategories, fetchProspects } from 'src/app/modules/Prospect/network/prospectCurd';
import { Prospect } from 'src/app/modules/Prospect/types/prospect-types';

export const getProspectCategories = async (locale: string, dispatch: React.Dispatch<any>) => {
  const res = await fetchProspectCategories(locale, dispatch);
  return res.prospectCategories.sort((a, b) => (a.type > b.type ? 1 : -1));
};

export const getProspects = async (
  dispatch: React.Dispatch<any>,
  params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
  sort?: { [key: string]: any },
  filter?: { [key: string]: any },
): Promise<RequestData<Prospect>> => {
  const { page, pageSize, keyword: searchkey } = params;
  const { types: filterTypes, sources: filterSourceTypes, categories: filterCategoryIds, dateRange, segments, isCustomerOpus, upgraderShortfall } = filter ?? {}; // PACS Customization | [PACSPFAP-3059]

  let sortBy = '-nameWeight -firstName';
  let flag = '';

  if (sort && Object.keys(sort).length > 0) {
    const [key, val] = Object.entries(sort)[0];

    if (val === 'desc') {
      flag = '-';
    }

    if (key === 'displayName') {
      if (flag) {
        sortBy = '-nameWeight -firstName';
      } else {
        sortBy = 'nameWeight firstName';
      }
    } else {
      sortBy = `${flag}${key}`;
    }
  }

  const res = await fetchProspects(
    {
      sortBy,
      page,
      pageSize,
      searchkey,
      filterTypes,
      filterSourceTypes,
      filterCategoryIds,
      queryDateFrom: dateRange?.value?.startDate,
      queryDateTo: dateRange?.value?.endDate,
      // PACS Customization | [PACSPFAP-3059] | Start
      segments,
      isCustomerOpus,
      upgraderShortfall,
      // PACS Customization | [PACSPFAP-3059] | End
    },
    dispatch,
  );

  if (res) {
    const { prospects, totalDocs, ...rest } = res;

    return {
      success: true,
      data: prospects ?? [],
      total: totalDocs,
      ...rest,
    };
  }

  return {
    success: false,
    data: [],
    total: 0,
  };
};
