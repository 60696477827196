export const SURVEY_TITLE = 'title.survey';

export const SURVEY_BASE_PATH = '/survey';

export const SURVEY_CUSTOMER_PATH = '/customer';

export enum SurveyPermissionType {
  TEMP_CREATE = 'CREATE_AGENT-PORTAL_SURVEY-RESULT',
  SECRETARY_READ_OWN_SURVEY = 'READ_OWN_SURVEY',
}
