import React, { memo, useMemo } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useMassCampaignLeadList } from './mass-campaign-lead-list.hook';
import ProFilterTable, { ActionNodeType, ActionNodeTypeProps } from 'src/app/common/components/ProTable';
import { ActionItems } from './action-items/action-items.component';
import { useHistory } from 'react-router-dom';
import { LEADS_DETAIL_PATH } from 'src/app/modules/Leads/constants';
import { OperationContext } from 'src/app/modules/Leads/components/lead-list-item/lead-operation-units/util/operation-context.util';
import { massCampaignDetailsGaDataClick } from 'src/app/common/ga/types/ga-click';

type ComponentProps = ParamsProps & {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
};

export const MassCampaignLeadListComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { columns, getData, rowSelection, params, enableMassAcceptLeads } = useMassCampaignLeadList({
    cacheParams: props.cacheParams,
  });
  const history = useHistory();
  const toLead = (i: any) => {
    history.push(`${LEADS_DETAIL_PATH}/${i._id}`);
  };

  const ActionNode: ActionNodeType<any> = useMemo(() => {
    return (props: ActionNodeTypeProps<any>) => <ActionItems {...props} />;
  }, []);

  const oppProviderValue = useMemo(() => {
    return {
      gaData: {
        accept: massCampaignDetailsGaDataClick,
      },
    };
  }, []);

  return (
    <OperationContext.Provider value={oppProviderValue}>
      <ProFilterTable
        filterConfigs={[]}
        filterState={params}
        showFilter={false}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        request={getData}
        enableRefresh={false}
        ActionNode={ActionNode}
        showSelect={enableMassAcceptLeads} //PACS Customization: PACSPFAP-3123 | [phase 1] - should be false untill next phase
        onClickRow={toLead}
      />
    </OperationContext.Provider>
  );
});
