import { ProColumns } from 'src/app/common/components/ProTable';
import { renderDateTime } from 'src/app/modules/AgencyCampaign/pages/Campaign/campaign-list/util/list.util';
import { renderOperation } from '../list.screen';
import { DateFormat, formatDate } from 'src/app/common/utils';

export const getColumns = ({
  t,
  history,
}: {
  t: (key: string, values?: Record<string, any>) => string;
  history: any;
}): ProColumns<any>[] => [
  {
    title: t('talkbot.export_history.export_date'),
    sorter: true,
    dataIndex: 'date',
    width: '176px',
    render: (curValue: string) => formatDate(curValue, DateFormat.date),
  },
  {
    title: t('talkbot.export_history.records_reported'),
    dataIndex: 'total',
    width: '176px',
  },
  {
    title: t('section.common.operation'),
    dataIndex: 'operation',
    width: '176px',
    render: (curVal, item) => renderOperation({ item, t, history }),
  },
];
