import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { takeUIScreenViewEvent } from 'src/app/common/ga/ga';
import { GAData } from 'src/app/common/ga/redux/gaSlice';
import { postAcceptLeads } from 'src/app/modules/Leads/network/leadsCrud';

interface HookProps {
  isAcceptLeads?: boolean;
}

export const doubleConfirmTurnOffAcceptLeadGaDataRoute: GAData = {
  module: 'PRULeads',
  feature: 'Lead',
  journey: 'switch_accept_lead_option',
  stage: 'start',
  screen_name: 'Double Confirm turn off accept lead',
};

export const useAcceptLeads = ({ isAcceptLeads }: HookProps) => {
  const [open, setOpen] = useState(false);
  const [accept, setAccept] = useState(isAcceptLeads);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setAccept(isAcceptLeads);
  }, [isAcceptLeads]);

  // custom-hook

  const switchAcceptLeads = async (accept: boolean) => {
    if (loading) return;
    try {
      setAccept(accept);
      setLoading(true);
      await postAcceptLeads({ isRefuseToReceiveLeads: !accept }, dispatch);
    } catch (err) {
      setAccept(!accept);
    } finally {
      setLoading(false);
    }
  };

  const onSwitch = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      switchAcceptLeads(checked);
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (open) {
      takeUIScreenViewEvent(doubleConfirmTurnOffAcceptLeadGaDataRoute);
    }
  }, [open]);

  return {
    open,
    accept,
    loading,
    switchAcceptLeads,
    onSwitch,
    setOpen,
  };
};
