import React, { FC, memo, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './update-lead-status-dialog.style';
import PruDialog from 'src/app/common/components/PruDialog';
import { LeadStatusEnum } from 'src/app/modules/Leads/types/types';
import { leadStatusToString } from '../../util/reassign-lead.util';

type ComponentProps = ParamsProps & {
  open: boolean;
  onCancel: () => void;
  onConfirm?: (newStatus: string) => void;
  targetLead: any;
};

export const UpdateLeadStatusDialog: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { open, onConfirm, onCancel, targetLead } = props;
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes, cx } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const [currentStatus, setCurrentStatus] = React.useState('');

  const onOk = () => {
    onConfirm && onConfirm(currentStatus);
  };

  const statusList: LeadStatusEnum[] = useMemo(() => {
    if (!targetLead) return [];
    return targetLead.leadStatus !== LeadStatusEnum.PENDING_FOR_CONTACT
      ? [
          // last one
          targetLead.leadStatus,
          LeadStatusEnum.PENDING_FOR_CONTACT,
        ]
      : [targetLead.leadStatus];
  }, [targetLead]);

  useEffect(() => {
    if (statusList.length > 0) {
      setCurrentStatus(statusList[0]);
    }
  }, [statusList]);

  return (
    <PruDialog
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      dialogTitle={Translation('lead.reassignLead.editLeadStatus')}
      canncelBtnText={Translation('app.button.cancel').toUpperCase()}
      confirmBtnText={Translation('app.button.confirm').toUpperCase()}
    >
      <div className={styles.wrap}>
        <div>
          {statusList.map((status, index) => (
            <Button
              onClick={() => setCurrentStatus(status)}
              variant="outlined"
              className={cx(styles.btn, { [styles.active]: currentStatus === status })}
            >
              {leadStatusToString(status, Translation)}
              {index === 0 ? ` (${Translation('lead.reassignLead.reassignLastStatus')})` : ''}
            </Button>
          ))}
        </div>
      </div>
    </PruDialog>
  );
});
