import React, { useContext, useState } from 'react';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useDispatch } from 'react-redux';
import { Lead } from 'src/app/modules/Leads/types/types';
import { accept } from 'src/app/modules/Leads/pages/leads/leads-list/util/api.util';
import { OperationContext } from '../../util/operation-context.util';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { allGaDataClick } from 'src/app/common/ga/types/ga-click-lead';
import moment from 'moment';

export type AcceptOpportunityButtonHook = {
  actions: (actions: ('clearRow' | 'refresh')[]) => void;
  item: Lead;
};

export const useAcceptOpportunityButton = ({ actions, item }: AcceptOpportunityButtonHook) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { gaData, onAccept, event } = useContext(OperationContext);

  const [toAccept, setToAccept] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);

  const doAcceptGA = () => {
    let isSLA = 'N/A';
    if (item.expiredAt) {
      const currDate = new Date();
      moment(item.expiredAt).isAfter(currDate) ? (isSLA = 'Y') : (isSLA = 'N');
    }
    const gaClickData = {
      ...allGaDataClick,
      extraInfo: {
        campaign: item?.campaign?.name,
        withinsla: isSLA,
      },
    };

    takeUIClickEvent(gaClickData, 'accept_lead');
    onAccept?.([item]);
    takeUIClickEvent(gaData.accept);
  };
  const doAccept = async () => {
    // doAcceptGA();
    setAcceptLoading(true);
    const res = await accept(item._id, dispatch, t);
    // onAccept?.([item]);
    event && event.emit();
    res && actions(['refresh', 'clearRow']);
    setAcceptLoading(false);
  };

  return {
    acceptLoading,
    toAccept,
    setToAccept,
    doAccept,
    doAcceptGA,
  };
};
