import React from 'react';
import { createSvgIcon } from '@mui/material';
// https://www.figma.com/file/HF9RkGTQ6mBZRMh4cboOEd/%F0%9F%8E%A8-Pivot-Guideline-3.0---Designer?type=design&node-id=7829%3A2045&mode=design&t=fLDkjlUFQ8KgtpfQ-1

export const PDFIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="22" height="22" rx="4" fill="#EB3B4C" />
    <path
      d="M11.3327 5.71384C14.8554 4.5397 7.49686 19.9483 5.83272 18.2138C2.85535 15.1105 20.3097 11.6104 18.3553 14.6104C15.8779 18.4133 7.33265 7.04707 11.3327 5.71384Z"
      stroke="white"
      strokeWidth="1.33"
      strokeLinecap="round"
    />
  </svg>,
  'pdfIcon',
);

export const ExcelCsvIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="22" height="22" rx="4" fill="#24C791" />
    <path d="M7.5 6.5L16.5 17.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M16.5 6.5L7.5 17.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
  </svg>,
  'excelCscIcon',
);

export const FileIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="22" height="22" rx="4" fill="#FFB83D" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.33334 7.33325C5.33334 6.78097 5.78106 6.33325 6.33334 6.33325H9.53351C9.85751 6.33325 10.1614 6.49023 10.349 6.75444L11.1011 7.81402C11.2886 8.07824 11.5925 8.23521 11.9165 8.23521H17.6667C18.219 8.23521 18.6667 8.68293 18.6667 9.23521V16.6666C18.6667 17.2189 18.219 17.6666 17.6667 17.6666H6.33334C5.78106 17.6666 5.33334 17.2189 5.33334 16.6666V7.33325Z"
      stroke="white"
      strokeWidth="1.33333"
    />
    <path d="M12.6667 15H15.3333" stroke="white" strokeWidth="1.33333" strokeLinecap="round" />
  </svg>,
  'fileIcon',
);

export const PicIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="22" height="22" rx="3.83333" fill="#4386E5" />
    <rect x="5.33334" y="6" width="13.3333" height="12" rx="1" stroke="white" strokeWidth="1.33333" />
    <circle cx="9" cy="10" r="1" fill="white" />
    <path d="M8.33334 17.6667C8.33334 17.6667 11 10.6667 18 10.6667" stroke="white" strokeWidth="1.33333" />
  </svg>,
  'picIcon',
);
