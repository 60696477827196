import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    borderRadius: 5,
    margin: theme.spacing(1),
  },
  error: {
    backgroundColor: 'red',
  },
  viewContainer: {
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.08)',
    marginTop: 24,
  },
  defaultPadding: {
    padding: 24,
  },
  detailTitleText: {
    fontWeight: 700,
    fontSize: 24,
  },
  dateRangeText: {
    fontWeight: 600,
    fontSize: 16,
  },
  descTitleText: {
    fontWeight: 600,
    fontSize: 16,
  },
  contentText: {
    fontWeight: 400,
    fontSize: 16,
  },
  questionTitleText: {
    fontWeight: 500,
    fontSize: 16,
  },
  marginBottom: {
    marginBottom: 16,
  },
  banner: {
    justifyContent: 'center',
    display: 'flex',
    objectFit: 'cover',
    height: 300,
  },
  dateDivider: {
    backgroundColor: '#E6E6E6',
    height: 1,
    flex: 1,
    marginRight: -24,
    marginLeft: -24,
    marginBottom: 24,
  },
  contentDivider: {
    backgroundColor: '#E6E6E6',
    height: 1,
    flex: 1,
    marginBottom: 24,
  },
  flyText: {
    fontSize: 14,
    fontWeight: 600,
    marginRight: 16,
  },
}));
