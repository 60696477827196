export const KPI_DASHBOARD_BASE_PATH = '/kpidashboard';
export const KPI_DASHBOARD_TITLE = 'title.kpiDashboard';

export const FINANCIAL_AGENT_NAMESPACE = 'KPI_DASHBOARD_FINANCIAL_AGENT';
export const AL_AGENT_NAMESPACE = 'KPI_DASHBOARD_AL_AGENT';
export const GL_AGENT_NAMESPACE = 'KPI_DASHBOARD_GL_AGENT';
export const DOWNLINE_AGENT_NAMESPACE = 'KPI_DASHBOARD_DOWNLINE_AGENT';
export const KPI_DASHBOARD_AGENT_PATH = '/agent';
export const KPI_DASHBOARD_FINANCIAL_AGENT_PATH = '/financial-agent';
export const KPI_DASHBOARD_AGENCY_LEAD_PATH = '/agency-lead';
export const KPI_DASHBOARD_GROUP_LEAD_PATH = '/group-lead';

export const FINANCIAL_AGENT_TITLE = 'title.kpiDashboard.agent.financialAgent';
export const AL_AGENT_TITLE = 'title.kpiDashboard.agent.alAgent';
export const GL_AGENT_TITLE = 'title.kpiDashboard.agent.glAgent';
export const AGENT_BASE_PATH = '/agent';
export const FINANCIAL_AGENT_AGENT_PATH = '/financialAgent';
export const DOWNLINE_AGENT_PATH = '/downlineAgent';
export const AL_AGENT_PATH_FOR_GL = '/alviewforgl';
export const FC_AGENT_PATH_FOR_GL = '/fcviewforgl';
export const AL_AGENT_AGENT_PATH = '/alAgent';
export const GL_AGENT_AGENT_PATH = '/glAgent';
export const AGENCY_CAMPAIGN_NAMESPACE = 'AGENCY_CAMPAIGN';
export const AGENCY_CAMPAIGN_DASHBOARD_NAMESPACE = 'AGENCY-CAMPAIGN-DASHBOARD';

export enum AgentTypeStatusEnum {
  PENDING = 'pendingApproval',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PAUSED = 'paused',
  CANCELED = 'canceled',
  DRAFT = 'draft',
}

export enum WorkflowApprovalStatusEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}
