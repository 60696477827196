import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    borderRadius: 5,
    margin: theme.spacing(1),
  },
  error: {
    backgroundColor: 'red',
  },
  btn: {
    '--active-color': '#E8192C',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '536px',
    marginBottom: 24,
    fontSize: '16px',
    fontWeight: 500,
    borderColor: '#F0F0F0',
  },
  active: {
    borderWidth: 2,
    borderColor: 'var(--active-color)',
    backgroundColor: 'rgba(232, 25, 44, 0.04)',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
