import React, { memo, useMemo } from 'react';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { useSlaTimeCounter } from './sla-time-counter.hook';
import { useStyles } from './sla-time-counter.style';
import { useTranslation } from '../../../../../common/hooks/translation.hook';
import { Lead } from '../../../types/types';

type ComponentProps = ParamsProps & {
  slaDate: string;
  item: Lead;
};

export const SlaTimeCounterComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // i18n
  const { t: Translation } = useTranslation();

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { time, show, isExpired, isAccepted, isAcceptExpired } = useSlaTimeCounter(props);

  return show ? (
    <div className={styles.content}>
      {isAccepted
        ? isAcceptExpired
          ? Translation('common_expired')
          : '-'
        : isExpired
          ? Translation('common_expired')
          : Translation('lead.listItem.sla', time)}
    </div>
  ) : null;
});
