import React from 'react';
import { Typography } from '@mui/material';
import { formatDate, DateFormat } from 'src/app/common/utils';
import { GenderMap, ProspectSourceTypesConfig } from '../constants';
import { ProspectName } from '../pages/components/prospect-name/prospect-name.component';
import { ProspectType } from '../pages/components/prospect-type/prospect-type.component';
import { Prospect, ProspectSourceTypes, ProspectCategory } from '../types/prospect-types';
import { getDateKey } from './purge-prospect.util';
import { Category } from '../pages/components/category/category.component';

export const renderProspectName = (currValue: string, item: Prospect, keyword?: string) => (
  <ProspectName displayName={currValue} item={item} keyword={keyword} />
);

export const renderDeleteDate = (currValue: string, styles: Record<string, any>) => (
  <Typography className={styles.majorRed}>{getDateKey(new Date(currValue))}</Typography>
);

export const renderGender = (currValue: 'F' | 'M', t: (val: string) => string) => {
  const key = GenderMap[currValue];
  return key ? t(GenderMap[currValue]) : currValue ?? '';
};

export const renderTag = (currValue: string[]) => <ProspectType currValue={currValue} />;

export const renderSource = (currValue: string, t: (val: string) => string) => {
  return currValue ? t(ProspectSourceTypesConfig[currValue as keyof typeof ProspectSourceTypes].i18nKey) : '';
};

export const renderCategory = (currValue: string, prospectCategories?: ProspectCategory[]) => (
  <Category currValue={currValue} prospectCategories={prospectCategories} />
);

export const renderDate = (currValue: string) => formatDate(currValue, DateFormat.date);

export const renderDateTime = (currValue: string) => formatDate(currValue);
