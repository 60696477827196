import React, { useState } from 'react';
import { getLOUTemplate, GetLOUParams } from '../../../../../../../common/network/crud/resourceCrud';
import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { useIntl } from 'react-intl';
interface Props {
  remarksItem: any;
  campaignObjId: string | undefined;
}
/** LOU html template: click to display the html in dialog */
export const LouTemplate: React.FC<Props> = ({ remarksItem, campaignObjId }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [open, setOpen] = useState<boolean>(false);
  const [louHtml, setHtml] = useState<string>(`<span> ${Translation('common.loading')} </span>`);

  const getLOU = (item: any) => {
    const param: GetLOUParams = {
      templateId: item?.templateId,
      id: campaignObjId || '',
    };
    if (!param.id || !param.templateId) {
      return;
    }
    getLOUTemplate(param)
      .then((html) => {
        setHtml(html);
      })
      .catch((err) => {
        const errTips = `<span> ${Translation('common.serviceError')} </span>`;
        setHtml(errTips);
      });
  };

  const openRemark = () => {
    setOpen(true);
    getLOU(remarksItem);
  };

  const toggleOpen = () => setOpen(!open);

  return (
    <>
      <div style={louItem} onClick={openRemark}>
        {remarksItem.templateId}
      </div>
      <Dialog
        open={open}
        onClose={toggleOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={dialogHeader} onClick={toggleOpen}>
          <CloseOutlined />
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div dangerouslySetInnerHTML={{ __html: louHtml }} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

const louItem = { textDecoration: 'underline', cursor: 'pointer' };
const dialogHeader = {
  padding: 16,
  textAlign: 'right' as const,
  borderBottom: '1px solid #ccc',
};
