import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  content: {
    color: '#666666',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    overflowWrap: 'anywhere',
  },
}));
