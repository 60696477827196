import React, { memo, useMemo } from 'react';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './customer-filter-table.style';
import ProFilterTable, { RowSelection } from 'src/app/common/components/ProTable';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components/pro-search-filter/pro-search-filter.component';
import { useCustomerFilterTable } from './customer-filter-table.hook';

type ComponentProps = ParamsProps & {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  rowSelection?: RowSelection<any>;
  defaultSelectedRows?: any[];
};

export const CustomerFilterTableComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const { tableColumns, getData, onFilterChange } = useCustomerFilterTable({
    defaultSelectedRows: props.defaultSelectedRows,
  });
  return (
    <>
      <ProFilterTable
        Filter={ProSearchFilter}
        showFilterIcon={false}
        showFilter={true}
        filterSx={{ marginLeft: '24px', paddingTop: '6px' }}
        rowKey="clientCode"
        rowSelection={props.rowSelection}
        columns={tableColumns}
        request={getData}
        enableRefresh={false}
        onFilterChange={onFilterChange}
      />
    </>
  );
});
