import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  sectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    height: 50,
    marginBottom: 8,
    fontWeight: 600,
    alignItems: 'center',
  },
  attachmentContainer: {
    background: '#FFFFFF',
    // width: '300px',
    minHeight: 250,
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  attachmentTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px',
    fontWeight: 600,
    height: '20%',
  },
  mandatory: {
    color: 'red',
  },
  binIcon: {
    width: 20,
    height: 20,
    margin: 10,
  },
  imageContainer: {
    // flex: 1,
    width: 300,
    border: '10px solid #F4F4F4',
    // justifyContent: 'center',
    // alignContent: 'center',
    margin: 10,
    borderRadius: 10,
    background: '#F4F4F4',
    marginRight: 10,
  },
  fileText: {
    textDecoration: 'underline',
    color: '#38527E',
    marginBottom: 8,
    padding: '5px',
  },
  uploadAreaOuterContainer: {
    borderRadius: '8px',
    background: '#F4F4F4',
    display: 'flex',
    flex: 1,
    height: 160,
    width: 280,
  },
  uploadArea: {
    // border: '2px dashed #C1C1C1',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  uploadAreaError: {
    border: '2px dashed #f018a6',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  uploadButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
    paddingLeft: 14,
  },
  viewBtn: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    background: '#F4F4F4',
    justifyContent: 'space-between',
  },
  card: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    background: '#F4F4F4',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
  },
  imgPreview: { width: '72px', height: '72px', textAlign: 'center', borderRadius: 8, objectFit: 'cover' },
  filePreview: {
    width: '100%',
    height: '120px',
    textAlign: 'center',
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
  },
  sectionTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadBtn: {
    width: '100%',
    height: 240,
    backgroundColor: 'rgba(240, 240, 240, 1)',
  },
  uploadBtnTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadBtnTitle: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '30px',
  },
  uploadBtnSubtitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: '#666666',
    marginTop: 8,
  },
  uploadBtnFormat: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '22px',
    color: '#666666',
    marginTop: 8,
  },
  browser: {
    color: 'rgba(235, 58, 52, 1)',
  },
  uploadBtnContainer: {
    width: '100%',
    marginTop: 28,
  },
  previewSpacing: {
    display: 'flex',
    marginTop: 24,
  },
  previewContainer: {
    position: 'relative',
    height: 72,
    width: 72,
    marginRight: 8,
  },
  previewDelBtn: {
    position: 'absolute',
    right: 0,
    zIndex: 800,
  },
  p_10: {
    padding: 10,
  },
}));
