import React, { memo, useMemo } from 'react';
import { FormLabel } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './list-filter-date-range-picker.style';
import { useListFilterDateRangePicker } from './list-filter-date-range-picker.hook';
import {
  DateRangePickerType,
  SimpleDateRangePickerComponent,
} from '../../../simple-date-range-picker/simple-date-range-picker.component';

type ComponentProps = ParamsProps & {
  input?: DateRangePickerType;
  fieldTitle: string;
  onChange?: (value: DateRangePickerType) => void;
};

export const ListFilterDateRangePickerComponent: React.FC<ComponentProps> = memo(
  ({ input, fieldTitle, onChange }: ComponentProps) => {
    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    // custom-hook
    const { fieldValue } = useListFilterDateRangePicker({ input });
    return (
      <div className={styles.mt_24}>
        <FormLabel classes={{ root: styles.formLabel }}>{fieldTitle || ''}</FormLabel>
        <div className={`${styles.fullWidth} ${styles.mt_8} ${styles.ml_8}`}>
          <SimpleDateRangePickerComponent
            value={fieldValue}
            onChange={(dateValue) => {
              onChange?.(dateValue);
            }}
          />
        </div>
      </div>
    );
  },
);
