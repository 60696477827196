import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import { Prospect } from '../../../types/prospect-types';
import { RequestData } from 'src/app/common/components/ProTable';
import { useStyles } from './prospect-filter-table.style';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';
import { getColumns, getFilterConfig } from './util/list.util';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { getProspectCategories, getProspects } from './util/api.util';

export type ListPageProps = {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
};

export const useProspectFilterTable = ({ cacheParams }: ListPageProps) => {
  const { t: Translation } = useTranslation();

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const [keyword, setKeyword] = useState(cacheParams?.current?.filterState?.keyword ?? '');

  const locale = useLang();
  const dispatch = useDispatch();

  const { data: prospectCategories } = useRequest(async () => getProspectCategories(locale, dispatch), {
    manual: false,
  });

  const columns = useMemo(
    () => getColumns({ t: Translation, prospectCategories, keyword, styles }),
    [Translation, prospectCategories, keyword, styles],
  );

  const filterConfig = useMemo(
    () => getFilterConfig(Translation, prospectCategories),
    [prospectCategories, Translation],
  );

  const getData = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<RequestData<Prospect>> => getProspects(dispatch, params, sort, filter),
    [dispatch],
  );
  const onFilterChange = useCallback(
    (params: FilterState) => {
      const { keyword } = params.filterState;
      cacheParams && (cacheParams.current = params);
      setKeyword(keyword);
    },
    [cacheParams],
  );

  return {
    Translation,
    filterConfig,
    cacheParams: cacheParams?.current,
    onFilterChange,
    columns,
    getData,
    prospectCategories,
  };
};
