import React, { ComponentProps, memo } from 'react';
import { Autocomplete, CircularProgress, TextField, TextFieldProps } from '@mui/material';

type AutocompleteProps = Partial<ComponentProps<typeof Autocomplete>>;

export type Option = {
  label: string;
  value: string;
};

export interface SearchSelectProps extends AutocompleteProps {
  loading?: boolean;
  options: Option[];
  renderInputProps?: TextFieldProps;
}

export const SearchSelect: React.FC<SearchSelectProps> = memo(
  ({ loading = false, renderInputProps, ...rest }: SearchSelectProps) => {
    return (
      <Autocomplete
        fullWidth
        loading={loading}
        filterOptions={(options) => options}
        renderInput={(params) => {
          return (
            <TextField
              variant="outlined"
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              {...renderInputProps}
            />
          );
        }}
        getOptionLabel={(option: any) => {
          return option.label ?? '';
        }}
        getOptionKey={(option: any) => {
          return option.value ?? '';
        }}
        isOptionEqualToValue={(option: any, value: any) => {
          return option.value === value?.value;
        }}
        {...rest}
      />
    );
  },
);
